import React from 'react';
import {
  Avatar,
  Card, Grid, IconButton, Tooltip, Typography
} from '@material-ui/core';
import { LeafBadge, VisibilityTooltip } from 'components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'polished'; // Docs : https://polished.js.org/docs/
import { API_URL_DOCUMENTS, ROLE_GROUPS } from 'utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faGraduationCap, faMapMarkerAlt, faUserSlash
} from '@fortawesome/pro-regular-svg-icons';
import { pluralize } from 'utils';
import { getSmallDescription, UserHelper } from 'utils/helpers';
import { translate, translateTheme } from 'utils/translation';

const BadgeContainer = styled.div`
  position: absolute;
  top: -15px;
  left: -15px;
  transition: all var(--transitionTime);
`;

const StyledCard = styled(({ color, ...otherProps }) => <Card {...otherProps} />)`
  position: relative;
  display: flex;
  min-height: 200px;
  padding: 1rem;
  overflow: visible !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;

  &:hover {
    text-decoration: none;
    transform: translateY(-5px);

    .badge {
      transform: scale(1.2)
    }

    &:after {
      border-bottom-width: 100px;
      border-right-width: 100px;
    }
  }

  ${(props) => props.color && css`
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-left: 80px solid transparent;
      border-right: 80px solid ${rgba(props.color, 0.5)};
      border-bottom: 80px solid transparent;
      transition: all 0.3s;
    }
  `}

  ${(props) => props.disabled && css`
    background: var(--grey-light) !important;
  `}

  .title {
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
  }

  .description {
    margin-top: 1rem;
    overflow: hidden;
  }
`;

const StyledGrid = styled(Grid)`
  z-index: 10;
`;

export const CardSummary = ({
  index, icon, name, description, logoURL, to, theme, disabled = false,
  location, numberOfDays, isPublished, hasAccess = true, descriptionSize
}) => {
  const canEditContent = UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS);

  return (
    <StyledCard
      color={theme && theme.color}
      component={disabled ? 'div' : Link}
      disabled={disabled}
      to={disabled ? null : to}
    >
      <StyledGrid container direction="column">
        <Grid item style={{ maxWidth: '100%' }}>
          <Grid alignItems="flex-start" container justifyContent="space-between">
            <Grid item xs={10}>
              <Tooltip title={name}>
                <Typography className="title" component="h3" noWrap>
                  {icon}
                  {name}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={2}>
              {canEditContent && (
                hasAccess ? (
                  <VisibilityTooltip isPublic={isPublished} />
                ) : (
                  <Tooltip
                    aria-label={translate('pageModuleDetail.noAccessContent')}
                    title={translate('pageModuleDetail.noAccessContent')}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon={faUserSlash} />
                    </IconButton>
                  </Tooltip>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ maxWidth: '100%', flex: 1 }}>
          <Grid container>
            <Grid item xs={logoURL ? 8 : 12}>
              {theme && (
                <Tooltip title={translateTheme(theme.label)}>
                  <Typography noWrap>
                    <FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '1rem' }} />
                    {translateTheme(theme.label)}
                  </Typography>
                </Tooltip>
              )}

              {numberOfDays && (
                <Typography>
                  <FontAwesomeIcon icon={faClock} style={{ marginRight: '1rem' }} />
                  {`${numberOfDays} ${pluralize(translate('common.day'), numberOfDays)}`}
                </Typography>
              )}

              {location && (
                <Typography>
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '1rem' }} />
                  {location.label}
                </Typography>
              )}

              {description && (
                <Tooltip title={description}>
                  <Typography className="description" variant="body2">
                    {getSmallDescription(description, descriptionSize)}
                  </Typography>
                </Tooltip>
              )}

              {!(index == null) && (
                <BadgeContainer className="badge">
                  <LeafBadge value={index + 1} />
                </BadgeContainer>
              )}
            </Grid>
            {logoURL && (
              <Grid item xs={4}>
                <Grid alignItems="center" container justifyContent="center" style={{ height: '100%' }}>
                  <Avatar
                    alt="logo"
                    src={`${API_URL_DOCUMENTS}${logoURL}`}
                    style={{ width: '70px', height: '70px' }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="flex-end">
          {isPublic && (
            <Grid item>
              <Chip
                label={translate('common.freeAccess')}
                size="small"
              />
            </Grid>
          )}
          {canRegister && (
            <Grid item>
              <Chip
                label={translate('common.canRegister')}
                size="small"
              />
            </Grid>
          )}
          {canSubscribe && (
            <Grid item>
              <Chip
                label={translate('common.canSubscribe')}
                size="small"
              />
            </Grid>
          )}
        </Grid> */}
      </StyledGrid>
    </StyledCard>
  );
};

CardSummary.propTypes = {
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  descriptionSize: PropTypes.number,
  icon: PropTypes.shape({}),
  index: PropTypes.number,
  logoURL: PropTypes.string,
  name: PropTypes.string.isRequired,
  theme: PropTypes.shape({}),
  to: PropTypes.string.isRequired
};

CardSummary.defaultProps = {
  description: '',
  descriptionSize: 50,
  icon: null,
  index: undefined,
  logoURL: '',
  theme: null
};