import React from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';


const StyledEditionPanel = styled(Grid)`
  height: calc(100vh - var(--header-with-edition-bar-height) - var(--showMore-height));
  overflow-y: auto;
  padding: 0 2rem;
  border-right: 1px solid var(--grey-light);
  ${(props) => props.edition !== 'true' && css`display: none;`}
`;


export const EditionPanel = ({ children, editionMode }) => (
  <StyledEditionPanel edition={editionMode.toString()} item xs={3}>
    {children}
  </StyledEditionPanel>
);


EditionPanel.propTypes = {
  editionMode: PropTypes.bool.isRequired
};