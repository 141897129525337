import React, { useCallback, useEffect, useState } from 'react';
import {
  faArrowLeft, faFileExport, faFileImport, faSpinner, faUsersMedical
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonContainer, DropdownButton } from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { useModal } from 'hooks';
import { SubscriptionService } from 'services';
import { translate } from 'utils/translation';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { ROUTES } from 'utils/constants';

export const TraineeListNav = ({
  search, filters, reloadTraineeList, contentInfos, traineeList
}) => {
  const showModal = useModal();
  const { content, contentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isExporting, setIsExporting] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const toggleExportTraineesButton = useCallback(() => {
    if (traineeList.length > 0) {
      return setIsButtonDisabled(false);
    }
    return setIsButtonDisabled(true);
  }, [traineeList]);

  useEffect(() => {
    toggleExportTraineesButton();
  }, [toggleExportTraineesButton]);

  const handleSubscribeTrainees = useCallback(() => showModal({
    type: 'SUBSCRIBE_TRAINEES',
    title: translate('button.subscribeTrainees'),
    onConfirm: (accessRequest) => {
      SubscriptionService.createSubscription({ ...accessRequest, [`${content}Id`]: contentId }).then(() => {
        enqueueSnackbar(translate('confirms.subscribeTrainees'), { variant: 'success' });
        reloadTraineeList();
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    }
  }), [showModal, content, contentId, reloadTraineeList, enqueueSnackbar]);

  const handleExportTrainees = useCallback(() => {
    setIsExporting(true);

    const searchFilters = {
      freeSearch: search
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const filt of filters) {
      searchFilters[filt.key] = filt.label;
    }

    SubscriptionService.exportTrainees({
      filters: searchFilters,
      programId: content === 'program' ? (contentInfos && contentInfos.id) : null,
      moduleId: content === 'module' ? (contentInfos && contentInfos.id) : null
    })
      .then(() => enqueueSnackbar(translate('confirms.exportTraineesIsOnGoing'), { variant: 'success', autoHideDuration: 5000 }))
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 5000 }))
      .finally(() => setIsExporting(false));
  }, [enqueueSnackbar, search, filters, contentInfos, content]);

  const handleImportSubscriptions = useCallback(() => showModal({
    type: 'IMPORT_FILE',
    isAsync: true,
    programId: content === 'program' ? (contentInfos && contentInfos.id) : null,
    moduleId: content === 'module' ? (contentInfos && contentInfos.id) : null,
    onConfirm: () => {
      enqueueSnackbar(translate('confirms.importTraineesIsOnGoing'), { variant: 'success' });
      reloadTraineeList();
    }
  }),
  [showModal, content, contentInfos, enqueueSnackbar, reloadTraineeList]);

  const getBackLink = useCallback(() => {
    const BL = {};

    switch (content) {
      case 'module':
        BL.name = translate('menu.backToModule');
        BL.url = ROUTES.MODULE(contentId);
        break;

      case 'program':
      default:
        BL.name = translate('menu.backToProgram');
        BL.url = ROUTES.PROGRAM(contentId);
        break;
    }

    return BL;
  }, [content, contentId]);

  const backLink = getBackLink();

  return (
    <HeaderEditionNav fullWidth>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid item>
          <Tooltip aria-label={backLink.name} placement="right" title={backLink.name}>
            <Link to={backLink.url}>
              <IconButton aria-label={backLink.name} color="primary">
                <FontAwesomeIcon icon={faArrowLeft} size="xs" />
              </IconButton>
            </Link>
          </Tooltip>
        </Grid>
        <Grid item>
          <ButtonContainer>
            {contentInfos && true && (
              <>
                <DropdownButton
                  color="primary"
                  icon={faFileImport}
                  label="pageProgramDetail.importTrainees"
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <a
                        href={`${process.env.PUBLIC_URL}/assets/files/import-stagiaires.xlsx`}
                        title="Fichier d'exemple pour l'import d'abonnements"
                      >
                        {translate('common.exampleFile')}
                      </a>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<FontAwesomeIcon icon={faFileImport} />}
                        type="secondary"
                        onClick={handleImportSubscriptions}
                      >
                        {translate('button.importSubscriptions')}
                      </Button>
                    </Grid>
                  </Grid>
                </DropdownButton>
              </>
            )}
            <Button
              startIcon={<FontAwesomeIcon icon={faUsersMedical} />}
              type="secondary"
              onClick={handleSubscribeTrainees}
            >
              {translate('button.subscribeTrainees')}
            </Button>
            {isButtonDisabled
              ? (
                <Tooltip title={translate('errors.noTraineesToExport')}>
                  <span>
                    <Button
                      disabled={isButtonDisabled}
                      startIcon={(
                        <FontAwesomeIcon
                          icon={faFileExport}
                        />
                      )}
                      type="secondary"
                      onClick={handleExportTrainees}
                    >
                      {translate('button.exportTrainees')}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button
                  disabled={isButtonDisabled}
                  startIcon={(
                    <FontAwesomeIcon
                      icon={isExporting ? faSpinner : faFileExport}
                      spin={isExporting}
                    />
                  )}
                  type="secondary"
                  onClick={handleExportTrainees}
                >
                  {translate('button.exportTrainees')}
                </Button>
              )}

          </ButtonContainer>
        </Grid>
      </Grid>

    </HeaderEditionNav>
  );
};