/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar, Grid, IconButton, TableCell
} from '@material-ui/core';
import {
  GenericFilters, GenericTable, PageHeader,
  SkeletonTable, Text, TextError, Wrapper
} from 'components';
import { observer } from 'mobx-react-lite';
import { UserHelper } from 'utils';
import shortid from 'shortid';
import { useParams } from 'react-router-dom';
import { ModuleService, ProgramService, SubscriptionService } from 'services';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useModal, useStores } from 'hooks';
import { API_URL_DOCUMENTS, ROLE_GROUPS } from 'utils/constants';
import { translate } from 'utils/translation';
import { TraineeListFilters } from './TraineeListFilters';
import { TraineeListNav } from './TraineeListNav';

const getTraineeListHeaders = (handleEditTrainee, handleDeleteTrainee) => ([
  {
    name: 'name',
    label: 'common.name',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {`${row.lastName} ${row.firstName}`}
      </TableCell>
    )
  }, {
    name: 'email',
    label: 'common.email',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.traineeEmail || '-'}
      </TableCell>
    )
  }, {
    name: 'startDate',
    label: 'forms.module.sessionModal.startDate',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {(row.startDate && new Date(row.startDate).toLocaleDateString()) || '-'}
      </TableCell>
    )
  }, {
    name: 'endDate',
    label: 'forms.module.sessionModal.endDate',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {(row.endDate && new Date(row.endDate).toLocaleDateString()) || '-'}
      </TableCell>
    )
  }, {
    name: 'edit',
    label: 'button.edit',
    width: '10px',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <IconButton
          color="primary"
          edge="end"
          onClick={(e) => { e.stopPropagation(); handleEditTrainee(row); }}
        >
          <FontAwesomeIcon icon={faEdit} size="xs" />
        </IconButton>
      </TableCell>
    )
  }, {
    name: 'delete',
    label: 'button.delete',
    width: '10px',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <IconButton
          edge="end"
          onClick={(e) => { e.stopPropagation(); handleDeleteTrainee(row); }}
        >
          <FontAwesomeIcon color="var(--danger-color)" icon={faTrashAlt} size="xs" />
        </IconButton>
      </TableCell>
    )
  }
]);

const TraineeList = observer(() => {
  const { userStore } = useStores();
  const { isConnected } = userStore;
  const { content, contentId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();

  const [traineeList, setTraineeList] = useState([]);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [totalTrainees, setTotalTrainees] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingContentInfos, setIsLoadingContentInfos] = useState(false);
  const [contentInfos, setContentInfos] = useState(null);

  const filterKey = 'traineeList';

  const getTraineeList = useCallback(() => {
    if (currentPage > maxPage) return false;

    setIsLoading(true);

    const handleResponse = (resp) => {
      if (search || currentPage === 0) {
        setTraineeList(resp.content);
      } else {
        setTraineeList([...traineeList, ...resp.content]);
      }
      setMaxPage(resp.totalPages > 0 ? resp.totalPages - 1 : 0);
      setTotalTrainees(resp.totalElements);
    };

    const searchFilters = {
      freeSearch: search
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const filt of filters) {
      searchFilters[filt.key] = filt.label;
    }

    switch (content) {
      case 'module':
        return SubscriptionService.getTraineesListForModule({
          contentId, filters: searchFilters, page: currentPage
        })
          .then((resp) => handleResponse(resp))
          .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
          .finally(() => setIsLoading(false));
      case 'program':
      default:
        return SubscriptionService.getTraineesListForProgram({
          contentId, filters: searchFilters, page: currentPage
        })
          .then((resp) => handleResponse(resp))
          .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
          .finally(() => setIsLoading(false));
    }
  }, [enqueueSnackbar, traineeList, search, filters, currentPage, maxPage, content, contentId]);

  // Get associated content to the subscriptions
  useEffect(() => {
    setIsLoadingContentInfos(true);

    switch (content) {
      case 'module':
        ModuleService.getModule('', contentId)
          .then((resp) => setContentInfos(resp))
          .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
          .finally(() => setIsLoadingContentInfos(false));
        break;
      case 'program':
      default:
        ProgramService.getProgram(contentId)
          .then((resp) => setContentInfos(resp))
          .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
          .finally(() => setIsLoadingContentInfos(false));
        break;
    }
  }, [content, contentId, enqueueSnackbar]);

  const refreshTraineeList = useCallback(() => {
    if (currentPage === 0) {
      getTraineeList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, getTraineeList]);

  useEffect(() => {
    refreshTraineeList();
  // eslint-disable-next-line
  }, [search, filters]);

  useEffect(() => {
    getTraineeList();
  // eslint-disable-next-line
  }, [currentPage]);

  const loadMore = useCallback(() => {
    !isLoading && setCurrentPage(currentPage + 1);
  }, [isLoading, currentPage]);

  const handleEditTrainee = useCallback((subscription) => showModal({
    type: 'UPDATE_SUBSCRIPTION',
    defaultValues: subscription,
    onConfirm: (dates) => {
      SubscriptionService.updateSubscription(subscription.id, dates)
        .then(() => {
          enqueueSnackbar(translate('confirms.subscriptionUpdated'), { variant: 'success' });
          refreshTraineeList();
        })
        .catch(() => enqueueSnackbar(translate('errors.subscriptionUpdateFailed'), { variant: 'error' }));
    }
  }), [enqueueSnackbar, refreshTraineeList, showModal]);

  const handleDeleteTrainee = useCallback((subscription) => showModal({
    type: 'WARNING',
    buttonConfirm: 'button.deleteSubscription',
    text: 'warnings.subcriptionDelete',
    onConfirm: () => {
      SubscriptionService.deleteSubscription(subscription.id)
        .then(() => {
          enqueueSnackbar(translate('confirms.subscriptionDeleted'), { variant: 'success' });
          refreshTraineeList();
        })
        .catch(() => enqueueSnackbar(translate('errors.subscriptionDeleteFailed'), { variant: 'error' }));
    }
  }), [showModal, refreshTraineeList, enqueueSnackbar]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <TraineeListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  return (
    <>
      {isConnected && UserHelper.hasAccessRight(ROLE_GROUPS.ADMINISTRATORS) && (
        <TraineeListNav
          contentInfos={contentInfos}
          filters={filters}
          reloadTraineeList={refreshTraineeList}
          search={search}
          traineeList={traineeList}
        />
      )}

      <Wrapper>
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
          <Grid item>
            <PageHeader title="pageTrainee.title" />
            <Text as="h3" color="var(--colored-subtitle-color)" component="h2" margin="-4rem auto 2rem auto" textAlign="center">
              <Grid container spacing={1}>
                <Grid item>
                  {translate('pageTrainee.subtitle', { content: content === 'program' ? 'programme' : 'module' })}
                </Grid>
                {isLoadingContentInfos && (
                  <Grid item>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </Grid>
                )}
                {!isLoadingContentInfos && contentInfos && (
                  <>
                    {contentInfos.logoURL && (
                      <Grid item>
                        <Avatar src={`${API_URL_DOCUMENTS}${contentInfos.logoURL}`} />
                      </Grid>
                    )}
                    <Grid item>
                      {contentInfos.name}
                    </Grid>
                  </>
                )}
              </Grid>
            </Text>
          </Grid>
        </Grid>

        <GenericFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-catalog-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setCurrentPage={setCurrentPage}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltip="pageTrainee.searchTooltip"
          withDrawer
        />

        {!isLoading && traineeList.length === 0 && (
          <TextError>{translate('errors.noSubscriptionList')}</TextError>
        )}

        {isLoading && traineeList.length === 0 ? (
          <SkeletonTable />
        ) : traineeList.length > 0 && (
          <GenericTable
            hasMore={currentPage < maxPage}
            headers={getTraineeListHeaders(handleEditTrainee, handleDeleteTrainee)}
            id={shortid.generate()}
            loadMore={loadMore}
            rows={traineeList}
            total={totalTrainees}
          />
        )}
      </Wrapper>
    </>
  );
});

export default TraineeList;