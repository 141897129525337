import {
  arrayOf, bool, exact, number, oneOfType, shape, string
} from 'prop-types';


export const selectItemModel = shape({
  value: string,
  label: string
});

export const documentElementFormModel = shape({
  name: string
});

export const quizElementFormModel = shape({
  name: string
});

export const documentElementModel = shape({
  name: string,
  url: string
});

export const quizElementModel = shape({
  id: string,
  title: string,
  description: string,
  questions: arrayOf(shape({})),
  shuffle: bool
});

export const imageElementFormModel = shape({
  title: string,
  legend: string
});

export const imageElementModel = exact({
  url: string,
  title: string,
  legend: string
});

export const textElementFormModel = shape({
  subtitle: string,
  text: string
});

export const textElementModel = shape({
  subtitle: string,
  text: string
});

export const genericSectionFormModel = shape({
  index: number,
  format: string
});

export const videoElementFormModel = shape({
  url: string,
  title: string,
  legend: string
});

export const videoElementModel = shape({
  url: string,
  title: string,
  legend: string
});

export const elementModel = oneOfType([
  textElementModel,
  imageElementModel,
  videoElementModel,
  documentElementModel
]);

export const moduleSummariesModel = shape({
  id: string,
  index: number,
  logoURL: string,
  name: string,
  resume: string
});

export const sectionModel = shape({
  index: number.isRequired,
  sectionType: string.isRequired,
  format: string,
  title: string,
  backgroundImage: string,
  elements: arrayOf(oneOfType([
    textElementFormModel,
    imageElementFormModel,
    videoElementFormModel,
    documentElementFormModel
  ]))
});

export const contentModel = shape({
  sections: arrayOf(sectionModel)
});

export const programModel = shape({
  id: string,
  index: number,
  name: string,
  description: string,
  logoURL: string,
  isPublic: bool,
  isFree: bool,
  moduleSummaries: arrayOf(moduleSummariesModel),
  content: contentModel
});

export const moduleModel = shape({
  sections: arrayOf(sectionModel)
});

export const sequenceModel = shape({
  moduleId: string,
  id: string,
  index: number,
  name: string,
  description: string,
  logoURL: string,
  isPublic: bool,
  isFree: bool,
  moduleSummaries: arrayOf(moduleSummariesModel),
  content: contentModel
});