import React from 'react';
import {
  Banner, Button, Text, Wrapper
} from 'components';
import { Grid } from '@material-ui/core';
import { ROUTES } from 'utils/constants';
import { Link } from 'react-router-dom';
import { translate } from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/pro-regular-svg-icons';

export const Ecler = () => (
  <>
    <Banner backgroundImage={`${process.env.PUBLIC_URL}/assets/images/bg/ecler_bg.png`}>
      <Text as="h1">
        <strong>Bienvenue</strong>
        {' sur la plateforme'}
        <br />
        de
        <strong>
          {' Formation à distance'}
        </strong>
      </Text>

      <Link to={ROUTES.CATALOG}>
        <Button margin="2rem 0 0 0" startIcon={<FontAwesomeIcon icon={faBook} />}>{translate('button.exploreCatalog')}</Button>
      </Link>
    </Banner>
    <Wrapper>
      <Text as="h2" margin="4rem 0" textAlign="center">
        {translate('pageHome.ecler.title')}
      </Text>

      <Grid container spacing={3} style={{ textAlign: 'justify' }}>
        <Grid item>
          {translate('pageHome.ecler.alimentarySecurity')}
        </Grid>
        <Grid item>
          {translate('pageHome.ecler.goodPractice')}
        </Grid>
        <Grid item>
          {translate('pageHome.ecler.trainingOffers')}
          <ul style={{ marginBottom: 0 }}>
            <li>{translate('pageHome.ecler.trainingList.jobAndUsingColdFormation')}</li>
            <li>{translate('pageHome.ecler.trainingList.reglementationFormation')}</li>
            <li>{translate('pageHome.ecler.trainingList.securityFormation')}</li>
          </ul>
        </Grid>
        <Grid item>
          {translate('pageHome.ecler.accompanyingMesure')}
        </Grid>
        <Grid item>
          {translate('pageHome.ecler.digitalTransformation')}
        </Grid>
        <Grid item>
          {translate('pageHome.ecler.availabilityForHelping')}
        </Grid>
      </Grid>
    </Wrapper>
  </>
);