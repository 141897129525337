import { programHeader } from './section';

const program = {
  id: '1',
  index: 1,
  name: 'Programme ECLER',
  description: `Le programme d’économies d’énergie ECLER (Economie Circulaire et Logistique Ecologique et Responsable), piloté par
   le Groupe Cemafroid Tecnea, a été retenu par les Ministères de la Transition Écologique et Solidaire, et de l’Agriculture et de
    l’Alimentation dans le cadre du dispositif des Certificats d’Économies d’Énergie(CEE) avec pour objectif d'effectuer des
     économies d'énergie à grande échelle dans le transport frigorifique.

    Parce que le transport est avant tout un métier de femmes et d’hommes, le programme repose dans un premier temps sur la
    formation de 10 000 conducteurs de véhicules frigorifiques aux bonnes pratiques d’utilisation du froid. Il prévoit
    également le marquage des 135 000 camions de transport sous température dirigée français qui arboreront les gestes
    clés de la logistique écologique et responsable. Enfin, près de 10 000 camions frigorifiques se verront également
    équipés d'outils digitaux.`,
  logoURL: '7bc6a287-37ba-4d88-9914-53176de1b871',
  isPublic: true,
  isPublished: false,
  moduleSummaries: [
    {
      id: '1',
      index: 1,
      logoURL: 'b22a72b5-b3cd-4a0c-9fb3-d333f9c3ea70',
      name: 'Les bonnes pratiques',
      description: 'Apprenez les bonnes pratiques du transport frigorifique'
    },
    {
      id: '2',
      index: 2,
      logoURL: '',
      name: 'Module 2',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '3',
      index: 3,
      logoURL: '',
      name: 'Module 3',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '4',
      index: 4,
      logoURL: '',
      name: 'Module 4',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '5',
      index: 5,
      logoURL: '',
      name: 'Module 5',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '6',
      index: 6,
      logoURL: '',
      name: 'Module 6',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '7',
      index: 7,
      logoURL: '',
      name: 'Module 7',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '8',
      index: 8,
      logoURL: '',
      name: 'Module 8',
      description: 'Apprenez tout sur ...'
    },
    {
      id: '9',
      index: 9,
      logoURL: '',
      name: 'Module 9',
      description: 'Apprenez tout sur ...'
    }
  ],
  content: {
    sections: [
      programHeader(0, 'Programme ECLER')
    ]
  }
};

export default program;