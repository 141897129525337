import { Button } from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import { Grid } from '@material-ui/core';

export const AddSequences = observer(({
  type, formData
}) => {
  const { editionStore } = useStores();

  const editMe = useCallback((event) => {
    editionStore.setElementEdited('section_0', formData, type, null, false);
    event.stopPropagation();
  }, [editionStore, formData, type]);

  return (
    <Grid container justifyContent="center">
      <Button size="large" onClick={editMe}>
        {translate('pageModuleDetail.addSequences')}
      </Button>
    </Grid>
  );
});

AddSequences.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired
};