import {
  BlueBlock, EditionContent, EditionPanel, ParentForm, SkeletonMain
} from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { UserHelper } from 'utils';
import { ParentSection } from 'components/Templates';
import { EDITION_PANEL_TYPES, ROLE_GROUPS, ROUTES } from 'utils/constants';
import { Grid } from '@material-ui/core';
import { SectionTitle } from 'components/Templates/sections/_commons';
import { useSnackbar } from 'notistack';
import { ModuleEditionNav } from './ModuleEditionNav';
import { AddSequences } from './AddSequences';
import { EducationalSheet } from './EducationalSheet';

export const ModuleDetail = observer(({
  programId, moduleId, isEditionMode, defaultViewMode
}) => {
  const { userStore, moduleStore, editionStore } = useStores();
  const { isConnected } = userStore;
  const { editionMode, elementEdited } = editionStore;
  const [isTempEditionMode, setIsTempEditionMode] = useState(isEditionMode);
  const { enqueueSnackbar } = useSnackbar();

  const [viewMode, setViewMode] = useState(defaultViewMode);

  const elementId = EDITION_PANEL_TYPES.MODULE;

  const canEditContent = UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS);
  const canAdministrate = UserHelper.hasAccessRight(ROLE_GROUPS.ADMINISTRATORS);

  const {
    content: { sections } = {}, name, logoURL, canSubscribe, isPublic
  } = moduleStore.module;

  const loadModule = useCallback(() => moduleStore.loadModule(programId, moduleId)
    .catch((err) => enqueueSnackbar(err.message, { variant: 'error' })), [moduleStore, programId, moduleId, enqueueSnackbar]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    !isEditionMode && editionStore.reset();

    loadModule().then(() => {
      // Redirect to the educational sheet if the user tries to access the detail while unauthorized
      if (viewMode === 'moduleDetail' && !moduleStore.module.hasAccess) {
        window.location.href = programId ? ROUTES.PROGRAM_MODULE(programId, moduleId) : ROUTES.MODULE(moduleId);
      }
      editionStore.setElementEdited(elementId, moduleStore.module, EDITION_PANEL_TYPES.MODULE, null, true);
      // Already saved with no changes when opening edition mode
      editionStore.setLastSaved();
    });
    // eslint-disable-next-line
  }, [isEditionMode, editionStore, loadModule]);

  useEffect(() => {
    isTempEditionMode && editionStore.setElementEdited(elementId, moduleStore.module, EDITION_PANEL_TYPES.MODULE, null, true);
  }, [moduleStore.module, editionStore, elementId, isTempEditionMode]);

  const editModule = useCallback(() => {
    if (editionStore.hasNewEditedElement(elementId)) {
      editionStore.setElementEdited(elementId, moduleStore.module, EDITION_PANEL_TYPES.MODULE, null, true);
    }
  }, [editionStore, moduleStore.module, elementId]);

  const editModuleHeaderSection = useCallback(() => {
    if (sections && sections.length) {
      editionStore.setElementEdited('_section_0', sections[0], 'section', null, false);
    }
  }, [editionStore, sections]);

  if (moduleStore.isLoading) {
    return <SkeletonMain />;
  }

  return (
    <section data-testid="sections">
      {isConnected && canEditContent && (
        <ModuleEditionNav
          allowTraineeManagement={!isPublic && canSubscribe && canAdministrate}
          isEditionMode={isTempEditionMode}
          loadModule={loadModule}
          setIsTempEditionMode={setIsTempEditionMode}
          setViewMode={setViewMode}
          viewMode={viewMode}
          onClickDetail={editModuleHeaderSection}
          onEdit={editModule}
        />
      )}

      <Grid container>
        <EditionPanel editionMode={editionMode}>
          <br />
          {isConnected && editionMode && elementEdited && elementEdited.elementId
            && <ParentForm {...elementEdited} />}
        </EditionPanel>
        <EditionContent
          editionMode={editionMode}
          elementId={elementId}
          onClick={editModule}
        >
          {viewMode === 'educationalSheet' ? (
            <EducationalSheet editionMode={editionMode} setViewMode={setViewMode} />
          ) : (
            <>
              <BlueBlock
                discreet={!editionMode}
                focused={elementEdited && elementEdited.elementId === elementId}
                isParams
              >
                <SectionTitle
                  background="var(--blue-light)"
                  logoURL={logoURL}
                  margin="1rem 2rem"
                  textAlign="center"
                  title={name}
                />
              </BlueBlock>
              {sections && sections.length > 0 && sections.map((section) => (
                <ParentSection
                  editionMode={editionMode}
                  key={section.index}
                  module={moduleStore.module}
                  section={section}
                />
              ))}
              {(!sections || sections.length === 0) && editionMode && (
                <AddSequences
                  formData={{ sectionType: 'MODULE_HEADER', format: 'MODULE_2_2' }}
                  type="section"
                />
              )}
            </>
          )}
        </EditionContent>
      </Grid>
    </section>
  );
});

ModuleDetail.propTypes = {
  programId: PropTypes.string,
  moduleId: PropTypes.string.isRequired,
  isEditionMode: PropTypes.bool
};

ModuleDetail.defaultProps = {
  isEditionMode: false,
  programId: ''
};