import { faArrowCircleLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import { useModal, useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import {
  documentElementFormModel, imageElementFormModel, textElementFormModel, videoElementFormModel
} from 'components/model/dataformModel';
import { DocumentElementForm } from '../DocumentElementForm';
import { EmptyElementForm } from '../EmptyElementForm';
import { ImageElementForm } from '../ImageElementForm';
import { TextElementForm } from '../TextElementForm';
import { VideoElementForm } from '../VideoElementForm';
import { QuizElementForm } from '../QuizElementForm';

const NO_CHANGE_ELEMENT = [
  'EMPTY_ELEMENT',
  'DOCUMENT_ELEMENT',
  'QUIZ_ELEMENT'
];

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
export const ParentElementForm = observer(({ formData }) => {
  const { editionStore } = useStores();
  const { elementType, element } = formData;
  const showModal = useModal();

  const resetElement = useCallback(() => {
    runInAction('resetElementFromElementForm', () => {
      formData.element = null;
      formData.elementType = null;
    });
  }, [formData.element, formData.elementType]);

  const showConfirmationModal = useCallback(() => showModal({
    type: 'WARNING',
    title: 'forms.element.titleConfirmElementTypeChange',
    text: 'forms.element.askConfirmationForElementTypeChange',
    onConfirm: resetElement
  }), [showModal, resetElement]);

  const deleteElement = useCallback(() => {
    editionStore.deleteFocusedElement();
    const rootElement = editionStore.rootElementEdited;
    editionStore.setElementEdited(rootElement.elementId, rootElement.data, rootElement.type, null, true);
    editionStore.setLastSaved(false);
  }, [editionStore]);

  const getSpecificElementForm = () => {
    switch (elementType) {
      case 'TEXT_ELEMENT':
        return <TextElementForm formData={element} />;
      case 'IMAGE_ELEMENT':
        return <ImageElementForm formData={element} />;
      case 'VIDEO_ELEMENT':
        return <VideoElementForm formData={element} />;
      case 'DOCUMENT_MAIN':
        return <DocumentElementForm formData={element} isMain onDelete={deleteElement} />;
      case 'DOCUMENT_ELEMENT':
        return <DocumentElementForm formData={element} onDelete={deleteElement} />;
      case 'QUIZ_ELEMENT':
        return <QuizElementForm formData={element} onDelete={deleteElement} />;
      case 'EMPTY_ELEMENT':
      default:
        return <EmptyElementForm formData={formData} />;
    }
  };

  return (
    <>
      {getSpecificElementForm()}
      {elementType && !NO_CHANGE_ELEMENT.includes(elementType) && (
        <Button
          margin="2rem 0 0 0"
          startIcon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
          type="danger"
          variant="text"
          onClick={showConfirmationModal}
        >
          {translate('forms.element.changeElementType')}
        </Button>
      )}
    </>
  );
});

ParentElementForm.propTypes = {
  formData: PropTypes.oneOfType([
    textElementFormModel,
    imageElementFormModel,
    videoElementFormModel,
    documentElementFormModel
  ]).isRequired
};