/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { useFormState } from 'react-use-form-state'; // https://github.com/wsmd/react-use-form-state
import { Datepicker } from 'components';
import {
  Button, DialogActions, DialogContent, Grid
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FormHelper } from 'utils/helpers';
import ModalHeader from './_ModalHeader';

const UpdateSubscriptionModal = observer(({ onClose, onConfirm, defaultValues }) => {
  const [formState, { raw }] = useFormState({ ...defaultValues });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onSubmit = useCallback((event) => {
    event.preventDefault();

    const form = {
      ...formState.values,
      startDate: FormHelper.formatDateForBackend(formState.values.startDate),
      endDate: FormHelper.formatDateForBackend(formState.values.endDate)
    };

    onConfirm(form);
    formState.reset();
    onClose();
  }, [onConfirm, onClose, formState]);

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader onClose={onClose}>
        {translate('modals.updateSubscription.title')}
      </ModalHeader>

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Datepicker
              autoComplete="off"
              clearable
              dataCy="startDate"
              disablePast
              error={formState.errors.startDate && formState.errors.startDate !== null}
              fullWidth
              label="forms.module.sessionModal.startDate"
              maxDate={formState.values.endDate || undefined}
              required
              style={{ marginBottom: '1rem' }}
              {...raw({
                name: 'startDate',
                compare(_, value) {
                  return value !== null;
                },
                validate(_, values) {
                  const today = new Date().setHours(0, 0, 0, 0);
                  const isNanDate = values.startDate && Number.isNaN(new Date(values.startDate).getDate());

                  if (isNanDate) {
                    setIsButtonDisabled(true);
                    return 'Invalid';
                  }

                  const startDateFormatted = new Date(values.startDate).setHours(0, 0, 0, 0);
                  const endDateFormatted = values.endDate && new Date(values.endDate).setHours(0, 0, 0, 0);

                  if (startDateFormatted < today) {
                    setIsButtonDisabled(true);
                    return 'invalid';
                  }

                  if (startDateFormatted > endDateFormatted) {
                    setIsButtonDisabled(true);
                    return 'invalid';
                  }

                  setIsButtonDisabled(false);
                  return '';
                }
              })}
            />
          </Grid>

          <Grid item>
            <Datepicker
              autoComplete="off"
              clearable
              dataCy="endDate"
              disablePast
              error={formState.errors.endDate && formState.errors.endDate !== null}
              fullWidth
              label="forms.module.sessionModal.endDate"
              minDate={formState.values.startDate}
              required
              style={{ marginBottom: '0.3rem' }}
              {...raw({
                name: 'endDate',
                compare(_, value) {
                  return value !== null;
                },
                validate(_, values) {
                  const startDateFormatted = values.startDate && new Date(values.startDate).setHours(0, 0, 0, 0);
                  const isNanDate = values.endDate && Number.isNaN(new Date(values.endDate).getDate());

                  if (isNanDate) {
                    setIsButtonDisabled(true);
                    return 'Invalid';
                  }

                  const endDateFormatted = new Date(values.endDate).setHours(0, 0, 0, 0);
                  if (values.endDate && endDateFormatted < startDateFormatted) {
                    setIsButtonDisabled(true);
                    return 'invalid';
                  }

                  setIsButtonDisabled(false);
                  return '';
                }
              })}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.close')}
        </Button>
        <Button color="primary" disabled={isButtonDisabled} type="submit">
          {translate('button.update')}
        </Button>
      </DialogActions>
    </form>
  );
});

UpdateSubscriptionModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default UpdateSubscriptionModal;