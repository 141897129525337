import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, InputAdornment
} from '@material-ui/core';
import { Text, TextError, TextField } from 'components';
import { DropZoneInput, SelectField } from 'components/forms/inputs';
import { ElementTypeChoice } from 'components/forms/templateForms/choices';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-use-form-state'; // https://github.com/wsmd/react-use-form-state
import { DocumentService } from 'services';
import { DocumentHelper } from 'utils';
import { API_URL_DOCUMENTS, MAX_FILE_SIZE, REF_DATA_MAP } from 'utils/constants';
import { translate } from 'utils/translation';
import { SkeletonFormDocument } from '../Skeletons';
import ModalHeader from './_ModalHeader';

const CreateDocumentFormModal = ({
  text, number, contentType, uploadDocument, getRefData,
  formState, initialFiles, handleSelect, onDelete
}) => {
  const handleDocumentUpload = useCallback((file) => {
    uploadDocument(file);
  }, [uploadDocument]);

  return (
    <Grid container spacing={1}>
      {contentType !== 'VIDEO' && (
        <Grid item xs={12}>
          <DropZoneInput
            acceptedFiles={contentType === 'DOWNLOADABLE' ? ['.pdf'] : ['.jpg', '.jpeg', '.png', '.webp']}
            initialFiles={initialFiles}
            onChange={handleDocumentUpload}
            onDelete={onDelete}
          />
        </Grid>
      )}

      {contentType === 'VIDEO' && (
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            error={formState.errors.url}
            label={translate('common.videoId')}
            name="url"
            required
            tooltip={translate('pageDocumentList.createOrEditDocument.videoTooltip')}
            {...text('url')}
          />
        </Grid>
      )}

      <Grid item sm={6} xs={12}>
        <TextField
          autoComplete="off"
          error={formState.errors.code}
          label={translate('common.code')}
          name="code"
          required
          {...text('code')}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          autoComplete="off"
          error={formState.errors.name}
          label={translate('common.name')}
          name="name"
          required
          {...text('name')}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          error={formState.errors.description}
          label={translate('common.description')}
          minRows={4}
          multiline
          name="description"
          {...text('description')}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          autoComplete="off"
          error={formState.errors.author}
          label={translate('common.author')}
          name="author"
          // required
          {...text('author')}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          autoComplete="off"
          error={formState.errors.duration}
          label={translate('common.durationMin')}
          name="duration"
          {...number('duration')}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="end">min</InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <SelectField
          isMulti={getRefData('ACCESS_RIGHTS').multiple}
          label="referenceData.ACCESS_RIGHTS"
          menuPlacement="top"
          name="accessRights"
          options={getRefData('ACCESS_RIGHTS').values}
          // required
          value={formState.values.accessRights}
          onChange={handleSelect}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <SelectField
          isMulti={getRefData('COPYRIGHT').multiple}
          label="referenceData.COPYRIGHT"
          menuPlacement="top"
          name="copyright"
          options={getRefData('COPYRIGHT').values}
          value={formState.values.copyright}
          onChange={handleSelect}
        />
      </Grid>
    </Grid>

  );
};

const DocumentModal = ({
  onClose, defaultValues, onConfirm
}) => {
  const [documentDetails, setDocumentDetails] = useState(null);
  const [isEditDocument, setIsEditDocument] = useState(defaultValues && defaultValues.id);
  const [contentType, setContentType] = useState();
  const [fileUploaded, setFileUploaded] = useState();
  const [initialFiles, setInitialFiles] = useState([]);
  const [documentError, setDocumentError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(defaultValues && defaultValues.id);
  const { referenceDataStore } = useStores();
  const { enqueueSnackbar } = useSnackbar() ?? {};
  const [formState, { text, number }] = useFormState({ accessRights: null, copyright: null });
  const getRefData = useCallback((key) => referenceDataStore.getRefData(key), [referenceDataStore]);
  const getSelectedDocumentRefData = useCallback((key, value) => {
    const type = REF_DATA_MAP[key];
    return getRefData(type).values.filter((ref) => value === ref.value)[0];
  }, [getRefData]);

  useEffect(() => {
    if (defaultValues && defaultValues.id) {
      setContentType(defaultValues.contentType);
      setIsDocumentLoading(true);

      DocumentService.getDocumentDetails(defaultValues.id).then((response) => {
        setDocumentDetails(response);
      }).finally(() => setIsDocumentLoading(false));
    }
  }, [defaultValues]);

  useEffect(() => {
    if (fileUploaded && fileUploaded.name) {
      const fileName = fileUploaded.name.split('.')[0];
      formState.setField('name', fileName);
    }

    if (documentDetails) {
      Object.entries(documentDetails).forEach(([key, value]) => {
        const ignoredKeys = ['creationDate', 'modificationDate'];
        const refDataKeys = ['accessRights', 'copyright'];
        let localValue = value;
        if (refDataKeys.includes(key)) {
          localValue = getSelectedDocumentRefData(key, value);
        }

        if (!ignoredKeys.includes(key)) {
          formState.setField(key, localValue);
        }
      });

      setInitialFiles([`${API_URL_DOCUMENTS}${documentDetails.id}`]);
    }
  }, [documentDetails, formState, fileUploaded, getSelectedDocumentRefData]);

  const uploadDocument = useCallback((file) => {
    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(translate('warnings.documentIsGoingBig'), { variant: 'warning' });
    }
    setFileUploaded(file);
  }, [enqueueSnackbar]);

  const handleChange = useCallback((_, event) => {
    if (event) {
      const elementType = event.split('_')[0];
      setContentType(elementType);
    }

    setIsEditDocument(!isEditDocument);
    formState.reset();
    setDocumentError(null);
  }, [isEditDocument, formState]);

  const handleDeleteFile = useCallback(() => {
    formState.setField('name', null);
    setFileUploaded(null);
  }, [formState, setFileUploaded]);

  const handleSelect = useCallback((name, value) => {
    formState.setField(name, value);
  }, [formState]);

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (isSubmitting) return false;

    const formData = new FormData();
    Object.entries(formState.values).forEach(([key, value]) => {
      let localValue = value;
      if (value && typeof value === 'object') {
        localValue = value.value;
      }

      return localValue && formData.append(key, localValue);
    });

    if (fileUploaded) {
      const byteFile = await DocumentHelper.getBase64(fileUploaded);
      formData.append('base64Content', byteFile);
      const docType = fileUploaded.type.split('/');
      if (docType.length > 1) {
        const extension = docType[1];
        const oldExtension = formData.get('name').split('.');
        if (oldExtension[oldExtension.length - 1] !== extension) formData.set('name', `${formData.get('name')}.${extension}`);
      }
      formData.set('mimeType', fileUploaded.type);

      if (docType.includes('image')) {
        formData.set('contentType', 'IMAGE');
      } else {
        formData.set('contentType', 'DOWNLOADABLE');
      }
    } else {
      formData.set('contentType', 'VIDEO');
    }

    if (contentType !== 'VIDEO' && !fileUploaded) {
      return enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }

    formData.set('isShared', 'true');
    formData.set('fileType', 'SHARED_DOCUMENT');

    setIsSubmitting(true);
    return onConfirm(formData)
      .then(() => onClose())
      .catch((error) => {
        setDocumentError(error.message);
        throw error.message;
      })
      .finally(() => setIsSubmitting(false));
  }, [formState, fileUploaded, onClose, contentType, enqueueSnackbar, isSubmitting, onConfirm]);

  return (
    <form onSubmit={onSubmit}>
      {isEditDocument && (
        <>
          {typeof isEditDocument === 'string' && (
            <ModalHeader onClose={onClose}>
              {contentType === 'VIDEO'
                ? translate('pageDocumentList.createOrEditDocument.videoTitleEditTitle')
                : translate('pageDocumentList.createOrEditDocument.editTitle')}
            </ModalHeader>
          )}

          {typeof isEditDocument !== 'string' && (
            <ModalHeader onClose={onClose}>
              {contentType === 'VIDEO'
                ? translate('pageDocumentList.createOrEditDocument.videoTitle')
                : translate('pageDocumentList.createOrEditDocument.title')}
            </ModalHeader>
          )}

          <DialogContent style={{ maxWidth: 800 }}>
            {isDocumentLoading ? <SkeletonFormDocument /> : (
              <>
                {documentError && <TextError>{documentError}</TextError>}

                <CreateDocumentFormModal
                  contentType={contentType}
                  formState={formState}
                  getRefData={getRefData}
                  handleSelect={handleSelect}
                  initialFiles={initialFiles}
                  number={number}
                  text={text}
                  uploadDocument={uploadDocument}
                  onDelete={handleDeleteFile}
                />

              </>
            )}
          </DialogContent>

          <DialogActions>
            {!defaultValues && (
              <Button disabled={isSubmitting} onClick={handleChange}>
                {translate('button.cancel')}
              </Button>
            )}

            {defaultValues && (
              <Button disabled={isSubmitting} onClick={onClose}>
                {translate('button.cancel')}
              </Button>
            )}

            <Button color="primary" type="submit" variant="contained">
              {isSubmitting ? (
                <FontAwesomeIcon icon={faSpinner} size="lg" spin />
              ) : translate('button.save')}
            </Button>
          </DialogActions>
        </>
      )}

      {!isEditDocument && (
        <>
          <ModalHeader onClose={onClose}>
            {translate('pageDocumentList.createOrEditDocument.title')}
          </ModalHeader>

          <DialogContent>
            <Text as="h5">{translate('pageDocumentList.createOrEditDocument.choiceTypeSubTitle')}</Text>
            <ElementTypeChoice color="var(--primary-color)" isDocumentAdmin name="elementType" onChange={handleChange} />
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>
              {translate('button.cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </form>
  );
};

DocumentModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DocumentModal;