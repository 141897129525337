/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import PropTypes from 'prop-types';
import { Datepicker, TextField } from 'components';
import { Grid } from '@material-ui/core';

export const TraineeListFilters = ({ currentFilters, setCurrentFilters }) => {
  const lastName = (currentFilters.find((filt) => filt.key === 'lastName')
    && currentFilters.find((filt) => filt.key === 'lastName').label) || '';
  const firstName = (currentFilters.find((filt) => filt.key === 'firstName')
    && currentFilters.find((filt) => filt.key === 'firstName').label) || '';
  const email = (currentFilters.find((filt) => filt.key === 'email')
    && currentFilters.find((filt) => filt.key === 'email').label) || '';
  const startDateValue = (currentFilters.find((filt) => filt.key === 'startDate')
    && currentFilters.find((filt) => filt.key === 'startDate').label) || null;
  const endDateValue = (currentFilters.find((filt) => filt.key === 'endDate')
    && currentFilters.find((filt) => filt.key === 'endDate').label) || null;

  const handleChangeInput = useCallback((event) => {
    const { value, name: filterName } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), { key: filterName, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleChangeDate = ({ date, key }) => {
    if (date && Number.isNaN(date.getDate())) {
      return null;
    }
    return key && setCurrentFilters([...currentFilters.filter((filt) => filt.key !== key), { key, label: date }]);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          autoComplete="off"
          label={translate('common.name')}
          name="lastName"
          value={lastName}
          onChange={handleChangeInput}
        />
      </Grid>

      <Grid item>
        <TextField
          autoComplete="off"
          label={translate('common.firstName')}
          name="firstName"
          value={firstName}
          onChange={handleChangeInput}
        />
      </Grid>

      <Grid item>
        <TextField
          autoComplete="off"
          label={translate('common.email')}
          name="email"
          value={email}
          onChange={handleChangeInput}
        />
      </Grid>

      <Grid item>
        <Datepicker
          autoComplete="off"
          clearable
          dataCy="traineeListFilterStartDate"
          label="forms.module.sessionModal.startDate"
          maxDate={endDateValue}
          value={startDateValue}
          onChange={(date) => handleChangeDate({ date, key: 'startDate' })}
        />
      </Grid>

      <Grid item>
        <Datepicker
          autoComplete="off"
          clearable
          dataCy="traineeListFilterEndDate"
          label="forms.module.sessionModal.endDate"
          minDate={startDateValue}
          value={endDateValue}
          onChange={(date) => handleChangeDate({ date, key: 'endDate' })}
        />
      </Grid>

    </Grid>
  );
};

TraineeListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};