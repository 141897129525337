
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Text } from 'components';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const RadioContainer = styled.div`
  > input {
    display: none;
  }
`;

export const ElementTypeRadio = observer(({
  name, value, label, icon, isChecked, onChange
}) => {
  const inputId = shortid.generate();

  return (
    <RadioContainer>
      <input checked={isChecked(value)} id={inputId} name={name} type="radio" value={value} onChange={onChange} />
      <label htmlFor={inputId}>
        <Paper clickable display="inline-block" textAlign="center" width="100px">
          <FontAwesomeIcon icon={icon} size="3x" />
          <br />
          <Text fontWeight="bold" margin="1rem 0 0 0" textTransform="uppercase">
            {label}
          </Text>
        </Paper>
      </label>
    </RadioContainer>
  );
});