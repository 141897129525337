import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { Wrapper } from 'components';
import { ASSISTANCE_EMAIL_AFF } from 'utils/constants';

const StyledFooter = styled.footer`
  margin-top: 40px;
  text-align: center;

  @media (max-width: 1081px) {
    padding-bottom: 40px;

    .desktop {
      display: none;
    }
  }

  @media (min-width: 1080px) {
    .mobile {
      display: none;
    }
  }
`;

const StyledDivider = styled(Divider)`
  display: inline-block;
  width: 2px;
  background-color: #7e7e7e !important;
  height: 14px !important;
`;

const StyledLogo = styled.img`
  @media (min-width: 1080px) {
    margin-top: -2rem;
  }
`;

export const FooterClimeco = () => (
  <Wrapper>
    <StyledFooter>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={2}
          >
            <Grid item>
              <a href="https://aff-froid.com/a-propos" rel="noopener noreferrer" target="_blank">
                <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                {translate('common.personalData')}
              </a>
            </Grid>

            {!isMobile && (
              <Grid item>
                <StyledDivider orientation="vertical" />
              </Grid>
            )}

            <Grid item>
              <a href={`mailto:${ASSISTANCE_EMAIL_AFF}`}>
                <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                {`${translate('common.assistance')} (${ASSISTANCE_EMAIL_AFF})`}
              </a>
            </Grid>

            {/* <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a
                href={`${URL_DATATECNEA}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIconStyled icon={faLink} />
                {translate('common.datatecneaLink')}
              </a>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item>
          <img alt="AFF_logo" src={`${process.env.PUBLIC_URL}/assets/images/partners/AFF_logo.jpg`} />
        </Grid>

        <Grid item>
          <StyledLogo
            alt={translate('menu.logoAlt')}
            className="desktop"
            src={`${process.env.PUBLIC_URL}/assets/images/bg/footer.png`}
            title={translate('menu.logoAlt')}
          />
          <StyledLogo
            alt={translate('menu.logoAlt')}
            className="mobile"
            src={`${process.env.PUBLIC_URL}/assets/images/bg/footer-mobile.png`}
            title={translate('menu.logoAlt')}
          />
        </Grid>
      </Grid>
    </StyledFooter>
  </Wrapper>
);