/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faCompressArrowsAlt, faExpandArrowsAlt, faFilePdf, faMinus, faPlus
} from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, Grid, IconButton, Tooltip, Typography
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import styled, { css } from 'styled-components';
import { Document as DocumentPDF, Page, pdfjs } from 'react-pdf'; // Docs : https://www.npmjs.com/package/react-pdf
import { API_URL_DOCUMENTS } from 'utils/constants';
import { translate } from 'utils/translation';
import ModalHeader from './_ModalHeader';
import { SkeletonPagePDF } from '..';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DisplayPDFModalContainer = styled.div`
  position: relative;

  .react-pdf__Page,
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }

  .MuiDialogContent-root {
    height: ${(props) => props.height};
    background: var(--grey-lighter);
  }

  .MuiDialogActions-root {
    background: var(--grey-lighter);
    border-top: 1px solid var(--grey-light);
  }
`;

const PaginationArrow = styled.aside`
  position: absolute;
  top: 64px;
  bottom: 64px;
  width: 50px;
  text-align: center;
  z-index: 10;

  ${(props) => props.left && css`left: 0;`}
  ${(props) => props.right && css`right: 0;`}

  button {
    height: 100%;
    width: 100%;
    min-width: auto;
  }
`;

const FullScreen = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  z-index: var(--zindexBig);
`;

const DisplayPDFModal = ({
  onClose, name, url, isMain, documentMode
}) => {
  const [pageTotal, setPageTotal] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(isMain);
  const [pdfProps, setPdfProps] = useState({});

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === pageTotal;
  const headerHeight = `${document.getElementById('modalHeader')?.clientHeight}px`;
  const containerHeight = isMobile ? `calc(100vh - ${headerHeight})` : `calc(100vh - ${headerHeight} - 65px)`;

  const handlePageMinus = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber]);

  const handlePagePlus = useCallback(() => {
    if (pageNumber < pageTotal) {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, pageTotal]);

  const handleZoomMinus = useCallback(() => {
    if (scale > 0.3) {
      setScale(scale - 0.1);
    }
  }, [scale]);

  const handleZoomPlus = useCallback(() => {
    if (scale < 2) {
      setScale(scale + 0.1);
    }
  }, [scale]);

  const addKeyboardListeners = useCallback(() => {
    document.onkeydown = (event) => {
      switch (event.key) {
      case 'ArrowLeft':
        handlePageMinus();
        break;
      case 'ArrowRight':
        handlePagePlus();
        break;
      case '+':
        handleZoomPlus();
        break;
      case '-':
        handleZoomMinus();
        break;
      default:
      }
    };
  }, [handlePageMinus, handlePagePlus, handleZoomPlus, handleZoomMinus]);

  useEffect(() => {
    const element = document.getElementById('fullscreen');

    const screenRatio = window.innerWidth / window.innerHeight;
    const margins = 160;
    const smallMargins = 60;
    const customWidth = Number(window.innerWidth - smallMargins);
    const customHeight = Number(window.innerHeight - margins);

    if (isFullscreen) {
      // In fullscreen mode, force the document to use all available width
      element.requestFullscreen();
      setPdfProps({ width: customWidth });
      return;
    }

    if (documentMode === 'landscape') {
      if (screenRatio > 1.65) {
        setPdfProps({ height: customHeight });
      } else {
        setPdfProps({ width: customWidth });
      }
    } else if (documentMode === 'portrait') {
      if (screenRatio < 0.65) {
        setPdfProps({ width: customWidth });
      } else {
        setPdfProps({ height: customHeight });
      }
    }
  }, [isFullscreen, documentMode]);

  useEffect(() => {
    const pageRef = document.getElementsByClassName('react-pdf__Page');

    if (pageRef && pageRef.item(0)) {
      pageRef.item(0).style.width = `${pdfProps.width}px`;
    }
  }, [pdfProps]);

  useEffect(() => {
    addKeyboardListeners();
  }, [addKeyboardListeners]);

  const handleFileSuccess = useCallback(({ numPages }) => setPageTotal(numPages), []);

  const handleChange = useCallback((_, value) => setPageNumber(value), []);

  const handleFullscreen = useCallback(() => {
    const element = document.getElementById('fullscreen');

    if (document.fullscreenElement) {
      setIsFullscreen(false);
      return document.exitFullscreen();
    }

    setIsFullscreen(true);
    return element.requestFullscreen();
  }, []);

  return (
    <DisplayPDFModalContainer height={containerHeight} id="fullscreen">
      <ModalHeader onClose={onClose}>
        <Grid alignItems="center" container wrap="nowrap">
          <Grid item>
            <FontAwesomeIcon icon={faFilePdf} />
          </Grid>
          <Grid item xs>
            <Typography>
              {name}
            </Typography>
          </Grid>
        </Grid>
      </ModalHeader>

      <DialogContent>
        <PaginationArrow left>
          <Button color="primary" disabled={isFirstPage} onClick={handlePageMinus}>
            <FontAwesomeIcon icon={faChevronLeft} size="2x" />
          </Button>
        </PaginationArrow>

        <DocumentPDF
          externalLinkTarget="_blank"
          file={`${API_URL_DOCUMENTS}${url}`}
          loading={<SkeletonPagePDF />}
          onLoadSuccess={handleFileSuccess}
        >
          <Page
            loading={<SkeletonPagePDF />}
            pageNumber={pageNumber}
            scale={scale}
            {...pdfProps}
          />
        </DocumentPDF>

        {!isMobile && (
          <FullScreen>
            <Tooltip title={isFullscreen ? translate('button.fullscreenOff') : translate('button.fullscreen')}>
              <IconButton color="primary" onClick={handleFullscreen}>
                <FontAwesomeIcon icon={isFullscreen ? faCompressArrowsAlt : faExpandArrowsAlt} />
              </IconButton>
            </Tooltip>
          </FullScreen>
        )}

        <PaginationArrow right>
          <Button color="primary" disabled={isLastPage} onClick={handlePagePlus}>
            <FontAwesomeIcon icon={faChevronRight} size="2x" />
          </Button>
        </PaginationArrow>
      </DialogContent>

      {!isMobile && (
        <DialogActions>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography component="span">
                {translate('common.zoom')}
              </Typography>
              <IconButton color="primary" onClick={handleZoomMinus}>
                <FontAwesomeIcon icon={faMinus} />
              </IconButton>
              <IconButton color="primary" onClick={handleZoomPlus}>
                <FontAwesomeIcon icon={faPlus} />
              </IconButton>
              <Button onClick={() => setScale(1)}>
                {translate('common.reset')}
              </Button>
            </Grid>
            <Grid item>
              <Pagination
                boundaryCount={2}
                color="primary"
                count={pageTotal}
                page={pageNumber}
                showFirstButton
                size="large"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </DisplayPDFModalContainer>
  );
};

DisplayPDFModal.propTypes = {
  documentMode: PropTypes.string.isRequired,
  isMain: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string.isRequired,
  name: PropTypes.string
};

DisplayPDFModal.defaultProps = {
  isMain: false,
  name: 'PDF',
  onClose: null
};

export default DisplayPDFModal;