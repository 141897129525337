import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Quiz } from 'components';
import { Grid } from '@material-ui/core';
import { useStores } from 'hooks';

const PagePublicQuiz = () => {
  const { quizId } = useParams();

  const { quizStore } = useStores();

  const data = {
    id: quizId
  };

  useEffect(() => {
    quizStore.setExamStatus({ isStarted: false, isEnded: false });
  }, [quizStore]);

  return (
    <Grid container style={{ padding: '100px 50px' }}>
      <Quiz editionMode={false} element={data} />
    </Grid>

  );
};

export default PagePublicQuiz;