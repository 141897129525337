import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Player from '@vimeo/player';
import { SkeletonVideo, Text } from 'components';
import { videoElementModel } from 'components/model/dataformModel';

const Figure = styled.figure`
  margin: 0;

  figcaption {
    margin: auto;
    margin-top: 1rem;
    text-align: center;
  }
`;

const VIMEO_PLAYER = 'https://player.vimeo.com/video';

export const VideoElement = observer(({ element }) => {
  const { title, legend, videoUrl } = element;

  const isCompleteUrl = videoUrl && videoUrl.indexOf('vimeo') > -1;
  const videoId = isCompleteUrl ? videoUrl.match(new RegExp(/\/([0-9]{9})\b/))[1] : videoUrl;

  useEffect(() => {
    if (videoId) {
      const vimeoPlayer = document.getElementById('vimeoPlayer');
      const player = new Player(vimeoPlayer);

      player.on('timeupdate', (update) => {
        console.log('timeupdate', update);
      });
    }
  }, [videoId]);


  return (
    <Figure>
      {videoId ? (
        <>
          <iframe
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            height="400"
            id="vimeoPlayer"
            src={`${VIMEO_PLAYER}/${videoId}`}
            title={title}
            width="100%"
          />
          {legend && (
            <figcaption>
              <Text margin="0" textAlign="center">
                {legend}
              </Text>
            </figcaption>
          )}
        </>
      ) : (
        <SkeletonVideo />
      )}
    </Figure>
  );
});

VideoElement.propTypes = {
  element: videoElementModel.isRequired
};