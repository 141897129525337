import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Wrapper } from 'components';
import { Grid } from '@material-ui/core';

export const SkeletonDetailUser = () => (
  <Wrapper>
    <Skeleton height={130} style={{ margin: '1rem auto' }} variant="circle" width={130} />
    <Skeleton height={40} style={{ margin: '2rem auto' }} width={200} />
    <Grid container spacing={4} wrap="wrap">
      <Grid item sm={6} xs={12}>
        <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
      </Grid>
    </Grid>
    <Skeleton height={200} style={{ marginBottom: '2rem' }} variant="rect" />
  </Wrapper>
);