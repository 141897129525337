import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { BlueBlock, InputField, ParentElement } from 'components';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import { translate } from 'utils/translation';
import { SectionContent, SectionTitle } from '../_commons';

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["section"] }] */
export const DocumentSection = observer(({ section, editionMode, editableHtmlId }) => {
  const {
    title, elements, isList, withSearch
  } = section;

  const { editionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState('');

  const getNewDocumentElementId = useCallback((index) => `${editableHtmlId}_element_${index}`, [editableHtmlId]);

  const deleteDocument = useCallback((index) => () => {
    section.elements = section.elements.filter((element) => element.index !== index);

    // Updating indexes
    for (let i = 0; i < section.elements.length; i++) {
      section.elements[i].index = i;
    }
    editionStore.setLastSaved(false);
    enqueueSnackbar(translate('forms.element.document.documentDeleted'), { variant: 'success' });
  }, [section.elements, enqueueSnackbar, editionStore]);

  const editDocument = useCallback((document) => {
    editionStore.setElementEdited(getNewDocumentElementId(document.index), document, 'element', deleteDocument);
  }, [editionStore, getNewDocumentElementId, deleteDocument]);

  const getDefaultDocument = (i) => ({
    index: i,
    elementType: 'DOCUMENT_ELEMENT',
    element: {
      name: translate('forms.element.document.newDocument'),
      url: ''
    }
  });

  const addDocument = useCallback((event) => {
    event.stopPropagation();

    const lastIndex = elements.length > 0 ? elements.length : 0;
    runInAction('AddDocumentFromDocumentSection', () => {
      elements.push(getDefaultDocument(lastIndex));
      editionStore.setLastSaved(false);
      editDocument(elements[elements.length - 1]);
    });
  }, [elements, editDocument, editionStore]);

  const updateFilter = useCallback((event, value) => {
    setFilter(value);
  }, []);

  // TODO
  const filterDocument = useCallback((element) => {
    if (!filter) {
      return true;
    }
    if (element.element.name.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    return false;
  }, [filter]);

  return (
    <section>
      <SectionTitle title={title} />
      {withSearch && (
        <InputField
          label={translate('common.filterDocuments')}
          name="filter"
          style={{ margin: '0 0 2rem 0', maxWidth: '400px' }}
          value={filter}
          onChange={updateFilter}
        />
      )}
      <SectionContent alignItems={isList ? 'left' : 'center'} direction={isList ? 'column' : 'row'}>
        {elements.filter((e) => filterDocument(e)).map((element) => (
          <Grid item key={element.index}>
            <ParentElement
              editionMode={editionMode}
              element={element}
              isList={isList}
              parentHtmlId={editableHtmlId}
              onDelete={deleteDocument(element.index)}
            />
          </Grid>
        ))}
        {editionMode && (
          <Grid item>
            <BlueBlock
              color="var(--primary-color)"
              colored
              fontWeight="bold"
              margin="0"
              role="button"
              style={{ zIndex: 30, width: '100px' }}
              textAlign="center"
              onClick={addDocument}
            >
              <Grid alignItems="center" container direction="column" justifyContent="center">
                <FontAwesomeIcon icon={faPlusCircle} size="2x" style={{ marginBottom: '1rem' }} />
                {translate('button.addDocument')}
              </Grid>
            </BlueBlock>
          </Grid>
        )}
      </SectionContent>
    </section>
  );
});