import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';


const ButtonClose = styled(IconButton)`
  position: absolute !important;
  right: 0;
  top: 0;
`;


export const CloseButton = ({
  onClose
}) => (
  <ButtonClose aria-label="close" onClick={onClose}>
    <FontAwesomeIcon color="var(--white)" icon={faTimes} style={{ margin: 0 }} />
  </ButtonClose>
);

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired
};