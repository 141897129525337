import i18n from 'i18next';

export const frenchLocale = 'fr_FR';

const translationNotFound = 'errors.translationNotFound';

export const translate = (key, options) => {
  if (i18n.exists(key)) {
    const translation = i18n.t(key, options);
    if (translation) {
      return translation;
    }
  }
  if (i18n.exists(translationNotFound)) {
    return i18n.t(translationNotFound);
  }
  return translationNotFound.substring(translationNotFound.lastIndexOf('.') + 1);
};

export const translateTheme = (label) => {
  if (label && /^[A-Z_]*$/.test(label)) {
    return translate(`themes.${label}`);
  }
  return label;
};