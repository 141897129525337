import {
  Button, Divider, Grid, Typography
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DocumentHelper, StorageHelper } from 'utils';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import Linkify from 'react-linkify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils/translation';
import { useModal, useStores } from 'hooks';
import {
  faBadgeCheck, faPollH, faRedo, faSpinner, faStar, faUserSlash
} from '@fortawesome/pro-regular-svg-icons';
import { Text } from 'components/_commons/Text';
import { QuizService } from 'services';
import { useParams } from 'react-router-dom';
import { runInAction } from 'mobx';
import { useSnackbar } from 'notistack';
import {
  buildQuizWarningMessage, EXAM_KEY,
  QUIZ_STATUS
} from '../QuizHelper';

const Header = styled.header`
  padding: 5px;
`;

const QuizHeader = observer(({ quizStatus }) => {
  const displayModal = useModal();

  const { enqueueSnackbar } = useSnackbar();

  const { programId, moduleId, sequenceId } = useParams();

  const { quizStore, editionStore, userStore } = useStores();

  const { notRunning, ended, displayingResults } = QUIZ_STATUS;

  const { isConnected } = userStore;
  const { editionMode } = editionStore;
  const {
    examinationState, startedExamination, lastExamination, finishedExamination
  } = quizStore;

  const { isQuizStarted, isQuizEnded } = examinationState;

  const [examination, setExamination] = useState(null);
  const [isQuizStarting, setIsQuizStarting] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [quiz, setQuiz] = useState({});
  const [finalScore, setFinalScore] = useState(null);

  const {
    isCertifying, title, description, endText, logo, displayRatingOnly, displayResults, allowRepeat, maxTimeToFinish
  } = quiz;

  useEffect(() => {
    if (examinationState.isQuizStarted) {
      setExamination(startedExamination);
      setFinalScore(startedExamination.finalScore);
      setQuiz(startedExamination.quiz);
    } else if (examinationState.isQuizEnded) {
      setExamination(finishedExamination);
      setFinalScore(finishedExamination.finalScore);
      setQuiz(finishedExamination.quiz);
    } else {
      setExamination(lastExamination);
      setFinalScore(lastExamination.finalScore);
      setQuiz(lastExamination.quiz);
    }
  }, [examinationState, startedExamination, lastExamination, finishedExamination]);

  const getRemainingTries = useCallback(() => {
    if (isConnected && startedExamination) {
      const { warning } = buildQuizWarningMessage(quiz);
      setWarningMessage(warning);
    }
  }, [startedExamination, quiz, isConnected]);

  useEffect(() => {
    getRemainingTries();
  }, [getRemainingTries]);

  const startExamination = useCallback((traineeCredentials) => {
    setIsQuizStarting(true);

    QuizService.startQuiz({
      quizId: quiz?.id,
      sequenceId,
      programId,
      moduleId,
      ...traineeCredentials
    })
      .then((response) => {
        StorageHelper.SET(EXAM_KEY, response.id);

        runInAction('triggerStart', () => {
          quizStore.setExaminationStart(response);
          quizStore.setCurrentPage(response.quiz.pages[0]);
          quizStore.setExamStatus({
            isStarted: true,
            isEnded: false,
            examinationId: response.id,
            quizId: response.quiz.id
          });
          quizStore.getQuestionNumbering();
        });
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => {
        setIsQuizStarting(false);
        quizStore.toggleShowResults(false);
      });
  }, [enqueueSnackbar, quizStore, quiz, moduleId, sequenceId, programId]);

  const handleStart = useCallback(() => {
    if (examination.quiz.isCertifying) {
      displayModal({
        type: 'TRAINEE_CONFIRMATION_CERTIFYING_QUIZ',
        afterClose: () => (examination.quiz.withIdentification && !isConnected
          ? displayModal({
            type: 'AUTHENTICATE_TRAINEE',
            onConfirm: startExamination,
            defaultValues: {}
          })
          : null),
        onConfirm: !examination.quiz.withIdentification || isConnected ? startExamination : null,
        defaultValues: {}
      });
    } else if (examination.quiz.withIdentification && !isConnected) {
      displayModal({
        type: 'AUTHENTICATE_TRAINEE',
        onConfirm: startExamination,
        defaultValues: {}
      });
    } else {
      startExamination();
    }
  }, [displayModal, isConnected, examination, startExamination]);

  const handleRestartQuiz = useCallback(() => {
    startExamination();
  }, [startExamination]);

  const handleShowResults = useCallback(() => {
    quizStore.toggleShowResults(true);
  }, [quizStore]);

  const getStartQuizLabelAndIcon = useCallback(() => {
    const { startDate, endDate, quiz: { remainingTries = 0 } } = examination;

    if (startDate && endDate && remainingTries <= 0) {
      return {
        label: 'button.noRemainingTries',
        icon: faUserSlash
      };
    }

    if (startDate && endDate && remainingTries > 0) {
      return {
        label: 'button.restart',
        icon: faRedo
      };
    }

    if (startDate && !endDate) {
      return {
        label: 'button.continue',
        icon: faPollH
      };
    }

    return {
      label: 'button.begin',
      icon: faPollH
    };
  }, [examination]);

  const renderStartButton = () => {
    const { label, icon } = getStartQuizLabelAndIcon();

    return (
      <Button
        color="primary"
        disabled={isQuizStarting || examination?.quiz?.remainingTries <= 0}
        startIcon={<FontAwesomeIcon icon={isQuizStarting ? faSpinner : icon} spin={isQuizStarting} />}
        style={{ marginTop: '1rem' }}
        variant="contained"
        onClick={handleStart}
      >
        {translate(label)}
      </Button>
    );
  };

  const renderRestartButton = () => (
    <Button
      color="secondary"
      disabled={isQuizStarting || examination?.quiz?.remainingTries <= 0}
      startIcon={<FontAwesomeIcon icon={isQuizStarting ? faSpinner : faRedo} spin={isQuizStarting} />}
      style={{ marginBottom: 10 }}
      variant="contained"
      onClick={handleRestartQuiz}
    >
      {translate('button.restart')}
    </Button>
  );

  const renderShowResultsButton = () => (
    <Button
      color="primary"
      startIcon={<FontAwesomeIcon icon={faStar} />}
      variant="contained"
      onClick={handleShowResults}
    >
      {translate('button.showResults')}
    </Button>
  );

  const renderQuizTitle = () => (
    <Grid
      container
      direction="row"
      spacing={2}
      style={{ marginBottom: '1rem' }}
    >
      {logo && (
        <Grid item lg={2}>
          <img alt={title} src={DocumentHelper.getDocumentWithBase64(logo)} style={{ maxHeight: 150, maxWidth: 150 }} />
        </Grid>
      )}
      <Grid item lg={10}>
        <Typography component="h2" variant="h5">{title}</Typography>
        {description && <Typography>{description}</Typography>}
      </Grid>
    </Grid>
  );

  const renderQuizStartAlert = () => (
    <>
      {isCertifying && (
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}
          style={{ padding: '5px 0px' }}
        >
          <Grid item>
            <FontAwesomeIcon color="var(--primary-color)" icon={faBadgeCheck} size="2x" />
          </Grid>
          <Grid item>
            <strong>{translate('forms.element.quiz.certifyingQuiz')}</strong>
          </Grid>
        </Grid>
      )}
      {maxTimeToFinish > 0 && (
        <Text fontWeight="bold">
          {translate('forms.element.quiz.startQuizTime', { maxTimeToFinishMinutes: maxTimeToFinish })}
        </Text>
      )}
    </>
  );

  const componentDecorator = (decoratedHref, decoratedText, key) => (
    <a href={decoratedHref} key={key} rel="noopener noreferrer" target="_blank">
      {decoratedText}
    </a>
  );

  // Quiz display on quiz results tab
  if (quizStatus === displayingResults || !quiz) {
    return null;
  }

  // Quiz display on sequence edition
  if (editionMode) {
    return renderQuizTitle();
  }

  if (!isQuizStarting && !examination) {
    return <Typography>{translate('errors.noQuiz')}</Typography>;
  }

  return (
    <Header>
      <Grid container direction="column">
        {renderQuizTitle()}

        {(quizStatus === notRunning || quizStatus === ended) && (
          <Grid item>
            {warningMessage && <Typography className="warning">{warningMessage}</Typography>}
          </Grid>
        )}

        {displayRatingOnly && finalScore && (quizStatus === notRunning || quizStatus === ended) && (
          <Grid item>
            <Typography component="h3" style={{ color: 'var(--success-color)' }} variant="h5">
              {`${translate('forms.element.quiz.globalScore')} : ${finalScore}`}
            </Typography>
          </Grid>
        )}

        {quizStatus === ended && (
          <Grid item>
            <Typography component="h3" style={{ color: 'var(--success-color)' }} variant="h5">
              {translate('forms.element.quiz.thanksForFinishing')}
            </Typography>
          </Grid>
        )}

        {quizStatus === ended && (
          <>
            {endText && endText.length > 0 && (
              <Grid item>
                <Linkify
                  // eslint-disable-next-line react/jsx-no-bind
                  componentDecorator={componentDecorator}
                >
                  {endText}
                </Linkify>
              </Grid>
            )}
          </>
        )}

        <Divider style={{ margin: '20px 0px' }} />

        {quizStatus === notRunning && renderQuizStartAlert()}

        <Grid item>
          {!isQuizStarted && !isQuizEnded && renderStartButton()}
        </Grid>

        <Grid item>
          {isConnected && isQuizEnded && allowRepeat && renderRestartButton()}
        </Grid>

        <Grid item>
          {isQuizEnded && displayResults && renderShowResultsButton()}
        </Grid>
      </Grid>

      {quizStatus === ended && <Divider style={{ margin: '20px 0px' }} />}
    </Header>
  );
});

export default QuizHeader;

QuizHeader.propTypes = {
  quizStatus: PropTypes.string.isRequired
};