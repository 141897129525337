/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
import { Text } from 'components';
import { FormHeader } from 'components/forms';
import { InputField } from 'components/forms/inputs';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import { SectionTypeChoice } from '../../choices';

export const GenericSectionForm = observer(({
  title, updateProperty, sectionType, formData
}) => {
  const handleSectionTypeChange = useCallback((sectionTypeFieldName, sectionTypeValue) => {
    runInAction('ChangeSectionTypeFromSectionForm', () => {
      if (sectionTypeValue !== sectionType) {
        formData.elements = [];
      }
      formData.format = '';
      updateProperty(sectionTypeFieldName, sectionTypeValue);
    });
  }, [formData.elements, formData.format, sectionType, updateProperty]);

  const handleListViewChange = useCallback(() => {
    runInAction('ChangeDocumentSectionViewFromSectionForm', () => {
      formData.isList = !formData.isList;
    });
  }, [formData.isList]);

  const handleWithSearchChange = useCallback(() => {
    runInAction('ChangeDocumentSectionSearchFromSectionForm', () => {
      formData.withSearch = !formData.withSearch;
    });
  }, [formData.withSearch]);

  return (
    <>
      <FormHeader title={title ? 'forms.section.title' : 'forms.section.titleSetup'} />

      <InputField label={translate('common.title')} name="title" value={title} onChange={updateProperty} />
      <Text>{translate('forms.section.choosePredefinedSection')}</Text>

      <SectionTypeChoice
        formData={formData}
        name="sectionType"
        value={sectionType}
        onChange={handleSectionTypeChange}
        onChangeListView={handleListViewChange}
        onChangeWithSearch={handleWithSearchChange}
      />
    </>
  );
});