import {
  Grid
} from '@material-ui/core';

import React, { useCallback } from 'react';

import { translate } from 'utils/translation';

import PropTypes from 'prop-types';

import { TextField } from 'components/forms/inputs';

export const ModuleListFilters = ({
  currentFilters, setCurrentFilters
}) => {
  const name = (currentFilters.find((filt) => filt.key === 'name')
    && currentFilters.find((filt) => filt.key === 'name').label) || null;

  // TODO: UNCOMMENT WHEN BACKEND WILL READY
  /* const code = (currentFilters.find((filt) => filt.key === 'code')
    && currentFilters.find((filt) => filt.key === 'code').label) || null;

  const reference = (currentFilters.find((filt) => filt.key === 'reference')
    && currentFilters.find((filt) => filt.key === 'reference').label) || null;

  const description = (currentFilters.find((filt) => filt.key === 'description')
    && currentFilters.find((filt) => filt.key === 'description').label) || null;
*/
  const handleChangeInput = useCallback((event) => {
    const { name: filterName, value } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), { key: filterName, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  return (
    <Grid container direction="column">
      <TextField
        autoComplete="off"
        label={translate('common.name')}
        name="name"
        value={name}
        onChange={handleChangeInput}
      />

      {/*  TODO: UNCOMMENT WHEN BACKEND WILL READY */}
      {/*   <TextField
        label={translate('common.code')}
        name="code"
        value={code}
        onChange={handleChangeInput}
      />

      <TextField
        label={translate('common.reference')}
        name="reference"
        value={reference}
        onChange={handleChangeInput}
      />

      <TextField
        label={translate('common.description')}
        name="description"
        value={description}
        onChange={handleChangeInput}
      /> */}
    </Grid>

  );
};

ModuleListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};