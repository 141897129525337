import React, { useCallback, useState } from 'react';
import { asForm, asInput, asInputRadio } from 'components/forms/hoc';
import { genericSectionFormModel } from 'components/model/dataformModel';
import { getFormat } from 'components/Templates/sections/GenericSection/GenericSectionFormats';
import { runInAction } from 'mobx';
import PropTypes from 'prop-types';
import { GenericSectionFormatRadio } from '../_commons';

const GenericSectionFormatInput = asInputRadio(asInput(({
  name, onChange, isChecked
}) => {
  const InputRadio = ({ value }) => (
    <GenericSectionFormatRadio isChecked={isChecked} name={name} value={value} onChange={onChange} />
  );

  return (
    <>
      <InputRadio value="GENERIC_1" />
      <InputRadio value="GENERIC_1_1" />
      <InputRadio value="GENERIC_1_2" />
      <InputRadio value="GENERIC_2_1" />
      <InputRadio value="GENERIC_1_1_1" />
    </>
  );
}));

const GenericSectionFormatChoice = ({ formData, updateProperty }) => {
  const { index, format } = formData;
  const [removedElementsPerSection, setRemovedElementsPerSection] = useState(new Map());

  const handleFormatChange = useCallback((name, value) => {
    const updateRemovedElementsPerSection = (k, v) => {
      setRemovedElementsPerSection(new Map(removedElementsPerSection.set(k, v)));
    };

    const removedElements = removedElementsPerSection.get(index) || [];
    const { columns } = getFormat(value);

    updateProperty(name, value);
    const { elements } = formData;
    const nbElements = elements.length;

    // If there is not enough elements for the new section format, add a formerly removed element, or a default one.
    if (nbElements < columns) {
      const nbElementsToAdd = columns - nbElements;
      runInAction('AddFormerlyRemovedElementOrDefaultOne', () => {
        elements.push(...removedElements.slice(0, nbElementsToAdd));
        updateRemovedElementsPerSection(index, removedElements.splice(nbElementsToAdd));

        const getDefaultElement = (i) => ({ index: i, elementType: null, element: null });
        for (let i = 0; i < nbElementsToAdd - removedElements.length; i += 1) {
          elements.push(getDefaultElement(elements.length));
        }
      });
    }

    // If there are to much elements, remove the last one(s) and memorize them.
    else if (nbElements > columns) {
      runInAction('UpdateRemovedLastElementsPerSectionAndMemorizeThem', () => {
        updateRemovedElementsPerSection(index, [...elements.splice(columns), ...removedElements]);
      });
    }
  }, [removedElementsPerSection, updateProperty, formData, index]);

  return <GenericSectionFormatInput name="format" value={format} onChange={handleFormatChange} />;
};

export default asForm(GenericSectionFormatChoice);

GenericSectionFormatChoice.propTypes = {
  formData: genericSectionFormModel.isRequired,
  updateProperty: PropTypes.func.isRequired
};