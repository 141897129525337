import { API_ROUTES, LIST_SIZE } from 'utils/constants';
import { RequestHelper } from 'utils';

// POST
// We send an empty object for the headers to have the default Content-Type as multipart/form-data with a boundary
const createDocument = (document) => RequestHelper.POST(
  API_ROUTES.DOCUMENT_CREATE(),
  document,
  {}
);

const getDocumentById = (documentId) => RequestHelper.GET(API_ROUTES.DOCUMENT(documentId));

const getDocumentDetails = (documentId) => RequestHelper.GET(API_ROUTES.DOCUMENT_DETAILS(documentId));

const updateDocument = (hashId, document) => RequestHelper.PUT(
  API_ROUTES.DOCUMENT_UPDATE(hashId),
  document,
  {}
);

const findDocuments = ({
  search = '', page = 0, size = LIST_SIZE, filters = ''
}) => RequestHelper.GET(
  API_ROUTES.DOCUMENTS_FIND({
    search, page, size, filters
  })
);

export const DocumentService = {
  createDocument,
  getDocumentById,
  updateDocument,
  findDocuments,
  getDocumentDetails
};