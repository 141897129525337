import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils/translation';


const BlueBlockContainer = styled.div`
  position: relative;

  ${(props) => !props.discreet && css`
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0 0 0 2px var(--border-primary-color);
    background-color: var(--white);
    border-radius: 5px;

    ${props.colored && css`background-color: var(--bg-primary-color);`}
    ${props.focused && css`background-color: var(--bg-primary-color-hover);`}

    &:hover {
      box-shadow: 0 0 0 3px var(--border-primary-color-focus);
      cursor: pointer;
    }

    ${props.position && css`position: ${props.position};`}
    ${props.display && css`display: ${props.display};`}
    ${props.margin && css`margin: ${props.margin};`}
    ${props.padding && css`padding: ${props.padding};`}
    ${props.width && css`width: ${props.width};`}
    ${props.height && css`height: ${props.height};`}
    ${props.textAlign && css`text-align: ${props.textAlign};`}
    ${props.color && css`color: ${props.color};`}
    ${props.fontWeight && css`font-weight: ${props.fontWeight};`}
  `}
`;

const Params = styled.span`
  position: absolute;
  top: 1rem;
  left: 1rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  z-index: 1;

  svg {
    margin-right: 0.5rem;
  }
`;


export const BlueBlock = ({
  children, discreet, isParams, ...props
}) => (
  <BlueBlockContainer discreet={discreet} {...props}>
    {!discreet && isParams && (
      <Params>
        <FontAwesomeIcon icon={faCog} />
        {translate('common.params')}
      </Params>
    )}
    {children}
  </BlueBlockContainer>
);

BlueBlock.propTypes = {
  children: PropTypes.node,
  discreet: PropTypes.bool,
  isParams: PropTypes.bool
};

BlueBlock.defaultProps = {
  children: null,
  discreet: false,
  isParams: false
};