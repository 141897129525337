import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

const TODAY = new Date().toISOString().slice(0, 10);

const getSessions = ({ moduleId, fromDate = TODAY, toDate = '' }) => RequestHelper.GET(
  API_ROUTES.SESSIONS({ moduleId, fromDate, toDate })
);

const getSessionById = (sessionId) => RequestHelper.GET(API_ROUTES.SESSION(sessionId));


const createSession = (session) => RequestHelper.POST(API_ROUTES.SESSION_CREATE(), session);


const updateSession = (sessionId, session) => RequestHelper.PUT(
  API_ROUTES.SESSION_UPDATE(sessionId),
  session
);


const deleteSession = (sessionId) => RequestHelper.DELETE(API_ROUTES.SESSION_DELETE(sessionId));

export const SessionService = {
  getSessions,
  getSessionById,
  createSession,
  updateSession,
  deleteSession
};