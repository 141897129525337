const question = (ques, answers) => ({
  question: ques || 'Une super question ?',
  answers: answers || [
    {
      name: 'Une super réponse !',
      isCorrect: true
    }, {
      name: 'Une réponse du tonerre !',
      isCorrect: false
    }
  ]
});

export const answerDefault = {
  name: '',
  isCorrect: true
};

export default question;