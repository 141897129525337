import { ThemeProvider } from '@material-ui/core';
import { App, DATAFORM_THEME } from 'app/App';

import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './locales/i18n';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

configure({
  enforceActions: 'observed'
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={DATAFORM_THEME}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const triggerSkipWaiting = (SW, state) => {
  if (state === 'installed' || state === 'waiting') {
    SW.postMessage({ type: 'SKIP_WAITING' });
  }
};

const updateSW = (registration) => {
  const newSW = registration.installing || registration.waiting;

  if (newSW) {
    triggerSkipWaiting(newSW, newSW.state);
    newSW.addEventListener('statechange', (event) => {
      triggerSkipWaiting(newSW, event.target.state);

      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const SWConfig = {
  onUpdate: (registration) => {
    updateSW(registration);

    registration.addEventListener('updatefound', () => updateSW(registration));
  }
};

serviceWorker.register(SWConfig);