import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils/translation';
import { FullWrapper } from 'components';
import { MENU_ITEMS } from 'utils/constants';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import shortid from 'shortid';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

const StyledHeaderMenu = styled.nav`
  position: relative;
  height: var(--header-nav-height);
  background: var(--white);
  border-bottom: 1px solid var(--primary-color);
  z-index: var(--zindexBig);
`;

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 2rem 3rem;
  color: var(--header-grey);
  text-transform: uppercase;

  &.active,
  &:hover {
    color: var(--primary-color);
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 4px;
      background: var(--primary-color);
    }
  }

  svg {
    margin-right: 1rem;
  }
`;

export const HeaderMenu = ({ backLink, handleLinkClick }) => (
  <StyledHeaderMenu>
    <FullWrapper style={{ padding: '0 2rem' }}>
      <Grid alignItems="center" container justifyContent="space-between">
        <Grid item>
          {backLink && (
            <Tooltip aria-label={backLink.name} placement="right" title={backLink.name}>
              <IconButton aria-label={backLink.name} color="primary" data-link={backLink.url} onClick={handleLinkClick}>
                <FontAwesomeIcon icon={faArrowLeft} size="xs" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            {MENU_ITEMS.map((item) => (
              <Grid item key={shortid.generate()}>
                <StyledLink activeClassName="active" data-link={item.url} to={item.url} onClick={handleLinkClick}>
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                  {translate(item.title)}
                </StyledLink>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </FullWrapper>
  </StyledHeaderMenu>
);