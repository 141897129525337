import { Text } from 'components';
import { asForm } from 'components/forms/hoc';
import { ElementTypeChoice } from 'components/forms/templateForms/choices';
import { runInAction } from 'mobx';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import { FormHeader } from 'components/forms';


const mediaElement = {
  url: '',
  title: '',
  legend: ''
};

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
const EmptyElementForm = ({ formData, updateProperty }) => {
  const { elementType } = formData;

  const getElementBody = useCallback((type) => {
    switch (type) {
    case 'TEXT_ELEMENT':
      return {
        subtitle: '',
        text: ''
      };
    case 'IMAGE_ELEMENT':
      return mediaElement;
    case 'VIDEO_ELEMENT':
      return mediaElement;
    case 'DOCUMENT_ELEMENT':
    case 'DOCUMENT_MAIN':
      return {
        url: '',
        name: ''
      };
    default:
      return null;
    }
  }, []);

  const handleChange = useCallback((name, value) => {
    runInAction('InitElementJsonFromEmptyElementForm', () => {
      formData.element = getElementBody(value);
    });

    updateProperty(name, value);
  }, [formData.element, updateProperty, getElementBody]);

  return (
    <>
      <FormHeader title="forms.element.title" />

      <Text>{translate('forms.element.chooseElement')}</Text>
      <ElementTypeChoice color="var(--primary-color)" name="elementType" value={elementType} onChange={handleChange} />
    </>
  );
};

export default asForm(EmptyElementForm);

EmptyElementForm.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  updateProperty: PropTypes.func.isRequired
};