import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import {
  faCheck,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';

const CheckMark = observer(({ isQuestionAnswered, isDefaultAnswerCorrect }) => {
  if (isQuestionAnswered) {
    if (isDefaultAnswerCorrect) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', width: 35 }}>
          <FontAwesomeIcon color="var(--success-color)" icon={faCheck} size="lg" />
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: 35 }}>
        <FontAwesomeIcon color="var(--danger-color)" icon={faTimes} size="lg" />
      </div>
    );
  }
  return null;
});

export default CheckMark;

CheckMark.propTypes = {
  isQuestionAnswered: PropTypes.bool.isRequired,
  isDefaultAnswerCorrect: PropTypes.bool
};

CheckMark.defaultProps = {
  isDefaultAnswerCorrect: null
};