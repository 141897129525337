
import React from 'react';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils/translation';
import { APP_PROFILE } from 'utils/constants';


const ButtonDownloadCatalog = () => {
  const catalog = APP_PROFILE === 'AFF' ? 'Catalogue-climeco.pdf' : 'Catalogue-cemafroid.pdf';

  return (
    <a
      href={`/assets/documents/${catalog}`}
      rel="noopener noreferrer"
      target="_blank"
      title={translate('button.downloadCatalog')}
    >
      <Button startIcon={<FontAwesomeIcon icon={faFilePdf} />}>
        {translate('button.downloadCatalog')}
      </Button>
    </a>
  );
};

export default ButtonDownloadCatalog;