import { action, decorate, observable } from 'mobx';
import logger from 'utils/logger';

export class EditionStore {
  constructor() {
    this.editionMode = false;
    this.editionPreviewMode = false;
    this.elementEdited = null;
    this.rootElementEdited = null;
    this.lastEditionSaved = true;
  }

  setEditionMode(status = true) {
    this.editionMode = status;
  }

  toggleEdition() {
    this.editionMode = !this.editionMode;
  }

  togglePreview() {
    this.toggleEdition();
    this.editionPreviewMode = !this.editionPreviewMode;
  }

  deleteFocusedElement() {
    if (this.elementEdited && this.elementEdited.onDelete) {
      this.elementEdited.onDelete();
    } else {
      logger.error('The function onDelete is not defined or there is no element being edited');
    }
  }

  setElementEdited(elementId, data, type, onDelete = null, isRoot = false) {
    this.elementEdited = {
      elementId, data, type, onDelete
    };

    if (isRoot === true) {
      this.rootElementEdited = {
        elementId, data, type, onDelete
      };
    }
  }

  setLastSaved(isSaved = true) {
    this.lastEditionSaved = isSaved;
  }

  hasNewEditedElement(newElementEditedId) {
    return !this.elementEdited || (this.elementEdited && this.elementEdited.elementId !== newElementEditedId);
  }

  reset() {
    this.editionMode = false;
    this.editionPreviewMode = false;
    this.elementEdited = null;
    this.rootElementEdited = null;
    this.lastEditionSaved = true;
  }
}

decorate(EditionStore, {
  editionMode: observable,
  editionPreviewMode: observable,
  elementEdited: observable,
  rootElementEdited: observable,
  lastEditionSaved: observable,
  setEditionMode: action,
  toggleEdition: action,
  togglePreview: action,
  setElementEdited: action,
  setLastSaved: action,
  deleteFocusedElement: action,
  reset: action
});

export const editionStore = new EditionStore();