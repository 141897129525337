import React from 'react';
import {
  ButtonContainer, ButtonCreateDocument, ButtonCreateModule, ButtonCreateProgram, ButtonCreateSequence
} from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';


export const AdminListNav = () => (
  <HeaderEditionNav>
    <ButtonContainer>
      <ButtonCreateDocument />
      <ButtonCreateSequence />
      <ButtonCreateModule />
      <ButtonCreateProgram />
    </ButtonContainer>
  </HeaderEditionNav>
);