import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/pro-regular-svg-icons';
import { faLockOpenAlt } from '@fortawesome/pro-light-svg-icons';
import { useStores } from 'hooks';

const SwitchVisibility = observer(({
  name, value, onChange, textYes, textNo, iconYes, iconNo
}) => {
  const { editionStore } = useStores();
  const handleChange = useCallback((event) => {
    onChange(event.target.name, event.target.checked);
    editionStore.setLastSaved(false);
  }, [editionStore, onChange]);

  return (
    <>
      <FormControlLabel
        control={(
          <Switch
            checked={value}
            color="primary"
            name={name}
            onChange={handleChange}
          />
        )}
        label={(
          <>
            <FontAwesomeIcon
              className="mr1"
              color={value ? 'var(--success-color)' : 'var(--warning-color)'}
              data-testid={value ? textYes : textNo}
              icon={value ? iconYes : iconNo}
              style={{ minWidth: 25 }}
            />
            {translate(value ? textYes : textNo)}
          </>
        )}
        style={{ marginBottom: '1rem' }}
      />
    </>
  );
});

SwitchVisibility.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  iconNo: PropTypes.shape({}),
  iconYes: PropTypes.shape({}),
  textNo: PropTypes.string,
  textYes: PropTypes.string
};

SwitchVisibility.defaultProps = {
  iconNo: faLockAlt,
  iconYes: faLockOpenAlt,
  textNo: 'common.isPrivate',
  textYes: 'common.isPublic'
};

export default SwitchVisibility;