import { useModal } from 'hooks';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4'; // DOCS: https://www.npmjs.com/package/react-ga4
import { GA4_TAG_ID } from 'utils/constants';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';

const GoogleAnalytics = () => {
  const showModal = useModal();
  const history = useHistory();
  const { location } = history;
  const [gaCookiesActivated, setGaCookiesActivated] = useState(false);

  useEffect(() => {
    if (CookiesHelper.getGAConsentCookie()) {
      return;
    }

    showModal({
      type: 'COOKIES_MODAL'
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (gaCookiesActivated) {
      ReactGA.initialize(GA4_TAG_ID);
    }
  }, [gaCookiesActivated]);

  useEffect(() => {
    if (!gaCookiesActivated) {
      return;
    }

    ReactGA.send({
      hitType: 'pageview',
      page: `${location.pathname}${location.search}`,
      title: ''
    });
  }, [location, gaCookiesActivated]);

  // Set an interval to check Google Analytics consent
  useEffect(() => {
    setInterval(() => {
      setGaCookiesActivated(CookiesHelper.getGAConsent());
    }, 1000 * 60);
  }, []);

  return null;
};

// eslint-disable-next-line no-restricted-syntax
export default GoogleAnalytics;