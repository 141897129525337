import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import { FormHeader } from 'components/forms';
import { asForm } from 'components/forms/hoc';
import { InputField } from 'components/forms/inputs';
import { documentElementFormModel } from 'components/model/dataformModel';
import { useModal, useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {
  lazy, useCallback, useEffect, useState
} from 'react';
import { DocumentService } from 'services';
import { translate } from 'utils/translation';

const SelectDocument = lazy(() => import('components/_commons/SelectDocument/SelectDocument'));

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
const DocumentElementForm = observer(({
  formData, updateProperty, onDelete, isMain
}) => {
  const [document, setDocument] = useState(null);
  const { name: legend, url } = formData;
  const showModal = useModal();
  const { editionStore } = useStores();

  useEffect(() => {
    setDocument(null);
    if (url) {
      const currentImage = url.split('/');
      url && DocumentService.getDocumentDetails(currentImage[currentImage.length - 1])
        .then((response) => setDocument(response));
    }
  }, [url]);

  const handleDocumentDelete = useCallback(() => {
    runInAction('deleteDocumentFromDocumentElementForm', () => {
      formData.url = null;
      editionStore.setLastSaved(false);
    });
  }, [formData.url, editionStore]);

  const handleDocumentUpload = useCallback((fieldName, value, documentUploaded) => {
    if (documentUploaded) {
      runInAction('handleDocumentUploadFromDocumentElementForm', () => {
        formData.name = documentUploaded.name;
        formData.creationDate = documentUploaded.creationDate;
        formData.type = documentUploaded.mimeType;
      });
    }

    updateProperty(fieldName, value);
  }, [updateProperty, formData]);

  const askConfirmationToDeleteDocument = useCallback(() => showModal({
    type: 'WARNING',
    title: 'forms.element.document.titleWarningDeleteDocument',
    text: 'forms.element.document.warningDeleteDocument',
    onConfirm: () => onDelete()
  }), [showModal, onDelete]);

  return (
    <>
      <FormHeader title="forms.element.document.title" />

      <SelectDocument
        contentType="DOWNLOADABLE"
        defaultDocument={document}
        label={translate('forms.element.document.chooseDocument')}
        name="url"
        onDelete={handleDocumentDelete}
        onSelect={handleDocumentUpload}
      />

      <InputField label={translate('common.legend')} name="name" value={legend} onChange={updateProperty} />

      {!isMain && (
        <Button
          margin="2rem 0 0 0"
          startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
          type="danger"
          variant="text"
          onClick={askConfirmationToDeleteDocument}
        >
          {translate('forms.element.document.deleteTheDocument')}
        </Button>
      )}
    </>
  );
});

export default asForm(DocumentElementForm);

DocumentElementForm.propTypes = {
  formData: documentElementFormModel.isRequired,
  isMain: PropTypes.bool,
  updateProperty: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

DocumentElementForm.defaultProps = {
  isMain: false
};