import {
  faList, faSearch, faSquare, faTimesCircle
} from '@fortawesome/pro-regular-svg-icons';
import { SwitchVisibility, Text } from 'components';
import { asInput, asInputRadio } from 'components/forms/hoc';
import { GenericSectionFormatChoice } from 'components/forms/templateForms/choices';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import { SectionRadio } from '../_commons';

const documentSectionValue = 'DOCUMENT_SECTION';
const quizSectionValue = 'QUIZ_SECTION';

const SectionTypeChoice = observer(({
  name, formData, onChange, onChangeListView, onChangeWithSearch, isChecked
}) => {
  const setSectionTypeToGenericSection = useCallback(() => {
    onChange({ target: { name, value: 'GENERIC_SECTION' } });
  }, [name, onChange]);

  return (
    <>
      <Text marginBottom="1rem">{translate('forms.section.predefinedSection')}</Text>

      <SectionRadio
        ariaLabel={documentSectionValue}
        discreet={false}
        isChecked={isChecked}
        name={name}
        value={documentSectionValue}
        onChange={onChange}
      >
        <Text color="var(--primary-color)" fontWeight="bold" margin="1rem auto">
          {translate('forms.section.documentSection')}
        </Text>
      </SectionRadio>
      {formData.sectionType === documentSectionValue && (
        <SwitchVisibility
          iconNo={faSquare}
          iconYes={faList}
          name="isList"
          textNo="common.isNotListView"
          textYes="common.isListView"
          value={!!formData.isList}
          onChange={onChangeListView}
        />
      )}
      {formData.sectionType === documentSectionValue && (
        <SwitchVisibility
          iconNo={faTimesCircle}
          iconYes={faSearch}
          name="withSearch"
          textNo="common.isWithoutSearch"
          textYes="common.isWithSearch"
          value={!!formData.withSearch}
          onChange={onChangeWithSearch}
        />
      )}

      <SectionRadio
        ariaLabel={quizSectionValue}
        discreet={false}
        isChecked={isChecked}
        name={name}
        value={quizSectionValue}
        onChange={onChange}
      >
        <Text color="var(--primary-color)" fontWeight="bold" margin="1rem auto">
          {translate('forms.section.quizSection')}
        </Text>
      </SectionRadio>

      <Text margin="3rem 0" textAlign="center">{translate('forms.section.or')}</Text>

      <Text marginBottom="1rem">{translate('forms.section.emptySection')}</Text>
      <GenericSectionFormatChoice formData={formData} onChange={setSectionTypeToGenericSection} />
    </>
  );
});

export default asInputRadio(asInput(SectionTypeChoice));