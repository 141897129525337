import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';

export const SkeletonQuiz = () => (
  <Skeleton
    style={{
      flex: '1',
      border: '1px solid rgb(213,225,239)'
    }}
    variant="rect"
  >
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Skeleton height={60} variant="rect" width={120} />
          </Grid>
          <Grid item>
            <Skeleton height={20} width={100} />
            <Skeleton height={20} width={100} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Skeleton height={30} width={300} />
      </Grid>
      <Grid item>
        <Skeleton height={30} width={300} />
      </Grid>
      <Grid item>
        <Skeleton height={30} width={300} />
      </Grid>
      <Grid item style={{ padding: '0 1.6rem' }}>
        <Skeleton height={50} width={180} />
      </Grid>
      <Grid item style={{ padding: '0 1.6rem' }}>
        <Skeleton height={50} width={180} />
      </Grid>
    </Grid>
  </Skeleton>
);