/**
 * asInput Higher Order Component
 */
import React, { useCallback } from 'react';

export const asInputRadio = (MyComponent) => ({
  value, ...props
}) => {
  const isChecked = useCallback((inputValue) => inputValue === value, [value]);
  return <MyComponent {...props} isChecked={isChecked} value={value} />;
};