import {
  faEdit, faEye, faFileExport, faSave, faSignOut
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonContainer } from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { useModal, useStores } from 'hooks';
import { SequenceService } from 'services';
import { StorageHelper } from 'utils';
import { ROUTES } from 'utils/constants';

import { translate } from 'utils/translation';

export const SequenceEditionNav = ({
  onEdit, isEditionMode, isCopyMode, loadSequence, setIsTempEditionMode
}) => {
  const { sequenceStore, editionStore } = useStores();
  const history = useHistory();
  const showModal = useModal();
  const { sequenceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { editionMode, editionPreviewMode } = editionStore;
  const { sequence } = sequenceStore;

  useEffect(() => {
    if (isEditionMode) {
      editionStore.setEditionMode();
      onEdit();
      setIsTempEditionMode(false);
    }
  }, [isEditionMode, editionStore, onEdit, setIsTempEditionMode]);

  const toggleEdition = useCallback(() => {
    editionStore.toggleEdition();
    if (onEdit) {
      onEdit();
    }

    sequenceStore.emptySectionsForViewMode();
  }, [editionStore, onEdit, sequenceStore]);

  const togglePreview = useCallback(() => {
    sequenceStore.emptySectionsForViewMode();
    editionStore.togglePreview();
  }, [editionStore, sequenceStore]);

  const showConfirmationModal = useCallback((action, title, text) => showModal({
    type: 'WARNING',
    title,
    text,
    onConfirm: action
  }), [showModal]);

  const handleQuitAction = useCallback(() => {
    editionStore.reset();

    // Reload sequence to remove non saved modifications
    loadSequence();
  }, [editionStore, loadSequence]);

  const handleQuit = useCallback(() => {
    if (!editionStore.lastEditionSaved) {
      showConfirmationModal(() => {
        handleQuitAction();
      },
      'forms.titleWarningLeaveEdition',
      'forms.warningLeaveEdition');
    } else {
      handleQuitAction();
    }
  },
  [editionStore, showConfirmationModal, handleQuitAction]);

  const handleSave = useCallback(() => {
    const isValid = JSON.parse(StorageHelper.GET('isSequenceFormValid'));
    const isFormValid = sequence.isPublished && isValid;

    if (isFormValid || !sequence.isPublished) {
      if (isCopyMode) {
        SequenceService.createSequenceCopy(sequence).then((response) => {
          sequenceStore.resetSequence();
          history.push({
            pathname: ROUTES.SEQUENCE(response),
            state: {
              isEditionMode: true
            }
          });
          enqueueSnackbar(translate('forms.sequence.sequenceCorrectlyCreated'), { variant: 'success' });
        }).catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      } else {
        SequenceService.updateSequence(sequenceId, sequence).then(() => {
          enqueueSnackbar(translate('forms.sequence.sequenceCorrectlySaved'), { variant: 'success' });
        }).catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      }
      editionStore.setLastSaved();
    } else {
      enqueueSnackbar(translate('errors.SequenceFormIncomplete'), { variant: 'error' });
    }
  }, [enqueueSnackbar, sequenceId, sequence, isCopyMode, history, sequenceStore, editionStore]);

  const handlePublish = useCallback(() => {
    const isValid = JSON.parse(StorageHelper.GET('isSequenceFormValid'));
    onEdit();

    if (isValid) {
      sequenceStore.publishSequence();
      SequenceService.updateSequence(sequenceId, sequence).then(() => {
        editionStore.reset();
        enqueueSnackbar(translate('forms.sequence.sequenceCorrectlyPublished'), { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
      editionStore.setLastSaved();
    } else {
      enqueueSnackbar(translate('errors.SequenceFormIncomplete'), { variant: 'error' });
    }
  }, [onEdit, editionStore, enqueueSnackbar, sequenceId, sequenceStore, sequence]);

  const HeaderPreviewMode = () => (
    <Button
      startIcon={<FontAwesomeIcon icon={faEdit} />}
      type="secondary"
      onClick={toggleEdition}
    >
      {translate('button.edit')}
    </Button>
  );

  const HeaderEditionMode = () => (
    <ButtonContainer>
      <Button
        data-testid="button.cancelAndQuit"
        startIcon={<FontAwesomeIcon icon={faSignOut} />}
        type="danger"
        variant="text"
        onClick={handleQuit}
      >
        {translate('button.cancelAndQuit')}
      </Button>
      <Button
        data-testid={editionPreviewMode ? 'button.continueEdition' : 'common.preview'}
        startIcon={<FontAwesomeIcon icon={faEye} />}
        type="secondary"
        onClick={togglePreview}
      >
        {editionPreviewMode ? translate('button.continueEdition') : translate('common.preview')}
      </Button>
      <Button
        data-testid="button.saveOnly"
        startIcon={<FontAwesomeIcon icon={faSave} />}
        onClick={handleSave}
      >
        {translate('button.saveOnly')}
      </Button>
      {!sequence.isPublished && (
        <Button
          data-testid="button.saveAndPublish"
          startIcon={<FontAwesomeIcon icon={faFileExport} />}
          onClick={handlePublish}
        >
          {translate('button.saveAndPublish')}
        </Button>
      )}
    </ButtonContainer>
  );

  return (
    <HeaderEditionNav>
      {editionMode || editionPreviewMode ? <HeaderEditionMode /> : <HeaderPreviewMode />}
    </HeaderEditionNav>
  );
};