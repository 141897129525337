import {
  faFile, faFilePdf, faFont, faImage, faPlay
} from '@fortawesome/pro-regular-svg-icons';
import { asInput, asInputRadio } from 'components/forms/hoc';
import React from 'react';
import styled, { css } from 'styled-components';
import { translate } from 'utils/translation';
import { observer } from 'mobx-react';
import shortid from 'shortid';
import { ElementTypeRadio } from './ElementTypeRadio';

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & div {
    ${(props) => props.color && css`color: ${props.color};`}
  }
  & > div {
    margin: 1rem 0;
  }
`;

const ElementTypeChoice = observer(({
  name, color, onChange, isChecked, isDocumentAdmin
}) => {
  const InputRadio = ({ icon, label, value }) => (
    <ElementTypeRadio icon={icon} isChecked={isChecked} label={label} name={name} value={value} onChange={onChange} />
  );

  return (
    <InputContainer color={color}>
      <InputRadio icon={faImage} label={translate('common.image')} value="IMAGE_ELEMENT" />
      <InputRadio icon={faPlay} label={translate('common.video')} value="VIDEO_ELEMENT" />
      {isDocumentAdmin ? <InputRadio icon={faFile} label={translate('common.other')} value="DOWNLOADABLE_ELEMENT" />
        : ([
          <InputRadio icon={faFont} key={shortid.generate()} label={translate('common.text')} value="TEXT_ELEMENT" />,
          <InputRadio icon={faFilePdf} key={shortid.generate()} label={translate('common.document')} value="DOCUMENT_MAIN" />
        ])}

    </InputContainer>
  );
});

export default asInputRadio(asInput(ElementTypeChoice));