import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { quizElementModel } from 'components/model/dataformModel';
import {
  Button, Grid, Paper, Typography
} from '@material-ui/core';
import {
  faFolderOpen, faPollH, faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { DocumentHelper } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuizService } from 'services';
import { useSnackbar } from 'notistack';
import { useStores } from 'hooks';
import { ROUTES } from 'utils/constants';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'utils/translation';

export const QuizElement = observer(({ element, editionMode }) => {
  const { id } = element;

  const { programId, moduleId, sequenceId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { quizStore } = useStores();

  const [examination, setExamination] = useState(null);
  const [isExamFetching, setIsExamFetching] = useState(false);

  const loadLastExamination = useCallback(() => {
    setIsExamFetching(true);

    if (id && id.length > 0) {
      QuizService.getLastExamination({
        quizId: id, sequenceId, moduleId, programId
      })
        .then((response) => {
          if (typeof response === 'object') {
            setExamination(response);
            quizStore.setLastExamination(response);
          }
        })
        .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
        .finally(() => setIsExamFetching(false));
    }
  }, [id, enqueueSnackbar, quizStore, sequenceId, moduleId, programId]);

  useEffect(() => {
    loadLastExamination();
  }, [loadLastExamination]);

  const handleOpenQuiz = useCallback(() => {
    let quizRoute;
    if (programId != null) {
      quizRoute = ROUTES.PROGRAM_MODULE_SEQUENCE_QUIZ(programId, moduleId, sequenceId, id);
    } else if (moduleId != null) {
      quizRoute = ROUTES.MODULE_SEQUENCE_QUIZ(moduleId, sequenceId, id);
    } else if (sequenceId != null) {
      quizRoute = ROUTES.SEQUENCE_QUIZ(sequenceId, id);
    } else {
      quizRoute = ROUTES.QUIZ(id);
    }
    history.push({
      pathname: quizRoute,
      state: {}
    });
  }, [history, programId, moduleId, sequenceId, id]);

  const renderQuizItem = () => {
    if (!isExamFetching && (!examination || !examination.quiz)) {
      return null;
    }

    return (
      <>
        {isExamFetching
          ? (
            <Grid container spacing={2}>
              <Grid item>
                <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="lg" spin />
              </Grid>
              <Grid item>
                <Typography>{translate('warnings.quizLoading')}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Paper style={{ padding: 10 }} variant="outlined">
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="flex-start"
                spacing={2}
              >
                {examination?.quiz?.logo && (
                  <Grid item>
                    <img
                      alt={examination.quiz.title}
                      src={DocumentHelper.getDocumentWithBase64(examination.quiz.logo)}
                      style={{ maxHeight: 110, maxWidth: 110, padding: 10 }}
                    />
                  </Grid>
                )}
                <Grid item lg>
                  <Typography component="h2" variant="h5">{examination.quiz.title}</Typography>
                  {examination.quiz.description && (<Typography>{examination.quiz.description}</Typography>)}
                </Grid>
                <Grid alignItems="center" container item justifyContent="flex-end" lg={3}>
                  <Button
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={faFolderOpen} size="xs" />}
                    variant="contained"
                    onClick={handleOpenQuiz}
                  >
                    {translate('button.accessToQuiz')}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          )}
      </>
    );
  };

  return (
    id
      ? renderQuizItem()
      : editionMode && (
        <Paper style={{ padding: '1rem' }}>
          <Grid alignItems="center" container direction="column">
            <FontAwesomeIcon icon={faPollH} size="3x" style={{ marginBottom: '1rem' }} />
            {translate('forms.element.quiz.selectQuiz')}
          </Grid>
        </Paper>
      ));
});

QuizElement.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  element: quizElementModel.isRequired
};