import { ProgramDetail } from 'components';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const PageProgramDetail = () => {
  const { programId } = useParams();
  const { state } = useLocation();

  const isEditionMode = (state && state.isEditionMode) || false;

  return (
    <ProgramDetail isEditionMode={isEditionMode} programId={programId} />
  );
};

export default PageProgramDetail;