import React, { useCallback, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { QuizContainer, QuizStartPage } from 'components';
import { useStores } from 'hooks';
import PropTypes from 'prop-types';
import { QUIZ_STATUS } from './QuizHelper';
import { QuizEndPage } from '.';
import { SkeletonQuiz } from '../Skeletons';

const Quiz = observer(({ children, element }) => {
  const { ended } = QUIZ_STATUS;

  const { quizStore } = useStores();

  const { examinationState, isLoading } = quizStore;

  const {
    examinationId, quizId, isQuizStarted, isQuizEnded, currentPage
  } = examinationState;

  useEffect(() => {
    if (currentPage && Object.keys(currentPage).length > 0) {
      quizStore.setPageWithTraineeAnswers(currentPage);
      quizStore.canGoNextPage();
    }
  }, [quizStore, currentPage]);

  const renderSpecificQuizPart = useCallback(() => {
    if (isLoading) return <SkeletonQuiz />;

    if (examinationId && quizId === element.id) {
      if (isQuizStarted) {
        return <QuizContainer />;
      }
      if (isQuizEnded) {
        return <QuizEndPage quizStatus={ended} />;
      }
    }

    if (element && !isQuizStarted) {
      return <QuizStartPage element={element} />;
    }

    return null;
  }, [element, isLoading, isQuizStarted, isQuizEnded, examinationId, quizId, ended]);

  return (
    <Grid container direction="column" id={element.id}>
      <Grid item>
        <Paper style={{ padding: 20 }} variant="outlined">
          <Grid container direction="column">
            <Grid item>
              {children}
            </Grid>
            <Grid item>
              {renderSpecificQuizPart()}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
});

export default Quiz;

Quiz.propTypes = {
  children: PropTypes.node,
  element: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  })
};

Quiz.defaultProps = {
  children: null,
  element: PropTypes.shape({
    id: '',
    title: '',
    description: ''
  })
};