import { faUsersClass, faUsersSlash } from '@fortawesome/pro-regular-svg-icons';
import { SelectDocument, SwitchVisibility } from 'components';
import { asForm } from 'components/forms/hoc';
import { InputField } from 'components/forms/inputs';
import { sequenceModel } from 'components/model/dataformModel';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentService } from 'services';
import { translate } from 'utils/translation';
import { FormHeader } from '..';

const ProgramForm = observer(({ formData, updateProperty }) => {
  const [logo, setLogo] = useState(null);
  const [background, setBackground] = useState(null);

  const {
    isPublic, canSubscribe, name, code, logoURL, description, backgroundImage
  } = formData;

  useEffect(() => {
    if (logoURL) {
      const currentLogo = logoURL.split('/');
      logoURL && DocumentService.getDocumentDetails(currentLogo[currentLogo.length - 1])
        .then((response) => setLogo(response));
    }
  }, [logoURL]);

  useEffect(() => {
    if (backgroundImage) {
      const currentBackground = backgroundImage.split('/');
      backgroundImage && DocumentService.getDocumentDetails(currentBackground[currentBackground.length - 1])
        .then((response) => setBackground(response));
    }
  }, [backgroundImage]);

  const handleImageUpload = useCallback((fieldName, value) => {
    updateProperty(fieldName, value);
  }, [updateProperty]);

  const handleImageDelete = useCallback((fieldName) => {
    updateProperty(fieldName, null);
  }, [updateProperty]);

  return (
    <form autoComplete="off" id="programForm" name="programForm">
      <FormHeader
        subtitle="forms.program.modifyProgramData"
        title="forms.program.title"
      />

      <SwitchVisibility
        name="isPublic"
        value={isPublic}
        onChange={updateProperty}
      />

      {!isPublic && (
        <SwitchVisibility
          iconNo={faUsersSlash}
          iconYes={faUsersClass}
          name="canSubscribe"
          textNo="common.cannotSubscribe"
          textYes="common.canSubscribe"
          value={!!canSubscribe}
          onChange={updateProperty}
        />
      )}

      <InputField
        label={translate('common.title')}
        name="name"
        required
        value={name}
        onChange={updateProperty}
      />

      <InputField
        label={translate('common.code')}
        name="code"
        required
        value={code}
        onChange={updateProperty}
      />

      <InputField
        label={translate('common.description')}
        minRows={12}
        multiline
        name="description"
        required
        value={description}
        onChange={updateProperty}
      />

      <SelectDocument
        defaultDocument={logo}
        label={translate('forms.program.programLogo')}
        name="logoURL"
        onDelete={handleImageDelete}
        onSelect={handleImageUpload}
      />

      <SelectDocument
        defaultDocument={background}
        label={translate('forms.program.programBackground')}
        name="backgroundImage"
        onDelete={handleImageDelete}
        onSelect={handleImageUpload}
      />
    </form>
  );
});

export default asForm(ProgramForm);

ProgramForm.propTypes = {
  formData: sequenceModel.isRequired,
  updateProperty: PropTypes.func
};