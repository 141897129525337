import { Document } from 'components';
import { documentElementModel } from 'components/model/dataformModel';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const DocumentWrapper = styled.figure`
  margin: 0;
`;

export const DocumentElement = observer(({
  editionMode, element, isMain, isList
}) => {
  const { name, url } = element;

  return (
    <DocumentWrapper>
      <Document
        editionMode={editionMode}
        isList={isList}
        isMain={isMain}
        name={name}
        url={url}
      />
    </DocumentWrapper>
  );
});

DocumentElement.propTypes = {
  editionMode: PropTypes.bool,
  element: documentElementModel,
  isMain: PropTypes.bool
};

DocumentElement.defaultProps = {
  editionMode: false,
  isMain: false,
  name: '',
  url: ''
};