import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  MODULES_UID, PROGRAMS_UID, ROUTES, SEQUENCES_UID
} from 'utils/constants';

const PageSequenceFromQRCode = () => {
  const history = useHistory();
  const { name } = useParams();
  const { pathname } = useLocation();
  const sequenceUID = SEQUENCES_UID[name];
  const programUID = PROGRAMS_UID.ecler;
  const moduleUID = MODULES_UID['good-practice'];

  if (sequenceUID) {
    history.push(ROUTES.PROGRAM_MODULE_SEQUENCE(programUID, moduleUID, sequenceUID));
  } else if (pathname === '/ecler') {
    history.push(ROUTES.PROGRAM(programUID));
  } else {
    history.push(ROUTES.HOME_ECLER);
  }

  return <></>;
};

export default PageSequenceFromQRCode;