import { EmailValidator } from 'commons-validator-js';
import { translate } from 'utils/translation';

const formatDateForBackend = (date) => {
  if (date) {
    const tempDate = new Date(date);

    const adjustedDate = new Date(tempDate.getTime() - tempDate.getTimezoneOffset() * 60000);
    const formattedDate = adjustedDate.toISOString();

    return formattedDate.split('T')[0];
  }
  return null;
};

const checkValidity = (object, validityMap) => {
  // If the object is undefined, it is invalid
  if (!object) {
    return false;
  }

  // If there is nothing to check, the object is valid
  if (!validityMap) {
    return true;
  }

  // If at least one of the object's entries doesn't respect the validation map, the object is invalid
  return !Object.entries(validityMap).some(([key, value]) => {
    switch (value.type) {
      case 'VALID':
      // Check the entry using custom rules
        return value.validate && value.validate(object[key]);
      case 'REQUIRED':
      default:
      // Make sure the entry is defined
        if (!object[key] && object[key] !== 0) {
          return true;
        }
    }
    return false;
  });
};

const validateEmail = (email) => {
  if (!email) return null;
  const emailValidator = new EmailValidator();
  const isValid = emailValidator.isValid(email);
  if (!isValid) {
    return translate('errors.invalidEmail');
  }
  return null;
};

export const FormHelper = {
  formatDateForBackend,
  checkValidity,
  validateEmail
};