import {
  DocumentHelper, RequestHelper, StorageHelper, UserHelper
} from './helpers';
import { KeyCloakUtils } from './keycloak/keycloakUtils';

export const pluralize = (string, length, defaultValue) => (
  length > 1 ? `${string}s` : (defaultValue || string)
);

export const RandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  return crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32;
};

export const generateUUID = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
  // eslint-disable-next-line no-bitwise
  const r = RandomNumber() * 16 | 0;
  const v = c === 'x' ? r : ((r && 0x3) || 0x8);
  return v.toString(16);
});

export const transformObjectListToStringQueriesParam = (objectList) => {
  if (!objectList.length) return '';

  let queriesParams = '&';

  const addArrayToQuery = (i, val, index, arrayOfValueLength) => {
    queriesParams += `${objectList[i].key}=${val.value}`;
    if (index < arrayOfValueLength - 1) queriesParams += '&';
  };

  for (let i = 0; i < objectList.length; i += 1) {
    // If the params is a SelectItem
    if (objectList[i].value) {
      // If we have an array of SelectItem
      if (objectList[i].value.length) {
        const arrayOfValueLength = objectList[i].value.length;
        objectList[i].value.map((val, index) => addArrayToQuery(i, val, index, arrayOfValueLength));
      } else {
        queriesParams += `${objectList[i].key}=${objectList[i].value.value}`;
      }
    } else {
      queriesParams += `${objectList[i].key}=${objectList[i].label}`;
    }

    if (i < objectList.length - 1) queriesParams += '&';
  }

  return queriesParams;
};

export {
  RequestHelper,
  StorageHelper,
  UserHelper,
  KeyCloakUtils,
  DocumentHelper
};