import React, { useCallback, useState } from 'react';
import { faFileExport, faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { Button, ButtonContainer, DropdownButton } from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { useModal } from 'hooks';
import { UserHelper } from 'utils';
import { Grid } from '@material-ui/core';
import { ROLE_GROUPS } from 'utils/constants';
import { ButtonDownloadCatalog } from 'components/_commons/Button';
import { SubscriptionService } from 'services';
import { translate } from 'utils/translation';

export const ProgramListNav = () => {
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [isExporting, setIsExporting] = useState(false);

  const handleImportSubscriptions = useCallback(() => showModal({
    type: 'IMPORT_FILE',
    isAsync: true,
    onConfirm: () => enqueueSnackbar(translate('confirms.importTraineesIsOnGoing'), { variant: 'success' })
  }), [showModal, enqueueSnackbar]);

  // const handleDocumentDownload = useCallback((resp) => {
  //   const link = document.createElement('a');
  //   link.download = resp.name;
  //   link.href = DocumentHelper.getExcelWithBase64(resp.base64Content);
  //   link.click();
  //   enqueueSnackbar(translate('confirms.exportTrainees'), { variant: 'success' });
  // }, [enqueueSnackbar]);

  const handleExportTrainees = useCallback(() => {
    setIsExporting(true);

    SubscriptionService.exportTrainees({})
      .then(() => enqueueSnackbar(translate('confirms.exportTraineesIsOnGoing'), { variant: 'success', autoHideDuration: 5000 }))
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 5000 }))
      .finally(() => setIsExporting(false));
  }, [enqueueSnackbar]);

  return (
    <HeaderEditionNav>
      <Grid alignItems="flex-end" container>
        <Grid item>
          <ButtonContainer>
            {UserHelper.hasAccessRight(ROLE_GROUPS.ADMINISTRATORS) && (
              <>
                <DropdownButton
                  color="secondary"
                  icon={faFileImport}
                  label="pageProgramDetail.importTrainees"
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <a
                        href={`${process.env.PUBLIC_URL}/assets/files/import-stagiaires-admin.xlsx`}
                        title="Fichier d'exemple pour l'import d'abonnements"
                      >
                        {translate('common.exampleFile')}
                      </a>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<FontAwesomeIcon icon={faFileImport} />}
                        type="secondary"
                        onClick={handleImportSubscriptions}
                      >
                        {translate('button.importSubscriptions')}
                      </Button>
                    </Grid>
                  </Grid>
                </DropdownButton>

                <Button
                  disabled={isExporting}
                  startIcon={(
                    <FontAwesomeIcon
                      icon={isExporting ? faSpinner : faFileExport}
                      spin={isExporting}
                    />
                  )}
                  type="secondary"
                  onClick={handleExportTrainees}
                >
                  {translate('button.exportTrainees')}
                </Button>
              </>
            )}

            <ButtonDownloadCatalog />
          </ButtonContainer>
        </Grid>
      </Grid>

    </HeaderEditionNav>
  );
};