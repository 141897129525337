
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { API_URL_DOCUMENTS, ROLE_GROUPS } from 'utils/constants';
import { LargeWrapper } from 'components/_commons/Wrapper';
import { observer } from 'mobx-react-lite';
import { useStores } from 'hooks';
import { UserHelper } from 'utils';
import { getFormat, SequenceList, ShowMoreBanner } from './_commons';

const ModuleHeaderContainer = styled.div`
  background-color: var(--blue-light);
`;

const ShowCaseContainer = styled.section`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.contained
    ? `calc(100vh - ${props.headerHeight} - var(--showMore-height) - var(--moduleHeader-height))`
    : `calc(100vh - ${props.headerHeight} - var(--moduleHeader-height))`)};
  overflow-y: ${(props) => (props.contained ? 'auto' : 'visible')};
`;

const SequenceContainer = styled(LargeWrapper)`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  padding-top: 1rem;
  padding-bottom: 3rem;
  background-position:  center center;
  background-repeat: no-repeat;
  ${(props) => props.background && css`
    background-image: url(${API_URL_DOCUMENTS}${props.background});
  `}
  background-size: auto 80%;

  @media (orientation: landscape) {
    background-size: auto 80%;
  }

  @media (orientation: portrait) {
    background-size: 40% auto;
  }

  @media(max-width: 960px) {
    justify-content: start;
  }

  @media(max-width: 600px) {
    background-size: contain;
  }
`;


export const ModuleHeader = observer(({
  section, editionMode
}) => {
  const { userStore, moduleStore } = useStores();
  const { format: formatType, backgroundImage } = section;
  const { sequenceSummaries: sequences } = moduleStore.module;
  const { isConnected } = userStore;

  const {
    nbColumnsInBackShop,
    nbColumnsInShowCase,
    nbRowsInShowCase,
    nbRowsInBackShop
  } = getFormat(formatType, sequences);

  const { moduleId, programId } = useParams();

  const [displayAll, setDisplayAll] = useState(false);
  const displayAllSequences = useCallback(() => setDisplayAll(true), []);

  useEffect(() => {
    if (displayAll) {
      window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight });
    }
  }, [displayAll]);

  const cssHeaderSize = isConnected
    && UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS)
    ? 'var(--header-with-edition-bar-height)'
    : 'var(--header-nav-height)';


  return (
    <ModuleHeaderContainer>
      <ShowCaseContainer contained={(displayAll || nbRowsInBackShop <= 0) ? 0 : 1} headerHeight={cssHeaderSize}>
        <SequenceContainer background={backgroundImage} data-testid="sequence-container">
          <SequenceList
            moduleId={moduleId}
            nbColumns={nbColumnsInShowCase}
            nbRows={nbRowsInShowCase}
            programId={programId}
            sequences={sequences}
          />
        </SequenceContainer>
      </ShowCaseContainer>

      {!displayAll && nbRowsInBackShop > 0
        && <ShowMoreBanner editionMode={editionMode} onClick={displayAllSequences} />}

      {(editionMode || displayAll) && (
        <LargeWrapper>
          <SequenceList
            moduleId={moduleId}
            nbColumns={nbColumnsInBackShop}
            nbPastSequences={nbRowsInShowCase * nbColumnsInShowCase}
            nbRows={nbRowsInBackShop}
            programId={programId}
            sequences={sequences}
          />
        </LargeWrapper>
      )}
    </ModuleHeaderContainer>
  );
});

ModuleHeader.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    format: PropTypes.string,
    backgroundImage: PropTypes.string
  }).isRequired,
  editionMode: PropTypes.bool.isRequired
};