import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import React from 'react';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { PropTypes } from 'prop-types';

const ShowMoreContainer = styled.aside`
  margin-top: -5rem;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

const ArrowScroll = styled.div`
  position: relative;
  top: 0px;
  transition: bottom var(--transitionTime);
  animation: upAndDown 1.1s ease-in infinite 10s;
  cursor: pointer;

  @keyframes upAndDown {
    50% { top: -2rem; }
    100% { top: 0px; }
  }
`;

const SeeMoreBanner = styled.div`
  background-color: white;
  padding: 1rem 0;
  width: 100%;
  height: var(--showMore-height);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;


export const ShowMoreBanner = ({ onClick, editionMode }) => (
  <ShowMoreContainer>
    <ArrowScroll onClick={onClick}>
      <FontAwesomeIcon color="var(--success-color)" icon={faChevronDown} size="3x" />
    </ArrowScroll>
    {!editionMode && (
      <SeeMoreBanner>
        <Button
          startIcon={<FontAwesomeIcon icon={faChevronDown} />}
          type="primary"
          onClick={onClick}
        >
          {translate('button.showMore')}
        </Button>
      </SeeMoreBanner>
    )}
  </ShowMoreContainer>
);

ShowMoreBanner.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};