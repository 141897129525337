import { ButtonCreateDocument, SelectDocument } from 'components';
import { InputField, SelectField, SwitchVisibility } from 'components/forms/inputs';
import { asForm } from 'components/forms/hoc';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'utils/translation';
import { sequenceModel } from 'components/model/dataformModel';
import { DocumentService } from 'services';
import { useStores } from 'hooks';
import { InputAdornment } from '@material-ui/core';
import { faBook, faBookAlt } from '@fortawesome/pro-regular-svg-icons';
import { FormHeader } from '..';

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
const SequenceForm = observer(({ formData, updateProperty }) => {
  const { referenceDataStore, sequenceStore } = useStores();
  const [logo, setLogo] = useState(null);

  const {
    name, description, logoURL, code, reference, author, nature,
    modality, duration, additionalDurationInformation, accessRights,
    copyright, location, accessMode, isPublic, isIndependent
  } = formData;

  const requiredFields = [
    accessMode, name, description, code, reference, author, nature, modality, duration, accessRights, copyright, location
  ];

  const getRefData = useCallback((key) => referenceDataStore.getRefData(key), [referenceDataStore]);

  useEffect(() => {
    if (logoURL) {
      const currentLogo = logoURL.split('/');
      logoURL && DocumentService.getDocumentDetails(currentLogo[currentLogo.length - 1])
        .then((response) => setLogo(response));
    }
    referenceDataStore.getAccessModes();
  }, [logoURL, referenceDataStore]);

  const handleImageDelete = useCallback(() => {
    updateProperty('logoURL', null);
  }, [updateProperty]);

  const handleImageUpload = useCallback((fieldName, value) => {
    updateProperty(fieldName, value);
  }, [updateProperty]);

  const isValidForm = useCallback(() => sequenceStore.isSequenceEditionFormValid(requiredFields), [
    sequenceStore,
    requiredFields
  ]);

  useEffect(() => {
    isValidForm();
  }, [isValidForm]);

  return (
    <form autoComplete="off" id="sequenceForm" name="sequenceForm">
      <FormHeader
        subtitle="forms.sequence.modifySequenceData"
        title="forms.sequence.title"
      />
      <SwitchVisibility
        name="isPublic"
        value={Boolean(isPublic)}
        onChange={updateProperty}
      />
      <SwitchVisibility
        iconNo={faBookAlt}
        iconYes={faBook}
        name="isIndependent"
        textNo="common.isNotIndependentSequence"
        textYes="common.isIndependentSequence"
        value={Boolean(isIndependent)}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={false}
        label="referenceData.ACCESS_MODE"
        name="accessMode"
        options={referenceDataStore.accessModes}
        required
        value={accessMode}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.title')}
        name="name"
        required
        value={name}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.description')}
        minRows={3}
        multiline
        name="description"
        required
        tooltip={translate('forms.sequence.descriptionInfo')}
        value={description}
        onChange={updateProperty}
      />
      <div style={{ margin: '1rem 0 1.5rem 0' }}>
        <ButtonCreateDocument />
      </div>
      <SelectDocument
        defaultDocument={logo}
        label={translate('forms.sequence.chooseLogo')}
        name="logoURL"
        onDelete={handleImageDelete}
        onSelect={handleImageUpload}
      />
      <InputField
        label={translate('common.code')}
        name="code"
        required
        value={code}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.reference')}
        name="reference"
        required
        value={reference}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.author')}
        name="author"
        required
        value={author}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('NATURE').multiple}
        label="referenceData.NATURE"
        name="nature"
        options={getRefData('NATURE').values}
        required
        value={nature}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('MODALITY').multiple}
        label="referenceData.MODALITY"
        name="modality"
        options={getRefData('MODALITY').values}
        required
        value={modality}
        onChange={updateProperty}
      />
      <InputField
        InputProps={{
          inputProps: { min: 0 },
          endAdornment: (
            <InputAdornment position="end">min</InputAdornment>
          )
        }}
        label={translate('common.durationMin')}
        name="duration"
        required
        type="number"
        value={duration}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.additionalDurationInformation')}
        name="additionalDurationInformation"
        value={additionalDurationInformation}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('ACCESS_RIGHTS').multiple}
        label="referenceData.ACCESS_RIGHTS"
        name="accessRights"
        options={getRefData('ACCESS_RIGHTS').values}
        required
        value={accessRights}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('COPYRIGHT').multiple}
        label="referenceData.COPYRIGHT"
        name="copyright"
        options={getRefData('COPYRIGHT').values}
        required
        value={copyright}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('LOCATION').multiple}
        label="referenceData.LOCATION"
        name="location"
        options={getRefData('LOCATION').values}
        required
        value={location}
        onChange={updateProperty}
      />
      {/* <SelectField
        isMulti={getRefData('LANGUAGE').multiple}
        label="referenceData.LANGUAGE"
        name="language"
        options={getRefData('LANGUAGE').values}
        value={language}
        onChange={updateProperty}
      /> */}

      {/* Addionnal div to have some space at the end to allow the last selects to open correctly */}
      <div style={{ height: '100px' }} />
    </form>
  );
});

export default asForm(SequenceForm);

SequenceForm.propTypes = {
  formData: sequenceModel.isRequired,
  updateProperty: PropTypes.func
};