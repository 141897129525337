import { action, decorate, observable } from 'mobx';
import { DocumentService } from 'services';


export class DocumentStore {
  constructor() {
    this.isLoading = false;
    this.isError = false;
    this.error = null;
    this.maxPage = 0;
    this.totalElements = 0;
    this.documentList = [];
  }

  loadDocumentList({
    search, size, filters, currentPage
  }) {
    if (this.documentList.length === 0) {
      this.isLoading = true;
    }

    DocumentService.findDocuments({
      search, page: currentPage, size, filters
    }).then(action((response) => {
      this.maxPage = response.totalPages - 1;
      this.totalElements = response.totalElements;

      if (currentPage === 0) {
        this.documentList = response.content;
      } else {
        this.documentList = this.documentList.concat(response.content);
      }

      this.isError = false;
      this.error = null;
    })).catch((error) => {
      this.error = error;
      this.isError = true;
    }).finally(action(() => {
      this.isLoading = false;
    }));
  }
}

decorate(DocumentStore, {
  isLoading: observable,
  isError: observable,
  error: observable,
  documentList: observable,
  maxPage: observable,
  totalElements: observable,
  loadDocumentList: action
});


export const documentStore = new DocumentStore();