import { faBars, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Fab, Grid, IconButton, Tooltip
} from '@material-ui/core';
import { Button } from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import {
  Link, useHistory, useLocation, useParams
} from 'react-router-dom';
import { UserAccountService } from 'services';
import styled, { css } from 'styled-components';
import { KeyCloakUtils } from 'utils';
import { APP_PROFILE, ROUTES } from 'utils/constants';
import logger from 'utils/logger';
import { browserDetectionHelper } from 'utils/helpers/browserDetectionHelper';
import { translate } from 'utils/translation';
import { SideMenu } from './SideMenu';
import { HeaderMenu } from './HeaderMenu';

const HeaderContainer = styled.div`
  position: relative;
  height: var(--header-nav-height);
  z-index: var(--zindexBig);
  background: ${APP_PROFILE === 'AFF'
    ? css` var(--header-blue)`
    : css`var(--header-grey) url('/assets/images/bg/header.png')`};
  background-size: contain;
  background-position: var(--header-item-width) center;
  background-repeat: no-repeat;
`;

const CustomGridItem = styled(Grid)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--header-item-width);
  height: var(--header-nav-height);
  background: var(--white);

  a {
    display: flex;
    align-items: center;
    height: 100%
  }

  img {
    max-width: 100%;
    height: 90%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -120px;
    border-left: 60px solid var(--white);
    border-right: 60px solid transparent;
    border-bottom: 60px solid transparent;
  }

  @media (max-width: 690px) {
    display: none;
  }
`;

const GridUser = styled(Grid)`
  width: var(--header-item-width);
  padding-right: 2rem;
  height: var(--header-nav-height);

  @media (max-width: 690px) {
    width: auto;
  }
`;

const Logo = styled(Link)`
  color: var(--white);
  font-size: 2.6rem;

  &:hover {
    text-decoration: none;
    color: var(--grey-light);
  }

  @media (max-width: 690px) {
    margin-left: 2rem;
  }
`;

const ButtonChangeLanguage = styled(IconButton)`
  &:disabled {
    svg {
      display: none;
    }
  }

  span {
    font-size: 1.7rem;
    color: var(--white);
  }
`;

const UserName = styled.span`
  max-width: 120px;
  color: var(--white);
  margin-right: 2rem;

  @media (max-width: 690px) {
    display: none;
  }
`;

const StyledButtonMenu = styled(Fab).attrs({
  size: 'small'
})`
  position: relative;

  span {
    font-size: 1.4rem;
  }

  .buttonMenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--primary-color);
    background: var(--white);
  }
`;

export const Header = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    programId, moduleId, sequenceId, quizId
  } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    userStore, editionStore, i18nStore, quizStore
  } = useStores();
  const { isConnected, userConnected } = userStore;
  const { currentLanguage, languageList } = i18nStore;

  const { isInFullScreen } = quizStore;

  const [toggleMenu, setToggleMenu] = useState(false);
  const showModal = useModal();

  const getBackLink = () => {
    if (programId && moduleId && sequenceId && quizId) {
      // QUIZ PAGE -> SEQUENCE
      return ({ name: translate('menu.backToSequence'), url: ROUTES.PROGRAM_MODULE_SEQUENCE(programId, moduleId, sequenceId) });
    }
    if (moduleId && sequenceId && quizId) {
      // QUIZ PAGE -> SEQUENCE
      return ({ name: translate('menu.backToSequence'), url: ROUTES.MODULE_SEQUENCE(moduleId, sequenceId) });
    }
    if (sequenceId && quizId) {
      // QUIZ PAGE -> SEQUENCE
      return ({ name: translate('menu.backToSequence'), url: ROUTES.SEQUENCE(sequenceId) });
    }
    if (programId && moduleId && sequenceId) {
      // PROGRAM_MODULE_SEQUENCE -> PROGRAM_MODULE_DETAIL
      return ({ name: translate('menu.backToModule'), url: ROUTES.PROGRAM_MODULE_DETAIL(programId, moduleId) });
    }
    if (moduleId && sequenceId) {
      // MODULE_SEQUENCE -> MODULE_DETAIL
      return ({ name: translate('menu.backToModule'), url: ROUTES.MODULE_DETAIL(moduleId) });
    }
    if (programId && moduleId && pathname.indexOf('detail') > -1) {
      // PROGRAM_MODULE_DETAIL -> PROGRAM_MODULE
      return ({ name: translate('menu.backToModuleSheet'), url: ROUTES.PROGRAM_MODULE(programId, moduleId) });
    }
    if (moduleId && pathname.indexOf('detail') > -1) {
      // MODULE_DETAIL -> MODULE
      return ({ name: translate('menu.backToModuleSheet'), url: ROUTES.MODULE(moduleId) });
    }
    if (programId && moduleId) {
      // PROGRAM_MODULE -> PROGRAM
      return ({ name: translate('menu.backToProgram'), url: ROUTES.PROGRAM(programId) });
    }
    if (programId || moduleId) {
      // PROGRAM || MODULE -> CATALOG
      return ({ name: translate('menu.backToProgramList'), url: ROUTES.CATALOG });
    }
    return null;
  };

  const backLink = getBackLink();

  const connectUser = useCallback(() => {
    if (userStore.keycloak) {
      userStore.keycloakLogin().catch((error) => {
        logger.error('keycloak error : ', error);
        showModal({ type: 'ERROR', text: (error && error.error_description) || error });
      });
    } else {
      KeyCloakUtils.init().then((keycloak) => {
        userStore.connectUser(keycloak);
      }).catch((error) => {
        logger.error('keycloak error : ', error);
        showModal({ type: 'ERROR', text: (error && error.error_description) || error });
      });
    }
  }, [userStore, showModal]);

  const handleToggleMenu = useCallback(() => setToggleMenu(!toggleMenu), [toggleMenu]);

  const followLink = useCallback((link) => {
    history.push(link);
    editionStore.reset();
  }, [history, editionStore]);

  const displayWarningModal = useCallback((onConfirm) => showModal({
    type: 'WARNING',
    title: 'forms.titleWarningLeaveEdition',
    text: 'forms.warningLeaveEdition',
    onConfirm
  }), [showModal]);

  const displayPopupBrowserModal = useCallback(() => showModal({
    type: 'BROWSER_POPUP',
    title: translate('warnings.warning'),
    primaryText: translate('browserModal.popupWarningBrowserPrimary'),
    secondaryText: translate('browserModal.popupWarningBrowserSecondary')
  }),
  [showModal]);

  useEffect(() => {
    browserDetectionHelper.modalDisplayControl(displayPopupBrowserModal);
  }, [displayPopupBrowserModal]);

  const handleLinkClick = useCallback((event) => {
    event.preventDefault();

    const { link } = event.currentTarget.dataset;
    if (editionStore.editionMode && !editionStore.lastEditionSaved) {
      displayWarningModal(() => followLink(link));
    } else {
      followLink(link);
    }
  }, [editionStore, followLink, displayWarningModal]);

  const handleChangeLanguage = useCallback((language) => {
    if (language) {
      if (userStore.isConnected && language.value !== localStorage.getItem('i18nextLng')) {
        UserAccountService.updateLocale(language.value).then(() => {
          i18nStore.loadLanguage(language.value);
          userStore.keycloak.updateToken(999999)
            .then((refreshed) => {
              refreshed && userStore.keycloak.login();
            })
            .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }));
        });
      } else {
        i18nStore.loadLanguage(language.value);
      }
    }
  }, [enqueueSnackbar, userStore.isConnected, userStore.keycloak, i18nStore]);

  const displayModalLanguage = useCallback(() => showModal({
    type: 'CHANGE_LANGUAGE',
    onConfirm: (language) => handleChangeLanguage(language),
    defaultValues: currentLanguage
  }), [showModal, handleChangeLanguage, currentLanguage]);

  const displayUserName = useCallback(() => (
    userConnected.family_name && userConnected.given_name ? (
      <span>{`${userConnected.given_name.charAt(0)}${userConnected.family_name.charAt(0)}`}</span>
    ) : <span>N/A</span>
  ), [userConnected]);

  return (
    <>
      {!isInFullScreen && (
        <>
          <HeaderContainer>
            <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
              <CustomGridItem item>
                <Link to={ROUTES.HOME}>
                  {APP_PROFILE === 'AFF' ? (
                    <img alt="AFF_GROUP" src="/assets/images/partners/AFF_logo.jpg" />
                  ) : (
                    <img alt="TECNEA_GROUP" src="/assets/images/partners/logo-tecnea.svg" />
                  )}
                </Link>
              </CustomGridItem>

              <Grid item>
                <Logo data-link={ROUTES.HOME} to={ROUTES.HOME} onClick={handleLinkClick}>
                  {APP_PROFILE === 'AFF' ? (
                    <>
                      <strong>CLIM&apos;</strong>
                      ECO
                      <sup>®</sup>
                    </>
                  ) : (
                    <>
                      <strong>DATA</strong>
                      FORM
                      <sup>®</sup>
                    </>
                  )}
                </Logo>
              </Grid>
              <Grid item>
                <GridUser
                  alignItems="center"
                  container
                  justifyContent="flex-end"
                >
                  {languageList.length > 1
                    && (
                      <Grid item style={{ paddingRight: isMobileOnly ? 0 : '2rem' }}>
                        <Tooltip
                          title={translate('modalChangeLanguage.tooltip', { language: currentLanguage.toUpperCase() })}
                        >
                          <span>
                            <ButtonChangeLanguage onClick={displayModalLanguage}>
                              <span className="mr1">{currentLanguage.split('_')[0].toUpperCase()}</span>
                              <FontAwesomeIcon color="#ffffff" icon={faChevronDown} size="xs" />
                            </ButtonChangeLanguage>
                          </span>
                        </Tooltip>
                      </Grid>
                    )}
                  {isConnected ? (
                    <>
                      <UserName>{userConnected.name}</UserName>
                      <StyledButtonMenu color="primary" data-cy="headerMenu" data-tour="step-header-user" onClick={handleToggleMenu}>
                        {displayUserName()}
                        <span className="buttonMenuIcon">
                          <FontAwesomeIcon icon={faBars} size="xs" />
                        </span>
                      </StyledButtonMenu>
                    </>
                  ) : (
                    <Button
                      startIcon={<FontAwesomeIcon icon={faUserCircle} />}
                      type="secondary"
                      onClick={connectUser}
                    >
                      {translate('button.connect')}
                    </Button>
                  )}
                </GridUser>
              </Grid>
            </Grid>
            <SideMenu isOpen={toggleMenu} setToggleMenu={setToggleMenu} />
          </HeaderContainer>
          <HeaderMenu backLink={backLink} handleLinkClick={handleLinkClick} />
        </>
      )}
    </>
  );
});