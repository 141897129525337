import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStores } from 'hooks';
import {
  Button, Grid, Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';
import shortid from 'shortid';
import { buildDefaultAndTraineeAnswers } from '../../QuizHelper';

const RatingQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { isAnswered } = currentQuestionData;

  const { quizStore } = useStores();

  const { currentPage } = quizStore;

  const [defaultAnswers, setDefaultAnswers] = useState({});
  const [ratingValues, setRatingValues] = useState([]);
  const [currentValue, setCurrentValue] = useState();

  const buildAnswers = useCallback(() => {
    setDefaultAnswers(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  const buildRatingValues = useCallback(() => {
    const values = [];
    for (let i = defaultAnswers.minValue; i <= defaultAnswers.maxValue; i++) {
      values.push(i);
    }
    setRatingValues(values);
    setCurrentValue(defaultAnswers.answeredValue);
  }, [defaultAnswers]);

  useEffect(() => {
    buildRatingValues();
  }, [buildRatingValues]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleRadioChange = useCallback((e, ratingVal) => {
    if (isAnswered) return e.preventDefault();

    setCurrentValue(ratingVal);

    quizStore.setTraineeAnswers(currentPage, currentQuestionData, ratingVal);
  }, [currentPage, currentQuestionData, quizStore, isAnswered]);

  if (ratingValues.length === 0) return null;

  return (
    <Grid
      alignItems="flex-start"
      container
      direction="column"
      spacing={2}
      style={{ margin: '10px 0px' }}
    >
      <Grid item>
        <Grid alignItems="center" container spacing={2}>
          <Grid item>
            <Typography>
              <strong>
                {`${ratingValues[0]}: ${defaultAnswers.minValueLabel}`}
              </strong>
            </Typography>
          </Grid>
          <Grid item>
            <FontAwesomeIcon icon={faChevronDoubleRight} />
          </Grid>
          <Grid item>
            <Typography>
              <strong>
                {`${ratingValues[ratingValues.length - 1]}: ${defaultAnswers.maxValueLabel}`}
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid alignItems="center" container direction="row" spacing={1}>
          {ratingValues && ratingValues.map((ratingValue) => (
            <Grid item key={shortid.generate()}>
              <Button
                color={currentValue === ratingValue ? 'primary' : 'secondary'}
                disabled={currentValue !== ratingValue && isReadOnlyQuestion}
                id="quiz-validate-btn"
                style={{ minWidth: '40px', fontSize: '1.6rem' }}
                variant="contained"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={(e) => handleRadioChange(e, ratingValue)}
              >
                {ratingValue}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default RatingQuestion;

RatingQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

RatingQuestion.defaultProps = {
  currentQuestionData: {}
};