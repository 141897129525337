import { action, decorate, observable } from 'mobx';

export class UserStore {
  constructor() {
    this.isConnected = false;
    this.keycloak = null;
    this.userConnected = null;
  }

  connectUser(keycloak) {
    this.keycloak = keycloak;

    if (keycloak && keycloak.tokenParsed) {
      this.isConnected = true;
      this.userConnected = keycloak.tokenParsed;
    }
  }

  disconnectUser() {
    this.keycloak.logout();
    this.keycloak.clearToken();
    this.isConnected = false;
    this.userConnected = null;
  }

  getToken() {
    return this.keycloak ? this.keycloak.token : null;
  }

  isTokenExpired() {
    return this.keycloak ? this.keycloak.isTokenExpired() : null;
  }

  refreshToken() {
    return this.keycloak.updateToken();
  }

  keycloakLogin() {
    return this.keycloak.login();
  }
}

decorate(UserStore, {
  isConnected: observable,
  connectUser: action,
  disconnectUser: action,
  getToken: action,
  isTokenExpired: action,
  refreshToken: action,
  keycloakLogin: action
});


export const userStore = new UserStore();