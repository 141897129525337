import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';
import { translate } from 'utils/translation';

const ConfirmModal = ({
  onClose, onSubmit, text, title
}) => {
  const handleClose = useCallback(() => {
    if (onSubmit) { onSubmit(); }
    return onClose();
  }, [onSubmit, onClose]);

  return (
    <>
      <DialogTitle className="confirmModal">
        <FontAwesomeIcon icon={faCheckCircle} />
        {translate(title)}
      </DialogTitle>

      <DialogContent dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />

      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          {translate('button.close')}
        </Button>
      </DialogActions>
    </>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

ConfirmModal.defaultProps = {
  onSubmit: null,
  text: '',
  title: 'common.actionSuccessful'
};

export default ConfirmModal;