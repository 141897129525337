import { Text } from 'components';
import { observer } from 'mobx-react-lite';

import React from 'react';
import styled from 'styled-components';
import { imageElementModel } from 'components/model/dataformModel';
import { API_URL_DOCUMENTS } from 'utils/constants';

const Figure = styled.figure`
  margin: 0;

  img {
    width: 100%;
  }

  figcaption {
    margin: auto;
    text-align: center;
  }
`;

export const ImageElement = observer(({ element }) => {
  const { url, title, legend } = element;

  return (
    <>
      {url && (
        <Figure>
          <img alt={title} src={`${API_URL_DOCUMENTS}${url}`} />
          {legend && (
            <figcaption>
              <Text margin="0" textAlign="center">
                {legend}
              </Text>
            </figcaption>
          )}
        </Figure>
      )}
    </>
  );
});

ImageElement.propTypes = {
  element: imageElementModel.isRequired
};