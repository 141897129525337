import { documentSection } from '__fixtures__/section/document_section';
import { genericSection1, genericSection11 } from '__fixtures__/section/generic_section';
import { moduleHeader } from '__fixtures__/section/module_header';
import { programHeader } from '__fixtures__/section/program_header';
import { quizSection } from '__fixtures__/section/quiz_section';

export const getSequenceContent = () => ({
  sections: [
    genericSection11(0),
    genericSection1(1),
    documentSection(2),
    quizSection(3)
  ]
});

export const getProgramContent = () => ({
  sections: [
    programHeader()
  ]
});

export const getModuleContent = () => ({
  sections: [
    moduleHeader()
  ]
});