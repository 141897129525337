/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ReorderableList, SelectDocument
} from 'components';
import { SelectField, StyledListItem } from 'components/forms/inputs';
import { translate } from 'utils/translation';
import {
  Avatar, ListItemAvatar, ListItemText
} from '@material-ui/core';
import { DocumentService, SequenceService } from 'services';
import { API_URL_DOCUMENTS } from 'utils/constants';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { FormHeader } from 'components/forms';
import { getSmallDescription } from 'utils/helpers';
import { ModuleFormatChoice } from '../../choices';

export const ModuleHeaderForm = observer(({
  updateProperty, formData
}) => {
  const [allSequences, setAllSequences] = useState([]);
  const [background, setBackground] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { moduleStore } = useStores();
  const { backgroundImage } = formData;
  const { module: { sequenceSummaries = [] } } = moduleStore;

  useEffect(() => {
    // TODO: limit size of response and allow backend filtering
    SequenceService.getAllSequences({ size: 1000 }).then((response) => {
      setAllSequences(response.content);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (backgroundImage) {
      const currentBg = backgroundImage.split('/');
      backgroundImage && DocumentService.getDocumentDetails(currentBg[currentBg.length - 1])
        .then((response) => setBackground(response));
    }
  }, [backgroundImage]);

  const updateSequenceSummaries = useCallback((newSeqSum) => {
    updateProperty('sequenceSummaries', newSeqSum);
    runInAction('UpdateSequenceSummaries', () => {
      moduleStore.updateSequenceSummaries(newSeqSum);
    });
  }, [updateProperty, moduleStore]);

  const handleImageDelete = useCallback(() => {
    updateProperty('backgroundImage', null);
  }, [updateProperty]);

  const handleImageUpload = useCallback((fieldName, value) => {
    updateProperty(fieldName, value);
  }, [updateProperty]);

  const handleAddSequences = useCallback((_, value) => {
    if (value) {
      const newSeqSum = [...sequenceSummaries];
      newSeqSum.push(value);
      updateSequenceSummaries(newSeqSum);
    }
  }, [sequenceSummaries, updateSequenceSummaries]);

  const handleDeleteSequence = useCallback((seq) => {
    const newSeqSum = sequenceSummaries.filter((seqSum) => seqSum.id !== seq.id);
    updateSequenceSummaries(newSeqSum);
  }, [sequenceSummaries, updateSequenceSummaries]);

  const CustomFilter = useCallback((option, search) => (
    option.data.name.toLowerCase().includes(search.toLowerCase())
    || (option.data.description && option.data.description.toLowerCase().includes(search.toLowerCase()))
  ), []);

  const CustomOptionSequenceSummaries = ({ data, innerProps }) => (
    <StyledListItem {...innerProps}>
      {data.logoURL && (
        <ListItemAvatar>
          <Avatar
            src={`${API_URL_DOCUMENTS}${data.logoURL}`}
            style={{ boxShadow: 'rgba(0,0,0,0.2) 0px 0px 10px' }}
          />
        </ListItemAvatar>
      )}
      <ListItemText primary={data.name} secondary={getSmallDescription(data.description)} />
    </StyledListItem>
  );

  const allSequencesAvailable = allSequences.filter((seq) => sequenceSummaries
    .filter((seqSum) => seqSum.id === seq.id).length === 0);

  return (
    <>
      <FormHeader title="forms.module.moduleFormat" />

      <ModuleFormatChoice formData={formData} updateProperty={updateProperty} />

      <SelectDocument
        defaultDocument={background}
        label={translate('forms.module.moduleImage')}
        name="backgroundImage"
        onDelete={handleImageDelete}
        onSelect={handleImageUpload}
      />

      <SelectField
        closeMenuOnSelect={false}
        components={{ Option: CustomOptionSequenceSummaries }}
        filterOption={CustomFilter}
        isLoading={isLoading}
        label="forms.module.addSequence"
        /* eslint-disable-next-line react/jsx-no-bind */
        loadingMessage={() => translate('common.loading')}
        messageNoOptions="warnings.noSequenceAvailable"
        name="sequenceSummaries"
        options={allSequencesAvailable}
        placeholder="common.search"
        onChange={handleAddSequences}
      />

      <ReorderableList
        list={sequenceSummaries}
        title={`${translate('common.sequenceSummaries')} :`}
        onDelete={handleDeleteSequence}
        onReorder={updateSequenceSummaries}
      />
    </>
  );
});