const quiz = {
  title: 'Exemple de quiz ECLER',
  description: 'Super quiz sur ECLER qui claque.',
  showProgressBar: 'bottom',

  questions: [
    {
      question: 'How can you access the state of a component from inside of a member function?',
      answerType: 'text',
      multipleAnswers: false,
      answers: [
        'this.getState()',
        'this.prototype.stateValue',
        'this.state',
        'this.values'
      ],
      correctAnswersIds: [2],
      messageForCorrectAnswer: 'Correct answer. Good job.',
      messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
      explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      points: 20
    },
    {
      question: 'ReactJS is developed by _____?',
      answerType: 'text',
      multipleAnswers: false,
      answers: [
        'Google Engineers',
        'Facebook Engineers'
      ],
      correctAnswersIds: [1],
      messageForCorrectAnswer: 'Correct answer. Good job.',
      messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
      explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      points: 20
    },
    {
      question: 'ReactJS is an MVC based framework?',
      answerType: 'text',
      multipleAnswers: false,
      answers: [
        'True',
        'False'
      ],
      correctAnswersIds: [1],
      messageForCorrectAnswer: 'Correct answer. Good job.',
      messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
      explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      points: 10
    },
    {
      question: 'Which of the following concepts is/are key to ReactJS?',
      answerType: 'text',
      multipleAnswers: false,
      answers: [
        'Component-oriented design',
        'Event delegation model',
        'Both of the above'
      ],
      correctAnswersIds: [2],
      messageForCorrectAnswer: 'Correct answer. Good job.',
      messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
      explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      points: 30
    },
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,',
      answerType: 'photo',
      multipleAnswers: false,
      answers: [
        'https://dummyimage.com/600x400/000/fff&text=A',
        'https://dummyimage.com/600x400/000/fff&text=B',
        'https://dummyimage.com/600x400/000/fff&text=C',
        'https://dummyimage.com/600x400/000/fff&text=D'
      ],
      correctAnswersIds: [1],
      messageForCorrectAnswer: 'Correct answer. Good job.',
      messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
      explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      points: 20
    },
    {
      question: 'What are the advantages of React JS?',
      answerType: 'text',
      multipleAnswers: true,
      answers: [
        'React can be used on client and as well as server side too',
        'Using React increases readability and makes maintainability easier.',
        'React components have lifecycle events that fall into State/Property Updates',
        'React can be used with any other framework (Backbone.js, Angular.js) as it is only a view layer'
      ],
      correctAnswersIds: [0, 1, 3],
      messageForCorrectAnswer: 'Correct answer. Good job.',
      messageForIncorrectAnswer: 'Incorrect answer. Please try again.',
      explanation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      points: 20
    }
  ]
};

export default quiz;