import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Quiz } from 'components';
import { Button, Grid } from '@material-ui/core';
import { useStores } from 'hooks';
import { translate } from 'utils/translation';
import { observer } from 'mobx-react';

const PagePrivateQuiz = observer(() => {
  const { quizId } = useParams();

  const { quizStore } = useStores();
  const { examinationState, isInFullScreen } = quizStore;
  const { isQuizStarted } = examinationState;

  const data = {
    id: quizId
  };

  useEffect(() => {
    quizStore.setExamStatus({ isStarted: false, isEnded: false });
  }, [quizStore]);

  useEffect(() => {
    const keyName = {
      PRINT_SCREEN_KEY: 'PrintScreen',
      C_KEY: 'c',
      V_KEY: 'v',
      S_KEY: 's',
      P_KEY: 'p',
      ESC_KEY: 'ESCAPE',
      F11_KEY: 'F11'
    };

    // Block right-click menu and text selection
    document.oncontextmenu = () => false;
    // document.onmousedown = () => false;
    document.onselectstart = () => false;

    document.onkeydown = (e) => {
      const { key, ctrlKey, altKey } = e;

      const isCtrlShortcut = ctrlKey
        && (key === keyName.C_KEY || key === keyName.V_KEY || key === keyName.S_KEY || key === keyName.P_KEY);

      const isPrintScreen = key === keyName.PRINT_SCREEN_KEY;

      if (key === keyName.ESC_KEY) {
        quizStore.toggleFullScreen(false);
      }

      if (isCtrlShortcut || isPrintScreen || altKey || key === keyName.F11_KEY) {
        return false;
      }

      return null;
    };

    document.onkeyup = (e) => {
      const { key } = e;

      const isPrintScreen = key === keyName.PRINT_SCREEN_KEY;

      if (isPrintScreen) {
        navigator.clipboard.writeText('');
      }

      return null;
    };

    document.onfullscreenchange = () => {
      if (document.fullscreenElement) {
        quizStore.toggleFullScreen(true);
      } else {
        quizStore.toggleFullScreen(false);
      }
    };
  }, [quizStore]);

  const element = document.documentElement;
  const openFullScreen = () => {
    quizStore.toggleFullScreen(true);
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) { /* IE11 */
      element.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    quizStore.toggleFullScreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }
  };

  return (
    <Grid alignItems="center" container direction="column" justifyContent="center" style={{ marginTop: 30 }}>
      <Grid item style={{ width: '90%', backgroundColor: 'var(--white)' }}>
        <Quiz editionMode={false} element={data}>
          {isInFullScreen ? (
            <Grid container justifyContent="flex-end" style={{ padding: '10px 25px 10px 0px' }}>
              <Button color="primary" variant="contained" onClick={closeFullscreen}>
                {translate('button.exitFullscreen')}
              </Button>
            </Grid>
          )
            : (
              <>
                {isQuizStarted && (
                  <Grid container justifyContent="flex-end">
                    <Button color="primary" variant="contained" onClick={openFullScreen}>
                      {translate('button.fullscreen')}
                    </Button>
                  </Grid>
                )}
              </>
            )}
        </Quiz>
      </Grid>
    </Grid>
  );
});

export default PagePrivateQuiz;