/**
 * asInput Higher Order Component
 */
import React, { useCallback } from 'react';
import { useStores } from 'hooks/useStores';

export const asInput = (MyComponent) => ({
  onChange, ...props
}) => {
  const { editionStore } = useStores();
  const changeHandler = useCallback((event) => {
    onChange(event.target.name, event.target.value);
    editionStore.setLastSaved(false);
  }, [onChange, editionStore]);
  return <MyComponent {...props} autoComplete="off" onChange={changeHandler} />;
};