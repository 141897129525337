import { getSequenceContent } from '__fixtures__/section/section_constants';
import { action, decorate, observable } from 'mobx';
import { SequenceService } from 'services';
import { StorageHelper } from 'utils';

import { editionStore } from './EditionStore';

export class SequenceStore {
  constructor() {
    this.isLoading = false;
    this.isError = false;
    this.error = null;
    this.maxPage = 0;
    this.totalElements = 0;
    this.sequenceList = [];
    this.sequence = { content: {} };
    this.sectionsForEditionMode = [];
    this.sectionsForViewMode = [];
    this.isValid = false;
  }

  /**
   * Fetch the sequence details.
   */
  loadSequence(programId, moduleId, sequenceId, isCopyMode = false) {
    action(() => {
      this.isLoading = true;
    })();
    const service = isCopyMode ? SequenceService.getSequenceCopy : SequenceService.getSequence;
    return service(programId, moduleId, sequenceId)
      .then(action((sequence) => {
        this.sequence = {
          ...sequence,
          content: sequence.content || getSequenceContent()
        };
        this.sectionsForEditionMode.push(...sequence.content.sections);
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  loadSequenceList({
    search, size, filters, currentPage
  }) {
    if (this.sequenceList.length === 0) {
      this.isLoading = true;
    }

    SequenceService.findSequences({
      search, page: currentPage, size, filters
    }).then(action((response) => {
      this.maxPage = response.totalPages - 1;
      this.totalElements = response.totalElements;

      if (currentPage === 0) {
        this.sequenceList = response.content;
      } else {
        this.sequenceList = this.sequenceList.concat(response.content);
      }

      this.isError = false;
      this.error = null;
    })).catch((error) => {
      this.error = error;
      this.isError = true;
    }).finally(action(() => {
      this.isLoading = false;
    }));
  }

  publishSequence() {
    this.sequence.isPublished = true;
  }

  resetSequence() {
    this.sequence = { content: getSequenceContent() };
    this.sectionsForEditionMode = [];
  }

  isSequenceEditionFormValid(requiredFields) {
    const found = requiredFields.some((field) => field === null || field === '');

    this.isValid = !found;
    StorageHelper.SET('isSequenceFormValid', !found);
  }

  setSections() {
    const genericSection = 'GENERIC_SECTION';
    const documentSection = 'DOCUMENT_SECTION';
    const quizSection = 'QUIZ_SECTION';
    const emptyElement = 'EMPTY_ELEMENT';

    const isSectionExists = (sectionObj) => this.sectionsForViewMode
      .some((s) => JSON.stringify(s) === JSON.stringify(sectionObj));

    if (editionStore.editionMode) {
      this.sequence.content.sections = this.sectionsForEditionMode;
    } else {
      this.sectionsForViewMode = [];
      this.sequence.content.sections.forEach((section) => {
        if (section.sectionType === genericSection && (section.title && section.title.length > 0)) {
          return !isSectionExists(section) && this.sectionsForViewMode.push(section);
        }
        if (section.sectionType === genericSection && (section.title || section.title.length === 0)) {
          const found = section.elements.find((element) => element.elementType !== null && element.elementType !== emptyElement);
          return found && !isSectionExists(section) && this.sectionsForViewMode.push(section);
        }
        if (section.sectionType === documentSection || section.sectionType === quizSection) {
          return !isSectionExists(section) && this.sectionsForViewMode.push(section);
        }
        return null;
      });

      this.sequence.content.sections = this.sectionsForViewMode;
    }
  }

  emptySectionsForViewMode() {
    this.sectionsForViewMode = [];
  }
}

decorate(SequenceStore, {
  isLoading: observable,
  isError: observable,
  error: observable,
  maxPage: observable,
  sequence: observable,
  isValid: observable,
  sequenceList: observable,
  totalElements: observable,
  sectionsForEditionMode: observable,
  sectionsForViewMode: observable,
  loadSequence: action,
  loadSequenceList: action,
  publishSequence: action,
  resetSequence: action,
  isSequenceEditionFormValid: action,
  setSections: action,
  emptySectionsForViewMode: action
});

export const sequenceStore = new SequenceStore();