import { emptyElement } from '../element';

export const genericSectionEmpty = (index, title) => ({
  index: index || 0,
  sectionType: 'GENERIC_SECTION',
  format: '',
  title: title || '',
  backgroundImage: null,
  elements: []
});

export const genericSection1 = (index, title, element) => ({
  index: index || 0,
  sectionType: 'GENERIC_SECTION',
  format: 'GENERIC_1',
  title: title || '',
  backgroundImage: null,
  elements: [
    element || emptyElement()
  ]
});

export const genericSection11 = (index, title, elements) => ({
  index: index || 0,
  sectionType: 'GENERIC_SECTION',
  format: 'GENERIC_1_1',
  title: title || '',
  backgroundImage: null,
  elements: elements || [emptyElement(0), emptyElement(1)]
});