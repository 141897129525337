import { Grid, LinearProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Text } from 'components/_commons/Text';
import QuizTimer from '../QuizTimer/QuizTimer';

const StickyParent = styled.div`
  position: sticky;
  top: 0;
  padding: 5px 0;
  margin-bottom: 10px;
  background: white;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  z-index: var(--zindexBig);
`;

const QuizProgressBar = observer(() => {
  const { quizStore } = useStores();

  const [progress, setProgress] = useState(0);

  const { currentPage, totalPages } = quizStore;

  const { name, index } = currentPage;

  useEffect(() => {
    const stepValue = ((index + 1) * 100) / totalPages;
    setProgress(stepValue);
  }, [index, totalPages]);

  return (
    <StickyParent>
      <Grid alignItems="center" container direction="column" spacing={1}>
        <Grid item>
          <QuizTimer />
        </Grid>
        <Grid item style={{ width: '70%' }}>
          <Grid alignItems="center" container direction="row" justifyContent="center">
            <Grid item xs={11}>
              <LinearProgress
                className="mr1"
                style={{ height: 20, borderRadius: 4 }}
                value={progress}
                variant="determinate"
              />
            </Grid>
            <Grid item xs={1}>
              <Typography style={{ fontWeight: 600 }} variant="body2">
                {`${(index + 1)}/${totalPages}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Text
            fontSize="20px"
            fontWeight="bold"
          >
            {name}
          </Text>
        </Grid>
      </Grid>
    </StickyParent>
  );
});

export default QuizProgressBar;