import React, { useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { RGPD_EMAIL } from 'utils/constants';
import { Wrapper } from 'components';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.section`
  p {
    margin: 1rem 0;
  }
`;

const TitlePage = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 2rem 0;
  color: var(--primary-color);
  font-size: 2.4rem;
  text-transform: uppercase;
  text-align: center;
`;

const SubtitlePage = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 42px;
  margin: 1rem 0;
  color: var(--grey-darker);
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
`;

const PageRGPD = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <TitlePage>{translate('common.personalData')}</TitlePage>
      <SubtitlePage>{translate('personalData.subTitle')}</SubtitlePage>

      <Container>
        <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('personalData.intro')) }} />
        <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('personalData.usages')) }} />
        <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('personalData.rights')) }} />
        <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('personalData.cemafroid')) }} />
        <a href={`mailto:${RGPD_EMAIL}`}>
          <FontAwesomeIcon className="mr1" icon={faEnvelope} />
          {RGPD_EMAIL}
        </a>
        <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('personalData.answer')) }} />
      </Container>

    </Wrapper>
  );
};

export default PageRGPD;