import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { asInput, asInputRadio } from 'components/forms/hoc';
import { genericSectionFormModel } from 'components/model/dataformModel';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { GenericSectionFormatRadioImages } from '../_commons';


const ModuleFormatChoiceRadios = asInputRadio(asInput(({
  name, onChange, isChecked
}) => {
  const InputRadio = ({ value }) => (
    <GenericSectionFormatRadioImages isChecked={isChecked} name={name} value={value} onChange={onChange} />
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <InputRadio value="MODULE_2_2" />
      </Grid>
      <Grid item>
        <InputRadio value="MODULE_2_3" />
      </Grid>
      <Grid item>
        <InputRadio value="MODULE_3_3" />
      </Grid>
    </Grid>
  );
}));


const ModuleFormatChoice = observer(({ formData, updateProperty }) => {
  const { format } = formData;

  const handleFormatChange = useCallback((name, value) => {
    updateProperty(name, value);
  }, [updateProperty]);


  return <ModuleFormatChoiceRadios name="format" value={format} onChange={handleFormatChange} />;
});

export default ModuleFormatChoice;

ModuleFormatChoice.propTypes = {
  formData: genericSectionFormModel.isRequired,
  updateProperty: PropTypes.func.isRequired
};