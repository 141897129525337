import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { QuizHeader, QuizPage, QuizQuestion } from 'components';
import {
  Grid, Typography
} from '@material-ui/core';
import { useStores } from 'hooks';
import { translate } from 'utils/translation';
import { QUIZ_STATUS } from '../QuizHelper';

const QuizEndPage = observer(({ quizStatus }) => {
  const { ended, displayingResults } = QUIZ_STATUS;

  const { quizStore } = useStores();

  const { finishedExamination, showCorrectedAnswers } = quizStore;

  const { quiz: { pages } } = finishedExamination;

  return (
    <>
      {quizStatus === ended ? <QuizHeader quizStatus={ended} /> : <QuizHeader quizStatus={displayingResults} />}

      {showCorrectedAnswers && (
        <>
          <Grid alignItems="center" container justifyContent="center">
            <Grid item>
              <Typography style={{ fontWeight: 'bolder', color: 'var(--black)' }} variant="h5">
                {translate('forms.element.quiz.answers')}
              </Typography>
            </Grid>
          </Grid>
          {pages.map((page) => (
            <QuizPage
              currentPageData={page}
              key={page.index}
              quizStatus={ended}
            >
              {page.questions.map((question) => (
                <QuizQuestion
                  currentPageData={page}
                  currentQuestionData={question}
                  key={question.id}
                  quizStatus={ended}
                />
              ))}
            </QuizPage>
          ))}
        </>
      )}
    </>
  );
});

export default QuizEndPage;

QuizEndPage.propTypes = {
  quizStatus: PropTypes.string.isRequired
};