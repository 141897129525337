import { documentSection, genericSection1, genericSection11 } from './section';
import { documentPdfElement, emptyElement, textElement } from './element';

const sequence = {
  id: '1',
  moduleId: '1',
  index: 1,
  name: 'LE CHARGEMENT DU CAMION',
  description: 'J\'assure la circulation de l\'air dans mon chargement',
  logoURL: 'image.jpg',
  isPublished: false,
  content: {
    sections: [
      genericSection11(0, 'Première section'),
      genericSection1(1, 'Deuxième section'),
      genericSection11(2, 'Section avec element vide à gauche', [emptyElement(), textElement(1)]),
      documentSection(3, 'Dernière section', [documentPdfElement()])
    ]
  }
};

export const sequenceFull = {
  id: '1',
  moduleId: '1',
  index: 1,
  name: 'LE CHARGEMENT DU CAMION',
  description: 'J\'assure la circulation de l\'air dans mon chargement',
  logoURL: 'image.jpg',
  isPublished: false,
  code: 123,
  reference: 'super',
  author: 'CEMAFROID',
  duration: 120,
  accessRights: { label: 'Exclusivité Tecnea', value: '43e24ff8-3a1e-40b7-8ced-c31f92c9680b' },
  copyright: { value: 'd8a49681-7677-4b16-8a64-97e936554f23', label: 'ADEME' },
  language: { value: '19127c0a-419b-4245-a010-86fa36152389', label: 'Français' },
  location: { value: '17685bc2-89de-4836-9413-386f341a6428', label: 'Autre' },
  modality: { value: '8cd59c5b-7401-4db4-ab1f-eba60fd5fa0f', label: 'Autre' },
  nature: { value: '40061fb2-2482-4747-8ea2-b392c9b3fae9', label: 'Évaluation théorique' },
  content: {
    sections: [
      genericSection11(0, 'Première section'),
      genericSection1(1, 'Deuxième section'),
      genericSection11(2, 'Section avec element vide à gauche', [emptyElement(), textElement(1)]),
      documentSection(3, 'Dernière section', [documentPdfElement()])
    ]
  }
};

export default sequence;