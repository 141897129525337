import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils/translation';
import { SequenceService } from 'services';
import { ROUTES } from 'utils/constants';

const ButtonCreateSequence = ({ programId, moduleId }) => {
  const history = useHistory();
  const { sequenceStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [isCreating, setIsCreating] = useState(false);

  const createSequence = useCallback(() => {
    setIsCreating(true);

    SequenceService.createSequence(moduleId).then((response) => {
      sequenceStore.resetSequence();

      let pathname = '';
      if (programId) {
        pathname = ROUTES.PROGRAM_MODULE_SEQUENCE(programId, moduleId, response);
      } else if (moduleId) {
        pathname = ROUTES.MODULE_SEQUENCE(moduleId, response);
      } else {
        pathname = ROUTES.SEQUENCE(response);
      }
      history.push({
        pathname,
        state: {
          isEditionMode: true
        }
      });
      enqueueSnackbar(translate('forms.sequence.sequenceCorrectlyCreated'), { variant: 'success' });
      setIsCreating(false);
    }).catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  }, [history, enqueueSnackbar, sequenceStore, programId, moduleId]);

  return (
    <Button
      disabled={isCreating}
      startIcon={<FontAwesomeIcon icon={isCreating ? faSpinner : faPlus} spin={isCreating} />}
      onClick={createSequence}
    >
      {translate('pageModuleDetail.createSequence')}
    </Button>
  );
};

ButtonCreateSequence.propTypes = {
  moduleId: PropTypes.string,
  programId: PropTypes.string
};

ButtonCreateSequence.defaultProps = {
  moduleId: '',
  programId: ''
};

export default ButtonCreateSequence;