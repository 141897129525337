import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { FormHelper } from 'utils/helpers';
import { useStores } from 'hooks';
import { TextField } from '@material-ui/core';

import { buildDefaultAndTraineeAnswers, QUESTION_TYPES_FROM_API } from '../../QuizHelper';

const EmailQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { emailInput } = QUESTION_TYPES_FROM_API;

  const { quizStore } = useStores();

  const { currentPage } = quizStore;

  const [currentValue, setCurrentValue] = useState('');
  const [error, setError] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);

  const buildAnswers = useCallback(() => {
    setCurrentValue(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleChangeAnswer = useCallback((event) => {
    const { value } = event.target;

    const errorResult = FormHelper.validateEmail(value);
    setError(errorResult);

    setCurrentValue(value);

    if (errorResult && errorResult.length > 0) {
      setIsValidEmail(false);
      quizStore.toggleQuestionValidity({ questionType: emailInput, status: false });
    } else {
      setIsValidEmail(true);
      quizStore.toggleQuestionValidity({ questionType: emailInput, status: true });
    }

    quizStore.setTraineeAnswers(currentPage, currentQuestionData, value);
  }, [currentPage, currentQuestionData, quizStore, emailInput]);

  return (
    <>
      <TextField
        autoComplete="off"
        disabled={isReadOnlyQuestion}
        error={!isValidEmail && error.length > 0}
        fullWidth
        helperText={error}
        name="textAnswer"
        placeholder={translate('common.writeEmail')}
        required
        style={{ margin: '10px 0px' }}
        value={currentValue}
        variant="outlined"
        onChange={handleChangeAnswer}
      />
    </>
  );
});

export default EmailQuestion;

EmailQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

EmailQuestion.defaultProps = {
  currentQuestionData: {}
};