import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components';
import { translate } from 'utils/translation';

export const PageSubtitle = ({ subtitle }) => (
  <Text as="h2" color="var(--colored-subtitle-color)" margin="2rem 0" textAlign="center">
    {translate(subtitle)}
  </Text>
);

export const PageHeader = ({ title, withoutTranslation }) => (
  <Text as="h1" margin="4rem 0" textAlign="center" textTransform="uppercase">
    {withoutTranslation ? title : translate(title)}
  </Text>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired
};