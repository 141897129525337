import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DocumentHelper } from './DocumentHelper';
import { FormHelper } from './FormHelper';
import { RequestHelper } from './RequestHelper';
import { StorageHelper } from './StorageHelper';
import { UserHelper } from './UserHelper';
import { browserDetectionHelper } from './browserDetectionHelper';

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const formatDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (!date) return '';
  return format(new Date(date), dateFormat);
};

const getSmallDescription = (description, size = 50) => {
  if (!description) return '';
  if (description.length < size) return description;

  return `${description.substring(0, size)}...`;
};

export {
  DocumentHelper,
  FormHelper,
  RequestHelper,
  StorageHelper,
  UserHelper,
  useDebounce,
  getSmallDescription,
  browserDetectionHelper,
  formatDate
};