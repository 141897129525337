/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { translate } from 'utils/translation';
import debounce from 'debounce-promise';
import { useFormState } from 'react-use-form-state'; // https://github.com/wsmd/react-use-form-state
import {
  Datepicker, FormElement
} from 'components';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { SubscriptionService } from 'services';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { StyledRequiredSelectHack } from 'components/forms/inputs/SelectField/SelectField';
import { FormHelper } from 'utils/helpers';
import ModalHeader from './_ModalHeader';

const SubscribeTraineesModal = observer(({ onClose, onConfirm }) => {
  const [traineesList, setTraineesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [formState, { raw }] = useFormState({
    trainees: null, startDate: null, endDate: null
  });

  useEffect(() => {
    setIsLoading(true);
    SubscriptionService.getTraineesList()
      .then((response) => setTraineesList(response))
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar]);

  const getAsyncOptions = useCallback((inputValue) => new Promise((resolve) => {
    SubscriptionService.getTraineesList(inputValue)
      .then((response) => resolve(response));
  }), []);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    let isValidForm = true;

    if (Number.isNaN(formState.values.startDate.getDate())) {
      isValidForm = false;
    }

    if (Number.isNaN(formState.values.endDate.getDate())) {
      isValidForm = false;
    }

    if (isValidForm) {
      const form = {
        ...formState.values,
        startDate: FormHelper.formatDateForBackend(formState.values.startDate),
        endDate: FormHelper.formatDateForBackend(formState.values.endDate)
      };

      onConfirm(form);
      formState.reset();
      onClose();
    } else {
      enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }
  }, [onConfirm, onClose, formState, enqueueSnackbar]);

  return (
    <form onSubmit={onSubmit}>
      <ModalHeader onClose={onClose}>
        {translate('modals.subscribeTrainees.title')}
      </ModalHeader>

      <DialogContent style={{ maxWidth: '500px' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography gutterBottom>{translate('modals.subscribeTrainees.description')}</Typography>
          </Grid>

          <Grid item>
            <Datepicker
              autoComplete="off"
              clearable
              dataCy="startDate"
              disablePast
              fullWidth
              label="forms.module.sessionModal.startDate"
              maxDate={formState.values.endDate || undefined}
              required
              style={{ marginBottom: '1rem' }}
              {...raw({
                name: 'startDate',
                compare(_, value) {
                  return value !== null;
                }
              })}
            />
          </Grid>

          <Grid item>
            <Datepicker
              autoComplete="off"
              clearable
              dataCy="endDate"
              disablePast
              fullWidth
              label="forms.module.sessionModal.endDate"
              minDate={formState.values.startDate}
              required
              style={{ marginBottom: '0.3rem' }}
              {...raw({
                name: 'endDate',
                compare(_, value) {
                  return value !== null;
                }
              })}
            />
          </Grid>

          <Grid item>
            <FormElement label={translate('modals.subscribeTrainees.traineesList')} required>
              <AsyncSelect
                cacheOptions
                defaultOptions={traineesList}
                error={formState.errors.trainees && formState.errors.trainees !== null}
                isClearable
                isLoading={isLoading}
                isMulti
                loadingMessage={() => translate('common.loading')}
                loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
                menuPortalTarget={document.body}
                name="trainees"
                // eslint-disable-next-line react/jsx-no-bind
                noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
                placeholder={translate('common.selectOption')}
                required
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 2000 }) }}
                {...raw({
                  name: 'trainees',
                  compare(_, value) {
                    return value !== null;
                  }
                })}
              />
              <StyledRequiredSelectHack
                autoComplete="off"
                defaultValue={formState.values.trainees || ''}
                required
                tabIndex={-1}
              />
            </FormElement>
            <Typography gutterBottom variant="body2">{translate('modals.subscribeTrainees.tutorial')}</Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          {translate('button.close')}
        </Button>
        <Button color="primary" type="submit">
          {translate('button.validate')}
        </Button>
      </DialogActions>
    </form>
  );
});

SubscribeTraineesModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SubscribeTraineesModal;