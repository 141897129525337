const GET = (key = '') => localStorage.getItem(key);

const SET = (key = '', value = '') => {
  if (typeof value === 'object') {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  return localStorage.setItem(key, value);
};

const REMOVE = (key = '') => localStorage.removeItem(key);

export const StorageHelper = {
  GET,
  SET,
  REMOVE
};