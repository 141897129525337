import {
  AdminListNav, DocumentList, ModuleList, SequenceList, Wrapper
} from 'components';
import React, { useCallback, useState } from 'react';
import { UserHelper } from 'utils';
import { Tab, Tabs } from '@material-ui/core';
import { Link, Redirect, useLocation } from 'react-router-dom';
import {
  PATHS, ROLE_GROUPS, ROUTES
} from 'utils/constants';
import shortid from 'shortid';
import { translate } from 'utils/translation';


const TAB_MAPPING = [
  'documents',
  'sequences',
  'modules'
];


const PageAdmin = () => {
  const { pathname } = useLocation();
  const currentLocation = pathname.split('/');
  const currentTabMapped = TAB_MAPPING.findIndex((url) => url === currentLocation[currentLocation.length - 1]);

  const [currentTab, setCurrentTab] = useState(currentTabMapped > 0 ? currentTabMapped : 0);

  const TABS_COMPONENTS = [];

  const handleTabChange = useCallback((_, tab) => setCurrentTab(tab), []);

  if (UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS)) {
    TABS_COMPONENTS.push(<DocumentList />, <SequenceList />, <ModuleList />);
  } else {
    return <Redirect to={PATHS.HOME} />;
  }

  return (
    <>
      <AdminListNav />

      <Wrapper>
        <Tabs
          aria-label={translate('pageDocumentList.documentType')}
          centered
          data-cy="adminTabs"
          data-tour="step-admin"
          indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          variant="fullWidth"
          onChange={handleTabChange}
        >
          {UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS) && [
            // TODO: delete comments when icons are available and replace faQuestionSquare icons by real icons
            <Tab
              component={Link}
              data-cy="adminDocument"
              data-tour="step-admin-document"
              /* icon={<FontAwesomeIcon icon={faFile} size="2x" />} */
              key={shortid.generate()}
              label={translate('pageAdmin.tabDocument')}
              to={ROUTES.ADMIN_DOCUMENT}
            />,
            <Tab
              component={Link}
              data-cy="adminSequence"
              data-tour="step-admin-sequence"
              /*  icon={<FontAwesomeIcon icon={faQuestionSquare} size="2x" />} */
              key={shortid.generate()}
              label={translate('pageAdmin.tabSequence')}
              to={ROUTES.ADMIN_SEQUENCE}
            />,
            <Tab
              component={Link}
              data-cy="adminModule"
              data-tour="step-admin-module"
              /* icon={<FontAwesomeIcon icon={faQuestionSquare} size="2x" />} */
              key={shortid.generate()}
              label={translate('pageAdmin.tabModule')}
              to={ROUTES.ADMIN_MODULE}
            />
          ]}
        </Tabs>

        <section>
          {TABS_COMPONENTS[currentTab]}
        </section>
      </Wrapper>
    </>
  );
};

export default PageAdmin;