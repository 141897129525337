import { faEdit, faEmptySet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton, TableCell
} from '@material-ui/core';
import {
  GenericFilters, GenericTable, PageHeader, SkeletonTable,
  TextError,
  Wrapper
} from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentService } from 'services';
import shortid from 'shortid';
import { transformObjectListToStringQueriesParam } from 'utils';

import { useSnackbar } from 'notistack';
import { DocumentHelper } from 'utils/helpers';
import { translate } from 'utils/translation';
import { DocumentListFilters } from './DocumentListFilters';

const getListHeaders = (showDocumentModal) => ([
  {
    name: 'previewContent',
    label: 'common.preview',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.previewContent ? (
          <img
            alt={row.name}
            src={DocumentHelper.getDocumentWithBase64(row.previewContent)}
            style={{ maxWidth: '200px', maxHeight: '60px' }}
          />
        ) : <FontAwesomeIcon icon={faEmptySet} />}
      </TableCell>
    )
  }, {
    name: 'name',
    label: 'common.name',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.name}
      </TableCell>
    )
  }, {
    name: 'documentType',
    label: 'pageDocumentList.documentType',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {translate(`common.${row.contentType}`)}
      </TableCell>
    )
  }, {
    name: 'creationUserName',
    label: 'common.creationUserName',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.creationUserName}
      </TableCell>
    )
  }, {
    name: 'creationDate',
    label: 'common.creationDate',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {new Date(row.creationDate).toLocaleDateString()}
      </TableCell>
    )
  },
  {
    name: 'edit',
    label: 'button.edit',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <IconButton color="primary" edge="end" onClick={(e) => { e.stopPropagation(); showDocumentModal(row); }}>
          <FontAwesomeIcon icon={faEdit} size="xs" />
        </IconButton>
      </TableCell>
    )
  }
]);

export const DocumentList = observer(() => {
  const { documentStore } = useStores();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { maxPage, totalElements } = documentStore;
  const { enqueueSnackbar } = useSnackbar();

  const showModal = useModal();

  const filterKey = 'documentList';

  const loadDocumentList = useCallback(() => {
    const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);
    documentStore.loadDocumentList({ search, currentPage, filters: filtersQueriesParams });
  }, [filters, search, currentPage, documentStore]);

  const refreshDocumentList = useCallback(() => {
    if (currentPage === 0) {
      loadDocumentList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, loadDocumentList]);

  useEffect(() => {
    refreshDocumentList();
  // eslint-disable-next-line
  }, [search, filters]);

  useEffect(() => {
    loadDocumentList();
  // eslint-disable-next-line
  }, [currentPage]);

  const loadMore = useCallback(() => {
    !documentStore.isLoading && setCurrentPage(currentPage + 1);
  }, [documentStore.isLoading, currentPage]);

  const updateDocument = useCallback((formData) => {
    const documentHashId = formData.get('id');
    setCurrentPage(0);

    return DocumentService.updateDocument(documentHashId, formData)
      .then(() => enqueueSnackbar(translate('forms.element.document.documentCorrectlyModified'), { variant: 'success' }))
      .finally(() => loadDocumentList());
  }, [loadDocumentList, enqueueSnackbar]);

  const showDocumentModal = useCallback((row) => showModal({
    type: 'CREATE_DOCUMENT',
    defaultValues: row,
    onConfirm: updateDocument
  }), [showModal, updateDocument]);

  const getContentTypes = useCallback(() => [
    { value: 'IMAGE', label: translate('common.IMAGE') },
    { value: 'VIDEO', label: translate('common.VIDEO') },
    { value: 'DOWNLOADABLE', label: translate('common.DOWNLOADABLE') }
  ], []);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <DocumentListFilters
      currentFilters={currentFilters}
      documentContentTypes={getContentTypes()}
      setCurrentFilters={setCurrentFilters}
    />
  ), [getContentTypes]);

  return (
    <>
      <Wrapper>
        <PageHeader title="pageDocumentList.title" />

        <GenericFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-intervention-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setCurrentPage={setCurrentPage}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltip="pageDocumentList.searchTooltip"
          withDrawer
        />

        {(!documentStore.isLoading && !documentStore.isError && documentStore.documentList.length === 0) && (
          <TextError data-testid="errors.noDocument">{translate('errors.noDocument')}</TextError>
        )}

        {(!documentStore.isLoading && documentStore.isError) && (
          <TextError data-testid="errors.documentListFailed">{translate('errors.documentListFailed')}</TextError>
        )}

        {documentStore.isLoading ? <SkeletonTable /> : documentStore.documentList.length > 0 && (
          <div data-testid="document-list">
            <GenericTable
              hasMore={currentPage < maxPage}
              headers={getListHeaders(showDocumentModal)}
              id={shortid.generate()}
              loadMore={loadMore}
              rows={documentStore.documentList}
              total={totalElements}
            />
          </div>
        )}
      </Wrapper>
    </>
  );
});