import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { ParentElement } from 'components';
import { elementModel } from 'components/model/dataformModel';
import { PlaceholderElement } from 'components/Templates/elements/ParentElement/ParentElement';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { translate } from 'utils/translation';
import logger from 'utils/logger';
import { SectionContent, SectionTitle } from '../_commons';
import { getFormat } from './GenericSectionFormats';

export const GenericSection = observer(({ section, editionMode, editableHtmlId }) => {
  const {
    title, format: formatType, elements
  } = section;

  let format;
  try {
    format = getFormat(formatType);
  } catch (error) {
    logger.error(error);
  }
  const nbElements = (elements && elements.length) || 0;
  const getElementKey = useCallback((i) => (i < nbElements ? elements[i].index : shortid.generate()), [elements, nbElements]);

  return (
    <section>
      <SectionTitle title={title} />
      <SectionContent>
        {format && [...Array(format.columns)].map((_, i) => (
          <Grid
            item
            key={getElementKey(i)}
            lg={format.getColumnFormat(i, 'lg')}
            md={format.getColumnFormat(i, 'md')}
            sm={format.getColumnFormat(i, 'sm')}
            xs={format.getColumnFormat(i, 'xs')}
          >
            {nbElements > i
              ? <ParentElement editionMode={editionMode} element={elements[i]} parentHtmlId={editableHtmlId} /> : ''}
          </Grid>
        ))}

        {!format && nbElements > 0 && elements.map((element) => (
          <ParentElement editionMode={editionMode} element={element} key={shortid.generate()} parentHtmlId={editableHtmlId} />
        ))}

        {nbElements === 0 && editionMode && (
          <PlaceholderElement>
            {translate('forms.section.setupSection')}
          </PlaceholderElement>
        )}
      </SectionContent>
    </section>
  );
});

GenericSection.propTypes = {
  section: PropTypes.shape({
    format: PropTypes.string,
    title: PropTypes.string,
    elements: PropTypes.arrayOf(elementModel)
  }).isRequired,
  editionMode: PropTypes.bool.isRequired,
  editableHtmlId: PropTypes.string
};

GenericSection.defaultProps = {
  editableHtmlId: ''
};