import { ModuleDetail } from 'components';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const PageModuleDetail = () => {
  const { programId, moduleId } = useParams();
  const { state, pathname } = useLocation();

  const isEditionMode = (state && state.isEditionMode) || false;
  const defaultViewMode = (state && state.viewMode) || (pathname.indexOf('detail') > -1 ? 'moduleDetail' : 'educationalSheet');

  return (
    <ModuleDetail
      defaultViewMode={defaultViewMode}
      isEditionMode={isEditionMode}
      moduleId={moduleId}
      programId={programId}
    />
  );
};

export default PageModuleDetail;