export const documentPdfElement = (index, name, url) => ({
  index: index || 0,
  elementType: 'DOCUMENT_ELEMENT',
  element: {
    name: name || 'La porte',
    creationDate: '',
    type: 'application/pdf',
    url: url || ''
  }
});

export const documentImageElement = (index, name, url) => ({
  index: index || 0,
  elementType: 'DOCUMENT_ELEMENT',
  element: {
    name: name || 'Practice',
    creationDate: '',
    type: 'image/png',
    url: url || ''
  }
});