import { EditableItem } from 'components';
import {
  elementModel
} from 'components/model/dataformModel';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { DocumentElement } from '../DocumentElement';
import { ImageElement } from '../ImageElement';
import { QuizElement } from '../QuizElement';
import { TextElement } from '../TextElement';
import { VideoElement } from '../VideoElement';

const DisablingBlock = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 30;
`;

export const PlaceholderElement = styled.div`
  height: 100%;
  width: 100%;
  min-height: 20rem;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: center;
  color: var(--primary-color);
  font-weight: bold;
`;

export const ParentElement = observer(({
  element, editionMode, parentHtmlId, onDelete, ...props
}) => {
  const { elementType } = element;
  const empty = !elementType || elementType === 'EMPTY_ELEMENT';

  const renderSpecificElement = useCallback(() => {
    switch (elementType) {
      case 'TEXT_ELEMENT':
        return <TextElement element={element.element} {...props} />;
      case 'IMAGE_ELEMENT':
        return <ImageElement element={element.element} {...props} />;
      case 'VIDEO_ELEMENT':
        return <VideoElement element={element.element} {...props} />;
      case 'DOCUMENT_MAIN':
        return <DocumentElement editionMode={editionMode} element={element.element} isMain {...props} />;
      case 'DOCUMENT_ELEMENT':
        return <DocumentElement editionMode={editionMode} element={element.element} {...props} />;
      case 'QUIZ_ELEMENT':
        return <QuizElement editionMode={editionMode} element={element.element} {...props} />;
      case 'EMPTY_ELEMENT':
      default:
        return (
          <PlaceholderElement>
            {editionMode && translate('forms.element.addElement')}
          </PlaceholderElement>
        );
    }
  }, [elementType, element.element, editionMode, props]);

  const renderSpecificElementWithDisablingLayer = useCallback(() => (
    <>
      {renderSpecificElement()}
      {editionMode && <DisablingBlock />}
    </>
  ), [editionMode, renderSpecificElement]);

  return (
    <EditableItem
      colored={empty}
      editionMode={editionMode}
      formData={element}
      height="100%"
      index={element.index}
      margin="0"
      parentHtmlId={parentHtmlId}
      position="relative"
      render={renderSpecificElementWithDisablingLayer}
      type="element"
      onDelete={onDelete}
    />
  );
});

ParentElement.propTypes = {
  element: PropTypes.shape({
    elementType: PropTypes.string,
    element: elementModel
  }).isRequired,
  editionMode: PropTypes.bool.isRequired,
  parentHtmlId: PropTypes.string,
  onDelete: PropTypes.func
};

ParentElement.defaultProps = {
  onDelete: null,
  parentHtmlId: ''
};