import React, { useCallback } from 'react';
import { useStores } from 'hooks';
import styled, { css } from 'styled-components';
import {
  Avatar, Card, CardContent, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography
} from '@material-ui/core';
import {
  Button, ButtonDisplaySession, InfoLine, Wrapper
} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { translate, translateTheme } from 'utils/translation';
import {
  API_URL_DOCUMENTS, APP_CONTACT_MAIL, APP_CONTACT_NAME, ROUTES
} from 'utils/constants';

import { rgba } from 'polished';
import shortid from 'shortid';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { getSmallDescription } from 'utils/helpers';

const EducationalSheetHeader = styled.header`
  position: relative;
  padding: 3rem 0;
  margin-bottom: 3rem;
  border-bottom: 1px solid #dddddd;
  background-color: #eeeeee;

  & > section {
    position: relative;
    z-index: 1;
  }

  ${(props) => props.color && css`
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-left: 80px solid transparent;
      border-right: 60vw solid ${rgba(props.color, 0.5)};
      border-bottom: 100px solid transparent;
    }
  `}
`;

const StyledWrapper = styled(Wrapper)`
  .MuiCard-root {
    margin-bottom: 1rem;
  }
`;

const AsideInfos = styled(Paper)`
  height: 100%;
  padding: 1rem;
`;

export const CustomCardTitle = ({
  title, icon
}) => (
  <Typography
    align="center"
    component="h2"
    style={{
      padding: '1rem 0',
      borderBottom: '1px solid #cacaca',
      textTransform: 'uppercase',
      backgroundColor: '#e4e4e4'
    }}
    variant="h6"
  >
    {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />}
    {translate(title)}
  </Typography>
);

export const EducationalSheet = observer(({ setViewMode, editionMode }) => {
  const { moduleStore, userStore } = useStores();
  const { module } = moduleStore;
  const { isConnected } = userStore;
  const { moduleId, programId } = useParams();
  const history = useHistory();

  const {
    id, name, logoURL, description, theme, keywords,
    numberOfDays, additionalDurationInformation, domain,
    trainingCategory, trainingType, fareCodeForIntra, fareCodeForInter,
    handicaps, publicExperiences, publicLevels, publicTypes,
    prerequisites, pedagogicalResources, locations, modality,
    objectives, professionalBenefits, acknowledgements, reviewTypes,
    sequenceSummaries, isPublic, hasAccess
  } = module;

  const handleChangeViewMode = useCallback(() => {
    history.push({
      pathname: programId ? ROUTES.PROGRAM_MODULE_DETAIL(programId, moduleId) : ROUTES.MODULE_DETAIL(moduleId),
      state: {
        viewMode: 'moduleDetail'
      }
    });

    setViewMode('moduleDetail');
  }, [setViewMode, history, moduleId, programId]);

  const handleConnectUser = useCallback(() => userStore.keycloak && userStore.keycloak.login(), [userStore]);

  const numberOfDaysDisplayed = Number(numberOfDays) === 1
    ? `${numberOfDays} ${translate('common.day')}`
    : `${numberOfDays} ${translate('common.days')}`;

  const renderButtonModuleDetail = useCallback(() => {
    // If the user can access the module, allow them to view the detail
    if (hasAccess) {
      return (
        <Button
          disabled={editionMode}
          startIcon={<FontAwesomeIcon icon={faClipboardList} />}
          onClick={handleChangeViewMode}
        >
          {translate('button.accessDetail')}
        </Button>
      );
    }
    // If the user isn't connected, ask them to connect to check their access rights
    if (!isConnected) {
      return (
        <Button
          disabled={editionMode}
          startIcon={<FontAwesomeIcon icon={faUserCircle} />}
          onClick={handleConnectUser}
        >
          {translate('button.connect')}
        </Button>
      );
    }
    // If the connected user cannot access the module, ask them to subscribe or register
    // TODO: Remove this temporary message once access requests are enabled
    return (
      <div>
        <b>
          <i>
            {`Pour plus de renseignements concernant l'accès à ce contenu, veuillez contacter ${APP_CONTACT_NAME} (`}
            <a href={`mailto:${APP_CONTACT_MAIL}`}>{APP_CONTACT_MAIL}</a>
            ).
          </i>
        </b>
      </div>
    );
    /*
    return (
      <Button
        disabled
        startIcon={<FontAwesomeIcon icon={faPenSquare} />}
      >
        {translate('button.subscription')}
      </Button>
    );
    */
  }, [handleConnectUser, handleChangeViewMode, hasAccess, isConnected, editionMode]);

  return (
    <>
      <EducationalSheetHeader color={theme && theme.color}>
        <Wrapper>
          <Grid alignItems="flex-start" container spacing={4}>
            <Grid item xs>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container spacing={2}>
                    {logoURL && (
                      <Grid item>
                        <Avatar
                          alt={name}
                          src={`${API_URL_DOCUMENTS}${logoURL}`}
                          style={{ height: '90px', width: '90px' }}
                        />
                      </Grid>
                    )}
                    <Grid item xs>
                      <Typography component="h1" variant="h4">{name}</Typography>
                      {theme && <Typography component="h2" variant="h5">{translateTheme(theme.label)}</Typography>}
                      <InfoLine info={keywords} label="referenceData.KEYWORD" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography style={{ marginBottom: '2rem' }}>{description}</Typography>

                  {renderButtonModuleDetail()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4} xs={12}>
              <AsideInfos>
                <InfoLine
                  displayIf={numberOfDays}
                  info={numberOfDaysDisplayed}
                  label="common.duration"
                />
                <InfoLine info={domain} label="referenceData.DOMAIN" />
                <InfoLine info={trainingCategory} label="referenceData.TRAINING_CATEGORY" />
                <InfoLine info={trainingType} label="referenceData.TRAINING_TYPE" />
                <InfoLine
                  displayIf={fareCodeForInter || fareCodeForIntra}
                  info={fareCodeForIntra && fareCodeForInter
                    ? `${fareCodeForIntra}/${fareCodeForInter}`
                    : `${fareCodeForIntra || ''} ${fareCodeForInter || ''}`}
                  label="referenceData.INTRA_INTER"
                />

                {!isPublic && id && (
                  <ButtonDisplaySession disabled={editionMode} moduleId={id} style={{ marginTop: '1rem' }} />
                )}
              </AsideInfos>
            </Grid>
          </Grid>
        </Wrapper>
      </EducationalSheetHeader>
      <StyledWrapper>
        <Grid container spacing={4} wrap="wrap">
          <Grid item sm={6} xs={12}>
            <Card>
              <CustomCardTitle title="common.public" />
              <CardContent>
                <InfoLine info={publicTypes} label="referenceData.PUBLIC_TYPE" />
                <InfoLine info={publicLevels} label="referenceData.PUBLIC_LEVEL" />
                <InfoLine info={publicExperiences} label="referenceData.PUBLIC_EXPERIENCE" />
                <InfoLine info={handicaps} label="referenceData.HANDICAP" />
              </CardContent>
            </Card>
            <Card>
              <CustomCardTitle title="common.prerequisites" />
              <CardContent>
                <InfoLine info={prerequisites} label="common.prerequisites" />
              </CardContent>
            </Card>
            <Card>
              <CustomCardTitle title="common.pedagogicalResources" />
              <CardContent>
                <InfoLine info={pedagogicalResources} label="common.pedagogicalResources" />
                <InfoLine info={reviewTypes} label="referenceData.REVIEW_TYPE" />
              </CardContent>
            </Card>
            <Card>
              <CustomCardTitle title="common.tarif" />
              <CardContent>
                <InfoLine info={fareCodeForIntra} label="common.fareCodeForIntra" />
                <InfoLine info={fareCodeForInter} label="common.fareCodeForInter" />
              </CardContent>
            </Card>
            <Card>
              <CustomCardTitle title="common.dateLocation" />
              <CardContent>
                <InfoLine info={locations} label="referenceData.LOCATION" />
                <InfoLine
                  displayIf={numberOfDays}
                  info={numberOfDaysDisplayed}
                  label="common.duration"
                />
                <InfoLine info={additionalDurationInformation} label="common.additionalDurationInformation" />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card>
              <CustomCardTitle title="common.objectives" />
              <CardContent>
                <InfoLine info={objectives} label="common.objectives" />
              </CardContent>
            </Card>
            <Card>
              <CustomCardTitle title="common.program" />
              <CardContent>
                <InfoLine info={modality} label="referenceData.MODALITY" />
                <Typography>{`${translate('pageSequenceList.title')} :`}</Typography>
                <List data-testid="sequence-container">
                  {sequenceSummaries && sequenceSummaries.map((seq) => (
                    <ListItem key={shortid.generate()}>
                      {seq.logoURL && (
                        <ListItemAvatar>
                          <Avatar
                            src={`${API_URL_DOCUMENTS}${seq.logoURL}`}
                            style={{ boxShadow: 'rgba(0,0,0,0.2) 0px 0px 10px' }}
                          />
                        </ListItemAvatar>
                      )}
                      <ListItemText primary={seq.name} secondary={getSmallDescription(seq.description, 100)} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
            <Card>
              <CustomCardTitle title="common.benefits" />
              <CardContent>
                <InfoLine info={professionalBenefits} label="common.professionalBenefits" />
                <InfoLine info={trainingCategory} label="referenceData.TRAINING_CATEGORY" />
                <InfoLine info={trainingType} label="referenceData.TRAINING_TYPE" />
                <InfoLine info={acknowledgements} label="referenceData.ACKNOWLEDGEMENT" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </StyledWrapper>
    </>
  );
});