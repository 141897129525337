import { Avatar, Grid } from '@material-ui/core';
import {
  CardSummary, Text, TextMultiline, Wrapper
} from 'components';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { observer } from 'mobx-react-lite';
import { API_URL_DOCUMENTS, ROUTES } from 'utils/constants';
import { useStores } from 'hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPlus } from '@fortawesome/pro-regular-svg-icons';

const ModulesContainer = styled.section`
  margin-top: ${(props) => (props.editionMode ? 0 : '-8rem')};
`;

export const ProgramHeader = observer(({
  editionMode
}) => {
  const { programId } = useParams();
  const { programStore } = useStores();
  const {
    description, logoURL, moduleSummaries, name
  } = programStore.program;

  return (
    <section>
      {moduleSummaries && moduleSummaries.length > 0 ? (
        <ModulesContainer editionMode={editionMode}>
          <Wrapper>
            <Grid container spacing={4}>
              {moduleSummaries.map((module) => (
                <Grid item key={module.id} md={4} xs={12}>
                  <CardSummary
                    icon={<FontAwesomeIcon icon={faPlay} style={{ marginRight: '1rem' }} />}
                    to={ROUTES.PROGRAM_MODULE(programId, module.id)}
                    {...module}
                  />
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </ModulesContainer>
      ) : editionMode && (
        <ModulesContainer editionMode={editionMode}>
          <Wrapper>
            <Grid container spacing={4}>
              <Grid item md={4} xs={12}>
                <CardSummary
                  icon={<FontAwesomeIcon icon={faPlus} style={{ marginRight: '1rem' }} />}
                  name={translate('forms.program.addModule')}
                  to=""
                />
              </Grid>
            </Grid>
          </Wrapper>
        </ModulesContainer>
      )}

      {description && (
        <Wrapper>
          <Text as="h2" margin="4rem 0" textAlign="center">
            {translate('pageProgramDetail.programDescription')}
          </Text>
          <Grid container style={{ marginBottom: '8rem' }}>
            {logoURL && (
              <Grid item sm={2} xs={12}>
                <Avatar
                  alt={name}
                  src={`${API_URL_DOCUMENTS}${logoURL}`}
                  style={{ height: '100px', width: '100px' }}
                />
              </Grid>
            )}
            <Grid item sm={logoURL ? 10 : 12} xs={12}>
              <TextMultiline color="var(--grey-darker)" margin="0" textAlign="justify">
                {description}
              </TextMultiline>
            </Grid>
          </Grid>
        </Wrapper>
      )}
    </section>
  );
});

ProgramHeader.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  section: PropTypes.shape({
    title: PropTypes.string,
    backgroundImage: PropTypes.string
  }).isRequired
};