import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { API_URL_DOCUMENTS } from 'utils/constants';
import { Avatar } from '@material-ui/core';

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.textAlign};
  min-height: 6rem;
  background: ${(props) => props.background};
`;

export const Title = styled.h2`
  margin: ${(props) => props.margin};
`;

export const SectionTitle = ({
  title, textAlign, margin, background, logoURL
}) => (
  title && (
    <TitleContainer background={background} textAlign={textAlign}>
      <Title margin={margin}>{title}</Title>
      {logoURL && (
        <Avatar
          alt={title}
          src={`${API_URL_DOCUMENTS}${logoURL}`}
          style={{ width: '40px', height: '40px' }}
        />
      )}
    </TitleContainer>
  )
);

SectionTitle.propTypes = {
  background: PropTypes.string,
  margin: PropTypes.string,
  title: PropTypes.string,
  textAlign: PropTypes.string,
  logoURL: PropTypes.string
};

SectionTitle.defaultProps = {
  background: 'transparent',
  logoURL: '',
  margin: '',
  textAlign: 'left',
  title: ''
};