import { moduleHeader } from './section/module_header';

const moduleFixture = {
  id: '0',
  index: 0,
  name: 'ECLER',
  description: 'Les bonnes pratiques du transport frigorifique',
  logoURL: null,
  isPublished: false,
  sequenceSummaries: [
    {
      id: '1',
      index: 1,
      name: 'LA PORTE',
      description: 'Je garde la porte fermée le plus longtemps',
      logoURL: 'b22a72b5-b3cd-4a0c-9fb3-d333f9c3ea70'
    },
    {
      id: '2',
      index: 2,
      name: 'LE RIDEAU',
      description: "J'utilise un rideau d'air",
      logoURL: '27785f05-f369-48df-aab6-ab9426c1f249'
    },
    {
      id: '3',
      index: 3,
      name: 'LE STATIONNEMENT',
      description: "Je stationne mon véhicule à l'ombre",
      logoURL: 'b49b86d8-784a-41c3-9a2c-150cd42c8449'
    },
    {
      id: '4',
      index: 4,
      name: 'LE GROUPE',
      description: "Je coupe mon groupe frigorifique dès l'ouverture des portes",
      logoURL: '0d38171e-30c6-42f2-9127-8be02c9bedb8'
    },
    {
      id: '5',
      index: 5,
      name: 'LE CHARGEMENT',
      description: "J'assure la circulation de l'air dans mon chargement",
      logoURL: 'f02b5318-d970-42ce-9890-d8aad71c420e'
    },
    {
      id: '6',
      index: 6,
      name: 'LA TEMPÉRATURE',
      description: "J'enregistre et contrôle les températures",
      logoURL: '19aaefc4-e514-4a1f-9ac6-c9eb0ddb088b'
    },
    {
      id: '7',
      index: 7,
      name: 'LA COULEUR ET LA PROPRETÉ DU VÉHICULE',
      description: 'Description à venir.',
      logoURL: '631bb60d-2ed6-4384-8180-10f609b14005'
    },
    {
      id: '8',
      index: 8,
      name: 'ÉTAT DE LA CAISSE',
      description: 'Description à venir.',
      logoURL: '631bb60d-2ed6-4384-8180-10f609b14005'
    },
    {
      id: '9',
      index: 9,
      name: 'ARRÊT DU GROUPE AU CHARGEMENT LIVRAISON',
      description: 'Description à venir.',
      logoURL: '631bb60d-2ed6-4384-8180-10f609b14005'
    },
    {
      id: '10',
      index: 10,
      name: 'LE BIBERONNAGE',
      description: 'Description à venir.',
      logoURL: '631bb60d-2ed6-4384-8180-10f609b14005'
    }
  ],
  content: {
    sections: [
      moduleHeader()
    ]
  }
};

export default moduleFixture;