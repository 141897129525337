import {
  Grid
} from '@material-ui/core';

import React, { useCallback } from 'react';

import { translate } from 'utils/translation';

import PropTypes from 'prop-types';

import { SelectField, TextField } from 'components/forms/inputs';

export const DocumentListFilters = ({
  currentFilters, setCurrentFilters, documentContentTypes
}) => {
  const code = (currentFilters.find((filt) => filt.key === 'code')
    && currentFilters.find((filt) => filt.key === 'code').label) || null;

  const name = (currentFilters.find((filt) => filt.key === 'name')
    && currentFilters.find((filt) => filt.key === 'name').label) || null;

  const author = (currentFilters.find((filt) => filt.key === 'author')
     && currentFilters.find((filt) => filt.key === 'author').label) || null;

  const contentType = (currentFilters.find((filt) => filt.key === 'contentType')
    && currentFilters.find((filt) => filt.key === 'contentType').value) || null;

  const handleChangeInput = useCallback((event) => {
    const { name: filterName, value } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), { key: filterName, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleSelect = useCallback((filterName, value) => {
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), {
        key: filterName,
        label: value.label,
        value
      }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  return (
    <Grid container direction="column">
      <TextField
        autoComplete="off"
        label={translate('common.code')}
        name="code"
        value={code}
        onChange={handleChangeInput}
      />

      <TextField
        autoComplete="off"
        label={translate('common.name')}
        name="name"
        value={name}
        onChange={handleChangeInput}
      />

      <TextField
        autoComplete="off"
        label={translate('common.author')}
        name="author"
        value={author}
        onChange={handleChangeInput}
      />

      <SelectField
        label="pageDocumentList.documentType"
        name="contentType"
        options={documentContentTypes}
        value={contentType}
        onChange={handleSelect}
      />
    </Grid>
  );
};

DocumentListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired,
  documentContentTypes: PropTypes.arrayOf(PropTypes.shape({}))
};

DocumentListFilters.defaultProps = {
  documentContentTypes: []
};