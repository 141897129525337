import React, { useCallback, useEffect } from 'react';
import {
  faEdit, faEye, faFileExport, faSave, faSignOut, faUsersClass
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, ButtonContainer, ButtonCreateSequence
} from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { useModal, useStores } from 'hooks';
import { ModuleService } from 'services';
import { translate } from 'utils/translation';
import { Grid } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { ROUTES } from 'utils/constants';

export const ModuleEditionNav = ({
  onEdit, onClickDetail, isEditionMode, viewMode, setViewMode, loadModule, setIsTempEditionMode, allowTraineeManagement
}) => {
  const history = useHistory();
  const { moduleStore, editionStore } = useStores();
  const { editionMode, editionPreviewMode } = editionStore;
  const { module } = moduleStore;
  const { programId, moduleId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();

  useEffect(() => {
    if (isEditionMode) {
      editionStore.setEditionMode();
      viewMode === 'moduleDetail' ? onClickDetail() : onEdit();
      setIsTempEditionMode(false);
    }
  }, [isEditionMode, editionStore, onEdit, viewMode, onClickDetail, setIsTempEditionMode]);

  const toggleEdition = useCallback(() => {
    editionStore.toggleEdition();
    viewMode === 'moduleDetail' ? onClickDetail() : onEdit();
  }, [editionStore, onEdit, viewMode, onClickDetail]);

  const togglePreview = useCallback(() => {
    editionStore.togglePreview();
  }, [editionStore]);

  const showWarningModal = useCallback((action, title, text) => showModal({
    type: 'WARNING',
    title,
    text,
    onConfirm: action
  }), [showModal]);

  const handleQuitAction = useCallback(() => {
    setViewMode('educationalSheet');
    editionStore.reset();

    // Reload module to remove non saved modifications
    loadModule();
  }, [setViewMode, editionStore, loadModule]);

  const handleQuit = useCallback(() => {
    if (!editionStore.lastEditionSaved) {
      showWarningModal(() => {
        handleQuitAction();
      },
      'forms.titleWarningLeaveEdition',
      'forms.warningLeaveEdition');
    } else {
      handleQuitAction();
    }
  },
  [editionStore, showWarningModal, handleQuitAction]);

  const handleSave = useCallback(() => {
    if (!module.isPublished || moduleStore.getValidity()) {
      ModuleService.updateModule(moduleId, module).then(() => {
        enqueueSnackbar(translate('forms.module.moduleCorrectlySaved'), { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
      editionStore.setLastSaved();
    } else {
      enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }
  }, [enqueueSnackbar, moduleId, module, moduleStore, editionStore]);

  const handlePublish = useCallback(() => {
    onEdit();
    if (moduleStore.getValidity()) {
      moduleStore.publishModule();
      ModuleService.updateModule(moduleId, module).then(() => {
        editionStore.reset();
        enqueueSnackbar(translate('forms.module.moduleCorrectlyPublished'), { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
      editionStore.setLastSaved();
    } else {
      enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }
  }, [onEdit, editionStore, enqueueSnackbar, moduleId, moduleStore, module]);

  const handleChangeMode = useCallback((_, mode) => {
    if (!mode) return;
    setViewMode(mode);

    // Set the module form to edit
    if (mode === 'educationalSheet') onEdit();
    if (mode === 'moduleDetail') onClickDetail();
  }, [onEdit, onClickDetail, setViewMode]);

  const handleViewTrainees = useCallback(() => {
    history.push(ROUTES.TRAINEES('module', moduleId));
  }, [history, moduleId]);

  const HeaderPreviewMode = () => (
    <ButtonContainer>
      {allowTraineeManagement && (
        <Button
          startIcon={<FontAwesomeIcon icon={faUsersClass} />}
          type="secondary"
          onClick={handleViewTrainees}
        >
          {translate('button.viewTrainees')}
        </Button>
      )}

      <ButtonCreateSequence moduleId={moduleId} programId={programId} />

      <Button
        startIcon={<FontAwesomeIcon icon={faEdit} />}
        type="secondary"
        onClick={toggleEdition}
      >
        {translate('button.edit')}
      </Button>
    </ButtonContainer>
  );

  const HeaderEditionMode = () => (
    <Grid alignItems="center" container justifyContent="space-between">
      <Grid item>
        <ToggleButtonGroup
          exclusive
          value={viewMode}
          onChange={handleChangeMode}
        >
          <ToggleButton value="educationalSheet">
            {translate('forms.module.educationalSheet.title')}
          </ToggleButton>
          <ToggleButton value="moduleDetail">
            {translate('forms.module.moduleDetail')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <ButtonContainer>
          <Button
            startIcon={<FontAwesomeIcon icon={faSignOut} />}
            type="danger"
            variant="text"
            onClick={handleQuit}
          >
            {translate('button.cancelAndQuit')}
          </Button>
          <Button
            startIcon={<FontAwesomeIcon icon={faEye} />}
            type="secondary"
            onClick={togglePreview}
          >
            {editionPreviewMode ? translate('button.continueEdition') : translate('common.preview')}
          </Button>
          <Button
            startIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={handleSave}
          >
            {translate('button.saveOnly')}
          </Button>
          {!module.isPublished && (
            <Button
              startIcon={<FontAwesomeIcon icon={faFileExport} />}
              onClick={handlePublish}
            >
              {translate('button.saveAndPublish')}
            </Button>
          )}
        </ButtonContainer>
      </Grid>
    </Grid>
  );

  return (
    <HeaderEditionNav fullWidth={editionMode || editionPreviewMode}>
      {editionMode || editionPreviewMode ? <HeaderEditionMode /> : <HeaderPreviewMode />}
    </HeaderEditionNav>
  );
};