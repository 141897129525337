import { action, decorate, observable } from 'mobx';

export class QuizStore {
  constructor() {
    this.lastExamination = {};
    this.startedExamination = {};
    this.finishedExamination = {};
    this.examinationState = {
      examinationId: '',
      quizId: '',
      isQuizStarted: false,
      isQuizEnded: false
    };
    this.questionsWithTraineeAnswers = [];
    this.pageWithTraineeAnswers = {};
    this.questionNumbers = [];
    this.isLoading = false;
    this.error = null;
    this.totalPages = 0;
    this.totalQuestions = 0;
    this.isPageValidated = false;
    this.currentPage = {};
    this.showCorrectedAnswers = false;
    this.isExaminationLoading = false;
    this.isQuestionValid = [];
    this.isInFullScreen = false;
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  setExamStatus({
    isStarted, isEnded, examinationId, quizId
  }) {
    this.examinationState = {
      examinationId,
      quizId,
      isQuizStarted: isStarted,
      isQuizEnded: isEnded
    };

    return this.examinationState;
  }

  setLastExamination(examination) {
    this.lastExamination = examination;
    return this.lastExamination;
  }

  setExaminationStart(examination) {
    this.startedExamination = examination;
    return this.startedExamination;
  }

  setPageWithTraineeAnswers(pageData) {
    this.pageWithTraineeAnswers = pageData;
  }

  setTraineeAnswers(currentPage, currentQuestion, traineeAnswer) {
    this.setPageWithTraineeAnswers(currentPage);

    const pageIndex = currentPage.index;

    const newCurrentQuestion = {
      ...currentQuestion,
      pageIndex
    };

    if (this.questionsWithTraineeAnswers && this.questionsWithTraineeAnswers.length === 0) {
      this.questionsWithTraineeAnswers = [
        {
          ...newCurrentQuestion,
          traineeAnswer
        }
      ];
    } else {
      this.pageWithTraineeAnswers.questions.map((ques) => {
        if (this.pageWithTraineeAnswers && this.pageWithTraineeAnswers.id === currentPage.id && ques.id === newCurrentQuestion.id) {
          ques.traineeAnswer = traineeAnswer;
        }

        const isQuestionExists = this.pageWithTraineeAnswers.questions.find((q) => q.id === newCurrentQuestion.id);

        if (!isQuestionExists) {
          this.questionsWithTraineeAnswers.push({
            ...newCurrentQuestion,
            traineeAnswer
          });
        }
        return this.questionsWithTraineeAnswers;
      });
    }

    currentPage.questions
      .filter((q) => q.id === newCurrentQuestion.id)
      .forEach((q) => {
        q.traineeAnswer = traineeAnswer;
        q.pageIndex = newCurrentQuestion.pageIndex;
      });

    this.pageWithTraineeAnswers = {
      ...currentPage
    };

    this.canGoNextPage();
    return this.pageWithTraineeAnswers;
  }

  updateQuestion(pageId, questionId, responseData) {
    // Update data with the responses not submitted yet
    this.startedExamination.quiz.pages
      .filter((pg) => pg.id === pageId)
      .forEach((page) => {
        page.questions
          .forEach((question) => {
            this.startedExamination
              .quiz
              .pages[page.index]
              .questions[question.index] = {
                ...question,
                traineeAnswer: this.pageWithTraineeAnswers.questions[question.index].traineeAnswer
              };
          });
      });

    // Update data with the submitted response
    this.startedExamination.quiz.pages
      .filter((pg) => pg.id === pageId)
      .forEach((page) => {
        page.questions
          .filter((q) => q.id === questionId)
          .forEach((question) => {
            this.startedExamination
              .quiz
              .pages[page.index]
              .questions[question.index] = {
                ...responseData
              };
            this.setCurrentPage(this.startedExamination.quiz.pages[page.index]);
          });
      });
  }

  updatePage(responseData) {
    return this.startedExamination.quiz.pages
      .filter((pg) => pg.id === responseData.id && pg.index === responseData.index)
      .map((pg) => {
        this.startedExamination.quiz.pages[pg.index] = responseData;
        return this.startedExamination;
      });
  }

  finishQuiz(responseData) {
    this.finishedExamination = responseData;
    return this.finishedExamination;
  }

  getTotalPagesAndQuestions() {
    if (this.startedExamination.quiz.pages) {
      this.totalPages = this.startedExamination.quiz.pages.length;
      const questionInArray = this.startedExamination.quiz.pages.map((page) => page.questions && page.questions.length);
      this.totalQuestions = questionInArray.reduce((a, b) => a + b, 0);
    } else {
      this.totalPages = 0;
      this.totalQuestions = 0;
    }
  }

  getQuestionNumbering() {
    if (this.startedExamination.quiz.pages) {
      this.startedExamination.quiz.pages.map((page, pageIndx) => {
        const indexOfPreviousPage = pageIndx - 1;

        page.questions.map((question, questionIndex) => {
          const lastPageNumber = this.questionNumbers.length > 0
            && this.questionNumbers[this.questionNumbers.length - 1].questionNumber;

          // First page
          if (indexOfPreviousPage < 0) {
            this.questionNumbers.push({
              pageIndex: page.index,
              questionId: question.id,
              questionNumber: questionIndex + 1
            });
          // Not first page, first question
          } else if (questionIndex === 0) {
            this.questionNumbers.push({
              pageIndex: page.index,
              questionId: question.id,
              questionNumber: lastPageNumber + 1
            });
          // Not first page, not first question
          } else {
            this.questionNumbers.push({
              pageIndex: page.index,
              questionId: question.id,
              questionNumber: lastPageNumber + 1
            });
          }
          return this.questionNumbers;
        });
        return this.questionNumbers;
      });
    }
    return null;
  }

  // Check if the current page is valid on render
  canGoNextPage() {
    if (Object.keys(this.pageWithTraineeAnswers).length === 0) return null;

    // Get required questions for page validation
    const requiredQuestions = [];
    this.pageWithTraineeAnswers.questions.forEach((q) => {
      if (q.isRequired) {
        return requiredQuestions.push(q);
      }
      return null;
    });

    const isNotAnsweredExists = this.pageWithTraineeAnswers.questions
      .some((question) => question.isAnswered === false);

    const isRequiredAnswered = requiredQuestions.every((question) => question.isRequired
      && question.traineeAnswer
      && (question.traineeAnswer.length > 0
        || typeof question.traineeAnswer === 'number'
        || question.traineeAnswer instanceof Date));

    const isInvalidQuestionExists = this.isQuestionValid
      .some((q) => q.status === false);

    if (this.startedExamination.quiz.forceValidation) {
      this.isPageValidated = !isNotAnsweredExists;
      return this.isPageValidated;
    }

    if (!isRequiredAnswered) {
      this.isPageValidated = false;
      return this.isPageValidated;
    }

    if (isInvalidQuestionExists) {
      this.isPageValidated = !isInvalidQuestionExists;
      return this.isPageValidated;
    }

    this.isPageValidated = true;
    return this.isPageValidated;
  }

  setCurrentPage(currentPage) {
    this.currentPage = currentPage;

    return this.currentPage;
  }

  setNextPage() {
    const pageIndex = this.currentPage.index;
    if (pageIndex < this.startedExamination.quiz.pages.length - 1) {
      this.currentPage = this.startedExamination.quiz.pages[pageIndex + 1];

      return this.currentPage;
    }
    return null;
  }

  setPreviousPage() {
    const pageIndex = this.currentPage.index;
    if (pageIndex >= 1) {
      this.currentPage = this.startedExamination.quiz.pages[pageIndex - 1];

      return this.currentPage;
    }
    return null;
  }

  toggleShowResults(status) {
    this.showCorrectedAnswers = status;

    return this.showCorrectedAnswers;
  }

  toggleLoadingExamination(status) {
    this.isExaminationLoading = status;

    return this.isExaminationLoading;
  }

  toggleQuestionValidity({ questionType, status }) {
    if (this.isQuestionValid.length === 0) {
      this.isQuestionValid.push({ questionType, status });
    }

    const questionStatusFound = this.isQuestionValid.find((q) => q.questionType === questionType);

    if (questionStatusFound) {
      this.isQuestionValid.forEach((q) => {
        if (q.questionType === questionType) {
          q.status = status;
          return q;
        }
        return null;
      });
    } else {
      this.isQuestionValid.push({ questionType, status });
    }
    this.canGoNextPage();
    return this.isQuestionValid;
  }

  toggleFullScreen(status) {
    this.isInFullScreen = status;
  }
}

decorate(QuizStore, {
  lastExamination: observable,
  startedExamination: observable,
  finishedExamination: observable,
  examinationState: observable,
  questionsWithTraineeAnswers: observable,
  pageWithTraineeAnswers: observable,
  questionNumbers: observable,
  isLoading: observable,
  error: observable,
  totalPages: observable,
  totalQuestions: observable,
  isPageValidated: observable,
  currentPage: observable,
  showCorrectedAnswers: observable,
  isExaminationLoading: observable,
  isQuestionValid: observable,
  isInFullScreen: observable,
  // Actions
  setLoading: action,
  setExamStatus: action,
  getQuestionNumbering: action,
  getTotalPagesAndQuestions: action,
  setQuizExamStart: action,
  setExaminationStart: action,
  setLastExamination: action,
  setTraineeAnswers: action,
  updatePage: action,
  updateQuestion: action,
  finishQuiz: action,
  canGoNextPage: action,
  setPageWithTraineeAnswers: action,
  setCurrentPage: action,
  setNextPage: action,
  setPreviousPage: action,
  toggleShowResults: action,
  toggleLoadingExamination: action,
  toggleQuestionValidity: action,
  toggleFullScreen: action
});

export const quizStore = new QuizStore();