import {
  faCopy, faEdit, faEye, faImage, faLink
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  IconButton, TableCell
} from '@material-ui/core';
import {
  GenericFilters, GenericTable, PageHeader, SkeletonTable,
  TextError, Wrapper
} from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { transformObjectListToStringQueriesParam } from 'utils';
import {
  API_URL_DOCUMENTS, APP_URL, ROUTES
} from 'utils/constants';
import { getSmallDescription } from 'utils/helpers';
import { translate } from 'utils/translation';
import { SequenceListFilters } from './SequenceListFilters';

const getSequenceListHeaders = (handleEditSequence, handleCopySequence, handleCreateLink) => ([
  {
    name: 'logo',
    label: 'common.logo',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.logoURL ? (
          <img
            alt={row.name}
            height="60px"
            src={`${API_URL_DOCUMENTS}${row.logoURL}`}
          />
        ) : <FontAwesomeIcon icon={faImage} />}
      </TableCell>
    )
  }, {
    name: 'name',
    label: 'common.name',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.name}
      </TableCell>
    )
  }, {
    name: 'description',
    label: 'common.description',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {getSmallDescription(row.description, 100)}
      </TableCell>
    )
  },
  {
    name: 'quizActions',
    label: 'common.actions',
    width: '306px',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>

          <Grid item>
            <Link to={ROUTES.SEQUENCE(row.id)}>
              <IconButton edge="end">
                <FontAwesomeIcon icon={faEye} size="xs" />
              </IconButton>
            </Link>
          </Grid>

          <Grid item>
            <IconButton color="primary" edge="end" onClick={(e) => { e.stopPropagation(); handleEditSequence(row); }}>
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton color="primary" edge="end" onClick={(e) => { e.stopPropagation(); handleCopySequence(row); }}>
              <FontAwesomeIcon icon={faCopy} size="xs" />
            </IconButton>
          </Grid>

          {row.isIndependent && (
            <Grid item>
              <IconButton color="primary" edge="end" onClick={(e) => { e.stopPropagation(); handleCreateLink(row); }}>
                <FontAwesomeIcon icon={faLink} size="xs" />
              </IconButton>
            </Grid>
          )}

        </Grid>
      </TableCell>
    )
  }
]);

export const SequenceList = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const { sequenceStore } = useStores();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const { maxPage, totalElements } = sequenceStore;
  const [currentPage, setCurrentPage] = useState(0);

  const history = useHistory();

  const filterKey = 'sequenceList';

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  useEffect(() => {
    const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);
    sequenceStore.loadSequenceList({ search, currentPage, filters: filtersQueriesParams });
  }, [sequenceStore, currentPage, search, filters]);

  const loadMore = useCallback(() => {
    if (currentPage <= maxPage - 1) {
      return !sequenceStore.isLoading && setCurrentPage(currentPage + 1);
    }
    return null;
  }, [sequenceStore.isLoading, currentPage, maxPage]);

  const handleEditSequence = useCallback((sequence) => {
    sequenceStore.resetSequence();
    history.push({
      pathname: ROUTES.SEQUENCE(sequence.id),
      state: {
        isEditionMode: true
      }
    });
  }, [history, sequenceStore]);

  const handleCopySequence = useCallback((sequence) => {
    sequenceStore.resetSequence();
    history.push({
      pathname: ROUTES.SEQUENCE(sequence.id),
      state: {
        isEditionMode: true,
        isCopyMode: true
      }
    });
  }, [history, sequenceStore]);

  const handleCreateLink = useCallback((sequence) => {
    const text = `${APP_URL}sequences/${sequence.id}`;
    if (!navigator.clipboard) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        //
      }
      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar(translate('confirms.linkCopied'), { variant: 'success' });
    });
  }, [enqueueSnackbar]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <SequenceListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  return (
    <>
      <Wrapper>
        <PageHeader title="pageSequenceList.title" />

        <GenericFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-intervention-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setCurrentPage={setCurrentPage}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltip="pageSequenceList.searchTooltip"
          withDrawer
        />

        {(!sequenceStore.isLoading && !sequenceStore.isError && sequenceStore.sequenceList.length === 0) && (
          <TextError data-testid="errors.noSequence">{translate('errors.noSequence')}</TextError>
        )}

        {(!sequenceStore.isLoading && sequenceStore.isError) && (
          <TextError data-testid="errors.sequenceListFailed">{translate('errors.sequenceListFailed')}</TextError>
        )}

        {sequenceStore.isLoading ? <SkeletonTable /> : sequenceStore.sequenceList.length > 0 && (
          <div data-testid="sequence-list">
            <GenericTable
              hasMore={currentPage < maxPage}
              headers={getSequenceListHeaders(handleEditSequence, handleCopySequence, handleCreateLink)}
              id={shortid.generate()}
              loadMore={loadMore}
              rows={sequenceStore.sequenceList}
              total={totalElements}
            />
          </div>
        )}
      </Wrapper>
    </>
  );
});