import React from 'react';
import styled from 'styled-components';
import { Box, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';

const BoxContainer = styled(Box)`
  margin: 0 0 1rem 0;
`;

export const SectionContent = ({
  children, direction, spacing, ...props
}) => (
  <BoxContainer>
    <Grid container direction={direction} spacing={spacing} {...props}>
      {children}
    </Grid>
  </BoxContainer>
);

SectionContent.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.string,
  spacing: PropTypes.number
};

SectionContent.defaultProps = {
  children: '',
  direction: 'row',
  spacing: 2
};