import React from 'react';
import {
  Avatar,
  Card, CardActions, CardContent, CardMedia, Grid, Tooltip, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, VisibilityTooltip } from 'components';
import { pluralize } from 'utils';
import { API_URL_DOCUMENTS, ROLE_GROUPS } from 'utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { getSmallDescription, UserHelper } from 'utils/helpers';
import { translate } from 'utils/translation';

const CardStyled = styled(Card)`
  display: flex;
  min-width: 250px;
  flex-flow: column nowrap;

  &:hover {
    text-decoration: none
  }
`;

const StyledCardContent = styled(CardContent)`
  margin-top: -50px;
  min-height: 200px;
  z-index: 1;
`;

const StyledCardMedia = styled(CardMedia)`
  height: 120px;
  filter: contrast(85%);
`;

const ProgramTitle = styled(Typography)`
  margin-bottom: 0.5rem !important;
  color: var(--white);
  text-shadow: 1px 1px 8px rgb(0, 0, 0, 0.9);
`;

export const CardProgram = ({
  name, description, numberOfModules, logoURL, isPublic, canSubscribe,
  backgroundImage, programDurationInDays, to, isPublished
}) => {
  const canEditContent = UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS);

  return (
    <CardStyled component={Link} to={to}>
      <StyledCardMedia
        image={backgroundImage ? `${API_URL_DOCUMENTS}${backgroundImage}` : '/assets/images/bg/cover.png'}
        title={name}
      />
      <StyledCardContent>
        <Grid container spacing={2} style={{ minHeight: '100px' }}>
          <Grid item>
            <Avatar
              alt={name}
              src={logoURL ? `${API_URL_DOCUMENTS}${logoURL}` : '/assets/images/icons/defaultProgram.png'}
              style={{ width: '80px', height: '80px' }}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Grid container justifyContent="space-between">
              <Grid item xs={10}>
                <Tooltip title={name}>
                  <ProgramTitle component="h3" noWrap variant="h5">
                    {name}
                  </ProgramTitle>
                </Tooltip>
              </Grid>
              {canEditContent && (
                <Grid alignItems="flex-start" container item justifyContent="flex-end" xs={2}>
                  <VisibilityTooltip color="var(--white)" isPublic={isPublished} />
                </Grid>
              )}
            </Grid>
            <Typography>
              <FontAwesomeIcon icon={faPlay} style={{ marginRight: '1rem' }} />
              {`${numberOfModules} `}
              {translate(`${numberOfModules > 1 ? 'common.modules' : 'common.module'}`)}
            </Typography>
            {programDurationInDays ? (
              <Typography>
                <FontAwesomeIcon icon={faClock} style={{ marginRight: '1rem' }} />
                {`${programDurationInDays} ${pluralize(translate('common.day'), programDurationInDays)}`}
              </Typography>
            ) : ''}
          </Grid>
        </Grid>
        <Typography component="p" style={{ marginTop: '2rem', minHeight: '60px' }} variant="body2">
          {getSmallDescription(description, 200)}
        </Typography>
      </StyledCardContent>
      <CardActions>
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Button type="primary">
              {translate('button.viewProgramDetail')}
            </Button>
          </Grid>
          {/* <Grid item>
            <Grid container justifyContent="flex-end">
              {isPublic && (
                <Grid item>
                  <Chip
                    label={translate('common.freeAccess')}
                    size="small"
                  />
                </Grid>
              )}
              {canSubscribe && (
                <Grid item>
                  <Chip
                    label={translate('common.canSubscribe')}
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
          </Grid> */}
        </Grid>
      </CardActions>
    </CardStyled>
  );
};

CardProgram.propTypes = {
  backgroundImage: PropTypes.string,
  description: PropTypes.string,
  logoURL: PropTypes.string,
  isPublished: PropTypes.bool,
  numberOfModules: PropTypes.number,
  name: PropTypes.string.isRequired,
  programDurationInDays: PropTypes.number,
  to: PropTypes.string.isRequired
};

CardProgram.defaultProps = {
  backgroundImage: '',
  description: '',
  isPublished: false,
  logoURL: '',
  numberOfModules: null,
  programDurationInDays: null
};