const GA_COOKIE = 'gaCookieConsent';
const CHANGE_PASSOWRD = 'changePassword';

const setChangePasswordCookie = status => {
  if (status) {
    document.cookie = `${CHANGE_PASSOWRD}=${status}; path='/'`;
  } else {
    document.cookie = `${CHANGE_PASSOWRD}=${status}; expires=-1 path='/'`;
  }
};

const getChangePasswordStatus = () => document.cookie
  .split('; ')
  .find(cookie => cookie.startsWith(`${CHANGE_PASSOWRD}=`))?.split('=')[1] === 'true';

const setGACookie = (consent) => {
  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 6);
  document.cookie = `${GA_COOKIE}=${consent}; expires=${expirationDate.toUTCString()}; path='/'`;
};

const getGAConsentCookie = () => document.cookie.split('; ').find((cookie) => cookie.startsWith(`${GA_COOKIE}=`));

const getGAConsent = () => getGAConsentCookie()?.split('=')[1] === 'true';

export const CookiesHelper = {
  setGACookie,
  getGAConsent,
  getGAConsentCookie,
  setChangePasswordCookie,
  getChangePasswordStatus
};