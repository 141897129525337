import { faCookieBite } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  ASSISTANCE_EMAIL, ROUTES
} from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { Wrapper } from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react';

const StyledFooter = styled.footer`
  margin-top: 40px;
  text-align: center;

  @media (max-width: 1081px) {
    padding-bottom: 40px;

    .desktop {
      display: none;
    }
  }

  @media (min-width: 1080px) {
    .mobile {
      display: none;
    }
  }
`;

const StyledDivider = styled(Divider)`
  display: inline-block;
  width: 2px;
  background-color: #7e7e7e !important;
  height: 14px !important;
`;

export const Footer = observer(() => {
  const { editionStore, quizStore } = useStores();
  const { editionMode } = editionStore;
  const { isInFullScreen } = quizStore;

  if (editionMode) return null;

  return (
    <>
      {!isInFullScreen && (
        <Wrapper>
          <StyledFooter>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item>
                    <Link to={ROUTES.RGPD}>
                      <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                      {translate('common.personalData')}
                    </Link>
                  </Grid>

                  {!isMobile && (
                    <Grid item>
                      <StyledDivider orientation="vertical" />
                    </Grid>
                  )}

                  <Grid item>
                    <Link to={ROUTES.COOKIES}>
                      <FontAwesomeIcon className="mr1" icon={faCookieBite} />
                      {translate('link.rgpd.cookies')}
                    </Link>
                  </Grid>

                  {!isMobile && (
                    <Grid item>
                      <StyledDivider orientation="vertical" />
                    </Grid>
                  )}

                  <Grid item>
                    <a href={`mailto:${ASSISTANCE_EMAIL}`}>
                      <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                      {`${translate('common.assistance')} (${ASSISTANCE_EMAIL})`}
                    </a>
                  </Grid>

                  {/* <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a
                href={`${URL_DATATECNEA}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIconStyled icon={faLink} />
                {translate('common.datatecneaLink')}
              </a>
            </Grid> */}
                </Grid>
              </Grid>

              <Grid item>
                <img alt="TECNEA_GROUP" src={`${process.env.PUBLIC_URL}/assets/images/partners/TECNEA_GROUP.png`} />
              </Grid>

              <Grid item>
                {isMobile ? (
                  <img
                    alt={translate('menu.logoAlt')}
                    className="supralog"
                    src={`${process.env.PUBLIC_URL}/assets/images/bg/footer-mobile.png`}
                    width="100%"
                  />
                ) : (
                  <img
                    alt={translate('menu.logoAlt')}
                    className="supralog"
                    src={`${process.env.PUBLIC_URL}/assets/images/bg/footer.png`}
                  />
                )}

              </Grid>
              {/* <FooterSupralog /> */}
            </Grid>
          </StyledFooter>
        </Wrapper>
      )}
    </>
  );
});