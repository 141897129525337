import React, { useCallback, useEffect } from 'react';
import { StorageHelper } from 'utils/helpers';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { QuizService } from 'services';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { ROOT_PATH, ROUTES } from 'utils/constants';
import { Text } from 'components/_commons/Text';
import { QuizHeader } from '..';
import { EXAM_KEY, QUIZ_STATUS } from '../QuizHelper';

const QuizStartPage = observer(({ element }) => {
  const { notRunning } = QUIZ_STATUS;

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { programId, moduleId, sequenceId } = useParams();

  const { quizStore } = useStores();

  const { lastExamination, isExaminationLoading } = quizStore;

  const getPreviousRoute = useCallback(() => {
    if (programId != null) {
      return ROUTES.PROGRAM_MODULE_SEQUENCE(programId, moduleId, sequenceId);
    }
    if (moduleId != null) {
      return ROUTES.MODULE_SEQUENCE(moduleId, sequenceId);
    }
    if (sequenceId != null) {
      return ROUTES.SEQUENCE(sequenceId);
    }
    return ROOT_PATH;
  }, [programId, moduleId, sequenceId]);

  const loadLastExamination = useCallback(() => {
    quizStore.toggleLoadingExamination(true);

    if (element.id.length > 0) {
      QuizService.getLastExamination({
        quizId: element.id, sequenceId, moduleId, programId
      })
        .then((response) => {
          if (typeof response !== 'object') {
            enqueueSnackbar(translate('errors.quizRemoved'), { variant: 'error' });
            history.push({
              pathname: getPreviousRoute(),
              state: {}
            });
            return;
          }
          quizStore.setLastExamination(response);
          StorageHelper.SET(EXAM_KEY, response.id);
        })
        .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
        .finally(() => quizStore.toggleLoadingExamination(false));
    }
  }, [getPreviousRoute, history, element, enqueueSnackbar, quizStore, sequenceId, moduleId, programId]);

  useEffect(() => {
    loadLastExamination();
  }, [loadLastExamination]);

  if (!isExaminationLoading && !lastExamination) {
    return <Text>{translate('errors.noQuiz')}</Text>;
  }

  if (!lastExamination) return null;

  return (
    <>
      {lastExamination.quiz && <QuizHeader quizStatus={notRunning} />}
    </>
  );
});

export default QuizStartPage;

QuizStartPage.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  })
};

QuizStartPage.defaultProps = {
  element: PropTypes.shape({
    id: '',
    title: '',
    description: ''
  })
};