import React, { useCallback } from 'react';
import { Button } from 'components';
import { translate } from 'utils/translation';
import { useModal } from 'hooks';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { SessionService } from 'services';


const ButtonDisplaySession = ({ moduleId, label, ...props }) => {
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();


  const createOrUpdateSession = useCallback((sessionId, formData, getSessionsList) => {
    if (sessionId) {
      SessionService.updateSession(sessionId, formData)
        .then(() => enqueueSnackbar(translate('forms.module.sessionModal.sessionCorrectlyModified'), { variant: 'success' }))
        .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => getSessionsList());
    } else {
      SessionService.createSession(formData)
        .then(() => enqueueSnackbar(translate('forms.module.sessionModal.sessionCorrectlyCreated'), { variant: 'success' }))
        .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
        .finally(() => getSessionsList());
    }
  }, [enqueueSnackbar]);

  const showSessionModal = useCallback(() => showModal({
    type: 'DISPLAY_SESSIONS',
    moduleId,
    onConfirm: createOrUpdateSession
  }), [showModal, createOrUpdateSession, moduleId]);


  return (
    <Button
      fullWidth
      startIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
      type="secondary"
      onClick={showSessionModal}
      {...props}
    >
      {translate(label)}
    </Button>
  );
};

ButtonDisplaySession.propTypes = {
  label: PropTypes.string,
  moduleId: PropTypes.string.isRequired
};

ButtonDisplaySession.defaultProps = {
  label: 'button.displaySessions'
};

export default ButtonDisplaySession;