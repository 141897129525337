/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UserHelper } from 'utils';
import { useFormState } from 'react-use-form-state'; // https://github.com/wsmd/react-use-form-state
import {
  Datepicker, FormElement, IconButton, TextError
} from 'components';
import {
  Button, DialogActions, DialogContent, Grid, TableCell
} from '@material-ui/core';
import { useStores } from 'hooks';
import { TextField } from 'components/forms/inputs';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import { faEdit, faPlus, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { REF_DATA_MAP, ROLE_GROUPS } from 'utils/constants';
import { SessionService } from 'services';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils/translation';
import ModalHeader from './_ModalHeader';
import { GenericTable } from '../GenericTable/GenericTable';
import { SkeletonTable } from '../Skeletons';

const CreateSessionFormModal = ({
  text, raw, formState, getRefData
}) => (
  <Grid container direction="row" spacing={2}>
    <Grid item xs={6}>

      <Datepicker
        autoComplete="off"
        clearable
        dataCy="createSessionStartDay"
        disablePast
        fullWidth
        label="common.startDay"
        maxDate={formState.values.endDay || undefined}
        style={{ marginBottom: '1rem' }}
        {...raw({
          name: 'startDay',
          compare(_, value) {
            return value !== null;
          }
        })}
      />

      <Datepicker
        autoComplete="off"
        clearable
        dataCy="createSessionEndDay"
        disablePast
        fullWidth
        label="common.endDay"
        minDate={formState.values.startDay}
        style={{ marginBottom: '0.3rem' }}
        {...raw({
          name: 'endDay',
          compare(_, value) {
            return value !== null;
          }
        })}
      />

      <FormElement label={translate('referenceData.LOCATION')} required>
        <Select
          error={formState.errors.location && formState.errors.location !== null}
          menuPortalTarget={document.body}
          name="location"
          noOptionsMessage={() => translate('warnings.noOptionsAvailable')}
          options={getRefData('LOCATION').values}
          required
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 2000 }) }}
          {...raw({
            name: 'location',
            compare(_, value) {
              return value !== null;
            }
          })}
          placeholder={translate('common.selectOption')}
        />
      </FormElement>
    </Grid>

    <Grid item xs={6}>
      <TextField
        autoComplete="off"
        label={translate('common.comment')}
        minRows={6}
        multiline
        name="comment"
        style={{ marginTop: 0 }}
        {...text('comment')}
      />
    </Grid>
  </Grid>
);

const getListHeaders = (getLocationItem) => ([
  {
    name: 'location',
    label: 'referenceData.LOCATION',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.location && getLocationItem('location', row.location).label}
      </TableCell>
    )
  }, {
    name: 'period',
    label: 'common.period',
    template: (row) => {
      const parseDate = (date) => new Date(date).toLocaleDateString();

      return (
        <TableCell key={shortid.generate()}>
          {row.startDay && row.endDay
            ? `${parseDate(row.startDay)} - ${parseDate(row.endDay)}` : ''}

          {row.startDay && !row.endDay
            ? `${translate('forms.module.sessionModal.startDate')}${parseDate(row.startDay)}` : ''}

          {!row.startDay && row.endDay
            ? `${translate('forms.module.sessionModal.endDate')}${parseDate(row.endDay)}` : ''}
        </TableCell>
      );
    }
  }, {
    name: 'comment',
    label: 'common.comment',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.comment}
      </TableCell>
    )
  }
]);

const getListHeadersAdmin = (getLocationItem, editSession, deleteSession) => ([
  ...getListHeaders(getLocationItem),
  {
    name: 'edit',
    label: 'button.edit',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <IconButton edge="end" type="primary" onClick={(e) => { e.stopPropagation(); editSession(row); }}>
          <FontAwesomeIcon icon={faEdit} size="xs" />
        </IconButton>
      </TableCell>
    )
  }, {
    name: 'delete',
    label: 'button.delete',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <IconButton
          aria-label={translate('button.delete')}
          type="danger"
          onClick={(e) => { e.stopPropagation(); deleteSession(row); }}
        >
          <FontAwesomeIcon icon={faTrashAlt} size="xs" />
        </IconButton>
      </TableCell>
    )
  }
]);

const SessionModal = observer(({ onClose, moduleId, onConfirm }) => {
  const { userStore, referenceDataStore } = useStores();
  const { isConnected } = userStore;
  const [isEditionMode, setIsEditionMode] = useState(false);
  const [sessionId, setSessionId] = useState();
  const [sessionList, setSessionList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const canEditContent = UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS);

  const [formState, { text, raw }] = useFormState({
    location: null, startDay: null, endDay: null, comment: ''
  });

  const { enqueueSnackbar } = useSnackbar();

  const getRefData = useCallback((key) => referenceDataStore.getRefData(key), [referenceDataStore]);

  const getLocationItem = useCallback((key, value) => {
    const type = REF_DATA_MAP[key];
    const refDataType = getRefData(type);
    if (refDataType.values) {
      return refDataType.values && refDataType.values.filter((ref) => value === ref.value)[0];
    }

    return {
      label: '',
      value: ''
    };
  }, [getRefData]);

  const getSessionsList = useCallback(() => {
    setIsLoading(true);
    SessionService.getSessions({ moduleId })
      .then((response) => setSessionList(response))
      .catch((error) => {
        setIsError(true);
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar, moduleId]);

  useEffect(() => {
    getSessionsList();
  }, [getSessionsList]);

  const displaySessionForm = useCallback(() => {
    formState.reset();
    setIsEditionMode(!isEditionMode);
  }, [setIsEditionMode, formState, isEditionMode]);

  const editSession = useCallback((row) => {
    setSessionId(row.id);

    Object.entries(row)
      .filter(([key]) => key !== 'id' && key !== 'moduleId')
      .forEach(([key, value]) => {
        const refDataKeys = ['location'];
        let localValue = value;
        if (refDataKeys.includes(key)) {
          localValue = getLocationItem(key, value);
        }

        return formState.setField(key, localValue);
      });

    setIsEditionMode(true);
  }, [setIsEditionMode, formState, getLocationItem]);

  const deleteSession = useCallback((row) => {
    SessionService.deleteSession(row.id)
      .then(() => enqueueSnackbar(translate('forms.module.sessionModal.deleteSessionSuccess'), { variant: 'success' }))
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => { getSessionsList(); });
  }, [enqueueSnackbar, getSessionsList]);

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    const isValidForm = formState.values && formState.values.location;

    if (isValidForm) {
      const formStateValues = {
        ...formState.values,
        location: formState.values.location.value,
        moduleId
      };

      onConfirm(sessionId, formStateValues, getSessionsList);
      formState.reset();

      setIsEditionMode(false);
      setSessionId(null);
    } else {
      enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }
  }, [moduleId, onConfirm, sessionId, formState, setIsEditionMode, getSessionsList, enqueueSnackbar]);

  const getCorrectListHeaders = useCallback(() => {
    if (isConnected && canEditContent) return getListHeadersAdmin(getLocationItem, editSession, deleteSession);

    return getListHeaders(getLocationItem);
  }, [isConnected, canEditContent, getLocationItem, editSession, deleteSession]);

  return (
    <>
      <ModalHeader onClose={onClose}>
        {isEditionMode
          ? translate('forms.module.sessionModal.createSession')
          : translate('forms.module.sessionModal.sessionList')}
      </ModalHeader>

      {!isEditionMode && (
        <>
          <DialogContent>
            {canEditContent && (
              <Grid container justifyContent="flex-end">
                <Button
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faPlus} />}
                  variant="contained"
                  onClick={displaySessionForm}
                >
                  {translate('button.addSession')}
                </Button>
              </Grid>
            )}

            {(!isLoading && !isError && sessionList.length === 0) && (
              <TextError>{translate('errors.noSession')}</TextError>
            )}

            {(!isLoading && isError) && (
              <TextError>{translate('errors.sessionListFailed')}</TextError>
            )}

            <div data-testid="session-list">
              {isLoading ? (
                <SkeletonTable />
              )
                : sessionList.length > 0 && (
                  <GenericTable
                    headers={getCorrectListHeaders()}
                    id={shortid.generate()}
                    rows={sessionList}
                  />
                )}
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={onClose}>
              {translate('button.close')}
            </Button>
          </DialogActions>
        </>
      )}

      {canEditContent && isEditionMode && (
        <form id="sessionForm" name="sessionForm" onSubmit={onSubmit}>
          <DialogContent style={{ minWidth: 900 }}>
            <CreateSessionFormModal
              formState={formState}
              getRefData={getRefData}
              raw={raw}
              text={text}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={displaySessionForm}>
              {translate('button.cancel')}
            </Button>

            <Button color="primary" type="submit">
              {translate('button.save')}
            </Button>
          </DialogActions>
        </form>
      )}
    </>
  );
});

SessionModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SessionModal;