import {
  Checkbox, FormControlLabel, FormGroup, Grid
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { useStores } from 'hooks';
import { buildDefaultAndTraineeAnswers } from '../../QuizHelper';
import { CheckMark } from '../_commons';

const CheckBoxQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { isCorrect, isAnswered } = currentQuestionData;

  const { quizStore } = useStores();

  const { currentPage } = quizStore;

  const [defaultAnswers, setDefaultAnswers] = useState([]);

  const buildAnswers = useCallback(() => {
    setDefaultAnswers(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleCheckBoxChange = useCallback((event, index) => {
    const { checked } = event.target;

    const newDefaultAnswers = defaultAnswers.map((ans, indx) => {
      if (index === indx) {
        return {
          ...ans,
          isItemAnswered: checked
        };
      }
      return ans;
    });

    const trainAnswers = newDefaultAnswers.filter((defaultAnswer) => defaultAnswer.isItemAnswered).map((ans) => ans.answerText);

    setDefaultAnswers(newDefaultAnswers);

    quizStore.setTraineeAnswers(currentPage, currentQuestionData, trainAnswers);
  }, [currentPage, currentQuestionData, defaultAnswers, quizStore]);

  return (
    <>
      {defaultAnswers && defaultAnswers.map((defaultAnswer, index) => (
        <Grid alignItems="center" container direction="row" key={shortid.generate()}>
          {withCorrection && isAnswered && !isCorrect && (
            <CheckMark
              isDefaultAnswerCorrect={defaultAnswer.isDefaultAnswerCorrect}
              isQuestionAnswered={isAnswered}
            />
          )}
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={defaultAnswer.isItemAnswered}
                    color="primary"
                    disabled={isReadOnlyQuestion}
                    name={`checkbox-answer-${index}`}
                    // eslint-disable-next-line react/jsx-no-bind
                    onChange={(event) => handleCheckBoxChange(event, index)}
                  />
                )}
                label={defaultAnswer.answerText}
              />
            </FormGroup>
          </Grid>
        </Grid>
      ))}
    </>
  );
});

export default CheckBoxQuestion;

CheckBoxQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

CheckBoxQuestion.defaultProps = {
  currentQuestionData: {}
};