import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getReferenceData = () => RequestHelper.GET(
  API_ROUTES.REFERENCE_DATA()
);
const getAccessModes = () => RequestHelper.GET(
  API_ROUTES.ACCESS_MODES()
);

export const ReferenceDataService = {
  getAccessModes,
  getReferenceData
};