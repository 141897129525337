import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import PropTypes from 'prop-types';
import { TextField } from 'components';
import { SelectField } from 'components/forms/inputs';
import { useStores } from 'hooks';

export const ProgramListFilters = ({ currentFilters, setCurrentFilters }) => {
  const { referenceDataStore } = useStores();

  const name = (currentFilters.find((filt) => filt.key === 'name')
    && currentFilters.find((filt) => filt.key === 'name').label) || '';
  const modality = (currentFilters.find((filt) => filt.key === 'modality')
    && currentFilters.find((filt) => filt.key === 'modality').value) || null;
  const trainingCategory = (currentFilters.find((filt) => filt.key === 'trainingCategory')
    && currentFilters.find((filt) => filt.key === 'trainingCategory').value) || null;
  const domain = (currentFilters.find((filt) => filt.key === 'domain')
    && currentFilters.find((filt) => filt.key === 'domain').value) || null;

  const handleChangeInput = useCallback((event) => {
    const { value, name: filterName } = event.target;
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), { key: filterName, label: value }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const handleChangeSelect = useCallback((filterName, value) => {
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), {
        key: filterName,
        label: value.label,
        value
      }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  const getRefData = useCallback((key) => referenceDataStore.getRefData(key), [referenceDataStore]);

  return (
    <>
      <TextField
        autoComplete="off"
        label={translate('common.name')}
        name="name"
        value={name}
        onChange={handleChangeInput}
      />

      <SelectField
        isMulti={getRefData('MODALITY').multiple}
        label="referenceData.MODALITY"
        name="modality"
        options={getRefData('MODALITY').values}
        required
        value={modality}
        onChange={handleChangeSelect}
      />

      <SelectField
        isMulti={getRefData('TRAINING_CATEGORY').multiple}
        label="referenceData.TRAINING_CATEGORY"
        name="trainingCategory"
        options={getRefData('TRAINING_CATEGORY').values}
        required
        value={trainingCategory}
        onChange={handleChangeSelect}
      />

      <SelectField
        isMulti={getRefData('DOMAIN').multiple}
        label="referenceData.DOMAIN"
        name="domain"
        options={getRefData('DOMAIN').values}
        required
        value={domain}
        onChange={handleChangeSelect}
      />
    </>
  );
};

ProgramListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};