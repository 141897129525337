/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DialogActions, DialogContent, Divider, Grid, Typography
} from '@material-ui/core';
import { translate } from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { DocumentHelper } from 'utils/helpers';
import { InputFile } from 'components';
import { useImportingReducer } from 'hooks';

import { SubscriptionService } from 'services';
import ModalHeader from './_ModalHeader';
import { TextError } from '../Text';

const ImportFileModal = ({
  onConfirm, onClose, isAsync, programId, moduleId
}) => {
  const [fileImported, setFileImported] = useState(null);
  const [state, dispatch] = useImportingReducer();

  const handleImportFile = useCallback((doc) => {
    if (doc) {
      setFileImported(doc);
      dispatch({ type: 'reset' });
    }
  }, [dispatch]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!fileImported) return;

    const sendFile = {
      name: fileImported.name,
      base64Content: DocumentHelper.getDocumentWithoutBase64(fileImported)
    };

    dispatch({ type: 'isImporting' });

    SubscriptionService.importSubscriptions({ file: sendFile, programId, moduleId })
      .then(() => {
        onConfirm();
        onClose();
      })
      .catch((error) => {
        if (isAsync) {
          onConfirm();
          onClose();
        }
        dispatch({ type: 'error', error });
        setFileImported(null);
      });
  }, [fileImported, onConfirm, onClose, isAsync, dispatch, programId, moduleId]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.importSubscriptions')}</ModalHeader>

      <DialogContent style={{ overflow: 'hidden', minWidth: '500px' }}>
        {state.error && state.error.message ? (
          <Typography component="div" gutterBottom>
            <TextError style={{ margin: 0, textAlign: 'left' }}>
              {state.error.message}
            </TextError>
          </Typography>
        ) : state.error && Object.keys(state.error).length > 0 && (
          <>
            <div>
              {Object.keys(state.error).map((err, index) => (
                <Typography component="div" gutterBottom key={index}>
                  <TextError style={{ margin: 0, textAlign: 'left' }}>
                    {`${err} : `}
                  </TextError>
                  {state.error[err].map((e, i) => (
                    <Typography component="p" key={i}>{e}</Typography>
                  ))}
                </Typography>
              ))}
            </div>
            <Divider style={{ margin: '2rem 0' }} />
          </>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <InputFile
              document={fileImported}
              fileAccepted=".xlsx"
              handleAddDocument={handleImportFile}
              id="importFile"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={state.isImporting} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!fileImported || state.isImporting}
          startIcon={<FontAwesomeIcon icon={state.isImporting ? faSpinner : faFileImport} spin={state.isImporting} />}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
};

ImportFileModal.propTypes = {
  isAsync: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  moduleId: PropTypes.string,
  programId: PropTypes.string
};

ImportFileModal.defaultProps = {
  isAsync: false,
  moduleId: null,
  programId: null
};

export default ImportFileModal;