import { DocumentHelper } from 'utils';
import { translate } from 'utils/translation';

const QUIZ_STATUS = {
  notRunning: 'notRunning',
  running: 'running',
  ended: 'ended',
  displayingResults: 'displayingResults'
};

const EXAM_KEY = 'examId';

const QUESTION_TYPES_FROM_API = {
  checkBox: 'checkbox',
  commentInput: 'comment',
  dateInput: 'date',
  dropDown: 'dropdown',
  emailInput: 'email',
  imageRadioPicker: 'imagepicker',
  phoneInput: 'phone',
  radioGroup: 'radiogroup',
  scoreRating: 'rating',
  textInput: 'text'
};

const {
  checkBox, radioGroup, dropDown, textInput, commentInput, imageRadioPicker, scoreRating, dateInput, emailInput, phoneInput
} = QUESTION_TYPES_FROM_API;

const buildDefaultAndTraineeAnswers = (questionData) => {
  const { type, answers, traineeAnswer } = questionData;

  const newDefaultAnswers = [...answers];
  let selectValue = 0;

  switch (type) {
    case (checkBox): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          answerText: newDefaultAnswers[index].answerText,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length > 0
            && traineeAnswer.some((traineeAns) => traineeAns === answer.answerText)
        };
        return newDefaultAnswers;
      });
      break;
    }
    case (radioGroup): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          answerText: newDefaultAnswers[index].answerText,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0 ? traineeAnswer === answer.answerText : false
        };
        return newDefaultAnswers;
      });
      break;
    }
    case (dropDown): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          label: newDefaultAnswers[index].answerText,
          value: selectValue += 1,
          answerText: newDefaultAnswers[index].answerText,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0 ? traineeAnswer === answer.answerText : false
        };
        return newDefaultAnswers;
      });
      break;
    }
    case (textInput):
    case (commentInput):
    case (dateInput):
    case (emailInput):
    case (phoneInput): {
      const freeAnswer = traineeAnswer && traineeAnswer.length !== 0 ? traineeAnswer : '';
      return freeAnswer;
    }
    case (imageRadioPicker): {
      answers.map((answer, index) => {
        newDefaultAnswers[index] = {
          answerPicture: DocumentHelper.getDocumentWithBase64(newDefaultAnswers[index].answerPicture),
          pictureId: newDefaultAnswers[index].id,
          isDefaultAnswerCorrect: newDefaultAnswers[index].isCorrect,
          isItemAnswered: traineeAnswer && traineeAnswer.length !== 0 ? traineeAnswer === answer.answerText : false
        };
        return newDefaultAnswers;
      });
      break;
    }
    case scoreRating: {
      const freeRatingAnswer = {
        maxValue: answers[0].maxValue,
        maxValueLabel: answers[0].maxValueLabel,
        minValue: answers[0].minValue,
        minValueLabel: answers[0].minValueLabel,
        answeredValue: traineeAnswer || null,
        isItemAnswered: traineeAnswer && traineeAnswer >= 0
          ? Boolean(traineeAnswer >= answers[0].minValue && traineeAnswer <= answers[0].maxValue)
          : false
      };
      return freeRatingAnswer;
    }
    default:
      return null;
  }

  return newDefaultAnswers;
};

const buildQuizWarningMessage = (quizModel) => {
  const { allowRepeat, repeatLimit, remainingTries } = quizModel;
  const canRepeatTheQuizInfinite = allowRepeat && !repeatLimit;
  const canRepeatTheQuiz = allowRepeat && remainingTries <= repeatLimit;

  if (canRepeatTheQuizInfinite) {
    return {
      warning: translate('forms.element.quiz.infiniteTries'),
      isStartDisabled: false
    };
  }

  if (canRepeatTheQuiz) {
    if (remainingTries > 1) {
      return {
        warning: translate('forms.element.quiz.remainingTries', { remainingTries }),
        isStartDisabled: false
      };
    }

    if (remainingTries === 1) {
      return {
        warning: translate('forms.element.quiz.onlyOneTryLeft'),
        isStartDisabled: false
      };
    }
  }

  if (!allowRepeat && remainingTries === 1) {
    return {
      warning: translate('forms.element.quiz.onlyOneTry'),
      isStartDisabled: false
    };
  }

  return {
    warning: translate('forms.element.quiz.noMoreTry'),
    isStartDisabled: true
  };
};

export {
  QUIZ_STATUS,
  EXAM_KEY,
  QUESTION_TYPES_FROM_API,
  buildDefaultAndTraineeAnswers,
  buildQuizWarningMessage
};