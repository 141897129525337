import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { COMMON_STEPS } from './common-steps';


const LaunchButton = styled.button`
  position: absolute;
  top: 122px;
  right: 0;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  background: url('/assets/images/bg/helpButton.png') no-repeat center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    width: 76px;
    height: 76px;

    svg {
      font-size: 2rem;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all 0.3s;
  }

  @media (max-width: 1080px) {
    top: 68px;
  }
`;

export const ReactTour = ({
  accentColor, noDoneStep, steps, stepWelcome, defaultOpen, tourId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [stepsAvailable, setStepsAvailable] = useState([]);

  const storageKey = `tour-${tourId}-hasBeenViewed`;

  const getStorageTag = () => (
    localStorage.getItem(storageKey)
  );

  const setStorageTag = () => (
    !getStorageTag() && localStorage.setItem(storageKey, true)
  );

  useEffect(() => {
    setIsLoading(true);
    const currentSteps = [];

    const setupTourTimeout = setTimeout(() => {
    // Add welcome step if there is one
      stepWelcome && currentSteps.push({
        selector: '',
        content: stepWelcome
      });

      steps.map(((step) => {
        if (step.selector && document.querySelectorAll(step.selector) && document.querySelectorAll(step.selector).length > 0) {
          return currentSteps.push(step);
        }
        return null;
      }));

      // Add default done step for all tutorials
      !noDoneStep
      && currentSteps.push({
        selector: '[data-tour="step-done"]',
        content: COMMON_STEPS.done
      });

      setStepsAvailable(currentSteps);
      setIsLoading(false);
      defaultOpen && (!getStorageTag()) && setIsTourOpen(true);
    }, 3000);

    return () => {
      clearTimeout(setupTourTimeout);
    };
  // eslint-disable-next-line
  }, [noDoneStep, steps, stepWelcome]);

  const closeTour = () => {
    setIsTourOpen(false);
    setStorageTag();
  };

  const openTour = () => (
    stepsAvailable.length > 0 && setIsTourOpen(true)
  );


  return (
    <>
      <Tour
        accentColor={accentColor}
        closeWithMask={false}
        disableInteraction
        isOpen={isTourOpen}
        lastStepNextButton={(
          <span style={{ color: 'var(--primary-color)' }}>
            FERMER
          </span>
        )}
        rounded={5}
        startAt={0}
        steps={stepsAvailable}
        onAfterOpen={() => { document.body.style.overflowY = 'hidden'; }}
        onBeforeClose={() => { document.body.style.overflowY = 'auto'; }}
        onRequestClose={closeTour}
      />
      <Tooltip
        disableFocusListener
        disableTouchListener
        title="Aide"
      >
        <LaunchButton
          data-tour="step-done"
          onClick={openTour}
        >
          {isLoading ? (
            <FontAwesomeIcon color="var(--white)" icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon color="var(--white)" icon={faQuestion} />
          )}
        </LaunchButton>
      </Tooltip>
    </>
  );
};

ReactTour.propTypes = {
  accentColor: PropTypes.string,
  defaultOpen: PropTypes.bool,
  noDoneStep: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string
    ]),
    selector: PropTypes.string
  })).isRequired,
  stepWelcome: PropTypes.shape({}),
  tourId: PropTypes.string
};

ReactTour.defaultProps = {
  accentColor: 'var(--turquoise)',
  defaultOpen: false,
  noDoneStep: false,
  stepWelcome: null,
  tourId: ''
};