import { RequestHelper } from 'utils';
import { API_URL, setCurrentVersion } from 'utils/constants';

const quizUrl = `${API_URL}/${setCurrentVersion(1)}/quiz`;

const examinationsUrl = `${API_URL}/${setCurrentVersion(1)}/examinations`;

const getQuizList = ({
  page = 0, freeSearch = '', name = '', description = ''
} = {}) => RequestHelper.POST(`${quizUrl}/filter?page=${page}`, { freeSearch, name, description });

const getQuiz = (quizId) => RequestHelper.GET(`${quizUrl}/${quizId}`);

const getLastExamination = ({
  quizId, sequenceId, moduleId, programId
}) => RequestHelper
  .POST(`${examinationsUrl}/last`, {
    quizId, sequenceId, moduleId, programId
  });

const startQuiz = ({
  quizId, programId, sequenceId, moduleId, traineeFirstName, traineeLastName, traineeEmail
}) => RequestHelper
  .POST(`${examinationsUrl}/start`, {
    quizId, programId, sequenceId, moduleId, traineeFirstName, traineeLastName, traineeEmail
  });

const getExamination = (examId) => RequestHelper.GET(`${examinationsUrl}/${examId}`);

const confirmQuestionsPage = (examinationId, question) => RequestHelper
  .PUT(`${examinationsUrl}/${examinationId}/question`, question);

const nextPrevQuiz = (examinationId, page) => RequestHelper.PUT(`${examinationsUrl}/${examinationId}/page`, page);

const updateQuestion = (examinationId, question) => RequestHelper
  .PUT(`${examinationsUrl}/${examinationId}/save-question`, question);

const updatePage = (examinationId, page) => RequestHelper
  .PUT(`${examinationsUrl}/${examinationId}/save-page`, page);

const finishQuiz = (examinationId) => RequestHelper.PUT(`${examinationsUrl}/${examinationId}/finish`);

export const QuizService = {
  getQuizList,
  getQuiz,
  getLastExamination,
  startQuiz,
  getExamination,
  confirmQuestionsPage,
  nextPrevQuiz,
  updatePage,
  updateQuestion,
  finishQuiz
};