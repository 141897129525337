import { faExpandArrowsAlt, faFile as faFileEmpty, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faCircle, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { Paper } from 'components';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, {
  createRef, useCallback, useEffect, useState
} from 'react';
import { isMobile } from 'react-device-detect';
import { pdfjs } from 'react-pdf'; // Docs : https://www.npmjs.com/package/react-pdf
import styled from 'styled-components';
import { API_URL_DOCUMENTS } from 'utils/constants';
import { translate } from 'utils/translation';
import { Button } from '..';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const IconsContainer = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: var(--white);
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity var(--transitionTime);
  cursor: pointer;
  border-radius: 4px;

  svg {
    transition: transform var(--transitionTime);
  }

  &:hover {
    > svg {
      transform: scale(1.2);
    }
  }

  button {
    margin-left: 2rem;

    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
`;

const DocumentContainer = styled.div`
  position: relative;
  min-width: 100px;
  width: ${(props) => props.width}px;
  max-width: 100%;
  text-align: center;
  word-break: break-word;

  &:hover {
    ${IconsContainer} {
      opacity: 1;
    }
  }

  canvas {
    max-width: 100%;
  }
`;

const DocumentListContainer = styled.div`
  margin-left: 2rem;
  cursor: pointer;
  &:hover {
    color: var(--colored-title-color);
    font-weight: bold;
  }
`;

export const Document = ({
  name, url, isMain, editionMode, isList
}) => {
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [width, setWidth] = useState(null);
  const [isLoadingPDF, setIsLoadingPDF] = useState(true);
  const [documentMode, setDocumentMode] = useState('portrait');

  const documentRef = createRef();
  const documentURL = `${API_URL_DOCUMENTS}${url}`;

  const getCanvasWidth = useCallback((docMode) => {
    if (isMain) {
      if (docMode === 'landscape') return isMobile ? 330 : 650;
      return 330;
    }

    if (docMode === 'landscape') return 300;
    return 180;
  }, [isMain]);

  useEffect(() => {
    if (url) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const pdfData = new Uint8Array(fileReader.result);
        const loadingTask = pdfjs.getDocument({ data: pdfData });
        loadingTask.promise.then((pdf) => {
          pdf.getPage(1).then((page) => {
            const canvas = documentRef.current;
            const viewportDefault = page.getViewport({ scale: 1 });
            const docMode = viewportDefault.width > viewportDefault.height ? 'landscape' : 'portrait';
            const canvasWidth = getCanvasWidth(docMode);
            const scale = canvasWidth / viewportDefault.width;
            const viewportScaled = page.getViewport({ scale });

            if (canvas) {
              const context = canvas.getContext('2d');
              canvas.height = viewportScaled.height;
              canvas.width = viewportScaled.width;
              setWidth(Number(viewportScaled.width + 20));
              setDocumentMode(docMode);

              page.render({
                canvasContext: context,
                viewport: viewportScaled
              });

              setIsLoadingPDF(false);
            }
          });
        }, (reason) => enqueueSnackbar(reason && reason.message ? reason.message : reason, { variant: 'warning' }));
      };

      fetch(documentURL).then((response) => {
        const reader = response.body.getReader();

        return new ReadableStream({
          start(controller) {
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return null;
                }
                controller.enqueue(value);
                return pump();
              });
            }

            return pump();
          }
        });
      })
        .then((stream) => new Response(stream))
        .then((response) => response.blob())
        .then((blob) => fileReader.readAsArrayBuffer(blob))
        .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
    }
  // eslint-disable-next-line
  }, [url, documentURL, enqueueSnackbar, isMain]);

  const handleOpenPDFModal = useCallback(() => showModal({
    type: 'DISPLAY_PDF',
    name,
    url,
    fullscreen: true,
    documentMode,
    disableClose: false
  }), [showModal, name, url, documentMode]);

  const handleOpenPDFModalFullscreen = useCallback((e) => {
    e.stopPropagation();
    showModal({
      type: 'DISPLAY_PDF',
      name,
      url,
      fullscreen: true,
      isMain: true,
      documentMode,
      disableClose: false
    });
  }, [showModal, name, url, documentMode]);

  return isList ? (
    <DocumentListContainer onClick={handleOpenPDFModal}>
      <FontAwesomeIcon icon={faCircle} size="xs" style={{ marginRight: '1rem' }} />
      {name}
    </DocumentListContainer>
  ) : (
    <Grid container justifyContent="center">
      <Grid item style={{ maxWidth: '100%' }}>
        <DocumentContainer width={width}>
          {url ? (
            <>
              <Paper>
                <Grid alignItems="center" container direction="column" justifyContent="center">
                  {isLoadingPDF && <FontAwesomeIcon color="var(--primary-color)" icon={faSpinner} size="4x" spin />}
                  <canvas ref={documentRef} />
                  {name}
                </Grid>
              </Paper>
              <IconsContainer onClick={handleOpenPDFModal}>
                <FontAwesomeIcon icon={faPlay} size="3x" />
                {isMain && (
                  <Button variant="text" onClick={handleOpenPDFModalFullscreen}>
                    <FontAwesomeIcon color="var(--white)" icon={faExpandArrowsAlt} size="3x" />
                  </Button>
                )}
              </IconsContainer>
            </>
          ) : editionMode && (
            <Paper>
              <Grid alignItems="center" container direction="column">
                <FontAwesomeIcon icon={faFileEmpty} size="3x" style={{ marginBottom: '1rem' }} />
                {translate('button.selectDocument')}
              </Grid>
            </Paper>
          )}
        </DocumentContainer>
      </Grid>
    </Grid>
  );
};

Document.propTypes = {
  editionMode: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string
};

Document.defaultProps = {
  editionMode: false,
  name: '',
  url: ''
};