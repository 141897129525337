import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Divider, Grid, Tooltip, Typography
} from '@material-ui/core';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import { translate } from 'utils/translation';
import { observer } from 'mobx-react-lite';
import {
  faChevronSquareLeft, faChevronSquareRight, faListAlt, faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuizService } from 'services';
import { useSnackbar } from 'notistack';

const QuizNavigationBar = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const { quizStore } = useStores();

  const {
    startedExamination, isPageValidated, pageWithTraineeAnswers, examinationState, currentPage
  } = quizStore;

  const {
    quiz: { id, pages, forceValidation }, quiz
  } = startedExamination;

  const { examinationId } = examinationState;

  const [hidePrevBtn, setHidePrevBtn] = useState(false);
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isFinishLoading, setIsFinishLoading] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [isInvalidatedQuestionExists, setIsInvalidatedQuestionExists] = useState(false);

  useEffect(() => {
    quizStore.getTotalPagesAndQuestions();
  }, [quizStore, currentPage]);

  useEffect(() => {
    if (currentPage?.index === pages?.length - 1) {
      setIsQuizFinished(true);
    }
    if (pages?.length === 1 || currentPage?.index === 0) {
      setHidePrevBtn(true);
    }
  }, [currentPage, pages, quizStore, setHidePrevBtn, startedExamination]);

  // Avoid sending a request if the question is already validated
  const canValidatePage = useCallback(() => {
    setIsInvalidatedQuestionExists(currentPage?.questions?.some((question) => question.isAnswered === false));
  }, [currentPage]);

  useEffect(() => {
    quizStore.setPageWithTraineeAnswers(currentPage);

    canValidatePage();
  }, [quizStore, currentPage, canValidatePage]);

  const scrollToQuizTop = useCallback(() => {
    const currentQuiz = document.getElementById(id);
    const currentQuizPosition = currentQuiz.getBoundingClientRect().top + window.scrollY;
    const currentQuizHeader = currentQuiz.getElementsByTagName('header');
    const currentQuizHeaderHeight = currentQuizHeader[0]?.getBoundingClientRect().height;

    window.scrollTo({
      top: Number(currentQuizPosition + (currentQuizHeaderHeight || 0)),
      behavior: 'smooth'
    });
  }, [id]);

  const handlePreviousPage = useCallback(() => {
    setIsQuizFinished(false);
    setIsPreviousLoading(true);

    quizStore.setExamStatus({
      isStarted: true, isEnded: false, examinationId: startedExamination.id, quizId: quiz.id
    });

    const pageData = {
      ...pageWithTraineeAnswers,
      nextPageIndex: pageWithTraineeAnswers.index + 1
    };

    QuizService.updatePage(examinationId, pageData)
      .then((response) => runInAction('updatePage', () => {
        quizStore.updatePage(response);
        quizStore.setPreviousPage();
        quizStore.getQuestionNumbering();
      }))
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => {
        setIsPreviousLoading(false);
        scrollToQuizTop();
      });
  }, [quizStore, pageWithTraineeAnswers, startedExamination, quiz, examinationId, enqueueSnackbar, scrollToQuizTop]);

  const handleNextPage = useCallback(() => {
    const pageIndex = currentPage.index;

    setIsNextLoading(true);

    const pageData = {
      ...pageWithTraineeAnswers,
      nextPageIndex: pageWithTraineeAnswers.index + 1
    };

    QuizService.updatePage(examinationId, pageData)
      .then((response) => {
        if (pageIndex < pages.length - 1) {
          setHidePrevBtn(false);
        }

        runInAction('updatePage', () => {
          quizStore.updatePage(response);
          quizStore.setNextPage();
          quizStore.getQuestionNumbering();
        });
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => {
        setIsNextLoading(false);
        scrollToQuizTop();
      });
  }, [currentPage, pageWithTraineeAnswers, pages, quizStore, examinationId, enqueueSnackbar, scrollToQuizTop]);

  const handleFinishQuiz = useCallback(() => {
    const pageData = {
      ...pageWithTraineeAnswers
    };

    if (!forceValidation && isInvalidatedQuestionExists) {
      setIsFinishLoading(true);

      QuizService.updatePage(examinationId, pageData)
        .then((response) => {
          quizStore.updatePage(response);
          quizStore.setPreviousPage();

          QuizService.finishQuiz(examinationId)
            .then((responseData) => {
              runInAction('triggerFinish', () => {
                quizStore.finishQuiz(responseData);
                quizStore.setExamStatus({
                  isStarted: false,
                  isEnded: true,
                  examinationId: startedExamination.id,
                  quizId: quiz.id
                });
              });
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
            .finally(() => {
              setIsFinishLoading(false);
              scrollToQuizTop();
            });
        });
    } else {
      setIsFinishLoading(true);

      QuizService.finishQuiz(examinationId)
        .then((response) => {
          runInAction('triggerFinish', () => {
            quizStore.finishQuiz(response);
            quizStore.setExamStatus({
              isStarted: false,
              isEnded: true,
              examinationId: startedExamination.id,
              quizId: quiz.id
            });
          });
        })
        .finally(() => {
          setIsFinishLoading(false);
          scrollToQuizTop();
        });
    }
  }, [quizStore, startedExamination, forceValidation, isInvalidatedQuestionExists, quiz, examinationId, scrollToQuizTop,
    enqueueSnackbar, pageWithTraineeAnswers]);

  useEffect(() => {
    quizStore.canGoNextPage();
  }, [quizStore, currentPage]);

  return (
    <>
      <Divider style={{ marginBottom: 10 }} />

      {!isPageValidated && (
        <Typography align="right" style={{ color: 'var(--warning-color)' }}>
          {forceValidation ? translate('errors.validateAllQuestions') : translate('errors.answerToRequiredQuestions')}
        </Typography>
      )}

      <Grid container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item>
          {!hidePrevBtn && (
            <Button
              color="primary"
              disabled={isPreviousLoading}
              id="quiz-prev-btn"
              startIcon={<FontAwesomeIcon icon={isPreviousLoading ? faSpinner : faChevronSquareLeft} spin={isPreviousLoading} />}
              variant="contained"
              onClick={handlePreviousPage}
            >
              {translate('button.previous')}
            </Button>
          )}
        </Grid>
        {isQuizFinished ? (
          <Grid item>
            {isPageValidated ? (
              <Button
                color="primary"
                disabled={isFinishLoading}
                id="quiz-finish-btn"
                startIcon={<FontAwesomeIcon icon={isFinishLoading ? faSpinner : faListAlt} spin={isFinishLoading} />}
                variant="contained"
                onClick={handleFinishQuiz}
              >
                {translate('button.terminate')}
              </Button>
            )
              : (
                <Tooltip title={forceValidation
                  ? translate('errors.validateAllQuestions')
                  : translate('errors.answerToRequiredQuestions')}
                >
                  <span>
                    <Button
                      color="primary"
                      disabled
                      id="quiz-finish-btn"
                      startIcon={<FontAwesomeIcon icon={faListAlt} />}
                      variant="contained"
                    >
                      {translate('button.terminate')}
                    </Button>
                  </span>
                </Tooltip>
              )}
          </Grid>
        )
          : (
            <Grid item>
              {isPageValidated ? (
                <Button
                  color="primary"
                  disabled={isNextLoading}
                  endIcon={<FontAwesomeIcon icon={isNextLoading ? faSpinner : faChevronSquareRight} spin={isNextLoading} />}
                  id="quiz-next-btn"
                  variant="contained"
                  onClick={handleNextPage}
                >
                  {translate('button.next')}
                </Button>
              )
                : (
                  <Tooltip title={forceValidation
                    ? translate('errors.validateAllQuestions')
                    : translate('errors.answerToRequiredQuestions')}
                  >
                    <span>
                      <Button
                        color="primary"
                        disabled
                        endIcon={<FontAwesomeIcon icon={faChevronSquareRight} />}
                        id="quiz-next-btn"
                        variant="contained"
                      >
                        {translate('button.next')}
                      </Button>
                    </span>
                  </Tooltip>
                )}
            </Grid>
          )}
      </Grid>
    </>
  );
});

export default QuizNavigationBar;