
export const textElement = (index, title, text) => ({
  index: index || 0,
  elementType: 'TEXT_ELEMENT',
  element: {
    subtitle: title || 'Les portes',
    text: text || `Les portes sont des éléments techniques incontournables pour le respect de la Chaîne du Froid. 
        En effet, lors des chargements ou déchargements des marchandises, elles sont, en raison de leurs ouvertures et fermetures
         répétées, à l’origine des échanges thermiques les plus importants. 
        Les portes représentent le point le plus critique dans l’étanchéité de la caisse, et donc son maintien en température. 
        Les Bonnes Pratiques pour manœuvrer les portes sont indispensables.`
  }
});