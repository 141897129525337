import { getProgramContent } from '__fixtures__/section/section_constants';
import { action, decorate, observable } from 'mobx';
import { ProgramService } from 'services';
import { PROGRAM_VALIDATION_MAP } from 'utils/constants';
import { FormHelper } from 'utils/helpers';

export class ProgramStore {
  constructor() {
    this.isLoading = false;
    this.program = { content: {} };
    this.programList = [];
  }

  /**
   * Fetches the program details.
   */
  loadProgram(programId) {
    this.isLoading = true;
    return ProgramService.getProgram(programId)
      .then(action((program) => {
        const newProgram = {
          ...program,
          content: program.content || getProgramContent()
        };
        this.program = newProgram;
      }))
      .catch((error) => { throw error; })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  /**
   * Fetches the list of programs (not detailed).
   */
  loadProgramList({ search, filters }) {
    this.isLoading = true;
    return ProgramService.getProgramList({ search, filters })
      .then(action((programList) => {
        this.programList = programList;
      }))
      .catch((error) => { throw error; })
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  updateModuleSummaries(newMod) {
    if (this.program.moduleSummaries) {
      this.program.moduleSummaries = newMod;
    }
  }

  publishProgram() {
    this.program.isPublished = true;
  }

  resetProgram() {
    this.program = { content: getProgramContent() };
  }

  getValidity() {
    return FormHelper.checkValidity(this.program, PROGRAM_VALIDATION_MAP);
  }
}

decorate(ProgramStore, {
  isLoading: observable,
  program: observable,
  programList: observable,
  loadProgram: action,
  loadProgramList: action,
  updateModuleSummaries: action,
  publishProgram: action,
  resetProgram: action,
  getValidity: action
});

export const programStore = new ProgramStore();