import { Text } from 'components';
import { asForm } from 'components/forms/hoc';
import { InputField } from 'components/forms/inputs';
import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'utils/translation';
import { textElementFormModel } from 'components/model/dataformModel';
import { FormHeader } from 'components/forms';
import { observer } from 'mobx-react-lite';

const TextElementForm = observer(({ formData, updateProperty }) => {
  const { subtitle, text } = formData;

  return (
    <>
      <FormHeader title="forms.element.text.title" />

      <Text>{translate('forms.element.text.fulfillText')}</Text>
      <InputField label={translate('common.title')} name="subtitle" value={subtitle} onChange={updateProperty} />
      <InputField label={translate('common.text')} minRows={20} multiline name="text" value={text} onChange={updateProperty} />
    </>
  );
});

export default asForm(TextElementForm);

TextElementForm.propTypes = {
  formData: textElementFormModel.isRequired,
  updateProperty: PropTypes.func.isRequired
};