import React, { useCallback } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { ListItem } from '@material-ui/core';
import { FormElement } from 'components';

export const StyledListItem = styled(ListItem)`
  cursor: pointer;

  &:hover {
    background-color: #DEEBFF
  }
`;

export const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

const SelectField = ({
  name, options, value, label, onChange, required,
  placeholder, isClearable, messageNoOptions, isMenuPortal,
  ...props
}) => {
  const displayNoOptions = useCallback(() => translate(messageNoOptions), [messageNoOptions]);

  const handleChange = useCallback((val) => onChange(name, val), [onChange, name]);

  return (
    <FormElement label={translate(label)} required={required}>
      <Select
        isClearable={isClearable}
        menuPortalTarget={isMenuPortal ? document.body : null}
        name={name}
        noOptionsMessage={displayNoOptions}
        options={options}
        placeholder={translate(placeholder)}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 2000 }),
          menu: (base) => ({ ...base, zIndex: 2000 })
        }}
        value={value}
        onChange={handleChange}
        {...props}
      />
      {required && (
        <StyledRequiredSelectHack
          autoComplete="off"
          name={name}
          required
          tabIndex={-1}
          value={value || ''}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={() => {}}
        />
      )}
    </FormElement>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  messageNoOptions: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

SelectField.defaultProps = {
  isClearable: true,
  messageNoOptions: 'warnings.noOptionsAvailable',
  options: [],
  placeholder: 'common.selectOption',
  required: false,
  value: null
};

export default SelectField;