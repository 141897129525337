import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils/translation';
import { ModuleService } from 'services';
import { ROUTES } from 'utils/constants';


const ButtonCreateModule = ({ programId }) => {
  const history = useHistory();
  const { moduleStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [isCreating, setIsCreating] = useState(false);

  const createModule = useCallback(() => {
    setIsCreating(true);

    ModuleService.createModule(programId).then((response) => {
      moduleStore.resetModule();

      history.push({
        pathname: programId ? ROUTES.PROGRAM_MODULE(programId, response) : ROUTES.MODULE(response),
        state: {
          isEditionMode: true
        }
      });
      enqueueSnackbar(translate('forms.module.moduleCorrectlyCreated'), { variant: 'success' });
      setIsCreating(false);
    }).catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  }, [history, enqueueSnackbar, moduleStore, programId]);


  return (
    <Button
      disabled={isCreating}
      startIcon={<FontAwesomeIcon icon={isCreating ? faSpinner : faPlus} spin={isCreating} />}
      onClick={createModule}
    >
      {translate('pageProgramDetail.createModule')}
    </Button>
  );
};

ButtonCreateModule.propTypes = {
  programId: PropTypes.string
};

ButtonCreateModule.defaultProps = {
  programId: ''
};

export default ButtonCreateModule;