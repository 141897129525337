const COOKIES = {
  DISPLAY: 'OUTDATED_DISPLAY',
  REPEAT: 'OUTDATED_REPEAT',
  TIMER: 'OUTDATED_TIMER'
};

const navigatorNames = {
  chrome: 'Chrome',
  firefox: 'Firefox',
  edge: 'Edge',
  opera: 'Opera',
  msie: 'msie',
  trident: 'trident',
  safari: 'Safari',
  safariVersion: 'Version',
  chromeIOS: 'CriOS',
  firefoxIOS: 'FxiOS',
  edgeIOS: 'EdgiOS',
  operaIOS: 'OPiOS',
  edgeAndroid: 'EdgA'
};

const {
  chrome, firefox, edge, opera, chromeIOS, firefoxIOS, edgeIOS, operaIOS, edgeAndroid
} = navigatorNames;

const usrAgent = navigator.userAgent;

const browserDetection = () => {
  // Retrieving the userAgent information from navigator
  let BrowserInfo = usrAgent
    .match(/(Opera|OPiOS|Chrome|CriOS|EdgiOS|Safari|Version|Firefox|FxiOS|msie|trident(?=\/))\/?\s*(\d+)/i)
    || [];

  let partOfBrowserInfo;
  let partOfBrowserInfoAndroid;
  let partOfBrowserInfoIOS;

  // Checking whether the browser is Internet Explorer
  if (/trident/i.test(BrowserInfo[1])) {
    partOfBrowserInfo = /\brv[ :]+(\d+)/g.exec(usrAgent) || [];
    return { name: 'IE', version: partOfBrowserInfo[1] || '' };
  }

  // Checking whether the browser is Opera
  if (BrowserInfo[1] === chrome || BrowserInfo[1] === operaIOS) {
    partOfBrowserInfo = usrAgent.match(/\b(OPR)\/(\d+)/);
    partOfBrowserInfoIOS = usrAgent.match(/\b(OPiOS)\/(\d+)/);
    if (partOfBrowserInfo !== null || partOfBrowserInfoIOS !== null) {
      if (partOfBrowserInfo) {
        return { name: partOfBrowserInfo[1].replace('OPR', opera), version: partOfBrowserInfo[2] };
      }
      return { name: partOfBrowserInfoIOS[1].replace(operaIOS, opera), version: partOfBrowserInfoIOS[2] };
    }
  }

  // Checking whether the browser is Edge
  if (BrowserInfo[1] === chrome || BrowserInfo[1] === edgeIOS) {
    partOfBrowserInfo = usrAgent.match(/\b(Edg)\/(\d+)/);
    partOfBrowserInfoAndroid = usrAgent.match(/\b(EdgA)\/(\d+)/);
    partOfBrowserInfoIOS = usrAgent.match(/\b(EdgiOS)\/(\d+)/);

    if (partOfBrowserInfo !== null || partOfBrowserInfoAndroid !== null || partOfBrowserInfoIOS !== null) {
      if (partOfBrowserInfo) {
        return { name: partOfBrowserInfo[1].replace('Edg', edge), version: partOfBrowserInfo[2] };
      }
      if (partOfBrowserInfoAndroid) {
        return { name: partOfBrowserInfoAndroid[1].replace(edgeAndroid, edge), version: partOfBrowserInfoAndroid[2] };
      }
      return { name: partOfBrowserInfoIOS[1].replace(edgeIOS, edge), version: partOfBrowserInfoIOS[2] };
    }
  }

  // Retrieving browser name and browser version in case of Chrome and Firefox
  BrowserInfo = BrowserInfo[2] ? [BrowserInfo[1], BrowserInfo[2]] : [navigator.appName, navigator.appVersion, '-?'];

  return { name: BrowserInfo[0], version: BrowserInfo[1] };
};

const cookieValue = (cookieName) => document.cookie
  .split('; ')
  .find((row) => row.startsWith(`${cookieName}=`))
  .split('=')[1];

const modalDisplayControl = (modalDisplayer) => {
  const { name } = browserDetection();

  if (((name !== chromeIOS)
  && (name !== chrome))
  && ((name !== firefoxIOS)
  && name !== firefox)) {
    const expireHourTimer = new Date();
    const expireMonthTimer = new Date();
    const expireYearTimer = new Date();

    expireHourTimer.setHours(expireHourTimer.getHours() + 4);
    expireMonthTimer.setMonth(expireMonthTimer.getMonth() + 1);
    expireYearTimer.setFullYear(expireYearTimer.getFullYear() + 1);

    let repeatCookieSum = 0;
    if (document.cookie.indexOf(COOKIES.DISPLAY) === -1) {
      if (document.cookie.indexOf(COOKIES.REPEAT) === -1) {
        document.cookie = `${COOKIES.REPEAT}=0; expires=${expireYearTimer.toUTCString()}`;
      }
      if (Number(cookieValue(COOKIES.REPEAT)) === 10) {
        document.cookie = `${COOKIES.DISPLAY}=true; expires=${expireMonthTimer.toUTCString()}`;
        document.cookie = `${COOKIES.REPEAT}=0; expires=${expireYearTimer.toUTCString()}`;
      } else if (document.cookie.indexOf(COOKIES.TIMER) === -1) {
        repeatCookieSum = (Number(cookieValue(COOKIES.REPEAT)) + 1);
        document.cookie = `${COOKIES.REPEAT}=${repeatCookieSum}; expires=${expireYearTimer.toUTCString()}`;
        document.cookie = `${COOKIES.TIMER}=true; expires=${expireHourTimer.toUTCString()}`;
        modalDisplayer();
      }
    } else {
      document.cookie = `${COOKIES.REPEAT}=0; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
      document.cookie = `${COOKIES.TIMER}=true; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
    }
  }
};

export const browserDetectionHelper = {
  navigatorNames,
  browserDetection,
  modalDisplayControl
};