import { Button as MaterialButton, IconButton as MaterialIconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';


export const ButtonContainer = styled.div`
  button:not(:last-child) {
    margin-right: 1rem;
  }
`;


const StyledMaterialButton = styled(
  ({
    children, type, margin, ...props
  }) => <MaterialButton {...props} color={type === 'danger' ? 'default' : type}>{children}</MaterialButton>
)`
  ${(props) => props.margin && css`
    margin: ${props.margin} !important;
  `}

  &:hover {
    ${(props) => props.type === 'danger' && css`
      color: var(--danger-color);
    `}
  }
`;

const StyledMaterialIconButton = styled(
  ({
    children, type, ...props
  }) => <MaterialIconButton {...props} color={type === 'danger' ? 'default' : type}>{children}</MaterialIconButton>
)`
  &:hover {
    ${(props) => props.type === 'danger' && css`
      color: var(--danger-color);
    `}
  }
`;

export const Button = ({
  children, ...props
}) => (
  <StyledMaterialButton {...props}>
    {children}
  </StyledMaterialButton>
);

export const IconButton = ({
  children, ...props
}) => (
  <StyledMaterialIconButton {...props}>
    {children}
  </StyledMaterialIconButton>
);

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  variant: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  margin: '',
  type: 'primary',
  variant: 'contained'
};