import React from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';


export const SkeletonFormDocument = () => (
  <div style={{ width: 760 }}>
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Skeleton height={140} width="50%" />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Skeleton height={40} variant="rect" width="100%" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={40} variant="rect" width="100%" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Skeleton height={140} />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Skeleton height={40} variant="rect" width="100%" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={40} variant="rect" width="100%" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Skeleton height={40} variant="rect" width="100%" />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Skeleton height={40} variant="rect" width="100%" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);