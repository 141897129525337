import { FormHeader } from 'components/forms';
import { asForm } from 'components/forms/hoc';
import { quizElementFormModel } from 'components/model/dataformModel';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {
  lazy, useCallback, useEffect, useState
} from 'react';
import { translate } from 'utils/translation';

const SelectQuiz = lazy(() => import('components/_commons/SelectQuiz/SelectQuiz'));

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
const QuizElementForm = observer(({
  formData
}) => {
  const [quiz, setQuiz] = useState(null);
  const { editionStore } = useStores();

  useEffect(() => {
    if (formData.title) {
      setQuiz(formData);
    } else {
      setQuiz(null);
    }
  }, [formData]);

  const handleQuizDelete = useCallback(() => {
    runInAction('deleteQuizFromQuizElementForm', () => {
      formData.id = null;
      formData.title = null;
      formData.description = null;
      editionStore.setLastSaved(false);
    });
  }, [formData, editionStore]);

  const handleQuizSelect = useCallback((quizSelected) => {
    if (quizSelected) {
      runInAction('handleQuizSelectFromQuizElementForm', () => {
        formData.id = quizSelected.id;
        formData.title = quizSelected.title;
        formData.description = quizSelected.description;
        editionStore.setLastSaved(false);
      });
    }
  }, [formData, editionStore]);

  return (
    <>
      <FormHeader title="forms.element.quiz.title" />

      <SelectQuiz
        defaultQuiz={quiz}
        label={translate('forms.element.quiz.chooseQuiz')}
        name="quiz"
        onDelete={handleQuizDelete}
        onSelect={handleQuizSelect}
      />
    </>
  );
});

export default asForm(QuizElementForm);

QuizElementForm.propTypes = {
  formData: quizElementFormModel.isRequired,
  updateProperty: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};