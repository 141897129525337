import { DropzoneArea } from 'material-ui-dropzone'; // Docs : https://yuvaleros.github.io/material-ui-dropzone/
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import './DropZoneInput.css';
import { translate } from 'utils/translation';
import { MAX_FILE_SIZE } from 'utils/constants';

const DropZoneContainer = styled.div`
  ${(props) => props.margin && css`margin: ${props.margin};`}
  ${(props) => props.padding && css`padding: ${props.padding};`}
`;

const DropZoneInput = ({
  onChange, acceptedFiles, initialFiles, onDelete, dropzoneText, ...props
}) => {
  const handleUploadFile = useCallback((files) => {
    if (files && files.length > 0) {
      const file = files[0];
      onChange(file);
    }
  }, [onChange]);

  return (
    <DropZoneContainer className="drop-zone-container" {...props}>
      <DropzoneArea
        acceptedFiles={acceptedFiles}
        dropzoneClass="FileDropZone"
        dropzoneText={translate(dropzoneText)}
        filesLimit={1}
        getDropRejectMessage={() => translate('common.fileTooBig')}
        getFileLimitExceedMessage={() => translate('common.onlyOnUpload')}
        initialFiles={initialFiles}
        maxFileSize={MAX_FILE_SIZE}
        previewGridProps={{ item: { xs: 11 } }}
        showAlerts={['error']}
        showPreviews
        showPreviewsInDropzone={false}
        onChange={handleUploadFile}
        onDelete={onDelete}
      />
    </DropZoneContainer>
  );
};

DropZoneInput.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  dropzoneText: PropTypes.string,
  initialFiles: PropTypes.arrayOf(PropTypes.string)
};

DropZoneInput.defaultProps = {
  acceptedFiles: [],
  dropzoneText: 'forms.element.document.dropZoneText',
  initialFiles: []
};

export default DropZoneInput;