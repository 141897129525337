import {TextMultiline} from 'components';
import {textElementModel} from 'components/model/dataformModel';
import {observer} from 'mobx-react-lite';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import {URL_REGEX} from 'utils/constants';

const Title = styled.h3`
  margin-top: 0px;
`;

const renderLinks = (txt) => txt
  .split(' ')
  .map((part) => (URL_REGEX.test(part) ? (
    <a href={part} key={shortid.generate()}>
      {part}
      {' '}
    </a>
  ) : `${part} `));

export const TextElement = observer(({ element }) => {
  const { subtitle, text } = element;

  return (
    <>
      {subtitle && <Title>{subtitle}</Title>}
      <TextMultiline color="var(--grey-darker)" textAlign="justify">
        {renderLinks(text)}
      </TextMultiline>
    </>
  );
});

TextElement.propTypes = {
  element: textElementModel.isRequired
};