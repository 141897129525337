import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { format, parseISO } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenSquare, faPollH, faSignOutAlt, faUserCircle, faUsersCog
} from '@fortawesome/pro-regular-svg-icons';
import {
  Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography
} from '@material-ui/core';
import {
  APP_VERSION, CREATED,
  ENVIRONMENT, PATHS, REVISION, ROLE_GROUPS, ROUTES, URL_DATATECNEA
} from 'utils/constants';
import { UserHelper } from 'utils';
import { useStores } from 'hooks';
import FooterSupralog from 'components/Footer/FooterSupralog';
import { translate } from 'utils/translation';

const SideMenuContainer = styled.div`
  display: flex;
  flex: 1;

  a:hover svg {
    color: var(--primary-color);
  }

  ul {
    width: 100%;
  }
`;

export const SideMenu = ({
  isOpen, setToggleMenu
}) => {
  const { userStore, editionStore } = useStores();
  const history = useHistory();

  const handleToggleMenu = useCallback((e) => {
    setToggleMenu(false);

    if (e.currentTarget && e.currentTarget.className !== 'MuiBackdrop-root') {
      editionStore.reset();
    }
  }, [setToggleMenu, editionStore]);

  const disconnect = useCallback(() => {
    history.push(PATHS.HOME);
    userStore.disconnectUser();
  }, [userStore, history]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleToggleMenu}>
      <SideMenuContainer>
        <List>
          <ListItem
            button
            component={Link}
            key={shortid.generate()}
            to={ROUTES.PROFILE('current')}
            onClick={handleToggleMenu}
          >
            <ListItemIcon><FontAwesomeIcon icon={faUserCircle} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.profile')} />
          </ListItem>
          {UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS) && (
            <ListItem
              button
              component={Link}
              data-cy="headerMenuAdministration"
              key={shortid.generate()}
              to={ROUTES.ADMINISTRATION}
              onClick={handleToggleMenu}
            >
              <ListItemIcon><FontAwesomeIcon icon={faPenSquare} size="lg" /></ListItemIcon>
              <ListItemText primary={translate('menu.admin')} />
            </ListItem>
          )}
          <Divider />
          {UserHelper.hasAccessRight(ROLE_GROUPS.ADMINISTRATORS) && (
            <a
              href={`${URL_DATATECNEA}/administration/utilisateurs?origin=DATAFORM`}
              key={shortid.generate()}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ListItem
                button
                data-cy="headerMenuAdministration"
                onClick={handleToggleMenu}
              >
                <ListItemIcon><FontAwesomeIcon icon={faUsersCog} size="lg" /></ListItemIcon>
                <ListItemText primary={translate('menu.adminUsers')} />
              </ListItem>
            </a>
          )}
          {UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS) && (
            <a
              href={`${URL_DATATECNEA}/administration-quiz`}
              key={shortid.generate()}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ListItem
                button
                data-cy="headerMenuAdministration"
                onClick={handleToggleMenu}
              >
                <ListItemIcon><FontAwesomeIcon icon={faPollH} size="lg" /></ListItemIcon>
                <ListItemText primary={translate('menu.adminQuiz')} />
              </ListItem>
            </a>
          )}
          <Divider />
          <ListItem
            button
            component={Link}
            data-cy="headerMenuLogout"
            to=""
            onClick={disconnect}
          >
            <ListItemIcon><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.disconnect')} />
          </ListItem>
        </List>
      </SideMenuContainer>

      <Typography align="center">
        {`${ENVIRONMENT} - ${APP_VERSION}`}
        <br />
        <Tooltip interactive leaveDelay={1000} title={REVISION}>
          <Typography component="span">
            {`Révision: ${REVISION.substring(0, 5)}`}
          </Typography>
        </Tooltip>
        <br />
        {`Créé le: ${format(parseISO(CREATED), 'dd/MM/yyyy à HH:mm:ss')}`}
      </Typography>

      <FooterSupralog borderPosition="top" />
    </Drawer>
  );
};

SideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setToggleMenu: PropTypes.func.isRequired
};