import { BlueBlock } from 'components';
import { PropTypes } from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

const StyledInput = styled.input`
  display: none;
`;

export const SectionRadio = ({
  name, value, children, isChecked, onChange, discreet, ariaLabel, ...props
}) => {
  const inputId = shortid.generate();

  return (
    <BlueBlock
      as="label"
      discreet={discreet}
      display="flex"
      focused={isChecked(value)}
      htmlFor={inputId}
      margin="1rem 0"
      padding="1rem"
      {...props}
    >
      <StyledInput
        aria-label={ariaLabel}
        checked={isChecked(value)}
        id={inputId}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
      />
      {children}
    </BlueBlock>
  );
};

SectionRadio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isChecked: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  props: PropTypes.arrayOf(PropTypes.any)
};

SectionRadio.defaultProps = {
  props: []
};