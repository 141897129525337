import { Grid } from '@material-ui/core';
import { CardSummary } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { ROUTES } from 'utils/constants';

const SequenceColumnsContainer = styled.section`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media(max-width: 960px) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

const SequenceBox = ({
  index, sequence, programId, moduleId
}) => {
  let pathname = '';
  if (programId) {
    pathname = ROUTES.PROGRAM_MODULE_SEQUENCE(programId, moduleId, sequence.id);
  } else if (moduleId) {
    pathname = ROUTES.MODULE_SEQUENCE(moduleId, sequence.id);
  } else {
    pathname = ROUTES.SEQUENCE(sequence.id);
  }
  return (
    <CardSummary
      {...sequence}
      descriptionSize={70}
      disabled={!sequence.hasAccess}
      index={index}
      to={pathname}
    />
  );
};

export const SequenceList = ({
  nbRows, nbColumns, sequences, programId, moduleId, nbPastSequences
}) => {
  const nbSequences = sequences.length;
  const getSequenceId = (colIndex, rowIndex) => nbPastSequences + (rowIndex * nbColumns) + colIndex;

  return (
    <>
      {[...Array(nbRows)].map((i, rowIndex) => (
        <SequenceColumnsContainer key={shortid.generate()}>
          {[...Array(nbColumns)].map((j, colIndex) => (
            <Grid item key={shortid.generate()} md={3} sm={12} style={{ marginTop: '2rem' }}>
              {getSequenceId(colIndex, rowIndex) < nbSequences && (
                <SequenceBox
                  index={getSequenceId(colIndex, rowIndex)}
                  moduleId={moduleId}
                  programId={programId}
                  sequence={sequences[getSequenceId(colIndex, rowIndex)]}
                />
              )}
            </Grid>
          ))}
        </SequenceColumnsContainer>
      ))}
    </>
  );
};

SequenceList.propTypes = {
  nbRows: PropTypes.number.isRequired,
  nbColumns: PropTypes.number.isRequired,
  sequences: PropTypes.arrayOf(PropTypes.object).isRequired,
  programId: PropTypes.string,
  moduleId: PropTypes.string,
  nbPastSequences: PropTypes.number
};

SequenceList.defaultProps = {
  moduleId: '',
  nbPastSequences: 0,
  programId: ''
};