import logger from 'utils/logger';

export const NB_COLUMNS_IN_BACK_SHOP = 3;

const extractFormatData = (formatType) => {
  const matchingFormatType = formatType.match('MODULE_([2,3])_([2,3,4])');
  if (!matchingFormatType) {
    logger.error(`ModuleHeader format not handled : ${formatType}`);
    return { nbColumns: 2, nbRows: 2 };
  }

  return {
    nbColumns: parseInt(matchingFormatType[1], 10),
    nbRows: parseInt(matchingFormatType[2], 10)
  };
};

export const getFormat = (formatType, sequences) => {
  const { nbColumns, nbRows } = extractFormatData(formatType);

  const nbSequences = sequences.length;
  const nbRowsInShowCase = Math.min(Math.ceil(nbSequences / nbColumns), nbRows);
  const nbSequencesInBackShop = nbSequences - (nbRowsInShowCase * nbColumns);
  const nbRowsInBackShop = Math.ceil(nbSequencesInBackShop / NB_COLUMNS_IN_BACK_SHOP);

  return {
    nbColumnsInBackShop: NB_COLUMNS_IN_BACK_SHOP,
    nbColumnsInShowCase: nbColumns,
    nbRowsInShowCase,
    nbRowsInBackShop
  };
};