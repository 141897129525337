/**
 * Defines the visual aspect of the different sections.
 * <p>
 * For each section, we give the number of columns.
 * For each Grid breakpoint (xs, sm, md, lg), we precise the size (in [1, 12]) of each column.
 *
 * @see The breakpoint values at {@link https://material-ui.com/customization/breakpoints/#breakpoints}
 */
const formats = {
  GENERIC_1: {
    columns: 1,
    xs: [12],
    sm: [12],
    md: [],
    lg: [],
    xl: []
  },
  GENERIC_1_1: {
    columns: 2,
    xs: [12],
    sm: [6, 6],
    md: [],
    lg: [],
    xl: []
  },
  GENERIC_1_1_1: {
    columns: 3,
    xs: [12, 12, 12],
    sm: [4, 4, 4],
    md: [],
    lg: [],
    xl: []
  },
  GENERIC_1_2: {
    columns: 2,
    xs: [12],
    sm: [4, 8],
    md: [],
    lg: [],
    xl: []
  },
  GENERIC_2_1: {
    columns: 2,
    xs: [12],
    sm: [8, 4],
    md: [],
    lg: [],
    xl: []
  }
};

/**
 * Returns an object specifying the format corresponding to the given format name.
 *
 * @param {string} formatType the name of the section format used.
 * @return an object containing the number of columns and a function to obtain the size of a given column for a given breakpoint.
 * @throws Error if the format name passed as parameter is unknown
 */
export const getFormat = (formatType) => {
  if (!formatType) return null;

  const format = formats[formatType];
  if (format === undefined) {
    throw Error(`Unknown section format name : ${formatType}`);
  }

  const { columns, ...sizes } = format;
  return {
    columns,
    getColumnFormat: (columnId, sizeName) => ((sizes[sizeName] && sizes[sizeName].length > columnId)
      ? sizes[sizeName][columnId]
      : null)
  };
};