import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';

export const SkeletonCardSummary = () => (
  <Skeleton
    height={180}
    style={{
      flex: '1',
      padding: '1rem',
      borderRadius: '4px',
      border: '1px solid rgb(213,225,239)'
    }}
    variant="rect"
  >
    <Grid container direction="column">
      <Grid item>
        <Skeleton height={30} style={{ marginBottom: '1rem' }} width={200} />
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Skeleton height={20} width={100} />
            <Skeleton height={20} width={100} />
            <Skeleton height={20} width={100} />
          </Grid>
          <Grid item>
            <Skeleton height={100} style={{ margin: '0 0 0 auto' }} variant="circle" width={100} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Skeleton>
);