import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { TextError } from '../Text';

export const SkeletonNoResult = ({ error }) => (
  <Grid alignItems="center" container direction="column" justifyContent="center">
    <Grid item>
      <TextError data-testid={error}>{translate(error)}</TextError>
    </Grid>
    <Grid item>
      <img alt={translate(error)} src="/assets/images/no-results.png" />
    </Grid>
  </Grid>
);

SkeletonNoResult.propTypes = {
  error: PropTypes.string
};

SkeletonNoResult.defaultProps = {
  error: 'errors.noResult'
};