// Redux store
import { userStore } from 'stores';


const hasAccessRight = (requiredRoles = []) => {
  const {
    userConnected
  } = userStore;

  if (userConnected && userConnected.resource_access && userConnected.resource_access['dataform-backend']) {
    const userRoles = userConnected.resource_access['dataform-backend'].roles;
    if (userRoles && userRoles.length > 0) {
      return requiredRoles.some((role) => userRoles.includes(role));
    }
  }

  return false;
};

const getParams = () => {
  const {
    userConnected
  } = userStore;

  if (userConnected && userConnected.tecnea) {
    return userConnected.tecnea;
  }

  return {};
};

export const UserHelper = {
  getParams,
  hasAccessRight
};