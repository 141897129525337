import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { useStores } from 'hooks';
import { TextField } from '@material-ui/core';

import { buildDefaultAndTraineeAnswers } from '../../QuizHelper';

const TextQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { quizStore } = useStores();

  const { currentPage } = quizStore;

  const [currentValue, setCurrentValue] = useState('');

  const buildAnswers = useCallback(() => {
    setCurrentValue(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleChangeAnswer = useCallback((event) => {
    const { value } = event.target;
    setCurrentValue(value);

    quizStore.setTraineeAnswers(currentPage, currentQuestionData, value);
  }, [currentPage, currentQuestionData, quizStore]);

  return (
    <>
      <TextField
        autoComplete="off"
        disabled={isReadOnlyQuestion}
        fullWidth
        inputProps={{ maxLength: 150 }}
        name="textAnswer"
        placeholder={translate('common.writeAnswer')}
        required
        style={{ margin: '10px 0px' }}
        value={currentValue}
        variant="outlined"
        onChange={handleChangeAnswer}
      />
    </>
  );
});

export default TextQuestion;

TextQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

TextQuestion.defaultProps = {
  currentQuestionData: {}
};