import { getProgramContent } from '__fixtures__/section/section_constants';
import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET
const getProgramList = ({ search = '', filters = '' } = {}) => RequestHelper.GET(API_ROUTES.PROGRAMS({ search, filters }));
const getProgram = (programId) => RequestHelper.GET(API_ROUTES.PROGRAM(programId));

// POST
const createProgram = () => RequestHelper.POST(API_ROUTES.PROGRAMS({}), {
  content: getProgramContent()
});

// PUT
const updateProgram = (programHashId, program) => RequestHelper.PUT(
  API_ROUTES.PROGRAM_UPDATE(programHashId),
  program
);

// DELETE
const deleteProgram = (programHashId) => RequestHelper.DELETE(
  API_ROUTES.PROGRAM_DELETE(programHashId)
);

export const ProgramService = {
  getProgramList,
  getProgram,
  createProgram,
  updateProgram,
  deleteProgram
};