import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, {
  lazy, Suspense, useCallback, useEffect, useState
} from 'react';
import { pluralize, UserHelper } from 'utils';
import { Grid, Typography } from '@material-ui/core';
import { API_URL_DOCUMENTS, EDITION_PANEL_TYPES, ROLE_GROUPS } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPlay } from '@fortawesome/pro-regular-svg-icons';
import {
  Banner, BlueBlock, EditionContent, EditionPanel, ParentSection, SkeletonFormDocument, SkeletonMain
} from 'components';
import { useSnackbar } from 'notistack';
import { translate } from 'utils/translation';
import { ProgramEditionNav } from './ProgramEditionNav';

const ParentForm = lazy(() => import('../forms/ParentForm/ParentForm'));

export const ProgramDetail = observer(({ programId, isEditionMode }) => {
  const { userStore, programStore, editionStore } = useStores();
  const { isConnected } = userStore;
  const { editionMode, elementEdited } = editionStore;
  const { isLoading } = programStore;
  const [isTempEditionMode, setIsTempEditionMode] = useState(isEditionMode);
  const { enqueueSnackbar } = useSnackbar();

  const elementId = EDITION_PANEL_TYPES.PROGRAM;

  const canEditContent = UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS);
  const canAdministrate = UserHelper.hasAccessRight(ROLE_GROUPS.ADMINISTRATORS);

  const editProgram = useCallback(() => {
    if (editionStore.hasNewEditedElement(elementId)) {
      editionStore.setElementEdited(elementId, programStore.program, EDITION_PANEL_TYPES.PROGRAM, null, true);
    }
  }, [editionStore, programStore.program, elementId]);

  const loadProgram = useCallback(() => programStore.loadProgram(programId)
    .catch((err) => enqueueSnackbar(err.message, { variant: 'error' })), [programStore, programId, enqueueSnackbar]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    !isEditionMode && editionStore.reset();

    loadProgram().then(() => {
      editionStore.setElementEdited(elementId, programStore.program, EDITION_PANEL_TYPES.PROGRAM, null, true);
      // Already saved with no changes when opening edition mode
      editionStore.setLastSaved();
    });
    // eslint-disable-next-line
  }, [isEditionMode, editionStore, loadProgram]);

  useEffect(() => {
    isTempEditionMode && editionStore.setElementEdited(elementId, programStore.program, EDITION_PANEL_TYPES.PROGRAM, null, true);
  }, [programStore.program, editionStore, elementId, isTempEditionMode]);

  if (isLoading) {
    return <SkeletonMain />;
  }

  const {
    content, moduleSummaries: modules, logoURL, name, backgroundImage, programDurationInDays, canSubscribe, isPublic
  } = programStore.program;
  const sections = content && content.sections;

  const nbModules = modules ? modules.length : 0;
  const moduleText = nbModules > 1 ? translate('common.modules') : translate('common.module');

  return (
    <section data-testid="program-container">
      {isConnected && canEditContent && (
        <ProgramEditionNav
          allowTraineeManagement={!isPublic && canSubscribe && canAdministrate}
          isEditionMode={isTempEditionMode}
          loadProgram={loadProgram}
          setIsTempEditionMode={setIsTempEditionMode}
          onEdit={editProgram}
        />
      )}

      <Grid container>
        <EditionPanel editionMode={editionMode}>
          <br />
          {isConnected && editionMode && elementEdited && elementEdited.elementId
            && (
              <Suspense fallback={SkeletonFormDocument()}>
                <ParentForm {...elementEdited} />
              </Suspense>
            )}
        </EditionPanel>
        <EditionContent
          editionMode={editionMode}
          elementId={elementId}
          onClick={editProgram}
        >
          <BlueBlock
            discreet={!editionMode}
            focused={elementEdited && elementEdited.elementId === elementId}
            isParams
          >
            <Banner
              backgroundImage={backgroundImage ? `${API_URL_DOCUMENTS}${backgroundImage}` : null}
              logoURL={logoURL}
              paddingBottom="8rem"
              title={name}
            >
              <Grid container direction="column">
                <Grid item>
                  <Typography component="h2">
                    <FontAwesomeIcon icon={faPlay} style={{ marginRight: '1rem' }} />
                    {`${nbModules} ${moduleText}`}
                  </Typography>
                </Grid>
                {programDurationInDays ? (
                  <Grid item>
                    <FontAwesomeIcon icon={faClock} style={{ marginRight: '1rem' }} />
                    {`${programDurationInDays} ${pluralize(translate('common.day'), programDurationInDays)}`}
                  </Grid>
                ) : ''}
              </Grid>
            </Banner>
          </BlueBlock>
          {sections && sections.map((section) => (
            <ParentSection
              editionMode={editionMode}
              key={section.index}
              program={programStore.program}
              section={section}
            />
          ))}
        </EditionContent>
      </Grid>
    </section>
  );
});

ProgramDetail.propTypes = {
  programId: PropTypes.string.isRequired,
  isEditionMode: PropTypes.bool
};

ProgramDetail.defaultProps = {
  isEditionMode: false
};