import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils/translation';
import { ProgramService } from 'services';
import { ROUTES } from 'utils/constants';


const ButtonCreateProgram = () => {
  const history = useHistory();
  const { programStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [isCreating, setIsCreating] = useState(false);

  const createProgram = useCallback(() => {
    setIsCreating(true);

    ProgramService.createProgram().then((response) => {
      programStore.resetProgram();

      history.push({
        pathname: ROUTES.PROGRAM(response),
        state: {
          isEditionMode: true
        }
      });
      enqueueSnackbar(translate('forms.program.programCorrectlyCreated'), { variant: 'success' });
      setIsCreating(false);
    }).catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  }, [history, enqueueSnackbar, programStore]);


  return (
    <Button
      disabled={isCreating}
      startIcon={<FontAwesomeIcon icon={isCreating ? faSpinner : faPlus} spin={isCreating} />}
      onClick={createProgram}
    >
      {translate('pageCatalog.createProgram')}
    </Button>
  );
};

export default ButtonCreateProgram;