import React from 'react';
import { SequenceDetails } from 'components/SequenceDetails/SequenceDetails';
import { useLocation, useParams } from 'react-router-dom';

const PageSequenceDetail = () => {
  const { programId, moduleId, sequenceId } = useParams();
  const { state } = useLocation();

  const isEditionMode = (state && state.isEditionMode) || false;
  const isCopyMode = (state && state.isCopyMode) || false;

  return (
    <SequenceDetails
      isCopyMode={isCopyMode}
      isEditionMode={isEditionMode}
      moduleId={moduleId}
      programId={programId}
      sequenceId={sequenceId}
    />
  );
};

export default PageSequenceDetail;