import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// PUT
const updateLocale = (locale) => RequestHelper.PUT(API_ROUTES.UPDATE_LOCALE(locale));

const getUserAccountDetail = (personId) => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS_DETAIL(personId));

const resetPassword = () => RequestHelper.GET(API_ROUTES.RESET_PASSWORD());

export const UserAccountService = {
  updateLocale,
  getUserAccountDetail,
  resetPassword
};