import { getSequenceContent } from '__fixtures__/section/section_constants';
import { RequestHelper } from 'utils';
import { API_ROUTES, LIST_SIZE } from 'utils/constants';

// GET
const getAllSequences = ({
  search = '', page = 0, size = LIST_SIZE, filters = ''
} = {}) => RequestHelper.GET(API_ROUTES.SEQUENCES_FIND({
  search, page, size, filters
}));

const findSequences = ({
  search = '', page = 0, size = LIST_SIZE, filters = ''
}) => RequestHelper.GET(
  API_ROUTES.SEQUENCES_FIND({
    search, page, size, filters
  })
);

const getSequence = (programId, moduleHashId, sequenceHashId) => RequestHelper.GET(
  API_ROUTES.SEQUENCE(programId, moduleHashId, sequenceHashId)
);

const getSequenceCopy = (programId, moduleHashId, sequenceHashId) => RequestHelper.GET(
  API_ROUTES.SEQUENCE_COPY(programId, moduleHashId, sequenceHashId)
);

// POST
const createSequence = (moduleId = '') => RequestHelper.POST(
  API_ROUTES.SEQUENCES(moduleId),
  { content: getSequenceContent() }
);

const createSequenceCopy = (sequence) => RequestHelper.POST(
  API_ROUTES.SEQUENCES(),
  sequence
);

// PUT
const updateSequence = (sequenceHashId, sequence) => RequestHelper.PUT(
  API_ROUTES.SEQUENCE_UPDATE(sequenceHashId),
  sequence
);

// DELETE
const deleteSequence = (sequenceHashId) => RequestHelper.DELETE(
  API_ROUTES.SEQUENCE_DELETE(sequenceHashId)
);

export const SequenceService = {
  getAllSequences,
  getSequence,
  getSequenceCopy,
  createSequence,
  createSequenceCopy,
  updateSequence,
  deleteSequence,
  findSequences
};