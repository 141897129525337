import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { QuizService } from 'services';
import styled from 'styled-components';
import {
  addSeconds, differenceInSeconds, formatDuration, intervalToDuration, isAfter
} from 'date-fns';
import { fr } from 'date-fns/locale';
import { useSnackbar } from 'notistack';
import { translate } from 'utils/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react';

import { runInAction } from 'mobx';

const StyledTimer = styled.aside`
  color: ${(props) => props.color};
`;

const QuizTimer = observer(() => {
  const { quizStore } = useStores();

  const { startedExamination } = quizStore;
  const {
    quiz: { maxTimeToFinish }, quiz, startDate, endDate
  } = startedExamination;

  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();

  const [timer, setTimer] = useState(undefined);
  const [timeLeft, setTimeLeft] = useState(undefined);
  const timerInterval = useRef();

  const handleFinishQuiz = useCallback(() => {
    quizStore.setLoading(true);
    showModal({
      type: 'QUIZ_TIME_OUT',
      quizTitle: quiz.title,
      onConfirm: () => {
        QuizService.finishQuiz(startedExamination.id)
          .then((responseData) => {
            runInAction('triggerFinish', () => {
              quizStore.finishQuiz(responseData);
              quizStore.setExamStatus({
                isStarted: false, isEnded: true, examinationId: startedExamination.id, quizId: quiz.id
              });
            });
          })
          .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
          .finally(() => {
            quizStore.setLoading(false);
          });
      }
    });
    // eslint-disable-next-line
  }, [quiz.title, quiz.id, showModal]);

  useEffect(() => {
    if (maxTimeToFinish > 0) {
      // Convert max time to finish to second to be more precise
      let timeRemaining = maxTimeToFinish * 60;
      // If the quiz was started but not completed, start the timer with the old value
      if (startDate && !endDate) {
        const timeSpend = differenceInSeconds(new Date(), new Date(startDate));
        timeRemaining = Number(maxTimeToFinish * 60 - timeSpend);
      }

      // if (timeRemaining <= 0) {
      //   return handleFinishQuiz();
      // }

      const timerEnd = addSeconds(new Date(), timeRemaining);
      timerInterval.current = setInterval(() => {
        setTimer(() => {
          const timeLeftFormatted = intervalToDuration({
            start: new Date(),
            end: timerEnd
          });

          // If startDate if after endDate, finish the quiz directly
          if (isAfter(new Date(), timerEnd)) {
            setTimeLeft(0);
            return 0;
          }

          if (timeLeftFormatted.hours === 0 && timeLeftFormatted.minutes === 0 && timeLeftFormatted.seconds === 0) {
            setTimeLeft(0);
            return 0;
          }

          setTimeLeft(timeLeftFormatted);
          return formatDuration(timeLeftFormatted, { locale: fr });
        });
      }, 1000);

      return () => {
        clearInterval(timerInterval.current);
      };
    }

    return undefined;
    // eslint-disable-next-line
  }, [maxTimeToFinish, startDate, endDate]);

  useEffect(() => {
    if (timeLeft === undefined) return;

    if (timeLeft <= 0) {
      clearInterval(timerInterval.current);
      handleFinishQuiz();
      return;
    }

    // Display a warning 5 min before the end if the quiz is longer than 10 min
    if (maxTimeToFinish >= 10 && timeLeft.minutes === 5 && timeLeft.seconds === 0) {
      enqueueSnackbar(translate('forms.element.quiz.timerWarning', { timeLeft: '5 min' }), { variant: 'warning' });
    }
    // Display a warning 1 min before the end
    if (timeLeft.minutes === 1 && timeLeft.seconds === 0) {
      enqueueSnackbar(translate('forms.element.quiz.timerWarning', { timeLeft: '1 min' }), { variant: 'warning' });
    }
    // eslint-disable-next-line
  }, [timeLeft, enqueueSnackbar, maxTimeToFinish]);

  const getTimerColor = useCallback(() => {
    if (timeLeft === undefined) return 'var(--success-color)';

    if (timeLeft.minutes < 1 || timeLeft === 0) return 'var(--danger-color)';

    if (timeLeft.minutes < 5) return 'var(--warning-color)';

    return 'var(--success-color)';
  }, [timeLeft]);

  if (maxTimeToFinish === null || maxTimeToFinish === 0 || !quiz) {
    return null;
  }

  if (timer === undefined) {
    return <FontAwesomeIcon icon={faSpinner} size="xs" spin />;
  }

  return (
    <StyledTimer color={getTimerColor()}>
      {timer <= 0
        ? 'Le temps est écoulé'
        : `Il vous reste ${timer} pour finir le quiz`}
    </StyledTimer>

  );
});

export default QuizTimer;