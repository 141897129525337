import React, { createContext, useContext, useState } from 'react';
import { Modal } from 'components';

const ModalContext = createContext(null);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({});
  const [open, setOpen] = useState(false);

  const openModal = (options) => {
    setOpen(true);
    setModalState(options);
  };

  const { onSubmit, onClose, ...modalProps } = modalState;

  const handleSubmit = () => {
    onSubmit && onSubmit();
    setOpen(false);
  };

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <>
      <ModalContext.Provider
        value={openModal}
      >
        {children}
      </ModalContext.Provider>

      <Modal
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        {...modalProps}
      />
    </>
  );
};