import i18n from 'i18next';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { createTheme, IconButton } from '@material-ui/core';
import { ModalProvider, useStores } from 'hooks';
import { SnackbarProvider } from 'notistack';

import { Routes } from 'routes/routes';
import { SkeletonMain } from 'components';
import { i18nStore } from 'stores';
import { KeyCloakUtils } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { APP_PROFILE } from 'utils/constants';
import GoogleAnalytics from 'components/_commons/GoogleAnalytics/GoogleAnalytics';

import { frenchLocale } from 'utils/translation';
import ErrorBoundary from './ErrorBoundary';

export const DATAFORM_THEME = createTheme({
  palette: {
    primary: {
      main: '#017db8'
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#017db8',
      dark: '#dde8f6'
    },
    error: {
      main: '#de1756'
    }
  },
  typography: {
    htmlFontSize: 10
  }
});

export const App = () => {
  const { userStore, referenceDataStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const [translationLoaded, setTranslationsLoaded] = useState(false);

  i18n.on('languageChanged', () => setTranslationsLoaded(true));

  const notistackRef = useRef();

  useEffect(() => {
    // Update document title depending on the current PROFILE
    document.title = APP_PROFILE;

    referenceDataStore.loadReferenceData();
    i18nStore.loadLanguageList();

    KeyCloakUtils.init().then((keycloak) => {
      userStore.connectUser(keycloak);

      if (keycloak.authenticated && userStore.isTokenExpired()) {
        userStore.refreshToken()
          .then(() => setTimeout(() => window.location.reload(), 1000))
          .catch(() => userStore.disconnectUser()
            .then(() => userStore.keycloakLogin()));
      }

      if (keycloak.tokenParsed) {
        i18nStore.checkLanguage(keycloak.tokenParsed.locale || frenchLocale);
        i18nStore.loadLanguage(keycloak.tokenParsed.locale || frenchLocale);
      } else if (localStorage.getItem('i18nextLng')) {
        i18nStore.checkLanguage(localStorage.getItem('i18nextLng'));
        i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error((error && error.error_description) || error);
    }).finally(() => {
      setIsLoading(false);
      if (!localStorage.getItem('i18nextLng')) {
        i18nStore.loadLanguage(frenchLocale);
      }
    });
  }, [userStore, referenceDataStore]);

  const onClickDismiss = useCallback((key) => () => {
    notistackRef.current.closeSnackbar(key);
  }, [notistackRef]);

  const displayCloseButton = useCallback((key) => (
    <IconButton onClick={onClickDismiss(key)}>
      <FontAwesomeIcon color="var(--white)" icon={faTimes} />
    </IconButton>
  ), [onClickDismiss]);

  if (isLoading || !translationLoaded) {
    return <SkeletonMain />;
  }

  return (
    <SnackbarProvider
      action={displayCloseButton}
      maxSnack={3}
      ref={notistackRef}
    >
      <ModalProvider>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
        <GoogleAnalytics />
      </ModalProvider>
    </SnackbarProvider>
  );
};