import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sanitizeHtml from 'sanitize-html';
import { translate } from 'utils/translation';
import {
  Grid, Paper, Popper, Tooltip, Typography
} from '@material-ui/core';
import { IconButton } from 'components';
import styled from 'styled-components';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { runInAction } from 'mobx';
import { genericSectionEmpty } from '__fixtures__';
import { useSnackbar } from 'notistack';
import PropTypes, { arrayOf } from 'prop-types';
import { sectionModel } from 'components/model/dataformModel';
import { add } from 'date-fns';
import { Button } from '.';
import {editionStore} from "../../../stores";

const COOKIE_NAME = 'SEQUENCE_ADD_SECTION_INFO';

const AddSectionContainer = styled.div`
  z-index: 80;
`;

const StyledPaper = styled(Paper)`
  position: relative;
  width: 260px;
  padding: 1rem;
  border: 1px solid var(--grey-light);

  &::after {
    content: '';
    position: absolute;
    top: -14px;
    left: 116px;
    border-left: 1.4rem solid transparent;
    border-right: 1.4rem solid transparent;
    border-bottom: 1.4rem solid var(--grey-light);
  }
`;

const ButtonCreateSection = ({
  ariaLabel, title, section, sections, hasInfoMessage
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const anchorRef = useRef(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  useEffect(() => {
    const isCookieSet = document.cookie.indexOf(COOKIE_NAME);
    anchorRef && isCookieSet === -1 && setIsPopperOpen(hasInfoMessage);
  }, [anchorRef, hasInfoMessage]);

  const handlePopper = useCallback(() => {
    setIsPopperOpen(false);
    // Set cookie for 10 years in the future
    const in10years = add(new Date(), { years: 10 });
    document.cookie = `${COOKIE_NAME}=true; expires=${in10years}; path=/`;
  }, []);

  const handleAddNewSection = useCallback(() => {
    const selectedSectionIndex = sections.indexOf(section);
    let newIndex = 0;

    if (sections && sections.length > 0) {
      newIndex = Math.max(...sections.map((elem) => elem.index)) + 1;
    }

    if (section) {
      if (selectedSectionIndex > -1) {
        runInAction('Adding new section', () => {
          sections.splice(selectedSectionIndex, 0, genericSectionEmpty(newIndex));
          editionStore.setLastSaved(false);
        });
      } else {
        enqueueSnackbar(translate('errors.failedAddSection'), { variant: 'error' });
      }
    } else {
      runInAction('Adding new section', () => {
        sections.push(genericSectionEmpty(newIndex));
        editionStore.setLastSaved(false);
      });
    }

    setTimeout(() => {
      const newSection = document.getElementById(`_section_${newIndex}`);
      newSection.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [enqueueSnackbar, sections, section]);

  return (
    <Grid alignItems="center" container justifyContent="center">
      <Tooltip aria-label={translate(ariaLabel)} title={translate(title)}>
        <AddSectionContainer ref={hasInfoMessage ? anchorRef : null}>
          <IconButton onClick={handleAddNewSection}>
            <FontAwesomeIcon color="var(--primary-color)" icon={faPlusCircle} size="sm" />
          </IconButton>
        </AddSectionContainer>
      </Tooltip>

      {hasInfoMessage && (
        <Popper
          anchorEl={anchorRef.current}
          open={isPopperOpen}
          style={{ zIndex: 30 }}
        >
          <StyledPaper elevation={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('pageSequenceDetail.addSectionInfo')) }} />
              </Grid>
              <Grid item>
                <Button variant="text" onClick={handlePopper}>
                  {translate('confirms.confirm')}
                </Button>
              </Grid>
            </Grid>
          </StyledPaper>
        </Popper>
      )}
    </Grid>
  );
};

ButtonCreateSection.propTypes = {
  ariaLabel: PropTypes.string,
  sections: arrayOf(sectionModel),
  title: PropTypes.string
};

ButtonCreateSection.defaultProps = {
  ariaLabel: 'forms.section.addNewSection',
  sections: [],
  title: 'forms.section.addNewSection'
};

export default ButtonCreateSection;