import React, { useCallback, useEffect } from 'react';
import {
  faEdit, faEye, faFileExport, faSave, faSignOut, faUsersClass
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button, ButtonContainer, ButtonCreateModule
} from 'components';
import { HeaderEditionNav } from 'components/Header/HeaderEditionNav';
import { useModal, useStores } from 'hooks';
import { ProgramService } from 'services';
import { translate } from 'utils/translation';
import { ROUTES } from 'utils/constants';

export const ProgramEditionNav = ({
  onEdit, isEditionMode, loadProgram, setIsTempEditionMode, allowTraineeManagement
}) => {
  const history = useHistory();
  const { programStore, editionStore } = useStores();
  const showModal = useModal();
  const { programId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { editionMode, editionPreviewMode } = editionStore;
  const { program } = programStore;

  useEffect(() => {
    if (isEditionMode) {
      editionStore.setEditionMode();
      onEdit();
      setIsTempEditionMode(false);
    }
  }, [isEditionMode, editionStore, onEdit, setIsTempEditionMode]);

  const toggleEdition = useCallback(() => {
    editionStore.toggleEdition();
    if (onEdit) {
      onEdit();
    }
  }, [editionStore, onEdit]);

  const togglePreview = useCallback(() => {
    editionStore.togglePreview();
  }, [editionStore]);

  const showConfirmationModal = useCallback(({ action, title, text }) => showModal({
    type: 'WARNING',
    title,
    text,
    onConfirm: action
  }), [showModal]);

  const handleQuitAction = useCallback(() => {
    editionStore.reset();

    // Reload program to remove non saved modifications
    loadProgram();
  }, [editionStore, loadProgram]);

  const handleQuit = useCallback(() => {
    if (!editionStore.lastEditionSaved) {
      showConfirmationModal({
        action: () => {
          handleQuitAction();
        },
        title: 'forms.titleWarningLeaveEdition',
        text: 'forms.warningLeaveEdition'
      });
    } else {
      handleQuitAction();
    }
  },
  [editionStore, showConfirmationModal, handleQuitAction]);

  const handleSave = useCallback(() => {
    if (!program.isPublished || programStore.getValidity()) {
      ProgramService.updateProgram(programId, program).then(() => {
        enqueueSnackbar(translate('forms.program.programCorrectlySaved'), { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
      editionStore.setLastSaved();
    } else {
      enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }
  }, [editionStore, enqueueSnackbar, programId, program, programStore]);

  const handlePublish = useCallback(() => {
    onEdit();
    const isFormValid = document.forms.programForm && document.forms.programForm.reportValidity();

    if (isFormValid) {
      programStore.publishProgram();
      ProgramService.updateProgram(programId, program).then(() => {
        editionStore.reset();
        enqueueSnackbar(translate('forms.program.programCorrectlyPublished'), { variant: 'success' });
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
      editionStore.setLastSaved();
    } else {
      enqueueSnackbar(translate('errors.formIncomplete'), { variant: 'error' });
    }
  }, [onEdit, editionStore, enqueueSnackbar, programId, programStore, program]);

  const handleViewTrainees = useCallback(() => {
    history.push(ROUTES.TRAINEES('program', programId));
  }, [history, programId]);

  const HeaderPreviewMode = () => (
    <ButtonContainer>
      {allowTraineeManagement && (
        <Button
          startIcon={<FontAwesomeIcon icon={faUsersClass} />}
          type="secondary"
          onClick={handleViewTrainees}
        >
          {translate('button.viewTrainees')}
        </Button>
      )}

      <ButtonCreateModule programId={programId} />

      <Button
        startIcon={<FontAwesomeIcon icon={faEdit} />}
        type="secondary"
        onClick={toggleEdition}
      >
        {translate('button.edit')}
      </Button>
    </ButtonContainer>
  );

  const HeaderEditionMode = () => (
    <ButtonContainer>
      <Button
        startIcon={<FontAwesomeIcon icon={faSignOut} />}
        type="danger"
        variant="text"
        onClick={handleQuit}
      >
        {translate('button.cancelAndQuit')}
      </Button>
      <Button
        startIcon={<FontAwesomeIcon icon={faEye} />}
        type="secondary"
        onClick={togglePreview}
      >
        {editionPreviewMode ? translate('button.continueEdition') : translate('common.preview')}
      </Button>
      <Button
        startIcon={<FontAwesomeIcon icon={faSave} />}
        onClick={handleSave}
      >
        {translate('button.saveOnly')}
      </Button>
      {!program.isPublished && (
        <Button
          startIcon={<FontAwesomeIcon icon={faFileExport} />}
          onClick={handlePublish}
        >
          {translate('button.saveAndPublish')}
        </Button>
      )}
    </ButtonContainer>
  );

  return (
    <HeaderEditionNav>
      {editionMode || editionPreviewMode ? <HeaderEditionMode /> : <HeaderPreviewMode />}
    </HeaderEditionNav>
  );
};