
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { Text } from 'components';
import { Typography } from '@material-ui/core';


const Header = styled.header`
  margin-bottom: 2rem;
`;


const FormHeader = ({ title, subtitle }) => (
  <Header>
    <Typography component="p" variant="h5">{translate(title)}</Typography>
    {subtitle && <Text margin="0">{translate(subtitle)}</Text>}
  </Header>
);


FormHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

FormHeader.defaultProps = {
  subtitle: ''
};

export default FormHeader;