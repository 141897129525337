import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

const Leaf = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 30px;
  color: var(--white);
  font-size: 2rem;
  font-weight: 600;
  background-color: var(--success-color);
  border-radius: 2rem 0;
`;

export const LeafBadge = ({ value }) => (
  <Leaf>{value}</Leaf>
);

LeafBadge.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LeafBadge.defaultProps = {
  value: ''
};