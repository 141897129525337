/* eslint no-console: ["error", { allow: ["log", "error", "warn"] }] */
// TODO: replace by a real logger
const logger = {
  error: (value) => console.error(value),
  warn: (value) => console.warn(value)
};

const testLogger = {
  error: () => {},
  warn: () => {}
};

export default (process.env && process.env.NODE_ENV !== 'test') ? logger : testLogger;