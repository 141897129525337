import { SelectDocument } from 'components';
import { FormHeader } from 'components/forms';
import { asForm } from 'components/forms/hoc';
import { InputField } from 'components/forms/inputs';
import { imageElementFormModel } from 'components/model/dataformModel';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentService } from 'services';
import { translate } from 'utils/translation';

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
const ImageElementForm = observer(({ formData, updateProperty }) => {
  const [document, setDocument] = useState(null);
  const { title, legend, url } = formData;
  const { editionStore } = useStores();

  useEffect(() => {
    if (url) {
      const currentImage = url.split('/');
      url && DocumentService.getDocumentDetails(currentImage[currentImage.length - 1])
        .then((response) => setDocument(response));
    }
  }, [url]);

  const handleDocumentDelete = useCallback(() => {
    runInAction('deleteImageFromImageElementForm', () => {
      formData.url = null;
      formData.legend = '';
      formData.title = '';
      editionStore.setLastSaved(false);
    });
  }, [formData, editionStore]);

  const handleDocumentUpload = useCallback((fieldName, value, documentUploaded) => {
    if (documentUploaded) {
      runInAction('handlePictureUploadFromImageElementForm', () => {
        formData.legend = documentUploaded.name;
        formData.title = documentUploaded.name;
      });

      updateProperty(fieldName, value);
    }
  }, [formData.title, formData.legend, updateProperty]);

  return (
    <>
      <FormHeader title="forms.element.image.title" />

      <SelectDocument
        defaultDocument={document}
        label={translate('forms.element.image.chooseImage')}
        name="url"
        onDelete={handleDocumentDelete}
        onSelect={handleDocumentUpload}
      />

      <InputField
        label={translate('common.title')}
        name="legend"
        tooltip={translate('forms.element.image.legendInfo')}
        value={legend}
        onChange={updateProperty}
      />

      <InputField
        label={translate('common.altText')}
        name="title"
        tooltip={translate('forms.element.image.altTextInfo')}
        value={title}
        onChange={updateProperty}
      />
    </>
  );
});

export default asForm(ImageElementForm);

ImageElementForm.propTypes = {
  formData: imageElementFormModel.isRequired,
  updateProperty: PropTypes.func.isRequired
};