import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Grid, InputLabel, Typography } from '@material-ui/core';
import { translate } from 'utils/translation';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { Button } from 'components/_commons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileImage } from '@fortawesome/pro-regular-svg-icons';
import { MAX_FILE_SIZE } from 'utils/constants';
import { DocumentHelper } from 'utils/helpers';

const CustomInputFile = styled(Button)`
  max-width: ${(props) => props.maxwidth};
  padding: 0.8rem;
  border: 1px solid var(--grey-light);
  transition: border var(--transitionTime);
  pointer-events: none;

  ${(props) => props.disabled && css`
    background-color: var(--grey-light);
  `}
`;

const InputFileContainer = styled.div`
  width: 100%;

  input[type="file"] {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }

  ${(props) => !props.disabled && css`
    label {
      cursor: pointer;
    }
  `}

  @media (max-width: 560px) {
    max-width: 260px;
  }
`;

const InputFile = observer(({
  label, labelVar, labelButton, document, handleAddDocument, maxSize,
  id, disabled, fileAccepted, maxWidth, required, needResize
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = useCallback((event) => {
    const allFiles = event.target.files;
    DocumentHelper.handleFileUpload(event, needResize, maxSize)
      .then((doc) => handleAddDocument(doc, allFiles))
      .catch((error) => enqueueSnackbar(translate(error.message), { variant: 'error' }));
  }, [handleAddDocument, needResize, maxSize, enqueueSnackbar]);

  const handleFileClick = useCallback((e) => {
    e.target.value = '';
  }, []);

  const isValid = Boolean((required && document) || !required);
  const customMaxWidth = isMobile ? '170px' : maxWidth;

  return (
    <InputFileContainer disabled={disabled}>
      <InputLabel htmlFor={`${id}_file`}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={2}
          wrap="wrap"
        >
          {label && (
            <Grid item xs={4} zeroMinWidth>
              <Typography noWrap>
                {translate(label, labelVar)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={8}>
            <CustomInputFile
              disabled={disabled}
              isvalid={String(isValid)}
              maxwidth={customMaxWidth}
              startIcon={<FontAwesomeIcon icon={needResize ? faFileImage : faFile} />}
              type="secondary"
            >
              {document && document.name ? (
                <Typography noWrap>
                  {document.name}
                </Typography>
              ) : <Typography noWrap>{translate(labelButton)}</Typography>}
            </CustomInputFile>
          </Grid>
        </Grid>
      </InputLabel>

      <input
        accept={fileAccepted}
        disabled={disabled}
        id={`${id}_file`}
        type="file"
        onChange={handleFileChange}
        onClick={handleFileClick}
      />
    </InputFileContainer>
  );
});

InputFile.propTypes = {
  document: PropTypes.shape({}),
  disabled: PropTypes.bool,
  fileAccepted: PropTypes.string,
  handleAddDocument: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  labelButton: PropTypes.string,
  labelVar: PropTypes.shape({}),
  maxSize: PropTypes.number,
  maxWidth: PropTypes.string,
  needResize: PropTypes.bool
};

InputFile.defaultProps = {
  disabled: false,
  document: null,
  fileAccepted: '.pdf, .doc, .docx, .jpg, .jpeg, .png, .xlsx',
  id: 1,
  label: 'common.inputFile',
  labelButton: 'common.selectFile',
  labelVar: null,
  maxSize: MAX_FILE_SIZE,
  maxWidth: '300px',
  needResize: false
};

export default InputFile;