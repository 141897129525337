import { Paper as MaterialPaper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

const PaperContainer = styled(({
  display, minHeight, minWidth, height, width, textAlign, alignItems, ...props
}) => <MaterialPaper {...props} />)`
  ${(props) => props.display && css`display: ${props.display};`}
  ${(props) => props.minHeight && css`min-height: ${props.minHeight};`}
  ${(props) => props.minWidth && css`min-width: ${props.minWidth};`}
  ${(props) => props.height && css`height: ${props.height};`}
  ${(props) => props.width && css`width: ${props.width};`}
  ${(props) => props.textAlign && css`text-align: ${props.textAlign};`}
  ${(props) => props.alignItems && css`align-items: ${props.alignItems};`}
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--white);
  transition: background-color var(--transitionTime);
  cursor: ${(props) => props.clickable && 'pointer'};

  &:hover {
    ${(props) => props.clickable && css`background-color: var(--grey-light);`}
  }
`;

export const Paper = ({ children, clickable, ...props }) => (
  <PaperContainer clickable={clickable.toString()} elevation={3} {...props}>
    {children}
  </PaperContainer>
);

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  clickable: PropTypes.bool
};

Paper.defaultProps = {
  clickable: false
};