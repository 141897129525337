import { Avatar, Grid } from '@material-ui/core';
import { Text } from 'components';
import { PropTypes } from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { API_URL_DOCUMENTS } from 'utils/constants';
import { Wrapper } from '../Wrapper';

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 30vh;
  position: relative;
  color: #fff;
  padding-top: calc(var(--header-edition-nav-height) + 2rem);
  padding-bottom: calc(var(--header-edition-nav-height) + 4rem);
  filter: contrast(85%);
  background-image: url('/assets/images/bg/cover.png');
  ${(props) => props.backgroundImage && css`
    background-image: url(${props.backgroundImage});
  `}
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const BannerWrapper = styled(Wrapper)`
  width: 100%;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width || '150px'};
  height: ${(props) => props.height || '150px'};
  box-shadow: rgba(0,0,0,0.2) 0px 0px 10px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;

  > img {
    width: 80%;
  }
`;

export const Banner = ({
  backgroundImage, logoURL, title, children
}) => {
  const logo = `${API_URL_DOCUMENTS}${logoURL}`;

  return (
    <BannerContainer
      backgroundImage={backgroundImage}
      data-testid="banner"
    >
      <BannerWrapper>
        <Grid container spacing={2}>
          {logoURL && (
            <Grid item>
              <Avatar alt={title} src={logo} style={{ width: '150px', height: '150px' }} />
            </Grid>
          )}
          <Grid item sm xs={12}>
            {title && (
              <Text as="h1" textTransform="uppercase">
                {title}
              </Text>
            )}
            {children}
          </Grid>
        </Grid>
      </BannerWrapper>
    </BannerContainer>
  );
};

Banner.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  logoURL: PropTypes.string,
  title: PropTypes.string
};

Banner.defaultProps = {
  backgroundImage: '',
  children: null,
  logoURL: '',
  title: ''
};