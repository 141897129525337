/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';


export const COMMON_STEPS = {
  done: (
    <>
      <span className="textEnhanced">Merci de votre attention !</span>
      <br />
      <br />
      Vous pouvez accéder au guide à tout moment en cliquant sur ce bouton.
      <br />
      <br />
      Chaque page possède des explications qui lui sont propres.
    </>
  )
};