import { getModuleContent } from '__fixtures__/section/section_constants';
import { action, decorate, observable } from 'mobx';
import { ModuleService } from 'services';
import { MODULE_VALIDATION_MAP } from 'utils/constants';
import { FormHelper } from 'utils/helpers';

export class ModuleStore {
  constructor() {
    this.isLoading = true;
    this.isError = false;
    this.error = null;
    this.module = { content: {} };
    this.maxPage = 0;
    this.totalElements = 0;
    this.moduleList = [];
  }

  /**
   * Fetches the module details.
   */
  loadModule(programId, moduleId) {
    this.isLoading = true;
    return ModuleService.getModule(programId, moduleId)
      .then(action((module) => {
        const newModule = {
          ...module,
          content: module.content || getModuleContent()
        };
        this.module = newModule;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));
  }

  /**
   * Fetches the list of modules (not detailed).
   */
  loadModuleList({
    search, currentPage, size, filters
  }) {
    if (this.moduleList.length === 0) {
      this.isLoading = true;
    }

    return ModuleService.getAllModules({
      search, page: currentPage, size, filters
    })
      .then(action((response) => {
        this.maxPage = response.totalPages - 1;
        this.totalElements = response.totalElements;

        if (currentPage === 0) {
          this.moduleList = response.content;
        } else {
          this.moduleList = this.moduleList.concat(response.content);
        }

        this.isError = false;
        this.error = null;
      })).catch((error) => {
        this.error = error;
        this.isError = true;
      }).finally(action(() => {
        this.isLoading = false;
      }));
  }

  updateSequenceSummaries(newSeq) {
    if (this.module.sequenceSummaries) {
      this.module.sequenceSummaries = newSeq;
    }
  }

  publishModule() {
    this.module.isPublished = true;
  }

  getValidity() {
    return FormHelper.checkValidity(this.module, MODULE_VALIDATION_MAP);
  }

  resetModule() {
    this.module = { content: getModuleContent() };
  }
}

decorate(ModuleStore, {
  isLoading: observable,
  isError: observable,
  error: observable,
  module: observable,
  maxPage: observable,
  moduleList: observable,
  totalElements: observable,
  loadModuleList: action,
  loadModule: action,
  updateSequenceSummaries: action,
  publishModule: action,
  getValidity: action,
  resetModule: action
});

export const moduleStore = new ModuleStore();