import React, { useCallback } from 'react';
import { Button } from 'components';
import { translate } from 'utils/translation';
import { useModal, useStores } from 'hooks';
import { DocumentService } from 'services';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const ButtonCreateDocument = () => {
  const { documentStore } = useStores();
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const createDocument = useCallback((formData) => {
    const currentPage = 0;
    return DocumentService.createDocument(formData)
      .then(() => enqueueSnackbar(translate('forms.element.document.documentCorrectlyCreated'), { variant: 'success' }))
      .finally(() => documentStore.loadDocumentList({ currentPage }));
  }, [documentStore, enqueueSnackbar]);

  const showDocumentModal = useCallback(() => showModal({
    type: 'CREATE_DOCUMENT',
    onConfirm: createDocument
  }), [showModal, createDocument]);

  return (
    <Button
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={showDocumentModal}
    >
      {translate('button.addDocument')}
    </Button>
  );
};

export default ButtonCreateDocument;