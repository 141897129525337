import React, { useCallback, useEffect, useState } from 'react';
import { InputField, SelectField, SwitchVisibility } from 'components/forms/inputs';
import { asForm } from 'components/forms/hoc';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import { translate, translateTheme } from 'utils/translation';
import { sequenceModel } from 'components/model/dataformModel';
import { useStores } from 'hooks';
import { InputAdornment } from '@material-ui/core';
import { ButtonDisplaySession, SelectDocument } from 'components';
import { DocumentService, ModuleService } from 'services';
import {
  faBook, faBookAlt, faUsersClass, faUsersSlash
} from '@fortawesome/pro-regular-svg-icons';
import { FormHeader } from '..';

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
const ModuleForm = observer(({ formData, updateProperty }) => {
  const [logo, setLogo] = useState(null);
  const [themes, setThemes] = useState([]);
  const { referenceDataStore } = useStores();

  const {
    name, logoURL, code, reference, description, modality = null, trainingCategory = null,
    domain = null, keywords = [], trainingType = null, prerequisites,
    numberOfDays, additionalDurationInformation, objectives, professionalBenefits,
    intraInter = null, fareCodeForInter, fareCodeForIntra, isIndependent,
    locations = [], handicaps = null, pedagogicalResources, acknowledgements = null, reviewTypes = null,
    publicTypes = null, publicLevels = null, publicExperiences = null, id, isPublic = false, canSubscribe
  } = formData;
  let { theme } = formData;

  useEffect(() => {
    if (logoURL) {
      const currentLogo = logoURL.split('/');
      logoURL && DocumentService.getDocumentDetails(currentLogo[currentLogo.length - 1])
        .then((response) => setLogo(response));
    }
  }, [logoURL]);

  useEffect(() => {
    ModuleService.getThemes().then((response) => {
      const themesTranslated = response.map((resp) => ({ ...resp, label: translateTheme(resp.label) }));
      setThemes(themesTranslated);
    });
  }, []);

  const getRefData = useCallback((key) => referenceDataStore.getRefData(key), [referenceDataStore]);

  const handleImageUpload = useCallback((fieldName, value) => {
    updateProperty(fieldName, value);
  }, [updateProperty]);

  const handleImageDelete = useCallback((fieldName) => {
    updateProperty(fieldName, null);
  }, [updateProperty]);

  if (theme) {
    theme = {
      ...theme,
      label: translateTheme(theme.label)
    };
  }

  if (!id) return null;

  return (
    <form autoComplete="off" id="moduleForm" name="moduleForm">
      <FormHeader
        subtitle="forms.module.modifyModuleData"
        title="forms.module.title"
      />

      <SwitchVisibility
        name="isPublic"
        value={isPublic}
        onChange={updateProperty}
      />

      {!isPublic && (
        <SwitchVisibility
          iconNo={faUsersSlash}
          iconYes={faUsersClass}
          name="canSubscribe"
          textNo="common.cannotSubscribe"
          textYes="common.canSubscribe"
          value={!!canSubscribe}
          onChange={updateProperty}
        />
      )}

      <SwitchVisibility
        iconNo={faBookAlt}
        iconYes={faBook}
        name="isIndependent"
        textNo="common.isNotIndependentModule"
        textYes="common.isIndependentModule"
        value={!!isIndependent}
        onChange={updateProperty}
      />

      <InputField
        label={translate('common.title')}
        name="name"
        required
        value={name}
        onChange={updateProperty}
      />
      <SelectDocument
        defaultDocument={logo}
        label={translate('forms.module.moduleLogo')}
        name="logoURL"
        onDelete={handleImageDelete}
        onSelect={handleImageUpload}
      />
      <InputField
        label={translate('common.code')}
        name="code"
        required
        value={code}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.reference')}
        name="reference"
        required
        value={reference}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.description')}
        minRows={3}
        multiline
        name="description"
        value={description}
        onChange={updateProperty}
      />

      {!isPublic && (
        <ButtonDisplaySession label="button.administrateSessions" moduleId={id} style={{ margin: '1rem 0' }} />
      )}

      <SelectField
        label="common.theme"
        name="theme"
        options={themes}
        required
        value={theme}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('MODALITY').multiple}
        label="referenceData.MODALITY"
        name="modality"
        options={getRefData('MODALITY').values}
        required
        value={modality}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('TRAINING_CATEGORY').multiple}
        label="referenceData.TRAINING_CATEGORY"
        name="trainingCategory"
        options={getRefData('TRAINING_CATEGORY').values}
        required
        value={trainingCategory}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('TRAINING_TYPE').multiple}
        label="referenceData.TRAINING_TYPE"
        name="trainingType"
        options={getRefData('TRAINING_TYPE').values}
        required
        value={trainingType}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('DOMAIN').multiple}
        label="referenceData.DOMAIN"
        name="domain"
        options={getRefData('DOMAIN').values}
        required
        value={domain}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.prerequisites')}
        name="prerequisites"
        value={prerequisites}
        onChange={updateProperty}
      />
      <InputField
        InputProps={{
          inputProps: { min: 0 },
          endAdornment: (
            <InputAdornment position="end">{translate('common.days')}</InputAdornment>
          )
        }}
        label={translate('common.numberOfDays')}
        name="numberOfDays"
        required
        type="number"
        value={numberOfDays}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.additionalDurationInformation')}
        name="additionalDurationInformation"
        value={additionalDurationInformation}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('KEYWORD').multiple}
        label="referenceData.KEYWORD"
        name="keywords"
        options={getRefData('KEYWORD').values}
        required
        value={keywords}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.objectives')}
        name="objectives"
        value={objectives}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.professionalBenefits')}
        name="professionalBenefits"
        required
        value={professionalBenefits}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('LOCATIONS').multiple}
        label="referenceData.LOCATIONS"
        name="locations"
        options={getRefData('LOCATION').values}
        required
        value={locations}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('INTRA_INTER').multiple}
        label="referenceData.INTRA_INTER"
        name="intraInter"
        options={getRefData('INTRA_INTER').values}
        required
        value={intraInter}
        onChange={updateProperty}
      />
      <InputField
        inputProps={{ min: 0 }}
        label={translate('common.fareCodeForIntra')}
        name="fareCodeForIntra"
        required
        value={fareCodeForIntra}
        onChange={updateProperty}
      />
      <InputField
        inputProps={{ min: 0 }}
        label={translate('common.fareCodeForInter')}
        name="fareCodeForInter"
        required
        value={fareCodeForInter}
        onChange={updateProperty}
      />
      <InputField
        label={translate('common.pedagogicalResources')}
        name="pedagogicalResources"
        required
        value={pedagogicalResources}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('HANDICAP').multiple}
        label="referenceData.HANDICAP"
        name="handicaps"
        options={getRefData('HANDICAP').values}
        value={handicaps}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('ACKNOWLEDGEMENT').multiple}
        label="referenceData.ACKNOWLEDGEMENT"
        name="acknowledgements"
        options={getRefData('ACKNOWLEDGEMENT').values}
        required
        value={acknowledgements}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('REVIEW_TYPE').multiple}
        label="referenceData.REVIEW_TYPE"
        name="reviewTypes"
        options={getRefData('REVIEW_TYPE').values}
        required
        value={reviewTypes}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('PUBLIC_TYPE').multiple}
        label="referenceData.PUBLIC_TYPE"
        name="publicTypes"
        options={getRefData('PUBLIC_TYPE').values}
        required
        value={publicTypes}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('PUBLIC_LEVEL').multiple}
        label="referenceData.PUBLIC_LEVEL"
        name="publicLevels"
        options={getRefData('PUBLIC_LEVEL').values}
        required
        value={publicLevels}
        onChange={updateProperty}
      />
      <SelectField
        isMulti={getRefData('PUBLIC_EXPERIENCE').multiple}
        label="referenceData.PUBLIC_EXPERIENCE"
        name="publicExperiences"
        options={getRefData('PUBLIC_EXPERIENCE').values}
        required
        value={publicExperiences}
        onChange={updateProperty}
      />
      {/* <SelectField
        isMulti={getRefData('LANGUAGE').multiple}
        label="referenceData.LANGUAGE"
        name="language"
        options={getRefData('LANGUAGE').values}
        value={language}
        onChange={updateProperty}
      /> */}

      {/* Addionnal div to have some space at the end to allow the last selects to open correctly */}
      <div style={{ height: '100px' }} />
    </form>
  );
});

export default asForm(ModuleForm);

ModuleForm.propTypes = {
  formData: sequenceModel.isRequired,
  updateProperty: PropTypes.func
};