import { Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { useStores } from 'hooks';
import { QUIZ_STATUS } from '../QuizHelper';

const QuizPage = observer(({ currentPageData, quizStatus, children }) => {
  const { quizStore } = useStores();

  const { currentPage } = quizStore;

  const { name, index } = currentPageData || currentPage;

  const { running, ended, displayingResults } = QUIZ_STATUS;

  const [pageNumber, setPageNumber] = useState(0);
  const [showPageInfos, setShowPageInfos] = useState(false);

  const toggleIsQuizRunning = useCallback(() => {
    if (quizStatus === running) {
      setShowPageInfos(false);
    }
    if (quizStatus === ended || quizStatus === displayingResults) {
      setShowPageInfos(true);
    }
  }, [quizStatus, running, ended, displayingResults]);

  useEffect(() => {
    toggleIsQuizRunning();
  }, [toggleIsQuizRunning]);

  const computePageNumber = useCallback(() => {
    setPageNumber(index + 1);
  }, [index]);

  useEffect(() => {
    computePageNumber();
  }, [computePageNumber]);

  return (
    <Grid container direction="column">
      {showPageInfos && (
        <Grid container>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              style={{
                margin: '30px 0px',
                display: 'flex',
                justifyContent: 'center',
                padding: 20,
                backgroundColor: 'var(--grey-lighter)'
              }}
            >
              <Typography style={{ color: 'var(--grey-dark)' }} variant="h5">
                {translate('forms.element.quiz.pageTitleAndNumber', { name, pageNumber })}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid container direction="column" spacing={2}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default QuizPage;

QuizPage.propTypes = {
  children: PropTypes.node,
  currentPageData: PropTypes.shape({}),
  quizStatus: PropTypes.string.isRequired
};

QuizPage.defaultProps = {
  children: null,
  currentPageData: null
};