const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

const resizeImage = (reader) => new Promise((resolve) => {
  const img = document.createElement('img');
  img.src = reader.result;
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // eslint-disable-next-line prefer-destructuring
    let width = img.width;
    // eslint-disable-next-line prefer-destructuring
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;
    const ctxx = canvas.getContext('2d');
    ctxx.drawImage(img, 0, 0, width, height);

    resolve(canvas.toDataURL());
  };
});

const handleFileUpload = (event, needResize, maxSize) => new Promise((resolve, reject) => {
  const allFiles = event.target.files;
  if (allFiles) {
    const readerOnLoad = async (readerLoaded, currentFileLoaded) => {
      const fileUploaded = {
        name: currentFileLoaded.name,
        base64Content: readerLoaded.result
      };

      if (!needResize) return resolve(fileUploaded);

      const imgResized = await resizeImage(readerLoaded);

      return resolve({
        ...fileUploaded,
        base64Content: imgResized
      });
    };

    const reader = new FileReader();
    const currentFile = allFiles[0];

    if (currentFile.size <= maxSize || needResize) {
      reader.readAsDataURL(currentFile);
      reader.onload = () => readerOnLoad(reader, currentFile);
    } else {
      reject(Error('errors.documentTooBig'));
    }
  }
});

function getDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', (e) => resolve(e.target.result));
    reader.addEventListener('error', reject);

    reader.readAsDataURL(file);
  });
}

async function getBase64(file) {
  const data = await getDataURL(file);
  return data.split(',')[1];
}

const getDocumentWithBase64 = (base64Content) => (
  `data:image/png;base64,${base64Content}`
);

const getPDFWithBase64 = (base64Content) => (
  `data:application/pdf;base64,${base64Content}`
);

const getExcelWithBase64 = (base64Content) => (
  `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Content}`
);

const getZipWithBase64 = (base64Content) => (
  `data:application/zip;base64,${base64Content}`
);

const getDocumentWithoutBase64 = (document) => (
  document.base64Content.split(',')[1]
);

export const DocumentHelper = {
  handleFileUpload,
  resizeImage,
  getDocumentWithBase64,
  getDocumentWithoutBase64,
  getPDFWithBase64,
  getExcelWithBase64,
  getZipWithBase64,
  getBase64
};