import {
  faEdit, faEye, faImage, faLink
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  IconButton, TableCell
} from '@material-ui/core';
import {
  GenericFilters, GenericTable, PageHeader, SkeletonTable,
  TextError, Wrapper
} from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import shortid from 'shortid';
import { transformObjectListToStringQueriesParam } from 'utils';
import {
  API_URL_DOCUMENTS, APP_URL, LIST_SIZE, ROUTES
} from 'utils/constants';
import { translate } from 'utils/translation';
import { ModuleListFilters } from './ModuleListFilters';

const getModuleListHeaders = (handleEditModule, handleCreateLink) => ([
  {
    name: 'logo',
    label: 'common.logo',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.logoURL ? (
          <img
            alt={row.name}
            height="60px"
            src={`${API_URL_DOCUMENTS}${row.logoURL}`}
          />
        ) : <FontAwesomeIcon icon={faImage} />}
      </TableCell>
    )
  }, {
    name: 'name',
    label: 'common.name',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.name}
      </TableCell>
    )
  }, {
    name: 'code',
    label: 'common.code',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.code}
      </TableCell>
    )
  }, {
    name: 'reference',
    label: 'common.reference',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.reference}
      </TableCell>
    )
  },
  {
    name: 'quizActions',
    label: 'common.actions',
    width: '306px',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>

          <Grid item>
            <Link to={ROUTES.MODULE(row.id)}>
              <IconButton edge="end">
                <FontAwesomeIcon icon={faEye} size="xs" />
              </IconButton>
            </Link>
          </Grid>

          <Grid item>
            <IconButton color="primary" edge="end" onClick={(e) => { e.stopPropagation(); handleEditModule(row); }}>
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </IconButton>
          </Grid>

          {row.isIndependent && (
            <Grid item>
              <IconButton color="primary" edge="end" onClick={(e) => { e.stopPropagation(); handleCreateLink(row); }}>
                <FontAwesomeIcon icon={faLink} size="xs" />
              </IconButton>
            </Grid>
          )}

        </Grid>
      </TableCell>
    )
  }
]);

export const ModuleList = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const { moduleStore } = useStores();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const history = useHistory();

  const { maxPage, totalElements } = moduleStore;

  const filterKey = 'moduleList';

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  useEffect(() => {
    const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);
    moduleStore.loadModuleList({
      search, size: LIST_SIZE, filters: filtersQueriesParams, currentPage
    });
  }, [moduleStore, currentPage, search, filters]);

  const loadMore = useCallback(() => {
    if (currentPage <= maxPage - 1) {
      return !moduleStore.isLoading && setCurrentPage(currentPage + 1);
    }
    return null;
  }, [moduleStore.isLoading, currentPage, maxPage]);

  const handleEditModule = useCallback((module) => {
    history.push({
      pathname: ROUTES.MODULE(module.id),
      state: {
        isEditionMode: true
      }
    });
  }, [history]);

  const handleCreateLink = useCallback((module) => {
    const text = `${APP_URL}modules/${module.id}`;
    if (!navigator.clipboard) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (err) {
        //
      }
      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar(translate('confirms.linkCopied'), { variant: 'success' });
    });
  }, [enqueueSnackbar]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <ModuleListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  return (
    <>
      <Wrapper>
        <PageHeader title="pageModuleList.title" />

        <GenericFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-module-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setCurrentPage={setCurrentPage}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltip="pageModuleList.searchTooltip"
          withDrawer
        />

        {(!moduleStore.isLoading && !moduleStore.isError && moduleStore.moduleList.length === 0) && (
          <TextError data-testid="errors.noModule">{translate('errors.noModule')}</TextError>
        )}

        {(!moduleStore.isLoading && moduleStore.isError) && (
          <TextError data-testid="errors.moduleListFailed">{translate('errors.moduleListFailed')}</TextError>
        )}

        {moduleStore.isLoading ? <SkeletonTable /> : moduleStore.moduleList.length > 0 && (
          <div data-testid="module-list">
            <GenericTable
              hasMore={currentPage < maxPage}
              headers={getModuleListHeaders(handleEditModule, handleCreateLink)}
              id={shortid.generate()}
              loadMore={loadMore}
              rows={moduleStore.moduleList}
              total={totalElements}
            />
          </div>
        )}
      </Wrapper>
    </>
  );
});