import { Grid } from '@material-ui/core';
import { getFormat } from 'components/Templates/sections/GenericSection/GenericSectionFormats';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import logger from 'utils/logger';
import { SectionRadio } from '..';

const SectionElement = styled.div`
  border: 2px solid var(--border-primary-color);
  border-radius: 5px;
  padding: 2rem;
  width: 100%;
  height: 100%;
`;

export const GenericSectionFormatRadio = ({
  name, value, isChecked, onChange
}) => {
  let format;
  try {
    format = getFormat(value);
  } catch (error) {
    logger.error(error);
  }


  return (
    <>
      {format
        && (
          <SectionRadio
            ariaLabel={value}
            height="8rem"
            isChecked={isChecked}
            margin="1rem 0"
            name={name}
            padding="1rem"
            value={value}
            onChange={onChange}
          >
            <Grid container spacing={2}>
              {[...Array(format.columns)].map((_, i) => (
                <Grid
                  item
                  key={shortid.generate()}
                  xs={format.getColumnFormat(i, 'sm')}
                >
                  <SectionElement />
                </Grid>
              ))}
            </Grid>
          </SectionRadio>
        )}
    </>
  );
};