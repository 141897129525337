import { EditableItem, IconButton } from 'components';
import { observer } from 'mobx-react-lite';
import PropTypes, { arrayOf } from 'prop-types';
import styled from 'styled-components';
import React, { useCallback } from 'react';
import { moduleModel, programModel, sectionModel } from 'components/model/dataformModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'utils/translation';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from '@material-ui/core';
import { useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { runInAction } from 'mobx';
import { DocumentSection } from '../DocumentSection';
import { GenericSection } from '../GenericSection';
import { ProgramHeader } from '../ProgramHeader';
import { ModuleHeader } from '../ModuleHeader';
import { QuizSection } from '../QuizSection';

const DisablingBlock = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 20;
`;

const ButtonDeleteSectionContainer = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 80;
`;

const ButtonDeleteSection = ({
  handleDeleteSection, section, ariaLabel, title
}) => (
  <Tooltip aria-label={translate(ariaLabel)} title={translate(title)}>
    <ButtonDeleteSectionContainer>
      <IconButton
        aria-label={translate('button.delete')}
        type="danger"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={(e) => { e.stopPropagation(); handleDeleteSection(section); }}
      >
        <FontAwesomeIcon icon={faTrashAlt} size="xs" />
      </IconButton>
    </ButtonDeleteSectionContainer>
  </Tooltip>
);

export const ParentSection = observer(({
  section, sections, editionMode, canDeleteSection
}) => {
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { editionStore } = useStores();

  const renderSpecificSection = useCallback((editableHtmlId) => {
    switch (section.sectionType) {
      case 'GENERIC_SECTION':
        return <GenericSection editableHtmlId={editableHtmlId} editionMode={editionMode} section={section} />;
      case 'DOCUMENT_SECTION':
        return <DocumentSection editableHtmlId={editableHtmlId} editionMode={editionMode} section={section} />;
      case 'PROGRAM_HEADER':
        return <ProgramHeader editionMode={editionMode} section={section} />;
      case 'MODULE_HEADER':
        return <ModuleHeader editionMode={editionMode} section={section} />;
      case 'QUIZ_SECTION':
        return <QuizSection editableHtmlId={editableHtmlId} editionMode={editionMode} section={section} />;
      default:
        return <div />;
    }
  }, [section, editionMode]);

  const showWarningModalToDeleteSection = useCallback((action, title, text) => showModal({
    type: 'WARNING',
    title,
    text,
    onConfirm: action
  }), [showModal]);

  const handleDeleteSection = useCallback(() => showWarningModalToDeleteSection(() => {
    const selectedSectionIndex = sections.indexOf(section);

    if (selectedSectionIndex > -1) {
      runInAction('Delete section', () => {
        sections.splice(selectedSectionIndex, 1);
        editionStore.setLastSaved(false);
      });
    } else {
      enqueueSnackbar(translate('errors.failedRemoveSection'), { variant: 'error' });
    }
  },
  'forms.section.titleDeleteSectionWarning',
  'forms.section.messageDeleteSectionWarning'),
  [showWarningModalToDeleteSection, sections, section, enqueueSnackbar, editionStore]);

  const renderSpecificSectionWithDisablingLayer = useCallback(() => (
    <>
      {canDeleteSection && editionMode && (
        <ButtonDeleteSection
          ariaLabel="forms.section.titleDeleteSectionWarning"
          handleDeleteSection={handleDeleteSection}
          section={section}
          title="forms.section.deleteSelectedSection"
        />
      ) }
      {renderSpecificSection()}
      {editionMode && <DisablingBlock />}
    </>
  ), [editionMode, handleDeleteSection, canDeleteSection, renderSpecificSection, section]);

  return (
    <>
      <EditableItem
        editionMode={editionMode}
        formData={section}
        index={section.index}
        padding="2rem"
        parentHtmlId=""
        render={renderSpecificSectionWithDisablingLayer}
        type="section"
      />
    </>
  );
});

ParentSection.propTypes = {
  editionMode: PropTypes.bool,
  canDeleteSection: PropTypes.bool,
  section: sectionModel.isRequired,
  sections: arrayOf(sectionModel),
  program: programModel,
  module: moduleModel
};

ParentSection.defaultProps = {
  canDeleteSection: false,
  editionMode: false,
  module: {},
  program: {},
  sections: []
};