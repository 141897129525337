import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide } from '@material-ui/core';
import { CookiesModal } from './CookiesModal';
import { SkeletonMain } from '..';

const ChangeLanguageModal = lazy(() => import('./ChangeLanguageModal'));
const ConfirmModal = lazy(() => import('./ConfirmModal'));
const DisplayPDFModal = lazy(() => import('./DisplayPDFModal'));
const DocumentModal = lazy(() => import('./DocumentModal'));
const ErrorModal = lazy(() => import('./ErrorModal'));
const ImportFileModal = lazy(() => import('./ImportFileModal'));
const SessionModal = lazy(() => import('./SessionModal'));
const SubscribeTraineesModal = lazy(() => import('./SubscribeTraineesModal'));
const UpdateSubscriptionModal = lazy(() => import('./UpdateSubscriptionModal'));
const WarningModal = lazy(() => import('./WarningModal'));
const BrowserPopupModal = lazy(() => import('./BrowserPopupModal'));
const QuizLoginModal = lazy(() => import('./QuizLoginModal'));
const QuizCertifyingWarningModal = lazy(() => import('./QuizCertifyingWarningModal'));
const QuizTimeOutModal = lazy(() => import('./QuizTimeOutModal'));

const MODAL_TYPES = {
  AUTHENTICATE_TRAINEE: QuizLoginModal,
  CHANGE_LANGUAGE: ChangeLanguageModal,
  CONFIRM: ConfirmModal,
  ERROR: ErrorModal,
  WARNING: WarningModal,
  CREATE_DOCUMENT: DocumentModal,
  DISPLAY_PDF: DisplayPDFModal,
  DISPLAY_SESSIONS: SessionModal,
  BROWSER_POPUP: BrowserPopupModal,
  IMPORT_FILE: ImportFileModal,
  SUBSCRIBE_TRAINEES: SubscribeTraineesModal,
  QUIZ_TIME_OUT: QuizTimeOutModal,
  TRAINEE_CONFIRMATION_CERTIFYING_QUIZ: QuizCertifyingWarningModal,
  UPDATE_SUBSCRIPTION: UpdateSubscriptionModal,
  COOKIES_MODAL: CookiesModal
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export const Modal = ({
  onClose, onSubmit, open, type, disableClose, fullscreen, ...rest
}) => {
  const CurrentModal = MODAL_TYPES[type];
  const isFullscreen = fullscreen || document.getElementsByTagName('body')[0].clientWidth <= 768;

  if (!CurrentModal) return null;

  return (
    <Dialog
      disableEscapeKeyDown={disableClose}
      fullScreen={isFullscreen}
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      transitionDuration={300}
      // eslint-disable-next-line react/jsx-no-bind
      onClose={(event, reason) => {
        // disableBackdropClick is now deprecated, onCLose reason needs to be used
        if (disableClose && reason === 'backdropClick') {
          return null;
        }
        return onClose(event, reason);
      }}
    >
      <Suspense fallback={SkeletonMain()}>
        <CurrentModal fullscreen={fullscreen} onClose={onClose} onSubmit={onSubmit} {...rest} />
      </Suspense>
    </Dialog>
  );
};

Modal.propTypes = {
  disableClose: PropTypes.bool,
  fullscreen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string
};

Modal.defaultProps = {
  disableClose: true,
  fullscreen: false,
  onClose: null,
  onSubmit: null,
  open: false,
  type: 'CONFIRM'
};