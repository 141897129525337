import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { StyledListItem } from 'components/forms/inputs';
import {
  Avatar, Grid, ListItemAvatar, ListItemText, Paper
} from '@material-ui/core';
import { API_URL_DOCUMENTS, REF_DATA_MAP } from 'utils/constants';
import { translate } from 'utils/translation';
import { DocumentService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import shortid from 'shortid';
import { useStores } from 'hooks';
import debounce from 'debounce-promise';
import { DocumentHelper, getSmallDescription } from 'utils/helpers';
import { useSnackbar } from 'notistack';
import { FormElement, IconButton, InfoLine } from '..';

const IMAGE_DATA_DISPLAYED = [
  'author', 'code', 'accessRights', 'copyright'
];

const SelectDocumentContainer = styled.section`
  margin: 1rem 0;
`;

const DocumentSelectedContainer = styled.article`
  padding-left: 1rem;
  border-left: 4px solid var(--disabled-color);
`;

const CustomOptionDocument = ({ data, innerProps }) => (
  <StyledListItem {...innerProps}>
    {data.logoURL && (
      <ListItemAvatar>
        <Avatar src={`${API_URL_DOCUMENTS}${data.logoURL}`} />
      </ListItemAvatar>
    )}
    {data.previewContent && (
      <ListItemAvatar>
        <Avatar src={DocumentHelper.getDocumentWithBase64(data.previewContent)} />
      </ListItemAvatar>
    )}
    <ListItemText
      primary={data.name}
      primaryTypographyProps={{ noWrap: true }}
      secondary={getSmallDescription(data.description)}
    />
  </StyledListItem>
);

const DocumentSelected = ({ handleDelete, contentType, ...props }) => {
  const { referenceDataStore } = useStores();
  const transformValue = useCallback((key, value) => {
    if (value) {
      switch (key) {
        case 'accessRights':
        case 'copyright': {
          const refData = referenceDataStore.getRefData(REF_DATA_MAP[key]);
          if (refData.values && refData.values.find((refD) => refD.value === value)) {
            return refData.values.find((refD) => refD.value === value).label;
          }
          return translate('common.none');
        }
        default:
          return value;
      }
    }

    return value;
  }, [referenceDataStore]);

  return (
    <DocumentSelectedContainer>
      <Paper>
        <Grid alignItems="center" container wrap="nowrap">
          <Grid item sm={10}>
            <CustomOptionDocument data={props} />
          </Grid>
          <Grid item sm={2}>
            <Grid container justifyContent="flex-end">
              <IconButton
                aria-label={translate('button.delete')}
                type="danger"
                onClick={handleDelete}
              >
                <FontAwesomeIcon icon={faTrashAlt} size="xs" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <div style={{ marginTop: '1rem' }}>
        {Object.entries(props)
          .filter(([key]) => IMAGE_DATA_DISPLAYED.includes(key))
          .map(([key, value]) => (
            <InfoLine info={transformValue(key, value)} key={shortid.generate()} label={`common.${key}`} />
          ))}
      </div>
    </DocumentSelectedContainer>
  );
};

const SelectDocument = ({
  name, onSelect, onDelete, contentType, label, defaultDocument
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [documentSelected, setDocumentSelected] = useState(defaultDocument);

  useEffect(() => {
    setDocumentSelected(defaultDocument);
  }, [defaultDocument]);

  const handleChange = useCallback((value) => {
    if (value) {
      setDocumentSelected(value);
      onSelect(name, `${value.id}`, value);
    }
  }, [onSelect, name, setDocumentSelected]);

  const getAsyncOptions = useCallback((inputValue) => new Promise((resolve) => {
    DocumentService.findDocuments({
      search: inputValue, page: 0, size: 10, filters: `&contentType=${contentType}`
    })
      .then((response) => {
        const documents = response.content.sort((a, b) => {
          if (b.creationDate < a.creationDate) { return -1; }
          return 0;
        });
        resolve(documents);
      })
      .catch(() => enqueueSnackbar(translate('errors.documentListFailed'), { variant: 'error' }));
  }), [contentType, enqueueSnackbar]);

  const handleDelete = useCallback(() => {
    setDocumentSelected(null);
    onDelete(name);
  }, [onDelete, name]);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const displayNoOptions = useCallback(() => translate('common.noOptions'), []);

  const getLabel = useCallback(() => label || translate('forms.selectImage'), [label]);

  return (
    <SelectDocumentContainer>
      <FormElement data-testid={label || 'forms.selectImage'} label={getLabel()}>
        {!documentSelected ? (
          <AsyncSelect
            cacheOptions
            components={{ Option: CustomOptionDocument }}
            defaultOptions
            loadingMessage={() => translate('common.loading')}
            loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
            name={name}
            noOptionsMessage={displayNoOptions}
            placeholder={translate('common.search')}
            styles={{ menu: (base) => ({ ...base, zIndex: 2000 }) }}
            onChange={handleChange}
          />
        ) : (
          <DocumentSelected handleDelete={handleDelete} {...documentSelected} />
        )}
      </FormElement>
    </SelectDocumentContainer>
  );
};

SelectDocument.propTypes = {
  defaultDocument: PropTypes.shape({}),
  contentType: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

SelectDocument.defaultProps = {
  contentType: 'IMAGE',
  defaultDocument: null,
  label: ''
};

export default SelectDocument;