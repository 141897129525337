import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { BlueBlock, ParentElement } from 'components';
import { elementModel } from 'components/model/dataformModel';
import { useStores } from 'hooks';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { translate } from 'utils/translation';
import { SectionContent, SectionTitle } from '../_commons';

const MAX_NUMBER_OF_QUIZ = 1;

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["section"] }] */
export const QuizSection = observer(({ section, editionMode, editableHtmlId }) => {
  const { title, elements } = section;

  const { editionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const getNewQuizElementId = useCallback((index) => `${editableHtmlId}_element_${index}`, [editableHtmlId]);

  const deleteQuiz = useCallback((index) => () => {
    section.elements = section.elements.filter((element) => element.index !== index);
    enqueueSnackbar(translate('forms.element.quiz.quizDeleted'), { variant: 'success' });
    editionStore.setLastSaved(false);
  }, [section.elements, enqueueSnackbar, editionStore]);

  const editQuiz = useCallback((quiz) => {
    editionStore.setElementEdited(getNewQuizElementId(quiz.index), quiz, 'element', deleteQuiz);
    editionStore.setLastSaved(false);
  }, [editionStore, getNewQuizElementId, deleteQuiz]);

  const getDefaultQuiz = (i) => ({
    index: i,
    elementType: 'QUIZ_ELEMENT',
    element: {
      id: '',
      title: '',
      description: '',
      questions: []
    }
  });

  const addQuiz = useCallback((event) => {
    event.stopPropagation();

    const lastIndex = elements.length > 0 ? [elements.length - 1].index : 0;
    runInAction('AddQuizFromQuizSection', () => {
      elements.push(getDefaultQuiz(lastIndex + 1));
      editQuiz(elements[elements.length - 1]);
    });
  }, [elements, editQuiz]);

  return (
    <section>
      <SectionTitle title={title} />
      <SectionContent alignItems="center">
        {elements.map((element) => (
          <Grid item key={element.index} style={{ width: '100%' }}>
            <ParentElement
              editionMode={editionMode}
              element={element}
              parentHtmlId={editableHtmlId}
              onDelete={deleteQuiz(element.index)}
            />
          </Grid>
        ))}
        {editionMode && elements.length < MAX_NUMBER_OF_QUIZ && (
          <Grid item>
            <BlueBlock
              color="var(--primary-color)"
              colored
              fontWeight="bold"
              margin="0"
              role="button"
              style={{ zIndex: 30, width: '100px' }}
              textAlign="center"
              onClick={addQuiz}
            >
              <Grid alignItems="center" container direction="column" justifyContent="center">
                <FontAwesomeIcon icon={faPlusCircle} size="2x" style={{ marginBottom: '1rem' }} />
                {translate('button.addQuiz')}
              </Grid>
            </BlueBlock>
          </Grid>
        )}
      </SectionContent>
    </section>
  );
});

QuizSection.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    elements: PropTypes.arrayOf(elementModel)
  }).isRequired,
  editionMode: PropTypes.bool.isRequired,
  editableHtmlId: PropTypes.string
};

QuizSection.defaultProps = {
  editableHtmlId: ''
};