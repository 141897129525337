import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';


export const SkeletonCardProgram = () => (
  <Skeleton
    height={320}
    style={{
      flex: '1',
      borderRadius: '4px',
      border: '1px solid rgb(213,225,239)'
    }}
    variant="rect"
  >
    <Grid container direction="column">
      <Grid item>
        <Skeleton height={120} variant="rect" />
      </Grid>
      <Grid item style={{ marginTop: '-50px', padding: '1.6rem' }}>
        <Grid container spacing={2}>
          <Grid item>
            <Skeleton height={100} variant="circle" width={100} />
          </Grid>
          <Grid item>
            <Skeleton height={30} style={{ marginBottom: '1rem' }} />
            <Skeleton height={20} width={100} />
            <Skeleton height={20} width={100} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ padding: '0 1.6rem' }}>
        <Skeleton height={70} />
      </Grid>
      <Grid item style={{ padding: '0 1.6rem' }}>
        <Skeleton height={36} variant="rect" width={250} />
      </Grid>
    </Grid>
  </Skeleton>
);