/**
 * asForm Higher Order Component
 */
import { useStores } from 'hooks/useStores';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';

/* eslint no-param-reassign: ["error", { "props": false }] */
export const asForm = (MyComponent) => observer(({ formData, onChange, ...props }) => {
  const { editionStore } = useStores();
  const updateProperty = useCallback((key, value) => {
    if (onChange) {
      onChange(value);
    }
    editionStore.setLastSaved(false);
    runInAction('editFormModel', () => {
      formData[key] = value;
    });
  }, [editionStore, formData, onChange]);

  return <MyComponent autoComplete="off" {...props} formData={formData} updateProperty={updateProperty} />;
});