import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';


const StyledEditionContent = styled(Grid)`
  ${(props) => props.edition === 'true' && css`
    height: calc(100vh - var(--header-with-edition-bar-height) - var(--showMore-height));
    overflow-y: auto;
  `}
`;


export const EditionContent = ({
  children, elementId, editionMode, onClick
}) => (
  <StyledEditionContent
    edition={editionMode.toString()}
    id={elementId}
    item
    role="button"
    xs={editionMode ? 9 : 12}
    onClick={onClick}
  >
    {children}
  </StyledEditionContent>
);


EditionContent.propTypes = {
  elementId: PropTypes.string.isRequired,
  editionMode: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

EditionContent.defaultProps = {
  onClick: null
};