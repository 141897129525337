import { observer } from 'mobx-react-lite';
import React from 'react';
import PropTypes from 'prop-types';
import { EDITION_PANEL_TYPES } from 'utils/constants';
import { ProgramForm } from '../ProgramForm';
import { ModuleForm } from '../ModuleForm';
import { SequenceForm } from '../SequenceForm';
import { ParentElementForm, ParentSectionForm } from '../templateForms';

const ParentForm = observer(({ data, type }) => {
  const renderSpecificForm = () => {
    switch (type) {
    case EDITION_PANEL_TYPES.PROGRAM:
      return <ProgramForm formData={data} />;
    case EDITION_PANEL_TYPES.MODULE:
      return <ModuleForm formData={data} />;
    case EDITION_PANEL_TYPES.SEQUENCE:
      return <SequenceForm formData={data} />;
    case EDITION_PANEL_TYPES.SECTION:
      return <ParentSectionForm formData={data} />;
    case EDITION_PANEL_TYPES.ELEMENT:
      return <ParentElementForm formData={data} />;
    default:
      return '';
    }
  };

  return (
    <section>
      {renderSpecificForm()}
    </section>
  );
});

ParentForm.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.string.isRequired
};

export default ParentForm;