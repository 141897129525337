import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, ListItemText, Paper } from '@material-ui/core';
import { StyledListItem } from 'components/forms/inputs';
import debounce from 'debounce-promise';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { QuizService } from 'services';
import styled from 'styled-components';
import { translate } from 'utils/translation';
import { getSmallDescription } from 'utils/helpers';

import { FormElement, IconButton } from '..';

const SelectQuizContainer = styled.section`
  margin: 1rem 0;
`;

const CustomOptionQuiz = ({ data, innerProps }) => (
  <StyledListItem {...innerProps}>
    <ListItemText
      primary={`${data.title}`}
      secondary={getSmallDescription(data.description)}
    />
  </StyledListItem>
);

const QuizSelected = ({ handleDelete, ...props }) => (
  <Paper>
    <Grid alignItems="center" container wrap="nowrap">
      <Grid item sm={10}>
        <CustomOptionQuiz data={props} />
      </Grid>
      <Grid item sm={2}>
        <Grid container justifyContent="flex-end">
          <IconButton
            aria-label={translate('button.delete')}
            type="danger"
            onClick={handleDelete}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="xs" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);
const SelectQuiz = ({
  name, onSelect, onDelete, label, defaultQuiz
}) => {
  const [quizSelected, setQuizSelected] = useState(defaultQuiz);

  useEffect(() => {
    setQuizSelected(defaultQuiz);
  }, [defaultQuiz]);

  const handleChange = useCallback((value) => {
    if (value) {
      QuizService.getQuiz(value.id)
        .then((response) => {
          setQuizSelected(response);
          onSelect(response);
        });
    }
  }, [onSelect]);

  const getAsyncOptions = useCallback((inputValue) => new Promise((resolve) => {
    QuizService.getQuizList({ freeSearch: inputValue, page: 0 })
      .then((response) => resolve(response.content));
  }), []);

  const handleDelete = useCallback(() => {
    setQuizSelected(null);
    onDelete();
  }, [onDelete]);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const displayNoOptions = useCallback(() => translate('common.noOptions'), []);

  const getLabel = useCallback(() => label || translate('forms.selectImage'), [label]);

  return (
    <SelectQuizContainer>
      <FormElement data-testid={label || 'forms.selectImage'} label={getLabel()}>
        {!quizSelected ? (
          <AsyncSelect
            cacheOptions
            components={{ Option: CustomOptionQuiz }}
            defaultOptions
            // eslint-disable-next-line react/jsx-no-bind
            loadingMessage={() => translate('common.loading')}
            // eslint-disable-next-line react/jsx-no-bind
            loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
            name={name}
            noOptionsMessage={displayNoOptions}
            placeholder={translate('common.search')}
            styles={{ menu: (base) => ({ ...base, zIndex: 2000 }) }}
            value={quizSelected}
            onChange={handleChange}
          />
        ) : (
          <QuizSelected handleDelete={handleDelete} {...quizSelected} />
        )}
      </FormElement>
    </SelectQuizContainer>
  );
};

SelectQuiz.propTypes = {
  defaultQuiz: PropTypes.shape({}),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

SelectQuiz.defaultProps = {
  defaultQuiz: null,
  label: ''
};

export default SelectQuiz;