import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils/translation';
import { useStores } from 'hooks';
import { TextField } from '@material-ui/core';

import { StorageHelper } from 'utils/helpers';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { buildDefaultAndTraineeAnswers, QUESTION_TYPES_FROM_API } from '../../QuizHelper';

const PhoneQuestion = observer(({ currentQuestionData, withCorrection, isReadOnlyQuestion }) => {
  const { phoneInput } = QUESTION_TYPES_FROM_API;

  const { quizStore } = useStores();

  const { currentPage } = quizStore;

  const [currentValue, setCurrentValue] = useState('');
  const [isValidPNumber, setIsValidPNumber] = useState(false);
  const [error, setError] = useState('');

  const buildAnswers = useCallback(() => {
    setCurrentValue(buildDefaultAndTraineeAnswers(currentQuestionData));
  }, [currentQuestionData]);

  useEffect(() => {
    buildAnswers();
  }, [buildAnswers]);

  const handleChangeAnswer = useCallback((event) => {
    const { value } = event.target;
    let currentLanguage;
    const currentLocale = StorageHelper.GET('i18nextLng');

    if (currentLocale.includes('_')) {
      // eslint-disable-next-line prefer-destructuring
      currentLanguage = StorageHelper.GET('i18nextLng').toUpperCase().split('_')[1];
    } else {
      currentLanguage = StorageHelper.GET('i18nextLng').toUpperCase();
    }

    setIsValidPNumber(isValidPhoneNumber(value, currentLanguage));
    isValidPhoneNumber(value, currentLanguage) ? setError('') : setError(translate('errors.phoneNumberError'));
    setCurrentValue(value);

    quizStore.toggleQuestionValidity({ questionType: phoneInput, status: isValidPhoneNumber(value, currentLanguage) });
    quizStore.setTraineeAnswers(currentPage, currentQuestionData, value);
  }, [currentPage, currentQuestionData, quizStore, phoneInput]);

  return (
    <>
      <TextField
        autoComplete="off"
        disabled={isReadOnlyQuestion}
        error={!isValidPNumber && error.length > 0}
        fullWidth
        helperText={error}
        name="textAnswer"
        placeholder={translate('common.writePhoneNumber')}
        required
        style={{ margin: '10px 0px' }}
        value={currentValue}
        variant="outlined"
        onChange={handleChangeAnswer}
      />
    </>
  );
});

export default PhoneQuestion;

PhoneQuestion.propTypes = {
  currentQuestionData: PropTypes.shape({}),
  withCorrection: PropTypes.bool.isRequired,
  isReadOnlyQuestion: PropTypes.bool.isRequired
};

PhoneQuestion.defaultProps = {
  currentQuestionData: {}
};