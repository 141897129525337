import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';
import { CloseButton } from './_CloseButton';

const ModalHeader = ({
  onClose, children, className
}) => (
  <DialogTitle
    className={className}
    id="modalHeader"
    style={{ paddingRight: '4rem' }}
  >
    {children}
    {onClose && (
      <CloseButton onClose={onClose} />
    )}
  </DialogTitle>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string
};

ModalHeader.defaultProps = {
  className: '',
  onClose: null
};

export default ModalHeader;