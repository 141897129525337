import { action, decorate, observable } from 'mobx';
import { ReferenceDataService } from 'services';
import { REF_DATA_MULTIPLE_MAP } from 'utils/constants';

export class ReferenceDataStore {
  constructor() {
    this.referenceData = null;
    this.accessModes = [];
  }

  /**
   * Fetches the module details.
   */
  loadReferenceData() {
    return ReferenceDataService.getReferenceData()
      .then(action((refData) => {
        const allRefData = refData;
        Object.values(refData).forEach((refD) => {
          allRefData[refD.code] = {
            ...refD,
            multiple: REF_DATA_MULTIPLE_MAP[refD.code]
          };
        });

        this.referenceData = allRefData;
      }));
  }

  getAccessModes() {
    return ReferenceDataService.getAccessModes()
      .then(action((accessModes) => {
        this.accessModes = accessModes;
      }))
      .catch(() => { this.accessModes = []; });
  }

  getRefData(key) {
    if (this.referenceData && this.referenceData[key]) {
      return this.referenceData[key];
    }
    return {
      multiple: REF_DATA_MULTIPLE_MAP[key]
    };
  }
}

decorate(ReferenceDataStore, {
  accessModes: observable,
  referenceData: observable,
  loadReferenceData: action
});

export const referenceDataStore = new ReferenceDataStore();