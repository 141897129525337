
import React from 'react';
import { SectionRadio } from '..';


export const GenericSectionFormatRadioImages = ({
  name, value, isChecked, onChange
}) => (
  <SectionRadio
    ariaLabel={value}
    height="auto"
    isChecked={isChecked}
    margin="1rem 0"
    name={name}
    padding="1rem"
    value={value}
    onChange={onChange}
  >
    <img alt={name} src={`${process.env.PUBLIC_URL}/assets/images/choices/${value}.png`} />
  </SectionRadio>
);