import React, { useCallback, useEffect, useState } from 'react';
import { faPlay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import {
  CardProgram, CardSummary, GenericFilters, PageHeader, PageSubtitle,
  SkeletonCardProgram, SkeletonCardSummary, TextError, Wrapper
} from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { transformObjectListToStringQueriesParam } from 'utils';
import { ROUTES } from 'utils/constants';
import shortid from 'shortid';

import { useSnackbar } from 'notistack';
import { translate } from 'utils/translation';
import { ProgramListFilters } from './ProgramListFilters';
import { ProgramListNav } from './ProgramListNav';

export const ProgramList = observer(() => {
  const { programStore, moduleStore, userStore } = useStores();
  const { programList = [] } = programStore;
  const { moduleList = [] } = moduleStore;
  const { isConnected } = userStore;
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState([]);

  const filterKey = 'programList';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);

    programStore.loadProgramList({ search, filters: filtersQueriesParams })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
    moduleStore.loadModuleList({
      search, size: 1000, filters: filtersQueriesParams, currentPage: 0
    })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  }, [programStore, moduleStore, search, filters, enqueueSnackbar]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <ProgramListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const displayProgramList = useCallback(() => (
    programList && programList.length > 0 ? programList.map((program) => (
      <Grid data-testid="program-list" item key={shortid.generate()} md={6} sm={12} xs={12}>
        <CardProgram
          to={ROUTES.PROGRAM(program.id)}
          {...program}
        />
      </Grid>
    )) : (
      <TextError data-testid="errors.noProgramList">{translate('errors.noProgramList')}</TextError>
    )
  ), [programList]);

  const displayModuleList = useCallback(() => (
    moduleList && moduleList.length > 0 ? moduleList.map((module) => (
      <Grid data-testid="module-list" item key={shortid.generate()} md={4} sm={12} xs={12}>
        <CardSummary
          icon={<FontAwesomeIcon icon={faPlay} style={{ marginRight: '1rem' }} />}
          to={ROUTES.MODULE(module.id)}
          {...module}
        />
      </Grid>
    )) : (
      <TextError>{translate('errors.noModuleList')}</TextError>
    )
  ), [moduleList]);

  return (
    <>
      {isConnected && (
        <ProgramListNav />
      )}

      <Wrapper>
        <PageHeader title="pageCatalog.title" />

        <GenericFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-catalog-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltip="pageCatalog.searchTooltip"
          withDrawer
        />

        <PageSubtitle subtitle="menu.programs" />

        {programStore.isLoading ? (
          <Grid container spacing={4}>
            <Grid item md={6} sm={12}><SkeletonCardProgram /></Grid>
            <Grid item md={6} sm={12}><SkeletonCardProgram /></Grid>
          </Grid>
        ) : (
          <Grid container data-testid="program-container" spacing={4}>
            {displayProgramList()}
          </Grid>
        )}

        <PageSubtitle subtitle="common.modules" />

        {moduleStore.isLoading ? (
          <Grid container spacing={4}>
            <Grid item md={4} sm={12}><SkeletonCardSummary /></Grid>
            <Grid item md={4} sm={12}><SkeletonCardSummary /></Grid>
            <Grid item md={4} sm={12}><SkeletonCardSummary /></Grid>
          </Grid>
        ) : (
          <Grid container data-testid="module-container" spacing={4}>
            {displayModuleList()}
          </Grid>
        )}
      </Wrapper>
    </>
  );
});

ProgramList.propTypes = {
};