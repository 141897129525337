/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["formData"] }] */
import React, { useCallback, useEffect, useState } from 'react';
import {
  ReorderableList
} from 'components';
import { SelectField, StyledListItem } from 'components/forms/inputs';
import { translate } from 'utils/translation';
import {
  Avatar, ListItemAvatar, ListItemText
} from '@material-ui/core';
import { ModuleService } from 'services';
import { API_URL_DOCUMENTS } from 'utils/constants';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { FormHeader } from 'components/forms';
import { getSmallDescription } from 'utils/helpers';

export const ProgramHeaderForm = observer(({
  updateProperty
}) => {
  const [allModules, setAllModules] = useState([]);
  const { programStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const { program: { moduleSummaries = [] } } = programStore;

  useEffect(() => {
    // TODO: limit size of response and allow backend filtering
    ModuleService.getAllModules({ size: 1000 }).then((response) => {
      setAllModules(response.content);
      setIsLoading(false);
    });
  }, []);

  const updateModuleSummaries = useCallback((newModSum) => {
    updateProperty('moduleSummaries', newModSum);
    runInAction('UpdateModuleSummaries', () => {
      programStore.updateModuleSummaries(newModSum);
    });
  }, [updateProperty, programStore]);

  const handleAddModules = useCallback((name, value) => {
    if (value) {
      const newModSum = [...moduleSummaries];
      newModSum.push({
        ...value
      });
      updateModuleSummaries(newModSum);
    }
  }, [moduleSummaries, updateModuleSummaries]);

  const handleDeleteModule = useCallback((mod) => {
    const newModSum = moduleSummaries.filter((modSum) => modSum.id !== mod.id);
    updateModuleSummaries(newModSum);
  }, [moduleSummaries, updateModuleSummaries]);

  const CustomFilter = useCallback((option, search) => (
    option.data.name.toLowerCase().includes(search.toLowerCase())
    || (option.data.description && option.data.description.toLowerCase().includes(search.toLowerCase()))
  ), []);

  const CustomOptionModuleSummaries = ({ data, innerProps }) => (
    <StyledListItem {...innerProps} style={{ cursor: 'pointer' }}>
      {data.logoURL && (
        <ListItemAvatar>
          <Avatar alt={data.name} src={`${API_URL_DOCUMENTS}${data.logoURL}`} />
        </ListItemAvatar>
      )}
      <ListItemText primary={data.name} secondary={getSmallDescription(data.description)} />
    </StyledListItem>
  );

  const allModulesAvailable = allModules.filter((mod) => moduleSummaries
    .filter((modSum) => modSum.id === mod.id).length === 0);

  return (
    <>
      <FormHeader title="forms.program.title" />

      <SelectField
        closeMenuOnSelect={false}
        components={{ Option: CustomOptionModuleSummaries }}
        filterOption={CustomFilter}
        isLoading={isLoading}
        label="forms.program.addModule"
        /* eslint-disable-next-line react/jsx-no-bind */
        loadingMessage={() => translate('common.loading')}
        messageNoOptions="warnings.noModulesAvailable"
        name="moduleSummaries"
        options={allModulesAvailable}
        placeholder="common.search"
        onChange={handleAddModules}
      />

      <ReorderableList
        list={moduleSummaries}
        title={`${translate('common.moduleSummaries')} :`}
        onDelete={handleDeleteModule}
        onReorder={updateModuleSummaries}
      />
    </>
  );
});