import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getTraineesList = (search = '') => RequestHelper.GET(API_ROUTES.SUBSCRIPTION_TRAINEES(search));

// POST
const createSubscription = (accessRequest) => RequestHelper.POST(API_ROUTES.SUBSCRIPTION_CREATE(), accessRequest);

const getTraineesListForProgram = ({
  contentId, filters = {}, page = 0
}) => RequestHelper.POST(API_ROUTES.TRAINEES_LIST_PROGRAM({ contentId, page }), filters);

const getTraineesListForModule = ({
  contentId, filters = {}, page = 0
}) => RequestHelper.POST(API_ROUTES.TRAINEES_LIST_MODULE({ contentId, page }), filters);

const importSubscriptions = ({
  file, programId, moduleId
}) => RequestHelper.POST(API_ROUTES.SUBSCRIPTION_IMPORT({ programId, moduleId }), file);

const exportTrainees = ({
  filters = {}, programId, moduleId
}) => RequestHelper.POST(API_ROUTES.SUBSCRIPTION_TRAINEES_EXPORT({ programId, moduleId }), filters);

// PUT
const updateSubscription = (id, dates) => RequestHelper.PUT(API_ROUTES.SUBSCRIPTION_UPDATE(id), dates);

// DELETE
const deleteSubscription = (id) => RequestHelper.DELETE(API_ROUTES.SUBSCRIPTION_DELETE(id));


export const SubscriptionService = {
  getTraineesList,
  createSubscription,
  getTraineesListForProgram,
  getTraineesListForModule,
  importSubscriptions,
  exportTrainees,
  updateSubscription,
  deleteSubscription
};