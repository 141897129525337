import React, { useCallback } from 'react';
import { asForm } from 'components/forms/hoc';
import PropTypes from 'prop-types';
import { sectionModel } from 'components/model/dataformModel';
import { observer } from 'mobx-react-lite';
import { GenericSectionForm } from '../GenericSectionForm/GenericSectionForm';
import { ModuleHeaderForm } from '../ModuleHeaderForm/ModuleHeaderForm';
import { ProgramHeaderForm } from '../ProgramHeaderForm/ProgramHeaderForm';

const ParentSectionForm = observer(({
  formData, updateProperty
}) => {
  const { title, sectionType } = formData;

  const renderSpecificForm = useCallback(() => {
    switch (sectionType) {
      case 'PROGRAM_HEADER':
        return (
          <ProgramHeaderForm
            formData={formData}
            sectionType={sectionType}
            title={title}
            updateProperty={updateProperty}
          />
        );
      case 'MODULE_HEADER':
        return (
          <ModuleHeaderForm
            formData={formData}
            sectionType={sectionType}
            title={title}
            updateProperty={updateProperty}
          />
        );
      case 'GENERIC_SECTION':
      default:
        return (
          <GenericSectionForm
            formData={formData}
            sectionType={sectionType}
            title={title}
            updateProperty={updateProperty}
          />
        );
    }
  }, [sectionType, formData, updateProperty, title]);

  return (
    renderSpecificForm()
  );
});

export default asForm(ParentSectionForm);

ParentSectionForm.propTypes = {
  formData: sectionModel.isRequired,
  updateProperty: PropTypes.func.isRequired
};