import { Grid } from '@material-ui/core';
import {
  BlueBlock, ButtonCreateSection, EditionContent, EditionPanel, ParentForm, ParentSection,
  SkeletonMain, Wrapper
} from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, {
  Fragment, useCallback, useEffect, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UserHelper } from 'utils';
import { EDITION_PANEL_TYPES, ROLE_GROUPS, ROUTES } from 'utils/constants';
import { SequenceEditionNav } from './SequenceEditionNav';
import { SequencePageTitle } from './SequencePageTitle/SequencePageTitle';

const SectionListContainer = styled.div`
  h1, h2, h3, h4, h5, h6 {
    color: var(--colored-subtitle-color);
  }

  ${(props) => props.editionMode && css`
    div[role="button"] {
      margin-bottom: 1rem;
    }
  `}
`;

export const SequenceDetails = observer(({
  programId, moduleId, sequenceId, isEditionMode, isCopyMode
}) => {
  const elementId = EDITION_PANEL_TYPES.SEQUENCE;

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { userStore, sequenceStore, editionStore } = useStores();
  const { isConnected } = userStore;
  const { editionMode, elementEdited } = editionStore;

  const [isTempEditionMode, setIsTempEditionMode] = useState(isEditionMode);

  const editSequence = useCallback(() => {
    if (editionStore.hasNewEditedElement(elementId)) {
      editionStore.setElementEdited(elementId, sequenceStore.sequence, EDITION_PANEL_TYPES.SEQUENCE, null, true);
    }
  }, [editionStore, sequenceStore.sequence, elementId]);

  const loadSequence = useCallback(() => sequenceStore.loadSequence(programId, moduleId, sequenceId, isCopyMode)
    .then(() => {
      editionStore.setElementEdited(elementId, sequenceStore.sequence, EDITION_PANEL_TYPES.SEQUENCE, null, true);
      // Already saved with no changes when opening edition mode
      editionStore.setLastSaved();
      sequenceStore.setSections();
    })
    .catch((err) => {
      isCopyMode && history.replace(ROUTES.ADMIN_SEQUENCE);
      enqueueSnackbar(err.message, { variant: 'error' });
    }), [sequenceStore, editionStore, programId, moduleId, sequenceId, elementId, enqueueSnackbar, history, isCopyMode]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    !isEditionMode && editionStore.reset();

    loadSequence();
    // eslint-disable-next-line
  }, [isEditionMode, editionStore]);

  useEffect(() => {
    isTempEditionMode && editionStore.setElementEdited(
      elementId, sequenceStore.sequence, EDITION_PANEL_TYPES.SEQUENCE, null, true
    );
  }, [sequenceStore.sequence, editionStore, elementId, isTempEditionMode]);

  if (sequenceStore.isLoading) {
    return <SkeletonMain />;
  }

  const {
    index, name, content, logoURL, description
  } = sequenceStore.sequence;
  const sections = content && content.sections;

  return (
    <section>
      {isConnected && UserHelper.hasAccessRight(ROLE_GROUPS.CONTENT_EDITORS) && (
        <SequenceEditionNav
          isCopyMode={isCopyMode}
          isEditionMode={isTempEditionMode}
          loadSequence={loadSequence}
          setIsTempEditionMode={setIsTempEditionMode}
          onEdit={editSequence}
        />
      )}

      <Grid container>
        <EditionPanel editionMode={editionMode}>
          <br />
          {isConnected && editionMode && elementEdited && elementEdited.elementId
            && <ParentForm {...elementEdited} />}
        </EditionPanel>
        <EditionContent
          editionMode={editionMode}
          elementId={elementId}
          onClick={editSequence}
        >
          <Wrapper>
            <BlueBlock
              discreet={!editionMode}
              focused={elementEdited && elementEdited.elementId === elementId}
              isParams
            >
              <SequencePageTitle
                color="var(--colored-title-color)"
                description={description || ''}
                logoURL={logoURL}
                number={index}
                title={name || ''}
              />
            </BlueBlock>

            <SectionListContainer editionMode={editionMode}>
              {editionMode ? (
                <>
                  {sections && sections.length > 0 && sections.map((section, currentIndex) => (
                    <Fragment key={section.index}>
                      <ButtonCreateSection
                        hasInfoMessage={currentIndex === 0}
                        section={section}
                        sections={sections}
                      />
                      <ParentSection
                        canDeleteSection
                        editionMode={editionMode}
                        section={section}
                        sections={sections}
                      />
                    </Fragment>
                  ))}

                  <ButtonCreateSection
                    sections={sections}
                  />
                </>
              )
                : (
                  <>
                    {sections && sections.length > 0 && sections.map((section) => (
                      <Fragment key={section.index}>
                        <ParentSection
                          canDeleteSection
                          editionMode={editionMode}
                          section={section}
                          sections={sections}
                        />
                      </Fragment>
                    ))}
                  </>
                )}

            </SectionListContainer>
          </Wrapper>
        </EditionContent>
      </Grid>
    </section>
  );
});

SequenceDetails.propTypes = {
  isCopyMode: PropTypes.bool,
  isEditionMode: PropTypes.bool,
  moduleId: PropTypes.string,
  sequenceId: PropTypes.string.isRequired
};

SequenceDetails.defaultProps = {
  isCopyMode: false,
  isEditionMode: false,
  moduleId: ''
};