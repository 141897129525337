import { getModuleContent } from '__fixtures__/section/section_constants';
import { API_ROUTES, LIST_SIZE } from 'utils/constants';
import { RequestHelper } from 'utils';

const getAllModules = ({
  // Display all modules on catalog page
  programId = '', search = '', page = 0, size = LIST_SIZE, filters = ''
} = {}) => RequestHelper.GET(API_ROUTES.MODULES({
  programId, search, filters, page, size
}));

const getModule = (programId, moduleId) => RequestHelper.GET(API_ROUTES.MODULE(programId, moduleId));

const getThemes = () => RequestHelper.GET(API_ROUTES.MODULE_THEMES());

// POST
const createModule = (programId = '') => RequestHelper.POST(API_ROUTES.MODULES({ programId }), {
  content: getModuleContent()
});

// PUT
const updateModule = (moduleHashId, module) => RequestHelper.PUT(
  API_ROUTES.MODULE_UPDATE(moduleHashId),
  module
);

// DELETE
const deleteModule = (moduleHashId) => RequestHelper.DELETE(
  API_ROUTES.MODULE_DELETE(moduleHashId)
);

export const ModuleService = {
  getAllModules,
  getModule,
  getThemes,
  createModule,
  updateModule,
  deleteModule
};