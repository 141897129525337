import { Grid, TextField as TextFieldMat } from '@material-ui/core';
import { InfoTooltip } from 'components';
import { asInput } from 'components/forms/hoc';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const TooltipWrapper = observer(({ tooltip, children }) => (
  <Grid alignItems="center" container>
    <Grid item xs={10}>
      {children}
    </Grid>
    <Grid item xs={2}>
      <InfoTooltip label={tooltip} />
    </Grid>
  </Grid>
));

export const TextField = ({
  name, value, label, onChange, tooltip, ...props
}) => {
  const input = (
    <TextFieldMat
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      label={label}
      margin="dense"
      name={name}
      value={value == null ? '' : value}
      variant="outlined"
      onChange={onChange}
      {...props}
    />
  );

  return tooltip ? <TooltipWrapper tooltip={tooltip}>{input}</TooltipWrapper> : input;
};

const InputField = observer(({
  tooltip, ...props
}) => {
  const input = (
    <TextField
      {...props}
    />
  );

  return tooltip ? <TooltipWrapper tooltip={tooltip}>{input}</TooltipWrapper> : input;
});

export default asInput(InputField);