import { BlueBlock } from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

export const EditableItem = observer(({
  parentHtmlId, index, type, formData, editionMode, render, onDelete, isRoot, ...props
}) => {
  const { editionStore } = useStores();
  const editableHtmlId = `${parentHtmlId}_${type}_${index}`;

  const isFocused = useCallback(
    () => editionStore.elementEdited && editionStore.elementEdited.elementId === editableHtmlId,
    [editableHtmlId, editionStore.elementEdited]
  );

  const editMe = useCallback((event) => {
    editionStore.setElementEdited(editableHtmlId, formData, type, onDelete, isRoot);
    event.stopPropagation();
  }, [editionStore, onDelete, isRoot, formData, type, editableHtmlId]);

  return (
    <BlueBlock
      discreet={!editionMode}
      focused={isFocused()}
      id={editableHtmlId}
      role="button"
      onClick={editMe}
      {...props}
    >
      {render(editableHtmlId)}
    </BlueBlock>
  );
});

EditableItem.propTypes = {
  editionMode: PropTypes.bool.isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number,
  onDelete: PropTypes.func,
  parentHtmlId: PropTypes.string,
  render: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isRoot: PropTypes.bool
};

EditableItem.defaultProps = {
  index: 0,
  isRoot: false,
  onDelete: null,
  parentHtmlId: ''
};