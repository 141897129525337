import { Avatar, Grid } from '@material-ui/core';
import { LeafBadge, Text } from 'components';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { API_URL_DOCUMENTS } from 'utils/constants';

const Header = styled(Grid).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  direction: 'column'
})`
  position: relative;
  margin: 2rem 0;

  h1 {
    font-size: 2.6rem;
    text-align: center;
  }

  @media(max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
  }
`;

export const SequencePageTitle = withRouter(({
  title, description, number, color, logoURL
}) => (
  <Header container>
    <Grid alignItems="center" container direction="row" justifyContent="center">
      <Grid item>
        <LeafBadge value={(number ?? 0) + 1} />
      </Grid>
      <Grid item>
        <Text
          as="h1"
          color={color}
          marginBottom="0"
          marginLeft="2rem"
          marginRight="2rem"
          textTransform="uppercase"
        >
          {title}
        </Text>
      </Grid>
      <Grid item>
        {logoURL && (
          <Avatar
            alt={title}
            src={`${API_URL_DOCUMENTS}${logoURL}`}
            style={{ width: '40px', height: '40px' }}
          />
        )}
      </Grid>
    </Grid>

    <Grid item>
      <Text>{description}</Text>
    </Grid>

  </Header>
));

SequencePageTitle.propTypes = {
  color: PropTypes.string,
  logoURL: PropTypes.string,
  number: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

SequencePageTitle.defaultProps = {
  color: '',
  logoURL: '',
  number: null
};